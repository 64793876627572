import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { terminatedEmployeesService } from '@/services'
import { TIndexedObject, ITerminatedEmployee } from '@/types'

@Module({ name: 'TerminatedEmployeesModule', namespaced: true })
export default class TerminatedEmployeesModule extends VuexModule {
  terminatedEmployees: readonly ITerminatedEmployee[] = []

  // G E T T E R S
  get indexedTerminated () {
    return this.terminatedEmployees.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {} as TIndexedObject<ITerminatedEmployee>)
  }

  /* M U T A T I O N S */
  @Mutation
  SET_TERMINATED_EMPLOYEES (payload: readonly ITerminatedEmployee[]) {
    this.terminatedEmployees = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_TERMINATED_EMPLOYEES', rawError: true })
  async getTerminatedEmployees () {
    return await terminatedEmployeesService.getTerminatedEmployees()
  }
}
