// D E T A I L S  T A B S
const reportDetailsWidth = { width: 'calc(100% - 250px)', minWidth: 'calc(100% - 250px)' }

const projectsSidebarRoutesNames = {
  projectCreate: 'projectCreate',
  projectDetails: 'projectDetails',
  projectInstallLabor: 'projectInstallLabor',
  projectParts: 'projectParts',
  projectSubcontracts: 'projectSubcontracts',
  projectWireConduit: 'projectWireConduit',
  projectRentals: 'projectRentals',
  projectTravelExpenses: 'projectTravelExpenses',
  projectRecurring: 'projectRecurring',
  projectCancellation: 'projectCancellation',
  projectCheckOut: 'projectCheckOut'
}
const projectsSidebarRoutes = [
  {
    name: projectsSidebarRoutesNames.projectCheckOut,
    path: '/project-:projectId?/check-out',
    meta: { width: '800px', group: '' }, // todo add group
    component: () => import('./project-check-out/ProjectCheckOut.vue')
  },
  {
    name: projectsSidebarRoutesNames.projectDetails,
    path: '/project-:projectId/details',
    component: () => import('./project-details/ProjectsDetails.vue'),
    redirect: { name: projectsSidebarRoutesNames.projectInstallLabor },
    meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' },
    children: [
      {
        name: projectsSidebarRoutesNames.projectInstallLabor,
        path: 'project-install-labor',
        component: () => import('./project-details/project-details-tabs/ProjectInstallLabor.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectParts,
        path: 'project-parts',
        component: () => import('./project-details/project-details-tabs/ProjectParts.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectSubcontracts,
        path: 'project-subcontracts',
        component: () => import('./project-details/project-details-tabs/ProjectSubcontracts.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectWireConduit,
        path: 'project-wire-conduit',
        component: () => import('./project-details/project-details-tabs/ProjectWireConduit.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectRentals,
        path: 'project-rentals',
        component: () => import('./project-details/project-details-tabs/ProjectRentals.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectTravelExpenses,
        path: 'project-travel-expenses',
        component: () => import('./project-details/project-details-tabs/ProjectTravelExpenses.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectRecurring,
        path: 'project-recurring',
        component: () => import('./project-details/project-details-tabs/ProjectRecurring.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      },
      {
        name: projectsSidebarRoutesNames.projectCancellation,
        path: 'project-cancellation',
        component: () => import('./project-details/project-details-tabs/ProjectCancellation.vue'),
        meta: { ...reportDetailsWidth, group: 'LDH_Projects_View' }
      }
    ]
  }
]

export {
  projectsSidebarRoutesNames,
  projectsSidebarRoutes
}
