import { RouteConfig } from 'vue-router'
import { dropShipSidebarRoutes, dropShipSidebarRoutesNames } from './drop-ship/sidebar/drop-ship.sidebar.routes'
import { projectsSidebarRoutes, projectsSidebarRoutesNames } from './projects/sidebar/projects.sidebar.routes'
import {
  serviceCallsSidebarRoutes,
  serviceCallsSidebarRoutesNames
} from './service-calls/sidebar/service-calls.sidebar.routes'
import { scheduleSidebarRoutes, scheduleSidebarRoutesNames } from './schedule/sidebar/schedule.sidebar.routes'
import { partSidebarRoutes, partSidebarRoutesNames } from '@/pages/tech-ops/parts/sidebar/parts.sidebar.routes'
import { unbillableSidebarRoutes, unbillableSidebarRoutesNames } from './unbillable/sidebar/unbillable.sidebar.routes'

export const techOpsSidebarRoutesNames = {
  ...dropShipSidebarRoutesNames,
  ...projectsSidebarRoutesNames,
  ...serviceCallsSidebarRoutesNames,
  ...scheduleSidebarRoutesNames,
  ...partSidebarRoutesNames,
  ...unbillableSidebarRoutesNames
}
export const techOpsSidebarRoutes: RouteConfig[] = [
  ...dropShipSidebarRoutes,
  ...projectsSidebarRoutes,
  ...serviceCallsSidebarRoutes,
  ...scheduleSidebarRoutes,
  ...partSidebarRoutes,
  ...unbillableSidebarRoutes
]
