import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { guardTermsService } from '@/services'
import {
  deepClone,
  parseSchedule
} from '@/helpers'

import {
  IGuardTermStatus,
  IGuardTermTypesType,
  IGuardTerm,
  IPostingApproval,
  IGuardTermSignature,
  IGuardTermByEmployeeId
} from '@/types'
import { modules } from '@/store'

@Module({ name: 'GuardTermsModule', namespaced: true })
export default class GuardTermsModule extends VuexModule {
  guardTermsLoading = false
  guardTerms = [] as readonly IGuardTerm[]
  guardTermsByEmployeeId: IGuardTermByEmployeeId[] = [] as IGuardTermByEmployeeId[]
  guardTermsStatuses: IGuardTermStatus[] = [] as IGuardTermStatus[]
  guardTermSignature: IGuardTermSignature = {} as IGuardTermSignature
  guardTermPostingsAttached: IPostingApproval[] = [] as IPostingApproval[]
  guardTermPostingsAttachedCopy: IPostingApproval[] = [] as IPostingApproval[]

  userProfileGuardTerms = [] as IGuardTermByEmployeeId[]

  get guardTermTypes () {
    return modules.collections.collections.guardTermTypes as IGuardTermTypesType[] || []
  }

  get selectedNewPostings () {
    return this.guardTermPostingsAttached.filter(posting => posting.isSelected)
  }

  /* M U T A T I O N S */
  @Mutation
  SET_GUARD_TERMS_LOADING (payload: boolean) {
    this.guardTermsLoading = payload
  }

  @Mutation
  SET_EMPLOYEE_GUARD_TERMS (payload: IGuardTermByEmployeeId[]) {
    this.guardTermsByEmployeeId = payload
  }

  @Mutation
  SET_GUARD_TERM_STATUSES (payload: { guardTermStatusTypes: IGuardTermStatus[] }) {
    this.guardTermsStatuses = payload.guardTermStatusTypes
  }

  @Mutation
  SET_POSTINGS_ATTACHED (payload: IPostingApproval[]) {
    this.guardTermPostingsAttached = payload
    this.guardTermPostingsAttachedCopy = deepClone(payload)
  }

  @Mutation
  DESELECT_POSTINGS_ATTACHED () {
    this.guardTermPostingsAttached.forEach(posting => {
      posting.isSelected = false
      posting.isPrimary = false
    })
  }

  @Mutation
  RESET_POSTINGS_ATTACHED () {
    this.guardTermPostingsAttached = []
  }

  @Mutation
  SET_POSTINGS_ATTACHED_COPY () {
    this.guardTermPostingsAttachedCopy = deepClone(this.guardTermPostingsAttached)
  }

  @Mutation
  SET_GUARD_TERMS (payload: readonly IGuardTerm[]) {
    this.guardTerms = payload
  }

  @Mutation
  SET_SIGNATURE (payload: any) {
    this.guardTermSignature = payload
  }

  @Mutation
  DESTROY_SIGNATURE () {
    this.guardTermSignature = {} as IGuardTermSignature
  }

  @Mutation
  SET_USER_PROFILE_GUARD_TERMS (payload: any) {
    this.userProfileGuardTerms = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_EMPLOYEE_GUARD_TERMS', rawError: true })
  async getEmployeeGuardTerms (employeeId: number) {
    return await guardTermsService.getActiveGuardTermsByEmployeeId(employeeId)
  }

  @Action({ commit: 'SET_POSTINGS_ATTACHED', rawError: true })
  async getPostingsAttached () {
    const res = await guardTermsService.getGuardTermPostingsAttached()
    if (res.length > 0 && res.length < 2) {
      res[0].primaryPosition = true
    }
    return res.map(posting => ({
      ...posting,
      isSelected: false,
      isDeletedLocally: false,
      isPrimary: posting.primaryPosition,
      schedule: parseSchedule(posting.schedule),
      newPosition: {
        postingId: posting.id,
        orientationDate: '',
        orientationHour: '',
        orientationMinute: '',
        startDate: '',
        unavailableDates: '',
        trainingConflicts: '',
        primaryPosition: posting.primaryPosition,
        positionNote: ''
      }
    } as IPostingApproval))
  }

  @Action({ commit: 'SET_GUARD_TERMS', rawError: true })
  async getGuardTerms (activeToggleState: boolean) {
    return activeToggleState
      ? await guardTermsService.getActiveGuardTerms()
      : await guardTermsService.getAllStatusGuardTerms()
  }

  @Action({ commit: 'SET_SIGNATURE', rawError: true })
  getGuardTermSignature (id: number) {
    return guardTermsService.getGuardTermSignature(id)
  }

  @Action({ commit: 'SET_USER_PROFILE_GUARD_TERMS', rawError: true })
  getGuardTermPostings (id: number) {
    return guardTermsService.getGuardTermPostings(id)
  }
}
