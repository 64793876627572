import { httpService } from '@/services'
import { IBaseModelNamed, IContractor, IOpportunity, IOpportunityTable } from '@/types'

class OpportunitiesService {
  getOpportunityTable (): Promise<IOpportunityTable[]> {
    return httpService.get('/Opportunity/GetOpportunityTable')
  }

  getOpportunities (): Promise<IOpportunity[]> {
    return httpService.get('/Opportunity/GetAll')
  }

  getActiveOpportunities (): Promise<IOpportunity[]> {
    return httpService.get('/Opportunity/GetAllActive')
  }

  getOpportunityById (id: string): Promise<IOpportunity> {
    return httpService.get('/Opportunity/GetById', { params: { id } })
  }

  createOpportunity (payload: any): Promise<''> {
    return httpService.post('/Opportunity/Create', payload)
  }

  updateOpportunity (payload: any): Promise<''> {
    return httpService.post('/Opportunity/Update', payload)
  }

  toggleOpportunity (id: string): Promise<''> {
    return httpService.post('/Opportunity/Toggle', {}, { params: { id } })
  }

  setupOpportunityForm (): Promise<any[]> {
    return httpService.get('/Opportunity/SetupOpportunityForm')
  }

  managementApprovalRequired (comparisonValue: string, comparisonValueType: string,
    contractApprovalTypeId: number): Promise<boolean> {
    return httpService.get('/Collection/ContractApprovalType/Compare', { params: { comparisonValue, comparisonValueType, contractApprovalTypeId } })
  }

  getContractors (): Promise<IContractor[]> {
    return httpService.get('/Contractor/GetContractors')
  }

  getLossReasonTypesByDivisionTypeId (id: number): Promise<any> {
    return httpService.get('/Collection/LossReasonType/GetByDivisionTypeId', { params: { id } })
  }

  getSelectableOpportunityStages (): Promise<any> {
    return httpService.get('/Collection/OpportunityStage/GetSelectableAsync')
  }

  getOpportunityNamesClosedWon (): Promise<IBaseModelNamed[]> {
    return httpService.get('/Opportunity/GetOpportunityNamesClosedWon')
  }
}

export const opportunitiesService = new OpportunitiesService()
