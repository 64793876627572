




























import { Component } from 'vue-property-decorator'
import { ConfirmMixin } from '@/mixins'

import Modal from '@/components/shared/Modal.vue'
import ButtonModule from '@/components/shared/form-controls/ButtonModule.vue'

@Component({
  name: 'UpdatedGroupsModal',
  components: {
    ButtonModule,
    Modal
  }
})
export default class UpdatedGroupsModal extends ConfirmMixin {
  async show () {
    return this.INIT_CONFIRM_PROMISE()
  }
}
