import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export class CurrentRouteMixin extends Vue {
  get $sidebarRoute () {
    return this.$sidebar.currentRoute
  }

  get $appRoute () {
    return this.$appRouter.currentRoute
  }
}
