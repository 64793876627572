import '@/plugins'
import Vue from 'vue'
import App from '@/App.vue'
import { store } from '@/store'
import { appRouter } from '@/router'
import { CurrentRouteMixin } from '@/mixins/routeMixin'
import '@/assets/icons/style.css'
import '@/assets/scss/main.scss'
import '@/filters'

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.observable(appRouter)
Vue.prototype.$appRouter = appRouter
Vue.mixin(CurrentRouteMixin)
Vue.config.productionTip = false

export default new Vue({
  el: '#app',
  router: appRouter,
  store,
  render: h => h(App)
})
