import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { collectionsService, paycheckService } from '@/services'
import { IPaycheck, IPaycheckStatusTypesType, IPaycheckTypesType } from '@/types'

@Module({ name: 'PaychecksModule', namespaced: true })
export default class PaychecksModule extends VuexModule {
  paychecksLoading = false
  paychecks: readonly IPaycheck[] = []
  selectedPaycheck = {} as IPaycheck
  paycheckStatusTypes: IPaycheckStatusTypesType[] = []
  paycheckTypes: IPaycheckTypesType[] = []
  paycheckStatusTypesActive: IPaycheckStatusTypesType[] = []
  paycheckTypesActive: IPaycheckTypesType[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_PAYCHECKS (payload: readonly IPaycheck[]) {
    this.paychecks = payload
  }

  @Mutation
  SET_SELECTED_PAYCHECK (payload: IPaycheck) {
    this.selectedPaycheck = payload
  }

  @Mutation
  SET_PAYCHECKS_LOADING (payload: boolean) {
    this.paychecksLoading = payload
  }

  @Mutation
  SET_PAYCHECK_STATUS_TYPES (payload: IPaycheckStatusTypesType[]) {
    this.paycheckStatusTypes = payload
  }

  @Mutation
  SET_PAYCHECK_TYPES (payload: IPaycheckTypesType[]) {
    this.paycheckTypes = payload
  }

  @Mutation
  SET_PAYCHECK_STATUS_TYPES_ACTIVE (payload: IPaycheckStatusTypesType[]) {
    this.paycheckStatusTypesActive = payload
  }

  @Mutation
  SET_PAYCHECK_TYPES_ACTIVE (payload: IPaycheckTypesType[]) {
    this.paycheckTypesActive = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_PAYCHECKS', rawError: true })
  async getPaychecks () {
    return await paycheckService.getPaychecks()
  }

  @Action({ commit: 'SET_SELECTED_PAYCHECK', rawError: true })
  async getPaycheckById (paycheckId: number) {
    return await paycheckService.getPaycheckById(paycheckId)
  }

  @Action({ commit: 'SET_PAYCHECK_STATUS_TYPES', rawError: true })
  async getPaycheckStatusTypes () {
    return await collectionsService.getCollection('paycheckStatusTypes')
  }

  @Action({ commit: 'SET_PAYCHECK_TYPES', rawError: true })
  async getPaycheckTypes () {
    return await collectionsService.getCollection('paycheckTypes')
  }

  @Action({ commit: 'SET_PAYCHECK_STATUS_TYPES_ACTIVE', rawError: true })
  async getActivePaycheckStatusTypes () {
    return await paycheckService.getActivePaycheckStatusTypes()
  }

  @Action({ commit: 'SET_PAYCHECK_TYPES_ACTIVE', rawError: true })
  async getActivePaycheckTypes () {
    return await paycheckService.getActivePaycheckTypes()
  }
}
