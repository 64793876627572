const assetsSidebarRouteNames = {
  assignedAssetDetails: 'assignedAssetDetails',
  assignedAssetCreate: 'assignedAssetCreate',
  assignedAssetEdit: 'assignedAssetEdit',
  assignedAssetNotes: 'assignedAssetNotes',
  assignedAssetReassign: 'assignedAssetReassign',
  assetDetails: 'assetDetails',
  assetCreate: 'assetCreate',
  assetEdit: 'assetEdit'
}

const assetsSidebarRoutes = [
  /* A S S I G N E D   A S S E T S */
  {
    name: assetsSidebarRouteNames.assignedAssetDetails,
    path: '/assigned-asset-:id/details',
    meta: { group: 'LDH_AssetToEmployee_View' },
    component: () => import('./assigned-assets-tab/AssignedAssetDetails.vue')
  },
  {
    name: assetsSidebarRouteNames.assignedAssetCreate,
    path: '/assigned-asset-create',
    meta: { group: 'LDH_AssetToEmployee_Add' },
    component: () => import('./assigned-assets-tab/AssignedAssetCreate.vue')
  },
  {
    name: assetsSidebarRouteNames.assignedAssetEdit,
    path: '/assigned-asset-:id/edit',
    meta: { group: 'LDH_AssetToEmployee_Update' },
    component: () => import('./assigned-assets-tab/AssignedAssetEdit.vue')
  },
  {
    name: assetsSidebarRouteNames.assignedAssetNotes,
    path: '/assigned-asset-:id/notes',
    meta: { group: 'LDH_AssetToEmployee_Update' },
    component: () => import('./assigned-assets-tab/AssignedAssetNotes.vue')
  },
  {
    name: assetsSidebarRouteNames.assignedAssetReassign,
    path: '/assigned-asset-:id/reassign',
    meta: { group: 'LDH_AssetToEmployee_Update' },
    component: () => import('./assigned-assets-tab/AssignedAssetReassign.vue')
  },
  /* A S S E T S */
  {
    name: assetsSidebarRouteNames.assetDetails,
    path: '/asset-:id/details',
    meta: { group: 'LDH_Asset_View' },
    component: () => import('./assets-tab/AssetDetails.vue')
  },
  {
    name: assetsSidebarRouteNames.assetCreate,
    path: '/asset-create',
    meta: { group: 'LDH_Asset_Add' },
    component: () => import('./assets-tab/AssetCreate.vue')
  },
  {
    name: assetsSidebarRouteNames.assetEdit,
    path: '/asset-:id/edit',
    meta: { group: 'LDH_Asset_Update' },
    component: () => import('./assets-tab/AssetEdit.vue')
  }
]

export {
  assetsSidebarRouteNames,
  assetsSidebarRoutes
}
