import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { TIndexedObject, IShortTicket } from '@/types'
import { ticketsService } from '@/services'

const ticketsRequests: TIndexedObject<() => Promise<IShortTicket[]>> = {
  unassigned: () => ticketsService.getUnassignedTickets(),
  allTickets: () => ticketsService.getAssignedTickets(),
  openAssignedToMe: () => ticketsService.getTicketsAssignedByCurrentUser(false),
  createdByMe: () => ticketsService.getTicketsRequestedByCurrentUser(false)
}

const getTicketStatus = (ticket: IShortTicket) => {
  if (ticket.isComplete) { return 'Closed' }
  if (ticket.isAssigned) { return 'Assigned' }
  return 'Unassigned'
}

@Module({ name: 'TicketsModule', namespaced: true })
export default class TicketsModule extends VuexModule {
  ticketsLoaded = false
  ticketsLoading = false

  activeTab = 'openAssignedToMe'
  ticketsByTab: TIndexedObject<IShortTicket[]> = {
    unassigned: [],
    allTickets: [],
    openAssignedToMe: [],
    createdByMe: []
  }

  get requestors () {
    const allRequestors = this.ticketsByTab[this.activeTab]
      .map(t => t.requestors)
      .join(';')
      .split(';')
      .map(req => req.trim())
      .filter(s => s.length > 0)
    return Array.from(new Set(allRequestors))
  }

  get assignments () {
    const allAssignments = this.ticketsByTab[this.activeTab]
      .map(t => t.assignedTo)
      .join(';')
      .split(';')
      .map(assign => assign.trim())
      .filter(s => s.length > 0)
    return Array.from(new Set(allAssignments))
  }

  get severities () {
    const severities = this.ticketsByTab[this.activeTab]
      .map(t => t.ticketSeverityDisplayName)
    return Array.from(new Set(severities))
  }

  get statuses () {
    const statuses = this.ticketsByTab[this.activeTab].map(getTicketStatus)
    return Array.from(new Set(statuses))
  }

  get categories () {
    const categories = this.ticketsByTab[this.activeTab]
      .map(t => t.departmentDisplayName)
    return Array.from(new Set(categories))
  }

  get subCategories () {
    const subCategories = this.ticketsByTab[this.activeTab].map(t => t.ticketSubCategoryDisplayName)
    return Array.from(new Set(subCategories))
  }

  get tickets () {
    return this.ticketsByTab[this.activeTab]
  }

  /* M U T A T I O N S */
  @Mutation
  SET_TICKETS ({ tab, tickets }: { tab: string; tickets: IShortTicket[] }) {
    this.ticketsByTab[tab] = tickets
    this.ticketsLoaded = true
  }

  @Mutation
  SET_TAB (tab: string) {
    this.activeTab = tab
  }

  @Mutation
  DELETE_TICKET (ticketId: number) {
    Object.keys(this.ticketsByTab).forEach(tab => {
      this.ticketsByTab[tab] = this.ticketsByTab[tab].filter(ticket => ticket.id !== ticketId)
    })
  }

  @Mutation
  LOADING (val: boolean) {
    this.ticketsLoading = val
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_TICKETS', rawError: true })
  async getTickets (tab: string) {
    return { tab, tickets: await ticketsRequests[tab]() }
  }

  @Action({ rawError: true })
  async updateTickets (tab?: string) {
    if (tab) {
      this.SET_TAB(tab)
    }
    this.LOADING(true)
    await this.getTickets(this.activeTab)
    for (let i = 0; i < this.tickets.length; i++) {
      this.tickets[i].ticketStatusType = getTicketStatus(this.tickets[i])
    }
    this.LOADING(false)
  }
}
