var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center flex-shrink-0 flex-wrap",on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"user-info-picture",class:[{
      'w-14 h-14': _vm.propSize.md,
      'w-8 h-8': _vm.propSize.sm,
      'order-last ml-3': _vm.propReversed,
      'mr-4': !_vm.propReversed
    }, _vm.pictureClass],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('image-click', _vm.picture)}}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.pictureLoading)?[_c('Skeleton',{staticClass:"h-full w-full",attrs:{"count":1,"item-class":"h-full w-full"}})]:_c('div',{staticClass:"w-full h-full flex items-center justify-center"},[(_vm.picture)?_c('img',{staticClass:"w-full h-full",staticStyle:{"object-fit":"cover"},attrs:{"src":("data:image;base64," + _vm.picture),"alt":"user-picture"}}):_c('span',[_vm._v(_vm._s(_vm.profileName.abbrv))])])],2)],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.loading)?_c('div',{key:'employee-loading'},[_c('Skeleton',{attrs:{"count":1,"item-class":"h-6 w-64 rounded-sm"}}),(_vm.showId)?_c('Skeleton',{attrs:{"count":1,"item-class":"h-2 mt-2 w-40 rounded-sm"}}):_vm._e(),(_vm.showEmail)?_c('Skeleton',{attrs:{"count":1,"item-class":"h-2 mt-2 w-48 rounded-sm"}}):_vm._e(),(_vm.isEmployeeProfile)?_c('div',{staticClass:"flex mt-2"},[_c('Skeleton',{attrs:{"count":1,"item-class":"h-3 w-3 rounded-full mr-3"}}),_c('Skeleton',{attrs:{"count":1,"item-class":"h-3 w-3 rounded-full mr-3"}}),_c('Skeleton',{attrs:{"count":1,"item-class":"h-3 w-3 rounded-full"}})],1):_vm._e()],1):_c('div',{key:'employee-loaded',class:{ 'text-right': _vm.propReversed}},[_c('p',{class:{
          'font-semibold text-13 leading-none': _vm.propSize.sm,
          'font-bold text-16': _vm.propSize.md,
        }},[_vm._v(" "+_vm._s(_vm.profileName.fullName)+" ")]),(_vm.showEmail)?_c('p',{staticClass:"text-basic-600",class:{
          'text-11 leading-none mt-1': _vm.propSize.sm,
          'text-12 leading-16': _vm.propSize.md,
        }},[_vm._v(" "+_vm._s(_vm.userProfileEmail)+" ")]):_vm._e(),(_vm.showId)?_c('div',[_c('div',{staticClass:"flex items-center text-12 leading-16 text-basic-600"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.isCandidateProfile ? 'Candidate' : 'Employee')+" ID:")]),(_vm.otherEmployeeIds.length > 1)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('p',{staticClass:"flex items-center text-12 leading-16 text-basic-600"},[_c('span',{staticClass:"hover:underline cursor-pointer mr-2",class:[ _vm.profileInfo.obsolete ? 'text-danger': 'hover:text-primary' ]},[_vm._v(_vm._s(_vm.profileInfo.id))]),_c('Icon',{staticClass:"icon-arrow-ios-down",attrs:{"size":"5"}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.otherEmployeeIds),function(id,i){return _c('el-dropdown-item',{key:("employee other id " + i),nativeOn:{"click":function($event){return _vm.getOtherEmployee(id)}}},[_c('span',{class:{ 'text-primary': !_vm.profileInfo.obsolete && id === _vm.profileInfo.id }},[_vm._v(_vm._s(id))])])}),1)],1):_c('span',{class:{'text-danger': _vm.profileInfo.obsolete}},[_vm._v(_vm._s(_vm.profileInfo ? _vm.profileInfo.id : ''))])],1)]):_vm._e(),(_vm.isEmployeeProfile)?_c('div',{staticClass:"flex mt-2"},_vm._l((_vm.employeeProfileTags),function(tag){return _c('el-tooltip',{key:tag.id,attrs:{"effect":"dark","content":((tag.displayName && tag.displayName.trim()) + ": " + (tag.description && tag.description.trim())),"placement":"top"}},[_c('CircleMarker',{staticClass:"mr-3 border border-basic-400",attrs:{"explicit-color":tag.color}})],1)}),1):_vm._e()])]),_vm._t("actions")],2)}
var staticRenderFns = []

export { render, staticRenderFns }