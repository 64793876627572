import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'

export const naming = [{
  component: 'InputModule',
  model: 'displayName',
  disabled: false,
  required: true,
  props: { label: 'Display Name', placeholder: 'Display Name', class: 'w-full mt-4' }
}, {
  component: 'InputModule',
  model: 'name',
  disabled: true,
  required: false,
  props: { label: 'Name', placeholder: 'Name', class: 'w-full mt-4' }
}]

export const namingWithoutDisplay = [{
  component: 'InputModule',
  model: 'name',
  disabled: false,
  required: true,
  props: { label: 'Name', placeholder: 'Name', class: 'w-full mt-4' }
}]

export const checkboxFormatter = (label: string[]): any => {
  return label.map((i) => ({
    component: 'CheckboxModule',
    model: i.split('_')[0],
    props: { label: i.split('_')[1], class: 'w-1/2 mt-4' }
  }))
}

export const collectionsGeneral: ICollectionConfig[] = [{
  label: 'Address State',
  group: 'AddressState',
  getEndpoint: '/AddressState/GetStates',
  saveEndpoint: '/AddressState/SaveState',
  updateEndpoint: '/AddressState/UpdateState',
  collectionName: collectionNames.addressStates,
  headings: [
    { label: 'Abbr', value: 'abbreviation', sortable: true, width: 80, align: 'center' }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'abbreviation',
    required: true,
    props: { label: 'Abbreviation', placeholder: 'Abbreviation', class: 'w-full mt-4' }
  }]
},

{
  label: 'Asset Type',
  group: 'AssetType',
  getEndpoint: '/AssetType/Get',
  saveEndpoint: '/AssetType/Save',
  updateEndpoint: '/AssetType/Update',
  collectionName: collectionNames.assetTypes,
  form: [...naming]
},

{
  label: 'Comment Type',
  group: 'CommentType',
  getEndpoint: '/CommentType/GetCommentTypes',
  saveEndpoint: '/CommentType/SaveCommentType',
  updateEndpoint: '/CommentType/UpdateCommentType',
  toggleEndpoint: '/CommentType/ToggleCommentType',
  collectionName: collectionNames.commentTypes,
  form: [...naming]
},

{
  label: 'Credit Card Type',
  group: 'CreditCardType',
  getEndpoint: '/CreditCardType/GetAll',
  getAllActive: '/CreditCardType/GetAllActive',
  saveEndpoint: '/CreditCardType/Create',
  updateEndpoint: '/CreditCardType/Update',
  toggleEndpoint: '/CreditCardType/Toggle',
  collectionName: collectionNames.creditCardTypes,
  form: [...naming]
},

{
  label: 'Department',
  group: 'Department',
  getEndpoint: '/Department/GetDepartments',
  saveEndpoint: '/Department/SaveDepartment',
  updateEndpoint: '/Department/UpdateDepartment',
  toggleEndpoint: '/Department/ToggleDepartment',
  collectionName: collectionNames.departments,
  headings: [
    { label: 'View Group Name', value: 'ticketViewGroupName', sortable: true, width: 300 },
    { label: 'Update Group Name', value: 'ticketUpdateGroupName', sortable: true, width: 300 },
    { label: 'Delete Group Name', value: 'ticketDeleteGroupName', sortable: true, width: 300 },
    { label: 'Asset Owner Group Name', value: 'assetOwnerGroupName', sortable: true, width: 300 },
    { label: 'Knowledge Base Group Name', value: 'knowledgeBaseGroupName', sortable: true, width: 300 }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'ticketViewGroupName',
    props: { label: 'View Group Name', placeholder: 'View Group Name', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'ticketUpdateGroupName',
    props: { label: 'Update Group Name', placeholder: 'Update Group Name', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'ticketDeleteGroupName',
    props: { label: 'Delete Group Name', placeholder: 'Delete Group Name', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'assetOwnerGroupName',
    props: { label: 'Asset Owner Group Name', placeholder: 'Asset Owner Group Name', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'knowledgeBaseGroupName',
    props: { label: 'Knowledge Base Group Name', placeholder: 'Knowledge Base Group Name', class: 'w-full mt-4' }
  }]
},

{
  label: 'Direct Deposit Account',
  group: 'DirectDepositType',
  getEndpoint: '/DirectDepositType/GetDirectDepositAccountTypes',
  saveEndpoint: '/DirectDepositType/SaveDirectDepositAccountType',
  updateEndpoint: '/DirectDepositType/UpdateDirectDepositAccountType',
  toggleEndpoint: '/DirectDepositType/ToggleDirectDepositAccountType',
  collectionName: collectionNames.directDepositTypes,
  form: [...naming]
},

{
  label: 'EEO Type',
  group: 'EeoType',
  getEndpoint: '/EeoType/GetEeoTypes',
  saveEndpoint: '/EeoType/SaveEeoType',
  updateEndpoint: '/EeoType/UpdateEeoType',
  toggleEndpoint: '/EeoType/ToggleEeoType',
  collectionName: collectionNames.eeoTypes,
  form: [...naming]
},

{
  label: 'Email Type',
  group: 'EmailType',
  getEndpoint: '/EmailType/GetEmailTypes',
  saveEndpoint: '/EmailType/SaveEmailType',
  updateEndpoint: '/EmailType/UpdateEmailType',
  toggleEndpoint: '/EmailType/ToggleEmailType',
  collectionName: collectionNames.emailTypes,
  form: [...naming]
},

{
  label: 'Facility Type',
  group: 'FacilityType',
  getEndpoint: '/FacilityType/GetAll',
  getAllActive: '/FacilityType/GetAllActive',
  saveEndpoint: '/FacilityType/Create',
  updateEndpoint: '/FacilityType/Update',
  toggleEndpoint: '/FacilityType/Toggle',
  collectionName: collectionNames.facilityTypes,
  form: [...naming]
},

{
  label: 'Gender',
  group: 'Gender',
  getEndpoint: '/Gender/GetGenders',
  saveEndpoint: '/Gender/SaveGender',
  updateEndpoint: '/Gender/UpdateGender',
  toggleEndpoint: '/Gender/ToggleGender',
  collectionName: collectionNames.genders,
  form: [...naming]
},

{
  label: 'Interview Type',
  group: 'InterviewType',
  getEndpoint: '/InterviewType/GetInterviewTypes',
  saveEndpoint: '/InterviewType/SaveInterviewType',
  updateEndpoint: '/InterviewType/UpdateInterviewType',
  toggleEndpoint: '/InterviewType/ToggleInterviewType',
  collectionName: collectionNames.interviewTypes,
  form: [...naming]
},

{
  label: 'Marital Status Type',
  group: 'MaritalStatusType',
  getEndpoint: '/MaritalStatusType/GetMaritalStatusTypes',
  saveEndpoint: '/MaritalStatusType/SaveMaritalStatusType',
  updateEndpoint: '/MaritalStatusType/UpdateMaritalStatusType',
  toggleEndpoint: '/MaritalStatusType/ToggleMaritalStatusType',
  collectionName: collectionNames.maritalStatusTypes,
  form: [...naming]
},

{
  label: 'Message Template',
  group: 'TextingMessageTemplate',
  getEndpoint: '/TextingMessageTemplate/GetAll',
  saveEndpoint: '/TextingMessageTemplate/Create',
  updateEndpoint: '/TextingMessageTemplate/Update',
  toggleEndpoint: '/TextingMessageTemplate/Toggle',
  collectionName: collectionNames.textingMessageTemplates,
  headings: [{ label: 'Template', value: 'data', sortable: true, minWidth: 300 }],
  form: [...naming, {
    component: 'TextareaModule',
    model: 'data',
    props: { label: 'Template', placeholder: 'Template', class: 'w-full mt-4' }
  }]
},

{
  label: 'Notification Email',
  group: 'NotificationEmail',
  getEndpoint: '/NotificationEmail/GetNotificationEmails',
  saveEndpoint: '/NotificationEmail/SaveNotificationEmail',
  updateEndpoint: '/NotificationEmail/UpdateNotificationEmail',
  toggleEndpoint: '/NotificationEmail/ToggleNotificationEmail',
  formWrapClass: 'h-full flex-no-wrap flex-col',
  collectionName: collectionNames.notificationEmails,
  form: [...naming, {
    component: 'SimpleEditor',
    model: 'body',
    props: { label: 'Notification', class: 'flex-1 w-full mt-4' }
  }]
},

{
  label: 'Phone Type',
  group: 'PhoneType',
  getEndpoint: '/PhoneType/GetPhoneTypes',
  saveEndpoint: '/PhoneType/SavePhoneType',
  updateEndpoint: '/PhoneType/UpdatePhoneType',
  toggleEndpoint: '/PhoneType/TogglePhoneType',
  collectionName: collectionNames.phoneTypes,
  form: [...naming]
},

{
  label: 'Posting Status Type',
  group: 'PostingStatus',
  getEndpoint: '/PostingStatus/GetPostingStatusTypes',
  saveEndpoint: '/PostingStatus/SavePostingStatusType',
  updateEndpoint: '/PostingStatus/UpdatePostingStatusType',
  toggleEndpoint: '/PostingStatus/TogglePostingStatusType',
  collectionName: collectionNames.postingStatuses,
  headings: [
    { label: 'Default', ...booleanFormatter('defaultValue') },
    { label: 'Training', ...booleanFormatter('training') },
    { label: 'Template', ...booleanFormatter('template') },
    { label: 'Filled', ...booleanFormatter('filled') },
    { label: 'Orientation', ...booleanFormatter('orientation') },
    { label: 'Hired', ...booleanFormatter('hired') }
  ],
  cellClass: ['defaultValue', 'training', 'template', 'filled', 'orientation', 'hired'],
  form: [...naming,
    { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'training', props: { label: 'Training', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'template', props: { label: 'Template', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'filled', props: { label: 'Filled', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'orientation', props: { label: 'Orientation', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'hired', props: { label: 'Hired', class: 'w-1/2 mt-4' } }
  ]
},

{
  label: 'Priority Type',
  group: 'PriorityType',
  getEndpoint: '/PriorityType/GetPriorityTypes',
  saveEndpoint: '/PriorityType/SavePriorityType',
  updateEndpoint: '/PriorityType/UpdatePriorityType',
  toggleEndpoint: '/PriorityType/TogglePriorityType',
  collectionName: collectionNames.priorityTypes,
  form: [...naming]
},

{
  label: 'Race Type',
  group: 'RaceType',
  getEndpoint: '/RaceType/GetRaceTypes',
  saveEndpoint: '/RaceType/SaveRaceType',
  updateEndpoint: '/RaceType/UpdateRaceType',
  toggleEndpoint: '/RaceType/ToggleRaceType',
  collectionName: collectionNames.raceTypes,
  form: [...naming]
},

{
  label: 'Referral Type',
  group: 'ReferralType',
  getEndpoint: '/ReferralType/GetReferralTypes',
  saveEndpoint: '/ReferralType/SaveReferralType',
  updateEndpoint: '/ReferralType/UpdateReferralType',
  toggleEndpoint: '/ReferralType/ToggleReferralType',
  collectionName: collectionNames.referralTypes,
  form: [...naming]
},

{
  label: 'Requisition Type',
  group: 'RequisitionType',
  getEndpoint: '/RequisitionType/GetRequisitionTypes',
  saveEndpoint: '/RequisitionType/SaveRequisitionType',
  updateEndpoint: '/RequisitionType/UpdateRequisitionType',
  toggleEndpoint: '/RequisitionType/ToggleRequisitionType',
  collectionName: collectionNames.requisitionTypes,
  form: [...naming]
},

{
  label: 'Suffix',
  group: 'Suffix',
  getEndpoint: '/Suffix/GetSuffixes',
  saveEndpoint: '/Suffix/SaveSuffix',
  updateEndpoint: '/Suffix/UpdateSuffix',
  toggleEndpoint: '/Suffix/ToggleSuffix',
  collectionName: collectionNames.suffix,
  form: [...naming]
},

{
  label: 'Tax Rate',
  group: 'TaxRate',
  getEndpoint: '/TaxRate/GetTaxRates',
  saveEndpoint: '/TaxRate/SaveTaxRate',
  updateEndpoint: '/TaxRate/UpdateTaxRate',
  toggleEndpoint: '/TaxRate/ToggleTaxRate',
  collectionName: collectionNames.taxRates,
  noName: true,
  headings: [
    { label: 'State', value: 'state', sortable: true, width: 120 },
    { label: 'County', value: 'county', sortable: true, minWidth: 200 },
    { label: 'Labor tax', value: 'taxForLabor', sortable: true, width: 120 },
    { label: 'Part tax', value: 'taxForPart', sortable: true, width: 120 }
  ],
  form: [{
    component: 'InputModule',
    model: 'county',
    props: { label: 'County', placeholder: 'County', class: 'w-full mt-4' }
  }, {
    component: 'SelectModule',
    model: 'state',
    options: 'taxRateBlanks',
    props: {
      optionKey: 'text',
      valueKey: 'value',
      label: 'State',
      placeholder: 'Select state',
      class: 'w-full mt-4'
    }
  }, {
    component: 'InputModule',
    model: 'taxForLabor',
    props: { label: 'Labor Rate', placeholder: 'Labor Rate', class: 'w-full mt-4', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'taxForPart',
    props: { label: 'Parts Rate', placeholder: 'Parts Rate', class: 'w-full mt-4', type: 'number' }
  }]
},

{
  label: 'Title',
  group: 'Title',
  getEndpoint: '/Title/GetTitles',
  saveEndpoint: '/Title/SaveTitle',
  updateEndpoint: '/Title/UpdateTitle',
  toggleEndpoint: '/Title/ToggleTitle',
  collectionName: collectionNames.titles,
  form: [...naming]
},

{
  label: 'Transportation Type',
  group: 'TransportationType',
  getEndpoint: '/TransportationType/GetTransportationTypes',
  saveEndpoint: '/TransportationType/SaveTransportationType',
  updateEndpoint: '/TransportationType/UpdateTransportationType',
  toggleEndpoint: '/TransportationType/ToggleTransportationType',
  collectionName: collectionNames.transportationTypes,
  form: [...naming]
},

{
  label: 'Unbillable Category',
  group: 'UnbillableCategory',
  getEndpoint: '/UnbillableCategory/GetAll',
  saveEndpoint: '/UnbillableCategory/Create',
  updateEndpoint: '/UnbillableCategory/Update',
  toggleEndpoint: '/UnbillableCategory/Toggle',
  collectionName: collectionNames.unbillableCategories,
  form: [...naming]
},

{
  label: 'Division Type',
  group: 'Collections',
  getEndpoint: '/Collection/DivisionType/GetAll',
  saveEndpoint: '/Collection/DivisionType/Save',
  updateEndpoint: '/Collection/DivisionType/Update',
  toggleEndpoint: '/Collection/DivisionType/Toggle',
  collectionName: collectionNames.divisionTypes,
  noName: true,
  form: [...namingWithoutDisplay]
},

{
  label: 'Billing Level Type',
  group: 'Collections',
  getEndpoint: '/Collection/BillingLevelType/GetAll',
  saveEndpoint: '/Collection/BillingLevelType/Save',
  updateEndpoint: '/Collection/BillingLevelType/Update',
  toggleEndpoint: '/Collection/BillingLevelType/Toggle',
  collectionName: collectionNames.billingLevelTypes,
  noName: true,
  form: [...namingWithoutDisplay]
},

{
  label: 'Loss Reason Type',
  group: 'Collections',
  getEndpoint: '/Collection/LossReasonType/GetCollection',
  saveEndpoint: '/Collection/LossReasonType/Save',
  updateEndpoint: '/Collection/LossReasonType/Update',
  toggleEndpoint: '/Collection/LossReasonType/Toggle',
  collectionName: collectionNames.lossReasonTypes,
  noName: true,
  headings: [{ label: 'Business Division', value: 'divisionTypeName', sortable: true, width: 250 }],
  form: [{
    component: 'InputModule',
    model: 'name',
    required: true,
    props: { label: 'Name', placeholder: 'Name', class: 'w-full mt-4' }
  }, {
    component: 'SelectModule',
    model: 'divisionTypeId',
    options: 'divisionTypes',
    required: true,
    props: { optionKey: 'name', valueKey: 'id', label: 'Business Division', placeholder: 'Business Division', class: 'w-full mt-4' }
  }]
},

{
  label: 'Opportunity Stage',
  group: 'Collections',
  getEndpoint: '/Collection/OpportunityStage/GetAll',
  saveEndpoint: '/Collection/OpportunityStage/Save',
  updateEndpoint: '/Collection/OpportunityStage/Update',
  toggleEndpoint: '/Collection/OpportunityStage/Toggle',
  collectionName: collectionNames.opportunityStages,
  noName: true,
  headings: [{
    label: 'Is Selectable',
    value: 'isSelectable',
    sortable: true,
    minWidth: 75,
    align: 'center',
    formatter: (i: any) => {
      if (!('isSelectable' in i) || i.isSelectable === null) return '-'
      return i.isSelectable ? 'YES' : 'NO'
    }
  },
  { label: 'Workflow Order By', value: 'workflowOrderBy', minWidth: 75 }],
  form: [
    {
      component: 'InputModule',
      model: 'name',
      required: true,
      props: { label: 'Name', placeholder: 'Name', class: 'w-full mt-4' }
    },
    { component: 'CheckboxModule', model: 'isSelectable', props: { label: 'Is Selectable', class: 'w-1/2 mt-4' } },
    {
      component: 'InputModule',
      model: 'workflowOrderBy',
      required: true,
      props: { label: 'Workflow Order By', placeholder: 'Workflow Order By', class: 'w-full mt-4', type: 'decimal' }
    }]
},
{
  label: 'Contract Approval Type To Role',
  group: 'Collections',
  getEndpoint: '/Collection/ContractApprovalTypeToRole/GetAll',
  saveEndpoint: '/Collection/ContractApprovalTypeToRole/Save',
  updateEndpoint: '/Collection/ContractApprovalTypeToRole/Update',
  toggleEndpoint: '/Collection/ContractApprovalTypeToRole/Toggle',
  collectionName: collectionNames.contractApprovalTypesToRole,
  noNames: true,
  headings: [{ label: 'Contract Approval Type', value: 'contractApprovalTypeName', sortable: true, minWidth: 250 },
    { label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 250 }],
  form: [{
    component: 'SelectModule',
    model: 'contractApprovalTypeId',
    required: true,
    options: 'contractApprovalTypes',
    props: { optionKey: 'name', valueKey: 'id', label: 'Contract Approval Type', placeholder: 'Contract Approval Type', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'securityGroupName',
    required: true,
    props: { label: 'Security Group Name', placeholder: 'Security Group Name', class: 'w-full mt-4' }
  }]
},
{
  label: 'Import Document Type',
  group: 'ImportDocumentType',
  getEndpoint: '/ImportDocumentType/GetAll',
  saveEndpoint: '/ImportDocumentType/Create',
  updateEndpoint: '/ImportDocumentType/Update',
  toggleEndpoint: '/ImportDocumentType/Toggle',
  collectionName: collectionNames.importDocumentTypes,
  form: [...naming]
}, {
  label: 'Document Status',
  group: 'Collections',
  getEndpoint: '/DocumentStatus/GetAll',
  saveEndpoint: '/DocumentStatus/Create',
  updateEndpoint: '/DocumentStatus/Update',
  toggleEndpoint: '/DocumentStatus/Toggle',
  collectionName: collectionNames.documentStatuses,
  noName: true,
  form: [...namingWithoutDisplay]
}]
