import { httpService } from '@/services/http'
import { IVendor, IVendorSearch } from '@/types'

class VendorsService {
  getVendors (): Promise<IVendor[]> {
    return httpService.get('/Vendor/GetVendors')
  }

  getVendorById (id: string): Promise<IVendor> {
    return httpService.get('/Vendor/GetVendorById', { params: { id } })
  }

  search (term: string): Promise<IVendorSearch[]> {
    return httpService.get('/Vendor/Search', { params: { term } })
  }

  createVendor (payload: any): Promise<''> {
    return httpService.post('/Vendor/SaveVendor', payload)
  }

  updateVendor (payload: any): Promise<''> {
    return httpService.post('/Vendor/UpdateVendor', payload)
  }

  toggleVendor (id: number): Promise<''> {
    return httpService.post('/Vendor/ToggleVendor', {}, { params: { id } })
  }
}

export const vendorsService = new VendorsService()
