import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { collectionsService, licensesService } from '@/services'
import { ILicense, ILicenseStatus, ILicenseType } from '@/types'

@Module({ name: 'LicensesModule', namespaced: true })
export default class LicensesModule extends VuexModule {
  licensesLoaded = false
  licensesLoading = false
  licenses: readonly ILicense[] = []
  licensesActiveStatuses: ILicenseStatus[] = []
  licensesActiveTypes: ILicenseType[] = []
  licenseTypes: ILicenseType[] = []
  licenseStatusTypes: ILicenseStatus[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_LICENSES_LOADING (payload: boolean) {
    this.licensesLoading = payload
  }

  @Mutation
  SET_LICENSES (payload: readonly ILicense[]) {
    this.licenses = payload
    this.licensesLoaded = true
  }

  @Mutation
  SET_LICENSE_TYPES (payload: ILicenseType[]) {
    this.licenseTypes = payload
  }

  @Mutation
  SET_LICENSE_STATUS_TYPES (payload: ILicenseStatus[]) {
    this.licenseStatusTypes = payload
  }

  @Mutation
  SET_LICENSES_ACTIVE_STATUSES (licensesActiveStatuses: ILicenseStatus[]) {
    this.licensesActiveStatuses = licensesActiveStatuses
  }

  @Mutation
  SET_LICENSES_ACTIVE_TYPES (licensesActiveTypes: ILicenseType[]) {
    this.licensesActiveTypes = licensesActiveTypes
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_LICENSES', rawError: true })
  async getLicenses (activeToggleState: boolean) {
    return await licensesService.getLicenses(activeToggleState)
  }

  @Action({ commit: 'SET_LICENSES_ACTIVE_STATUSES', rawError: true })
  async getLicensesActiveStatuses (id?: number) {
    return id
      ? await licensesService.getLicenseStatusWorkflowById(id)
      : await licensesService.getLicensesActiveStatuses()
  }

  @Action({ commit: 'SET_LICENSES_ACTIVE_TYPES', rawError: true })
  async getLicensesActiveTypes () {
    return await licensesService.getLicensesActiveTypes()
  }

  @Action({ commit: 'SET_LICENSE_TYPES', rawError: true })
  async getLicenseTypes () {
    return await collectionsService.getCollection('licenseTypes')
  }

  @Action({ commit: 'SET_LICENSE_STATUS_TYPES', rawError: true })
  async getLicenseStatusTypes () {
    return await collectionsService.getCollection('licenseStatusTypes')
  }
}
