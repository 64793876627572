



































































































































import { deepClone, uniqueHeaderFilter } from '@/helpers'
import { modules } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import InputModule from '@/components/shared/form-controls/InputModule.vue'
import CheckboxModule from '@/components/shared/form-controls/CheckboxModule.vue'
import DatePickerModule from '@/components/shared/form-controls/DatePickerModule.vue'
import Icon from '@/components/shared/Icon.vue'
import { IFilterPayload, IFilter } from './table-header-filter.types'

@Component({
  name: 'TableHeaderFilter',
  components: {
    InputModule,
    CheckboxModule,
    DatePickerModule,
    Icon
  }
})

export default class TableHeaderFilter extends Vue {
  /* R E F S */

  /* P R O P S */
  @Prop({ default: () => [] }) values!: any[]
  @Prop({ default: () => true }) isFilterable!: boolean
  @Prop() filterName!: string
  @Prop({ default: () => [] }) formattedValues!: [{ label: any; value: any }]

  /* D A T A */
  searchString: any = ''
  editingFilteredValues: string[] = []
  editingBooleanFilteredValues: boolean[] = []
  editingNumberFrom: any
  editingNumberTo: any
  editingDateFrom: any
  editingDateTo: any
  editingExcludeSelected = false

  loading = false

  /*
  Passing Strings
  2022-01-01T00:00:00.000
  2022-01-01T00:00:00Z
  2022-01-01T00:00:00
  01/01/2022
  1/1/2022
  */
  dateTimeRegex = /^(\d{1,2}[/]\d{1,2}[/]\d{4}|\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([.]\d{3}|[Z]?))/

  selectableValues: any[] = this.isLargerThanCap ? this.uniqueValues.slice(0, this.filterCap) : this.uniqueValues
  formattedSelectableValues: any[] = this.isLargerThanCap && this.uniqueFormattedValues
    ? this.uniqueFormattedValues.slice(0, this.filterCap) : this.uniqueFormattedValues

  /* C O M P U T E D */
  get configurations () {
    return modules.configurations || []
  }

  get headerFilters () {
    return modules.tableHeaderFilters.filters || []
  }

  get filterExists () {
    return this.headerFilters[`${this.filterName}`]
  }

  get filteredValues () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[1].values || []
    } else {
      return []
    }
  }

  get booleanFilteredValues () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[1].values || []
    } else {
      return []
    }
  }

  get numberFrom () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[1].numberFrom || ''
    } else {
      return ''
    }
  }

  get numberTo () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[1].numberTo || ''
    } else {
      return ''
    }
  }

  get dateFrom () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[1].dateFrom || ''
    } else {
      return ''
    }
  }

  get dateTo () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[1].dateTo || ''
    } else {
      return ''
    }
  }

  get excludeSelected () {
    if (this.filterExists) {
      return Object.values(this.headerFilters[`${this.filterName}`])[2] || ''
    } else {
      return ''
    }
  }

  get filterCap () {
    // return Number(modules.configurations.configurations.filter((f: any) => f.name === 'TableConstructor:HeaderFilterCap')[index].value) || 25
    return 25
  }

  get hasFormattedValues () {
    return this.formattedValues.length > 0 || false
  }

  get uniqueFormattedValues () {
    if (this.formattedValues) {
      return this.formattedValues.filter((val: { label: any; value: any }, index, self) =>
        index === self.findIndex((t) => (
          t.label === val.label
        ))).sort((a: { label: any; value: any }, b: { label: any; value: any }) => a.label < b.label ? -1 : 1)
    } else {
      return []
    }
  }

  get uniqueValues () {
    return uniqueHeaderFilter(this.values).sort((a: any, b: any) => a < b ? -1 : 1)
  }

  get numUniqueValues () {
    if (this.hasFormattedValues) {
      return this.uniqueFormattedValues.length
    } else {
      return this.uniqueValues.length
    }
  }

  get filterType () {
    // Find first non-null index in the array
    let index
    if (this.hasFormattedValues) {
      index = this.formattedValues.findIndex(val => val.value !== undefined && val.value !== null && val.value !== '-' && val.value !== '')
      index = index === -1 ? this.formattedValues.findIndex(val => val.label !== undefined && val.label !== null && val.label !== '-' && val.label !== '') : index

      if (typeof this.formattedValues[index].value === 'boolean') return 'boolean'
      else if (typeof this.formattedValues[index].value === 'number' && (!isNaN(this.formattedValues[index].label) || this.formattedValues[index].label.startsWith('$'))) {
        return 'number'
      } else if ((typeof this.formattedValues[index].value === 'string' && this.dateTimeRegex.test(this.formattedValues[index].value)) ||
      (this.formattedValues[index].value === undefined && this.dateTimeRegex.test(this.formattedValues[index].label))) {
        return 'dateTime'
      } else return 'string'
    } else {
      index = this.values.findIndex(val => val !== undefined && val !== null && val !== '-' && val !== '')

      if ((typeof this.values[index] === 'string' || typeof this.values[index] === 'object') && this.dateTimeRegex.test(this.values[index])) {
        return 'dateTime'
      } else if (typeof this.values[index] === 'object' ||
      typeof this.values[index] === 'symbol' ||
      typeof this.values[index] === 'undefined' ||
      typeof this.values[index] === 'function' ||
      (typeof this.values[index] === 'string' && isNaN(this.values[index]))) {
        return 'string'
      } else if (typeof this.values[index] === 'bigint' ||
      (typeof this.values[index] === 'string' && !isNaN(this.values[index]))) {
        return 'number'
      } else {
        return typeof this.values[index]
      }
    }
  }

  get isLargerThanCap () {
    return this.numUniqueValues > this.filterCap
  }

  /* M E T H O D S */
  stringFilter () {
    if (this.searchString.length > 0 && !this.hasFormattedValues) {
      if (!this.isLargerThanCap) {
        this.selectableValues = this.uniqueValues.filter((uv) =>
          String(uv).toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase()))
      } else {
        this.selectableValues = this.uniqueValues.filter((uv) =>
          String(uv).toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase())).slice(0, this.filterCap)
      }
    } else if (this.searchString.length > 0 && this.hasFormattedValues) {
      if (!this.isLargerThanCap) {
        this.formattedSelectableValues = this.uniqueFormattedValues.filter((uv) =>
          String(uv.label).toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase()))
      } else {
        this.formattedSelectableValues = this.uniqueFormattedValues.filter((uv) =>
          String(uv.label).toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase())).slice(0, this.filterCap)
      }
    } else {
      this.resetAndSortStringValues()
    }
    this.emitUpdate()
  }

  async addFilter () {
    this.loading = true
    switch (this.filterType) {
      case 'string': {
        if (this.editingFilteredValues.length > 0) {
          const filter: IFilter = {
            type: this.filterType,
            value: { values: this.editingFilteredValues },
            excludeSelected: this.editingExcludeSelected
          }
          const payload: IFilterPayload = { filterName: this.filterName, filter: filter }
          modules.tableHeaderFilters.ADD_TABLE_HEADER_FILTER(payload)

          Vue.set(this, 'searchString', '')
          this.resetAndSortStringValues()
          break
        } else {
          modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTER(this.filterName)
          Vue.set(this, 'searchString', '')
          this.resetAndSortStringValues()
          break
        }
      }
      case 'boolean': {
        if (this.editingBooleanFilteredValues.length > 0) {
          const filter: IFilter = {
            type: this.filterType,
            value: { values: this.editingBooleanFilteredValues },
            excludeSelected: this.editingExcludeSelected
          }
          const payload: IFilterPayload = { filterName: this.filterName, filter: filter }
          modules.tableHeaderFilters.ADD_TABLE_HEADER_FILTER(payload)
          break
        } else {
          modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTER(this.filterName)
          break
        }
      }
      case 'dateTime': {
        this.editingDateFrom = this.editingDateFrom === null ? '' : this.editingDateFrom
        this.editingDateTo = this.editingDateTo === null ? '' : this.editingDateTo
        if (this.editingDateTo < this.editingDateFrom && this.editingDateTo !== '') {
          Vue.toasted.info('Upper Limit must be greater than Lower Limit.')
          modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTER(this.filterName)
          break
        } else if ((this.editingDateTo === '' && this.editingDateFrom === '') ||
          (this.editingDateTo === null && this.editingDateFrom === null)) {
          modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTER(this.filterName)
          break
        } else {
          const to = this.editingDateTo === null || this.editingDateTo === '' ? '' : new Date(this.editingDateTo)
          const from = this.editingDateFrom === null || this.editingDateFrom === '' ? '' : new Date(this.editingDateFrom)

          // If DateTo is populated, set it to the end of the day
          if (to !== '') to.setHours(23, 59, 59, 0)
          const filter: IFilter =
            {
              type: this.filterType,
              value: { dateFrom: from === '' ? '' : from, dateTo: to === '' ? '' : to },
              excludeSelected: this.editingExcludeSelected
            }
          const payload: IFilterPayload = { filterName: this.filterName, filter: filter }
          modules.tableHeaderFilters.ADD_TABLE_HEADER_FILTER(payload)
          break
        }
      }
      case 'number': {
        const from = this.editingNumberFrom === '' ? '' : Number(this.editingNumberFrom)
        const to = this.editingNumberTo === '' ? '' : Number(this.editingNumberTo)
        if (to < from && to !== '') {
          Vue.toasted.info('Upper Limit must be greater than Lower Limit.')
          modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTER(this.filterName)
          break
        } else if (to === '' && from === '') {
          modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTER(this.filterName)
          break
        } else {
          const filter: IFilter =
            {
              type: this.filterType,
              value: { numberFrom: this.editingNumberFrom, numberTo: this.editingNumberTo },
              excludeSelected: this.editingExcludeSelected
            }
          const payload: IFilterPayload = { filterName: this.filterName, filter: filter }
          modules.tableHeaderFilters.ADD_TABLE_HEADER_FILTER(payload)
          break
        }
      }
      default: {
        // Catch all for any missing filter values
        Vue.toasted.info('Filter not available for type ' + this.filterType)
      }
    }

    await modules.personalization.updateFilters({ filters: this.headerFilters })
    this.loading = false
    this.emitUpdate()
  }

  resetAndSortStringValues () {
    if (!this.hasFormattedValues) {
      if (this.filteredValues && this.filteredValues.length > 0) {
        this.filteredValues.sort((a: any, b: any) => a > b ? -1 : 1)
        this.uniqueValues.sort((a: any, b: any) => this.filteredValues.indexOf(b) - this.filteredValues.indexOf(a))
      } else {
        this.uniqueValues.sort((a: any, b: any) => a < b ? -1 : 1)
      }
      Vue.set(this, 'selectableValues', this.isLargerThanCap ? this.uniqueValues.slice(0, this.filterCap) : this.uniqueValues)
    } else {
      if (this.filteredValues && this.filteredValues.length > 0) {
        this.filteredValues.sort((a: any, b: any) => a > b ? -1 : 1)
        this.uniqueFormattedValues.sort((a: any, b: any) =>
          this.filteredValues.indexOf(b.value) - this.filteredValues.indexOf(a.value))
      } else {
        this.uniqueFormattedValues.sort((a: any, b: any) => a < b ? -1 : 1)
      }
      Vue.set(this, 'formattedSelectableValues', this.isLargerThanCap ? this.uniqueFormattedValues.slice(0, this.filterCap) : this.uniqueFormattedValues)
    }
  }

  emitUpdate () {
    this.loading = true
    this.$emit('update', this)
    this.loading = false
  }

  async init () {
    this.loading = true
    this.editingFilteredValues = deepClone(this.filteredValues)
    this.editingBooleanFilteredValues = deepClone(this.booleanFilteredValues)
    this.editingNumberFrom = deepClone(this.numberFrom)
    this.editingNumberTo = deepClone(this.numberTo)
    this.editingDateFrom = deepClone(this.dateFrom)
    this.editingDateTo = deepClone(this.dateTo)
    this.editingExcludeSelected = deepClone(this.excludeSelected)
    this.emitUpdate()
    this.loading = false
  }

  /* W A T C H E R S */
  /* H O O K S */
  async created () {
    this.loading = true
    await this.init()
    this.loading = false
  }
}
