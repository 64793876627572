import { modules } from '@/store'
import { httpService } from '@/services'
import { IPaycheck, IPaycheckTypesType, IPaycheckStatusTypesType } from '@/types'

class PaycheckService {
  private paychecksPromise: Promise<any> | null = null

  getActivePaycheckStatusTypes (): Promise<IPaycheckStatusTypesType[]> {
    return httpService.get('/PaycheckStatusType/GetActivePaycheckStatusTypes')
  }

  getActivePaycheckTypes (): Promise<IPaycheckTypesType[]> {
    return httpService.get('/PaycheckType/GetActivePaycheckTypes')
  }

  /* P A Y C H E C K */
  getPaychecks (): Promise<IPaycheck[]> {
    return httpService.get('/Paycheck/GetPaychecks')
  }

  getPaychecksByEmployeeId (id: number): Promise<{ paychecks: IPaycheck[] }> {
    return httpService.get('/Paycheck/GetPaychecksByEmployeeId', { params: { id } })
  }

  getPaycheckById (id: number): Promise<IPaycheck> {
    return httpService.get('/Paycheck/GetPaycheckById', { params: { id } })
  }

  createPaycheck (payload: any): Promise<''> {
    return httpService.post('/Paycheck/SavePaycheck', payload)
  }

  updatePaycheck (payload: any): Promise<''> {
    return httpService.post('/Paycheck/UpdatePaycheck', payload)
  }

  removePaycheck (id: number): Promise<''> {
    return httpService.post('/Paycheck/RemovePaycheck', { id })
  }

  signPaycheck (signature: string, id: number, checkNumber: string): Promise<''> {
    return httpService.post('/Paycheck/SignPaycheck', { signature, id, checkNumber })
  }

  generatePaycheckPayload (type: 'update' | 'create', paycheckForm: IPaycheck) {
    return {
      ...(type === 'update' && { id: paycheckForm.id }),
      employeeId: Number(paycheckForm.employeeId),
      payDate: paycheckForm.payDate,
      paycheckTypeId: paycheckForm.paycheckTypeId,
      paycheckStatusTypeId: paycheckForm.paycheckStatusTypeId,
      comments: paycheckForm.comments,
      checkNumber: paycheckForm.checkNumber,
      signature: ''
    }
  }

  initPaychecksStatusesTypes () {
    if (!this.paychecksPromise && modules.paychecks.paychecks.length === 0) {
      const { getActivePaycheckStatusTypes, getActivePaycheckTypes } = modules.paychecks
      this.paychecksPromise = Promise.all([
        getActivePaycheckStatusTypes(),
        getActivePaycheckTypes()
      ]).finally(() => (this.paychecksPromise = null))
    }
    return this.paychecksPromise
  }
}

export const paycheckService = new PaycheckService()
