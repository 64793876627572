import { httpService } from '@/services'
import { ITicketDepartment, IDepartment } from '@/types'

class DepartmentsService {
  getDepartments (): Promise<ITicketDepartment[]> {
    return httpService.get('/Department/GetDepartments')
  }

  getDepartmentForCurrentEmployee (): Promise<ITicketDepartment[]> {
    return httpService.get('/Department/GetForCurrentEmployee')
  }

  getAssetOwnerDepartments (): Promise<ITicketDepartment[]> {
    return httpService.get('/Department/GetAssetOwnerDepartments')
  }

  search (term: string) {
    return httpService.get<{ departments: { text: string; value: string }[] }>('/Department/Search', {
      params: { term }
    })
  }

  getDepartmentById (id: string): Promise<IDepartment> {
    return httpService.get('/Department/GetDepartmentById', { params: { id } })
  }

  saveDepartment (payload: any): Promise<''> {
    return httpService.post('/Department/SaveDepartment', payload)
  }

  updateDepartment (payload: IDepartment): Promise<''> {
    return httpService.post('/Department/UpdateDepartment', payload)
  }

  toggleDepartment (id: number): Promise<''> {
    return httpService.post('/Department/ToggleDepartment', {}, { params: { id } })
  }
}

export const departmentsService = new DepartmentsService()
