import { httpService } from '@/services'
import { config } from './config/collections'

import {
  ICollectionConfig,
  IVendorSearch,
  IPostInspectionQuestion,
  IBenefitCoverage,
  IBenefitPlan,
  ITagBlank,
  ITicketDepartment,
  IDepartment,
  IDirectDepositType,
  IGuardTermUpdateTypeStatusPayload,
  ITicketSubCategory,
  IHistoricalTableGroupType
} from '@/types'

class CollectionsService {
  getCollection (name: string) {
    const { getEndpoint, getAllActive } = config.find(i => i.collectionName === name.split('_')[0]) as ICollectionConfig
    return httpService.get((name.endsWith('Active') ? getAllActive : getEndpoint) || '')
  }

  saveCollectionItem (endpoint: string, payload: any): Promise<''> {
    return httpService.post(endpoint, payload)
  }

  toggleCollectionItem (endpoint: string, id: number) {
    return httpService.post(endpoint, {}, { params: { id } })
  }

  /* T A X   R A T E */
  getTaxRateBlank (): Promise<any> {
    return httpService.get('/TaxRate/GetTaxRateBlank')
  }

  /* D I V I S I O N   T Y P E S */
  getDivisionTypes (): Promise<any> {
    return httpService.get('/Collection/DivisionType/GetActive')
  }

  /* C O N T R A C T   A P P R O V A L   T Y P E S */
  getContractApprovalTypes (): Promise<any> {
    return httpService.get('/Collection/ContractApprovalType/GetActive')
  }

  /* V E N D O R   D O C U M E N T   T Y P E   A P I */
  search (term: string): Promise<IVendorSearch[]> {
    return httpService.get('/Vendor/Search', { params: { term } })
  }

  /* P O S T   I N S P E C T I O N   Q U E S T I O N */
  getPostInspectionQuestionsByCustomerSiteId (id: number): Promise<IPostInspectionQuestion[]> {
    return httpService.get('/PostInspectionQuestion/GetByCustomerSiteId', { params: { id } })
  }

  /* B E N E F I T   C O V E R A G E */
  getActiveBenefitCoverages (planTypeId?: number): Promise<IBenefitCoverage[]> {
    return httpService.get('/BenefitCoverage/GetAllActiveByPlanTypeId', { params: { planTypeId } })
  }

  /* B E N E F I T   P L A N S */
  getActiveBenefitPlans (carrierId: number, planTypeId: number): Promise<IBenefitPlan[]> {
    return httpService.get('/BenefitPlan/GetAllActiveByCarrierIdAndPlanTypeId', { params: { carrierId, planTypeId } })
  }

  /* B E N E F I T   P L A N   C O V E R A G E S */
  getActiveBenefitPlanCoverages (planId: number) {
    httpService.get('/BenefitPlanCoverage/GetAllByPlanId', { params: { planId } })
  }

  /* G U A R D   B R A N C H */
  assignCustomerToBranch (branchId: number, customerId: number) {
    return httpService.post('/GuardBranch/AssignCustomerToBranch', {}, { params: { branchId, customerId } })
  }

  removeCustomerFromBranch (branchId: number, customerId: number) {
    return httpService.post('/GuardBranch/RemoveCustomerFromBranch', {}, { params: { branchId, customerId } })
  }

  /* G U A R D   L O C A T I O N */
  getLocationsForTagAssignment (id: number) {
    return httpService.get('/GuardLocation/GetLocationsForTagAssignment', { params: { id } })
  }

  /* G U A R D   R E P R E S E N T A T I V E */
  refreshGuardRepresentative (typeId: number) {
    return httpService.post('/GuardRepresentative/RefreshGuardRepresentative', {}, { params: { typeId } })
  }

  /* G U A R D   T A G */
  getGuardTagBlank (): Promise<ITagBlank[]> {
    return httpService.get('/GuardTag/GetGuardTagBlank')
  }

  getGuardTagsForLocation (): Promise<[]> {
    return httpService.get('/GuardTag/GetGuardTagsForLocation')
  }

  assignLocationToTag (tagId: number, locationId: string): Promise<''> {
    return httpService.post('/GuardTag/AssignLocationToTag', {}, { params: { tagId, locationId } })
  }

  removeLocationFromTag (tagId: number, locationId: string): Promise<''> {
    return httpService.post('/GuardTag/RemoveLocationFromTag', {}, { params: { tagId, locationId } })
  }

  /* D E P A R T M E N T S */
  getDepartmentById (id: string): Promise<IDepartment> {
    return httpService.get('/Department/GetDepartmentById', { params: { id } })
  }

  getAssetOwnerDepartments (): Promise<ITicketDepartment[]> {
    return httpService.get('/Department/GetAssetOwnerDepartments')
  }

  getDepartmentsActive (): Promise<any[]> {
    return httpService.get('/Department/GetDepartmentsActive')
  }

  departmentsSearch (term: string): Promise<{ departments: { text: string; value: string }[] }> {
    return httpService.get('/Department/Search', { params: { term } })
  }

  /* D I R E C T   D E P O S I T */
  getDirectDepositByEmployeeId (id: number): Promise<IDirectDepositType[]> {
    return httpService.get('/DirectDeposit/GetDirectDepositByEmployeeId', { params: { id } })
  }

  setDirectDepositStatus (id: number, isActive: boolean): Promise<''> {
    return httpService.post('/DirectDeposit/SetDirectDepositStatus', {}, { params: { id, isActive } })
  }

  /* G U A R D   T E R M   T Y P E S */
  updateGuardTermTypeOrStatus (payload: IGuardTermUpdateTypeStatusPayload): Promise<''> {
    return httpService.post('/EmployeeGuardTerm/Update', payload)
  }

  /* I N C I D E N T   N O T E   T Y P E */
  createNote (incidentId: number, data: string, isFinal = false) {
    return httpService.post('/IncidentNote/SaveIncidentNote', { incidentId, data, isFinal })
  }

  // C A T E G O R I E S   &   S U B C A T E G O R I E S
  searchSubCategoryByDepartmentId (term: string, departmentId: number): Promise<ITicketSubCategory[]> {
    return httpService.get('/TicketSubCategory/SearchByDepartmentId', { params: { departmentId, term } })
  }

  updateTicketDepartment (id: number, newDepartmentId: number, newSubCategoryId: number) {
    return httpService.post('/Ticket/UpdateDepartment', {}, { params: { id, newDepartmentId, newSubCategoryId } })
  }

  /* H I S T O R Y   T A B L E   G R O U P   T Y P E */
  getHistoricalTableGroupTypeByName (name: string): Promise<any> {
    return httpService.get('/HistoricalTableGroupType/GetByName', { params: { name } })
  }

  getHistoricalTableGroupTypeTables (schemaName: string): Promise<any> {
    return httpService.get('/HistoricalTableGroupType/GetDatabaseTables', { params: { schemaName } })
  }

  getHistoricalTableGroupTypeSchemas (): Promise<any> {
    return httpService.get('/HistoricalTableGroupType/GetDatabaseSchemas')
  }

  getParentIdFieldNameByParentTableName (schemaName: string, tableName: string): Promise<any> {
    return httpService.get('/HistoricalTableGroupType/GetParentIdFieldNameByParentTableName', { params: { schemaName, tableName } })
  }

  getHistoricalTableGroupTypeDataFieldChangesByGroup (
    historicalTableGroupTypeId: string,
    parentIdFieldValue: string
  ): Promise<any> {
    return httpService.get('/HistoricalTableGroupType/GetDataFieldChangesByGroup',
      { params: { historicalTableGroupTypeId, parentIdFieldValue } })
  }

  /* H I S T O R Y   F I E L D   G R O U P   T Y P E */
  getHistoricalTableFieldTypeTableName (historicalTableGroupTypeId: number): Promise<any> {
    return httpService.get('/HistoricalTableFieldType/GetDatabaseTablesByGroupId',
      { params: { groupId: historicalTableGroupTypeId } })
  }

  getHistoricalTableFieldName (schemaName: string, tableName: string): Promise<any> {
    return httpService.get(
      '/HistoricalTableFieldType/GetDatabaseTableColumnNames',
      { params: { schemaName, tableName } }
    )
  }

  getHistoricalTableFieldSchemas (): Promise<any> {
    return httpService.get('/HistoricalTableFieldType/GetDatabaseSchemas')
  }

  getHistoricalTableFields (): Promise<any> {
    return httpService.get('/HistoricalTableFieldType/Get')
  }

  /* H I S T O R Y   F I E L D   T O   G R O U P */
  getHistoricalTableGroupTypes (): Promise<IHistoricalTableGroupType[]> {
    return httpService.get('/HistoricalTableGroupType/Get')
  }

  getFieldsAvailableByGroupId (groupId: number): Promise<any> {
    return httpService.get('/HistoricalTableFieldTypeToGroupType/GetFieldsAvailableByGroupId', { params: { groupId } })
  }
}

export const collectionsService = new CollectionsService()
