













import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors } from '@/types'

@Component({
  name: 'Icon'
})
export default class Icon extends Vue {
  @Prop() size?: string
  @Prop({ default: 'primary' }) color?: TColors | string
  @Prop({ default: false }) disabled!: boolean
  @Prop() title!: string
}
