import { httpService } from '@/services'
import {
  IAddressNonEmployee,
  ICityStateByZip,
  IFindDuplicateAddressesPayload,
  IFindDuplicateAddresses,
  IZipFromAddress,
  TNullableField,
  IZipFromAddressPayload,
  INonEmployeeCityStateByZip,
  IZipFromAddressNonEmployeePayload,
  IAddressAssociatedEntity,
  IUpdateAddressNonEmployeeId,
  IZipFromAddressNonEmployee,
  IDuplicateAddressResponse
} from '@/types'

class AddressService {
  /**
   * API urls
   */
  private apiUrls = {
    getAll: '/Address/GetAddresses',
    getById: '/Address/GetAddressById',
    create: '/Address/SaveAddress',
    update: '/Address/UpdateAddress',
    toggle: '/Address/ToggleAddress',
    getCityStateByZip: '/Address/GetCityStateByZip',
    getZipFromAddress: '/Address/GetZipFromAddress',
    validateAddress: '/Address/ValidateAddress',
    findDuplicateAddresses: '/CustomerSiteV2/FindDuplicateAddresses'
  }

  /**
   * Get all addresses
   * @returns Promise<IAddressNonEmployee[] | any[]
   */
  getAll (activeOnly: boolean): Promise<IAddressNonEmployee[] | any[]> {
    return httpService.get(this.apiUrls.getAll, { params: { activeOnly } })
  }

  /**
   * Get address by id
   * @returns Promise<IAddressNonEmployee[] | any[]
   */
  getById (id: number | string): Promise<IAddressNonEmployee> {
    return httpService.get(this.apiUrls.getById, { params: { id } })
  }

  /**
   * Create new address
   * @param payload New address object
   * @returns Promise<''>
   */
  create (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.create, payload)
  }

  /**
   * Update an existant address
   * @param payload Address object with field to update
   * @returns Promise<''>
   */
  update (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.update, payload)
  }

  /**
   * Delete an existant address
   * @param id Id of the address to delete
   * @param addressType Select the address controller [Address | AddressNonEmployee]
   * @returns Promise<''>
   */
  delete (id: number): Promise<''> {
    return httpService.post(this.apiUrls.toggle, {}, { params: { id } })
  }

  /**
   * Get City and State of the provided Zip Code
   * @param zip Zip Code to lookup
   * @returns Promise<ICityStateByZip>
   */
  getCityStateByZip (zip: TNullableField<string>): Promise<ICityStateByZip> {
    return httpService.post(
      this.apiUrls.getCityStateByZip,
      {},
      { params: { zip } }
    )
  }

  /**
   * Get Zip Code of the provided address
   * @param payload Address object
   * @returns Promise<IZipFromAddress>
   */
  getZipFromAddress (payload: IZipFromAddressPayload): Promise<IZipFromAddress> {
    return httpService.post(this.apiUrls.getZipFromAddress, payload)
  }

  /**
   * Validate an address
   * @param payload
   * @returns Promise<any>
   */
  // TODO: Create data type for payload and return object
  validate (payload: any): Promise<any> {
    return httpService.post(this.apiUrls.validateAddress, payload)
  }

  /**
   * Find duplicate address
   * @param payload
   * @returns Promise<IFindDuplicateAddresses>
   */
  findDuplicateAddresses (
    payload: IFindDuplicateAddressesPayload
  ): Promise<IFindDuplicateAddresses> {
    return httpService.post(this.apiUrls.findDuplicateAddresses, payload)
  }
}

class AddressNonEmployeeService {
  /**
   * API urls
   */
  private apiUrls = {
    getAll: '/AddressNonEmployee/GetAll',
    getAllActive: '/AddressNonEmployee/GetAllActive',
    getById: '/AddressNonEmployee/GetById',
    create: '/AddressNonEmployee/Create',
    update: '/AddressNonEmployee/Update',
    toggle: '/AddressNonEmployee/Toggle',
    getCityStateByZip: '/AddressNonEmployee/GetCityStateByZip',
    getZipFromAddress: '/AddressNonEmployee/GetZipFromAddress',
    validateAddress: '/AddressNonEmployee/ValidateAddress',
    isDuplicateAddress: '/AddressNonEmployee/IsDuplicateAddress',
    getAddressNonEmployeeList: '/AddressNonEmployee/GetAddressNonEmployeeList',
    updateAddressNonEmployeeId: '/AddressNonEmployee/UpdateAddressNonEmployeeId'
  }

  /**
   * Get all addresses
   * @returns Promise<IAddressNonEmployee[] | any[]
   */
  getAll (): Promise<IAddressNonEmployee[] | any[]> {
    return httpService.get(this.apiUrls.getAll)
  }

  /**
   * Get all active addresses
   * @returns Promise<IAddressNonEmployee[] | any[]
   */
  getAllActive (): Promise<IAddressNonEmployee[] | any[]> {
    return httpService.get(this.apiUrls.getAllActive)
  }

  getById (id: number | string): Promise<IAddressNonEmployee> {
    return httpService.get(this.apiUrls.getById, { params: { id } })
  }

  /**
   * Create new address
   * @param payload New address object
   * @returns Promise<IAddressNonEmployee>
   */
  create (payload: any): Promise<IAddressNonEmployee> {
    return httpService.post(this.apiUrls.create, payload)
  }

  /**
   * Update an existant address
   * @param payload Address object with field to update
   * @returns Promise<''>
   */
  update (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.update, payload)
  }

  /**
   * Delete an existant address
   * @param id Id of the address to delete
   * @returns Promise<''>
   */
  delete (id: number): Promise<''> {
    return httpService.post(this.apiUrls.toggle, {}, { params: { id } })
  }

  /**
   * Get City and State of the provided Zip Code
   * @param zip Zip Code to lookup
   * @returns Promise<ICityStateByZip>
   */
  getCityStateByZip (
    zip: TNullableField<string>
  ): Promise<INonEmployeeCityStateByZip> {
    return httpService.post(
      this.apiUrls.getCityStateByZip,
      {},
      { params: { zip } }
    )
  }

  /**
   * Get Zip Code of the provided address
   * @param payload Address object
   * @returns Promise<IZipFromAddress>
   */
  getZipFromAddress (
    payload: IZipFromAddressNonEmployeePayload
  ): Promise<IZipFromAddressNonEmployee> {
    return httpService.post(this.apiUrls.getZipFromAddress, payload)
  }

  /**
   * Validate an address
   * @param payload
   * @returns Promise<any>
   */
  // TODO: Create data type for payload and return object
  validate (payload: any): Promise<any> {
    return httpService.post(this.apiUrls.validateAddress, payload)
  }

  /**
   * Check if duplicate address
   * @param address
   * @returns Promise<IFindDuplicateAddresses>
   */
  isDuplicateAddress (address: IAddressNonEmployee): Promise<IDuplicateAddressResponse> {
    return httpService.post(this.apiUrls.isDuplicateAddress, address)
  }

  /**
   * Get address associated records
   * @param id Id of the address to obtain its associated records
   * @returns Promise<IAddressAssociatedEntity[]>
   */
  getAddressNonEmployeeList (id: number): Promise<IAddressAssociatedEntity[]> {
    return httpService.get(this.apiUrls.getAddressNonEmployeeList, {
      params: { id }
    })
  }

  updateAddressNonEmployeeId (
    payload: IUpdateAddressNonEmployeeId[]
  ): Promise<any> {
    return httpService.post(this.apiUrls.updateAddressNonEmployeeId, payload)
  }
}

export const addressService = new AddressService()

export const addressNonEmployeeService = new AddressNonEmployeeService()
