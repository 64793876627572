import Vue from 'vue'
import VueRouter, { Route, RawLocation } from 'vue-router'
import { routes } from './routes'
import { routeGuard } from '@/router/routeGuard'

const originalPush = VueRouter.prototype.push
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
VueRouter.prototype.push = function push (
  location: RawLocation,
  onResolve: Function | undefined,
  onReject: any
) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return (originalPush.call(this, location) as any).catch((err: any) => err)
}

Vue.use(VueRouter)

export { routeNames } from './routeNames'
export const appRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

appRouter.beforeEach(routeGuard)

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix,@typescript-eslint/naming-convention
  interface Vue {
    $appRouter: VueRouter;
    $appRoute: Route;
  }
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix,@typescript-eslint/naming-convention
  interface VueConstructor {
    $appRouter: VueRouter;
    $appRoute: Route;
  }
}
