import Vue, { VNode } from 'vue'
import { modules } from '@/store'

/*
  Example:
  userGroups = ['LDH_Tickets_View', 'LDH_Tickets_Update']

  === V-CAN (multiple groups will works as AND) ===

  v-can:LDH_Tickets_Update
  ... it will be visible

  v-can:LDH_Tickets_View.LDH_Tickets_Update
  ... it will be visible

  v-can:LDH_Tickets_Delete
  ... it will be hidden

  v-can:LDH_Tickets_View.LDH_Tickets_Delete
  ... it will be hidden

  v-can:LDH_Tickets_Create.disable
  ... it will be visible with 'disabled' attribute (not prop)

  === V-CAN:NOT (multiple groups will works as OR) ===

  v-can:not.LDH_Tickets_Delete
  ... it will be visible

  v-can:not.LDH_Tickets_Delete.LDH_Tickets_Create
  ... it will be visible

  v-can:not.LDH_Tickets_Create
  ... it will be hidden
*/

/**
 * Create comment node
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
function commentNode (el: HTMLElement, vnode: VNode) {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })

  vnode.text = ' '
  vnode.elm = comment
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  if (vnode.data) {
    vnode.data.directives = undefined
  }

  if (vnode.componentInstance) {
    (vnode.componentInstance as any).$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

const canDirective = {
  install: function () {
    Vue.directive('can', function (el, binding, vnode) {
      const userGroups = modules.user.groups
      if (binding.arg === 'not') {
        const groupNames = (binding.arg || '').split('.').filter(gr => !!gr)
        const hasEveryGroup = groupNames.every(group => userGroups.includes(group))
        if (hasEveryGroup) {
          commentNode(el, vnode)
        }
      } else {
        const behaviour = (binding.arg || '').includes('disabled') ? 'disable' : 'hide'
        const groupNames = (binding.arg || '').split('.')
          .filter(gr => gr !== 'disabled')
          .filter(gr => !!gr)

        const hasEveryGroup = groupNames.every(group => userGroups.includes(group))

        if (!hasEveryGroup) {
          if (behaviour === 'hide') {
            commentNode(el, vnode)
          } else if (behaviour === 'disable') {
            el.setAttribute('disabled', '')
            if (vnode.componentInstance) {
              (vnode.componentInstance.$nextTick(() => {
                (vnode.componentInstance as any).$el.setAttribute('disabled', '')
              }))
            }
          }
        }
      }
    })
  }
}

Vue.use(canDirective)
