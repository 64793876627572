import { employeesSidebarRoutes, employeesSidebarRoutesNames } from './employees/sidebar/employees.sidebar.routes'
import { candidatesSidebarRoutes, candidatesSidebarRoutesNames } from './candidates/sidebar/candidates.sidebar.routes'
import { guardTermsSidebarRoutes, guardTermsSidebarRoutesNames } from './guard-terms/sidebar/guard-terms.sidebar.routes'
import { licenseSidebarRoutes, licenseSidebarRoutesNames } from './licenses/sidebar/licenses.sidebar.routes'
import { messagesSidebarRoutes, messagesSidebarRoutesNames } from './messages/sidebar/messages.sidebar.routes'
import { paycheckSidebarRoutes, paycheckSidebarRoutesNames } from './paychecks/sidebar/paychecks.sidebar.routes'
import { postingsSidebarRoutes, postingsSidebarRoutesNames } from './posting/sidebar/postings.sidebar.routes'
import { requisitionsSidebarRoutes, requisitionsSidebarRoutesNames } from './requisitions/requisitions.sidebar.routes'
import { reimbursementsSidebarRoutes, reimbursementsSidebarRoutesNames } from './reimbursements/sidebar/reimbursements.sidebar.routes'

const humanResourcesSidebarRoutesNames = {
  ...employeesSidebarRoutesNames,
  ...candidatesSidebarRoutesNames,
  ...guardTermsSidebarRoutesNames,
  ...licenseSidebarRoutesNames,
  ...messagesSidebarRoutesNames,
  ...paycheckSidebarRoutesNames,
  ...postingsSidebarRoutesNames,
  ...requisitionsSidebarRoutesNames,
  ...reimbursementsSidebarRoutesNames
}

const humanResourcesSidebarRoutes = [
  ...candidatesSidebarRoutes,
  ...employeesSidebarRoutes,
  ...guardTermsSidebarRoutes,
  ...licenseSidebarRoutes,
  ...messagesSidebarRoutes,
  ...paycheckSidebarRoutes,
  ...postingsSidebarRoutes,
  ...requisitionsSidebarRoutes,
  ...reimbursementsSidebarRoutes
]

export {
  humanResourcesSidebarRoutesNames,
  humanResourcesSidebarRoutes
}
