import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.commandCenter

export const commandCenterRouteNames = {
  massMailings: 'MassMailing',
  postingDetached: 'PostingDetached',
  terminatedEmployees: 'TerminatedEmployee'
}

export const commandCenterRoutes = [
  {
    name: commandCenterRouteNames.massMailings,
    path: 'mass-mailings',
    meta: {
      parentName,
      label: 'Mass Mailings',
      group: 'LDH_Nav_Mass_Mailings_View'
    },
    component: () => import('@/pages/command-center/mass-mailings/MassMailings.vue')
  },
  {
    name: commandCenterRouteNames.postingDetached,
    path: 'posting-detached',
    meta: {
      parentName,
      group: 'LDH_Nav_Posting_Detached_View',
      label: 'Posting (Detached)'
    },
    component: () => import('@/pages/command-center/detached-postings/DetachedPostings.vue')
  },
  {
    name: commandCenterRouteNames.terminatedEmployees,
    path: 'terminated-employees',
    meta: {
      parentName,
      group: 'LDH_Nav_Terminated_Employees_View',
      label: 'Terminated Employees'
    },
    component: () => import('@/pages/command-center/terminated-employees/TerminatedEmployees.vue')
  }
]
