const requisitionsUnattachedPostingsSidebarRoutesNames = {
  unattachedPostingsAttach: 'unattachedPostingsAttach',
  unattachedPostingRequisition: 'unattachedPostingRequisition'
}

const requisitionsUnattachedPostingsSidebarRoutes = [
  {
    name: requisitionsUnattachedPostingsSidebarRoutesNames.unattachedPostingsAttach,
    path: '/unattached-postings-:id/attach',
    meta: { width: '600px' },
    component: () => import('./UnattachedPostingsAttach.vue')
  },
  {
    name: requisitionsUnattachedPostingsSidebarRoutesNames.unattachedPostingRequisition,
    path: '/requisition-:id',
    meta: { width: '800px' },
    component: () => import('../components/UnattachedPostingsRequisition.vue')
  }
]

export {
  requisitionsUnattachedPostingsSidebarRoutesNames,
  requisitionsUnattachedPostingsSidebarRoutes
}
