export const collectionsSidebarRoutes = [
  {
    name: 'collection-create',
    path: '/collection-create',
    component: () => import('./components/CollectionForm.vue')
  },
  {
    name: 'collection-edit',
    path: '/collection-:id/edit',
    component: () => import('./components/CollectionForm.vue')
  },
  {
    name: 'guard-representative-sync',
    path: '/guard-representative-sync',
    component: () => import('./components/GuardRepresentativeSync.vue')
  }
]
