const patrolReportsSidebarRoutesNames = {
  patrolReportCreate: 'patrolReportCreate',
  patrolReportDetails: 'patrolReportDetails',
  patrolReportNotes: 'patrolReportNotes'
}

const patrolReportsSidebarRoutes = [
  {
    name: patrolReportsSidebarRoutesNames.patrolReportCreate,
    path: '/patrol-report-create',
    meta: { group: 'LDH_PatrolReport_Add' },
    component: () => import('./PatrolReportCreate.vue')
  },
  {
    name: patrolReportsSidebarRoutesNames.patrolReportDetails,
    path: '/patrol-report-:id/details',
    meta: { group: 'LDH_PatrolReport_View' },
    component: () => import('./PatrolReportDetails.vue')
  },
  {
    name: patrolReportsSidebarRoutesNames.patrolReportNotes,
    path: '/patrol-report-:id/notes',
    meta: { group: 'LDH_PatrolReportNote_View' },
    component: () => import('./PatrolReportNotes.vue')
  }
]

export {
  patrolReportsSidebarRoutesNames,
  patrolReportsSidebarRoutes
}
