import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { customerService } from '@/services'
import { ICustomer, ICustomerInformation, ICustomerSiteContact, ICustomerSiteListModel, ICustomerSiteModel, IGuardUniform, TIndexedObject } from '@/types'
import { deepClone, parsePhoneNumber } from '@/helpers'

const initCustomerCreateSites = (): TIndexedObject<any> => {
  return [{ displayName: '', street1: '', street2: '', city: '', state: '', stateId: '', zip: '', county: '', validated: false, facilityTypeId: null }]
}

const initCustomerCreateForm = (): TIndexedObject<any> => {
  return {
    name: '',
    customerStatusTypeId: '',
    relationshipStatusId: '',
    address: {
      street1: '',
      street2: '',
      zip: '',
      city: '',
      state: '',
      stateId: '',
      county: ''
    },
    contacts: [{
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isPrimary: true,
      contactTypes: [],
      street1: '',
      street2: '',
      zip: '',
      city: '',
      state: '',
      stateId: '',
      county: ''
    }]
  }
}

@Module({ name: 'CustomersModule', namespaced: true })
export default class CustomersModule extends VuexModule {
  customers: readonly ICustomer[] = []
  customer = {} as ICustomerInformation
  customersLoading = false
  customerDetailsLoading = false
  customerCreateLoading = false
  guardUniforms: IGuardUniform[] = []
  customerSites: any[] = []
  customerContacts: any[] = []
  customerCreateForm = initCustomerCreateForm()
  customerCreateSites = initCustomerCreateSites()
  customerCreateIsLead = false

  validatedCustomerForm = ''
  currentCustomerSites: ICustomerSiteListModel[] = []
  site = {} as ICustomerSiteModel
  currentCustomerOpportunities: any[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_CUSTOMERS_LOADING (payload: boolean) {
    this.customersLoading = payload
  }

  @Mutation
  SET_CUSTOMER_DETAILS_LOADING (payload: boolean) {
    this.customerDetailsLoading = payload
  }

  @Mutation
  SET_CUSTOMER_CREATE_LOADING (payload: boolean) {
    this.customerCreateLoading = payload
  }

  @Mutation
  SET_CUSTOMERS (payload: ICustomer[]) {
    this.customers = Object.freeze(payload.sort((a: any, b: any) =>
      a.name < b.name ? -1 : 1))
  }

  @Mutation
  SET_CUSTOMER_INFORMATION (payload: ICustomerInformation) {
    this.customer = payload
  }

  @Mutation
  SET_GUARD_UNIFORM (payload: IGuardUniform[]) {
    this.guardUniforms = payload
  }

  @Mutation
  SET_CUSTOMER_CREATE_FORM (payload: any) {
    this.customerCreateForm = payload
  }

  @Mutation
  SET_CUSTOMER_CREATE_SITES (payload: any) {
    this.customerCreateSites = payload
  }

  @Mutation
  RESET_CUSTOMER_CREATE_FORM () {
    this.customerCreateForm = deepClone(initCustomerCreateForm())
  }

  @Mutation
  RESET_CUSTOMER_CREATE_SITES () {
    this.customerCreateSites = deepClone(initCustomerCreateSites())
  }

  @Mutation
  SET_VALIDATED_CUSTOMER_FORM (payload: string) {
    this.validatedCustomerForm = payload
  }

  @Mutation
  SET_SITES_FOR_CUSTOMER (payload: any[]) {
    this.currentCustomerSites = payload
  }

  @Mutation
  SET_SITE (payload: any) {
    this.site = payload
  }

  @Mutation
  DESTROY_SITES_FOR_CUSTOMER () {
    this.currentCustomerSites = []
  }

  @Mutation
  SET_CONTACTS_FOR_CUSTOMER (payload: any[]) {
    this.customerContacts = payload
  }

  @Mutation
  DESTROY_CONTACTS_FOR_CUSTOMER () {
    this.customerContacts = []
  }

  @Mutation
  SET_OPPORTUNITIES_FOR_CUSTOMER (payload: any[]) {
    this.currentCustomerOpportunities = payload
  }

  @Mutation
  SET_CUSTOMER_CREATE_IS_LEAD (payload: boolean) {
    this.customerCreateIsLead = payload
  }

  get getInitCustomerCreateSites (): any {
    return deepClone(initCustomerCreateSites())
  }

  get getInitCustomerCreateForm (): any {
    return deepClone(initCustomerCreateForm())
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CUSTOMERS', rawError: true })
  async getCustomers (activeToggleState: boolean) {
    return await customerService.getCustomers(activeToggleState)
  }

  @Action({ commit: 'SET_CUSTOMERS', rawError: true })
  async getCustomerNames () {
    return await customerService.getCustomerNames()
  }

  @Action({ commit: 'SET_CUSTOMER_INFORMATION', rawError: true })
  async getCustomerInformation (customerId: string | number) {
    return await customerService.getCustomerInformation(String(customerId))
  }

  @Action({ commit: 'SET_GUARD_UNIFORM', rawError: true })
  async getGuardUniforms () {
    return await customerService.getGuardUniform()
  }

  @Action({ commit: 'SET_SITES_FOR_CUSTOMER', rawError: true })
  async getSitesForCustomer (customerId: number) {
    return await customerService.getSitesForCustomer(customerId)
  }

  @Action({ commit: 'SET_SITE', rawError: true })
  async getSiteById (siteId: number) {
    return await customerService.getCustomerSiteById(siteId)
  }

  @Action({ commit: 'SET_SITE', rawError: true })
  async getMinimumSiteById (siteId: number) {
    return await customerService.getMinimumSiteByid(siteId)
  }

  @Action({ commit: 'SET_CONTACTS_FOR_CUSTOMER', rawError: true })
  async getContactsForCustomer (customerId: number) {
    const contacts = (await customerService.getAllCustomerContactsByCustomerId(customerId)).contacts
    const contactDetails = []

    for (let i = 0; i < contacts.length; i++) {
      const contact: ICustomerSiteContact = {
        id: contacts[i].id,
        fullName: contacts[i].contact.mobilePhone ? contacts[i].contact.displayName + ' (' + parsePhoneNumber(contacts[i].contact.mobilePhone) + ')'
          : contacts[i].contact.phone ? contacts[i].contact.displayName + ' (' + parsePhoneNumber(contacts[i].contact.phone) + ')' : contacts[i].contact.displayName
      }
      contactDetails.push(contact)
    }

    return contactDetails
  }

  @Action({ commit: 'SET_OPPORTUNITIES_FOR_CUSTOMER', rawError: true })
  async getOpportunitiesForCustomer (customerId: number) {
    return await customerService.getOpportunitiesForCustomer(customerId)
  }
}
