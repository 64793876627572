import { httpService } from '@/services'
import { IUnbillable } from '@/types'
// import {} from '@/types'

class UnbillableService {
  getAll (activeToggleState: boolean): Promise<IUnbillable[]> {
    return httpService.get(`/Unbillable/GetAll${activeToggleState ? 'Active' : ''}`)
  }

  getById (id: number | string): Promise<IUnbillable> {
    return httpService.get('/Unbillable/GetById', { params: { id } })
  }

  // getById (id: number | string): Promise<IServiceCallById> {
  //   return httpService.get('/ServiceCall/GetById', { params: { id } })
  // }
  //
  // save (payload: any): Promise<{ id: number }> {
  //   return httpService.post('/ServiceCall/Save', payload)
  // }
  //
  // update (payload: { id: number; customerSiteId: number; contacts: any }): Promise<''> {
  //   return httpService.post('/ServiceCall/Update', payload)
  // }

  toggle (id: number): Promise<''> {
    return httpService.post('/Unbillable/Toggle', {}, { params: { id } })
  }
}

export const unbillableService = new UnbillableService()
