





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors, TSizes } from '@/types'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import LabelModule from '@/components/shared/form-controls/LabelModule.vue'
import { debounce } from '@/helpers'

const numberMask = (allowDecimal: boolean, allowLeadingZeroes: boolean) => createNumberMask({
  prefix: '',
  allowDecimal,
  allowLeadingZeroes,
  includeThousandsSeparator: false
})

@Component({
  name: 'InputModule',
  components: { LabelModule }
})
export default class InputModule extends Vue {
  /* P R O P S */
  @Prop() label?: string
  @Prop() required?: boolean
  @Prop({ default: false }) readonly!: boolean
  @Prop({ default: 'text' }) type!: 'text' | 'number' | 'email' | 'password' | 'decimal'
  @Prop() mask?: string
  @Prop() value!: string
  @Prop({ default: false }) hideChars!: boolean
  @Prop() placeholder!: string
  @Prop() icon!: string
  @Prop({ default: '18' }) iconSize!: string
  @Prop() activatorClass?: string
  @Prop() maxlength?: string
  @Prop() minlength?: string
  @Prop({ default: 'basic' }) color!: TColors
  @Prop({ default: 'lg' }) size!: TSizes
  @Prop({ default: false }) disabled!: boolean
  @Prop() padding?: string
  @Prop({ default: false }) searchable!: boolean;
  @Prop({ default: false }) allowLeadingZeroes!: boolean;

  /* R E F S */
  $refs!: {
    input: HTMLInputElement;
  }

  /* D A T A */
  inputKey = new Date().getMilliseconds()

  /* C O M P U T E D */
  get listeners () {
    const { input, change, ...rest } = this.$listeners
    return rest
  }

  get inputMask () {
    if (this.mask) {
      return this.mask
    }
    if (this.type === 'number') {
      return numberMask(false, this.allowLeadingZeroes)
    } else if (this.type === 'decimal') {
      return numberMask(true, this.allowLeadingZeroes)
    }
  }

  get inputType () {
    if (this.type === 'number') return this.hideChars ? 'password' : 'text'
    else return this.type
  }

  get paddingClass (): string {
    if (this.padding) return this.padding
    return this.icon ? 'pl-4 pr-10' : 'px-4'
  }

  /* M E T H O D S */
  updateValue (eventType: string, event: Event) {
    if (event.isTrusted) {
      if (this.searchable) {
        this.updateValueDelay(eventType, event)
        return
      }

      if (this.type === 'number') {
        this.$emit(eventType, (event.target as HTMLTextAreaElement).value)
        this.inputKey = new Date().getMilliseconds()
        this.$nextTick(() => this.focus())
      } else {
        this.$emit(eventType, (event.target as HTMLTextAreaElement).value)
      }
    }
  }

  @debounce()
  updateValueDelay (eventType: string, event: Event) {
    this.$emit(eventType, (event.target as HTMLTextAreaElement).value)
  }

  focus () {
    this.$refs.input.focus()
  }
}
