var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button focus:outline-none transition-2ms",class:[
    ("button--" + _vm.color),
    ("button--" + _vm.size),
    {
      'button--icon-only': _vm.iconOnly,
      'button--outline': _vm.outline
    }
  ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(!!_vm.badge)?_c('div',{staticClass:"button__badge"},[_vm._v(" "+_vm._s(_vm.badge)+" ")]):_vm._e(),_vm._t("default"),(!!_vm.icon)?_c('Icon',{class:[_vm.icon, { 'ml-3': !_vm.iconOnly }],attrs:{"color":_vm.color === 'basic' ? 'text-basic-600' : 'text-white'}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }