import { articlesSidebarRoutes, articlesSidebarRoutesNames } from './articles/sidebar/articles.sidebar.routes'

const knowledgeBaseSidebarRoutesNames = {
  ...articlesSidebarRoutesNames
}

const knowledgeBaseSidebarRoutes = [
  ...articlesSidebarRoutes
]

export {
  knowledgeBaseSidebarRoutes,
  knowledgeBaseSidebarRoutesNames
}
