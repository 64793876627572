import Vue from 'vue'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Tag,
  Tooltip,
  TimeSelect,
  TimePicker,
  DatePicker,
  Select,
  Option,
  Notification,
  Loading,
  Upload,
  Popover,
  Breadcrumb,
  BreadcrumbItem,
  Tree
} from 'element-ui'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import lang from 'element-ui/lib/locale/lang/en'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import locale from 'element-ui/lib/locale'

locale.use(lang)

// Fixes an issue with filters not working on mobile
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
Select.computed.readonly = function () {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
  return !(this.multiple || !isIE) && !this.visible
}

Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(Popover)
Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tree)

Vue.prototype.$notify = Notification
Vue.use(Loading.directive)
