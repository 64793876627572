import { RouteConfig } from 'vue-router'

const incidentsSidebarRoutesNames = {
  incidentCreate: 'incidentCreate',
  createIncidentResponse: 'createIncidentResponse',
  incidentResponse: 'incidentResponse',
  incidentNotes: 'incidentNotes',
  incidentAssignment: 'incidentAssignment',
  incidentDetails: 'incidentDetails',
  incidentComplete: 'incidentComplete',
  incidentEdit: 'incidentEdit',
  incidentAttachTickets: 'incidentAttachTickets'
}

const incidentsSidebarRoutes: RouteConfig[] = [
  {
    name: incidentsSidebarRoutesNames.incidentCreate,
    path: '/incident-create',
    meta: { width: '600px' },
    component: () => import('./IncidentCreate.vue')
  },
  {
    name: incidentsSidebarRoutesNames.createIncidentResponse,
    path: '/incident-:id/response',
    meta: { width: '600px' },
    component: () => import('./IncidentResponseCreate.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentResponse,
    path: '/incident-response-:id/details',
    meta: { width: '600px' },
    component: () => import('./IncidentResponseDetails.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentNotes,
    path: '/incident-:id/notes',
    meta: { width: '600px' },
    component: () => import('./IncidentNotes.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentAssignment,
    path: '/incident-:id/assign',
    meta: { width: '600px' },
    component: () => import('./IncidentAssignment.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentDetails,
    path: '/incident-:id/details',
    meta: { width: '600px' },
    component: () => import('./IncidentDetails.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentComplete,
    path: '/incident-:id/complete',
    meta: { width: '600px' },
    component: () => import('./IncidentComplete.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentEdit,
    path: '/incident-:id/edit',
    meta: { width: '600px' },
    component: () => import('./IncidentEdit.vue')
  },
  {
    name: incidentsSidebarRoutesNames.incidentAttachTickets,
    path: '/incident-:id/attach-tickets',
    meta: { width: '600px' },
    component: () => import('./IncidentAttachTickets.vue')
  }
  // {
  //   name: 'incident-change-category',
  //   path: '/incident-:id/change-category',
  //   component: IncidentChangeCategory
  // },
  // {
  //   name: 'incident-severity-create',
  //   path: '/incident-severity-create',
  //   component: IncidentSeverityCreate
  // },
  // {
  //   name: 'incident-severity-modify',
  //   path: '/incident-severity-:id/modify',
  //   component: IncidentSeverityModify
  // },
  // {
  //   name: 'incident-sub-category-create',
  //   path: '/incident-sub-category-create',
  //   component: IncidentSubCategoryCreate
  // },
  // {
  //   name: 'incident-sub-category-modify',
  //   path: '/incident-sub-category-:id/modify',
  //   component: IncidentSubCategoryModify
  // }
]

export {
  incidentsSidebarRoutesNames,
  incidentsSidebarRoutes
}
