import { Component, Vue } from 'vue-property-decorator'
import { modules } from '@/store'

@Component
export class EscMixin extends Vue {
  /* M E T H O D S */
  onEscHandler ({ key }: any) {
    if (key === 'Escape' && !modules.general.modalIsOpen) {
      this.$sidebar.push('/')
    }
  }

  /* H O O K S */
  created () {
    window.addEventListener('keydown', this.onEscHandler)
  }

  beforeDestroy () {
    window.removeEventListener('keydown', this.onEscHandler)
  }
}
