import AuxLayout from '@/layouts/AuxLayout.vue'
const customersSidebarRoutesNames = {
  customerCreate: 'customerCreate',
  customerDetails: 'customerDetails',
  customerInformation: 'customerInformation',
  customerAddress: 'customerAddress',
  customerIntegrations: 'customerIntegrations',
  customerNotes: 'customerNotes',
  customerDocuments: 'customerDocuments',
  customerDashboard: 'customerDashboard',

  customerContact: 'customerContact',
  customerContactsList: 'customerContactsList',
  customerContactDetails: 'customerContactDetails',
  customerContactAdd: 'customerContactAdd',

  customerBillingsList: 'customerBillingsList',
  customerBillingDetails: 'customerBillingDetails',

  customerSite: 'customerSite',
  customerSitesList: 'customerSitesList',
  customerSiteCreate: 'customerSiteCreate',
  customerSiteDetails: 'customerSiteDetails',

  customerOpportunity: 'customerOpportunity',
  customerOpportunitiesList: 'customerOpportunitiesList',

  customerPost: 'customerPost',
  customerPostsList: 'customerPostsList',
  customerPostDetails: 'customerPostDetails',
  customerPostCreate: 'customerPostCreate',
  customerPostServices: 'customerPostServices',
  customerPostServiceDetails: 'customerPostServiceDetails',
  customerPostPostingPositions: 'customerPostPostingPositions',
  customerPostPostingPositionDetails: 'customerPostPostingPositionDetails',

  customerContract: 'customerContract',
  customerContractsList: 'customerContractsList',
  customerContractsByTypeList: 'customerContractsByTypeList',
  customerContractByTypeDetails: 'customerContractByTypeDetails',
  customerContractSiteContract: 'customerContractSiteContract'
}

const meta = { width: '700px', minWidth: '500px' }

const customersSidebarRoutes = [
  {
    name: customersSidebarRoutesNames.customerCreate,
    path: '/customers-create',
    component: () => import('./customer-create/CustomerCreate.vue'),
    meta: { group: 'LDH_Customer_Add', width: '700px' }
  },
  {
    name: customersSidebarRoutesNames.customerDetails,
    path: '/customer-:customerId/details',
    redirect: { name: customersSidebarRoutesNames.customerInformation },
    component: () => import('./customer-details/CustomerDetails.vue'),
    meta: { ...meta, group: 'LDH_Customer_View' },
    children: [
      // I N F O
      {
        name: customersSidebarRoutesNames.customerInformation,
        path: 'customer-information',
        component: () => import('./customer-details/customer-details-tabs/CustomerInformation.vue'),
        meta
      },

      // C O N T A C T
      {
        path: 'customer-contact',
        name: customersSidebarRoutesNames.customerContact,
        redirect: { name: customersSidebarRoutesNames.customerContactsList },
        component: AuxLayout,
        meta,
        children: [{
          name: customersSidebarRoutesNames.customerContactsList,
          path: '',
          component: () => import('./customer-details/customer-details-tabs/customer-contact/CustomerContactsList.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerContactDetails,
          path: 'contact-:contactId',
          component: () => import('./customer-details/customer-details-tabs/customer-contact/CustomerContactDetails.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerContactAdd,
          path: 'add',
          component: () => import('./customer-details/customer-details-tabs/customer-contact/CustomerContactAdd.vue'),
          meta
        }]
      },

      // B I L L I N G
      {
        name: customersSidebarRoutesNames.customerBillingsList,
        path: 'customer-billings',
        component: () => import('./customer-details/customer-details-tabs/customer-billing/CustomerBillingsList.vue'),
        meta: { ...meta, group: 'LDH_CustomerBilling_View' }
      },
      {
        name: customersSidebarRoutesNames.customerBillingDetails,
        path: 'customer-billing/',
        component: () => import('./customer-details/customer-details-tabs/customer-billing/CustomerBillingDetails.vue'),
        meta: { ...meta, group: 'LDH_CustomerBilling_View' }
      },

      // P O S T S
      {
        path: 'customer-post',
        name: customersSidebarRoutesNames.customerPost,
        redirect: { name: customersSidebarRoutesNames.customerPostsList },
        component: AuxLayout,
        meta: { ...meta, group: 'LDH_CustomerSite_View' },
        children: [{
          name: customersSidebarRoutesNames.customerPostsList,
          path: '',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/CustomerPostsList.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerPostDetails,
          path: 'post-:postId/details',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/CustomerPostDetails.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerPostCreate,
          path: 'create',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/CustomerPostCreate.vue'),
          meta: { ...meta, group: 'LDH_CustomerSite_Add' }
        }, {
          name: customersSidebarRoutesNames.customerPostServices,
          path: 'post-:siteId/services',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/customer-post-services/CustomerPostServicesList.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerPostServiceDetails,
          path: 'post-:siteId/services/service-:siteServiceId',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/customer-post-services/CustomerPostServiceDetails.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerPostPostingPositions,
          path: 'post-:siteId/services/service-:siteServiceId/postings/posting-:sitePostingId',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/customer-post-posting-positions/CustomerPostPostingPositions.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerPostPostingPositionDetails,
          path: 'post-:siteId/services/service-:siteServiceId/postings/posting-:sitePostingId/positions/posting-position-:sitePostingPositionId',
          component: () => import('./customer-details/customer-details-tabs/customer-posts/customer-post-posting-positions/CustomerPostPostingPositionDetails.vue'),
          meta
        }]
      },

      // A D D R E S S
      {
        path: 'customer-address',
        name: customersSidebarRoutesNames.customerAddress,
        component: () => import('./customer-details/customer-details-tabs/customer-address/CustomerAddress.vue'),
        meta: { ...meta, group: 'LDH_CustomerContract_View' }
      },

      // C O N T R A C T
      {
        path: 'customer-contract',
        name: customersSidebarRoutesNames.customerContract,
        redirect: { name: customersSidebarRoutesNames.customerContractsList },
        component: AuxLayout,
        meta: { ...meta, group: 'LDH_CustomerContract_View' },
        children: [{
          name: customersSidebarRoutesNames.customerContractsList,
          path: '',
          component: () => import('./customer-details/customer-details-tabs/customer-contract/CustomerContractsList.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerContractsByTypeList,
          path: 'contract-:contractId',
          component: () => import('./customer-details/customer-details-tabs/customer-contract/CustomerContractsByTypeList.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerContractByTypeDetails,
          path: 'contract-:contractId/type-:contractTypeId',
          component: () => import('./customer-details/customer-details-tabs/customer-contract/CustomerContractByTypeDetails.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerContractSiteContract,
          path: 'contract-:contractId/type-:contractTypeId/site-contract-:siteContractId',
          component: () => import('./customer-details/customer-details-tabs/customer-contract/CustomerContractSiteContractDetails.vue'),
          meta
        }]
      },

      // I N T E G R A T I O N S
      {
        path: 'customer-integrations',
        name: customersSidebarRoutesNames.customerIntegrations,
        component: () => import('./customer-details/customer-details-tabs/customer-integrations/CustomerIntegrations.vue'),
        meta
      },

      // N O T E S
      {
        path: 'customer-notes',
        name: customersSidebarRoutesNames.customerNotes,
        component: () => import('./customer-details/customer-details-tabs/customer-notes/CustomerNotes.vue'),
        meta
      },

      // D O C U M E N T S
      {
        path: 'customer-documents',
        name: customersSidebarRoutesNames.customerDocuments,
        component: () => import('./customer-details/customer-details-tabs/customer-documents/CustomerDocumentsList.vue'),
        meta: { ...meta, group: 'LDH_CustomerDocument_View' }
      },

      // D A S H B O A R D
      {
        path: 'customer-dashboard',
        name: customersSidebarRoutesNames.customerDashboard,
        component: () => import('./customer-details/customer-details-tabs/customer-dashboard/CustomerDashboard.vue'),
        meta
      },

      // S I T E S
      {
        path: 'customer-site',
        name: customersSidebarRoutesNames.customerSite,
        redirect: { name: customersSidebarRoutesNames.customerSitesList },
        component: AuxLayout,
        meta: { ...meta, group: 'LDH_CustomerSite_View' },
        children: [{
          name: customersSidebarRoutesNames.customerSitesList,
          path: '',
          component: () => import('./customer-details/customer-details-tabs/customer-sites/CustomerSitesList.vue'),
          meta
        }, {
          name: customersSidebarRoutesNames.customerSiteCreate,
          path: 'create',
          component: () => import('./customer-details/customer-details-tabs/customer-sites/CustomerSiteCreate.vue'),
          meta: { ...meta, group: 'LDH_CustomerSite_Add' }
        }, {
          name: customersSidebarRoutesNames.customerSiteDetails,
          path: 'site-:siteId/details',
          component: () => import('./customer-details/customer-details-tabs/customer-sites/CustomerSiteDetails.vue'),
          meta
        }]
      }, {
        path: 'customer-opportunity',
        name: customersSidebarRoutesNames.customerOpportunity,
        redirect: { name: customersSidebarRoutesNames.customerOpportunitiesList },
        component: AuxLayout,
        meta: { ...meta },
        children: [{
          name: customersSidebarRoutesNames.customerOpportunitiesList,
          path: '',
          component: () => import('./customer-details/customer-details-tabs/customer-opportunity/CustomerOpportunityList.vue'),
          meta
        }]
      }
    ]
  }
]

export { customersSidebarRoutesNames, customersSidebarRoutes }
