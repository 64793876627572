import { TNullableField } from '@/types'

export interface IAlertByEmployeeId {
  alertChannelId: number;
  alertTypeId: number;
  description: TNullableField<string>;
  alertId: number;
  isSubscribed: boolean;
  isSendEmail: boolean;
  isShowInTeams: boolean;
  isShowPopup: boolean;
  title: TNullableField<string>;
}

export interface IAlertQueueItem {
  alertChannelDisplayName: TNullableField<string>;
  alertChannelId: number;
  alertChannelSecurityGroupName: TNullableField<string>;
  alertDescription: TNullableField<string>;
  alertId: number;
  alertName: TNullableField<string>;
  alertSecurityGroupName: TNullableField<string>;
  alertTitle: TNullableField<string>;
  alertTypeDisplayName: TNullableField<string>;
  alertTypeId: number;
  created: TNullableField<string>;
  createdBy: TNullableField<string>;
  displayName: TNullableField<string>;
  employeeId: number;
  employeeName: TNullableField<string>;
  id: number;
  isRead: boolean;
  isShowInTeams: boolean;
  message: TNullableField<string>;
  modified: TNullableField<string>;
  modifiedBy: TNullableField<string>;
  name: TNullableField<string>;
  obsolete: boolean;
  obsoleteDate: TNullableField<string>;
  remindMeDateTime: TNullableField<string>;
  uniqueIdValue: number;
}

export interface IAlertType {
  created: TNullableField<string>;
  createdBy: TNullableField<string>;
  displayName: TNullableField<string>;
  id: number;
  modified: TNullableField<string>;
  modifiedBy: TNullableField<string>;
  name: TNullableField<string>;
  obsolete: boolean;
  obsoleteDate: TNullableField<string>;
}

export interface IAlertChannel {
  created: TNullableField<string>;
  createdBy: TNullableField<string>;
  displayName: TNullableField<string>;
  id: number;
  modified: TNullableField<string>;
  modifiedBy: TNullableField<string>;
  name: TNullableField<string>;
  obsolete: boolean;
  obsoleteDate: TNullableField<string>;
  securityGroupName: TNullableField<string>;
}

export interface IAlertUpdateSubscriptionPayload {
  employeeId: number;
  alertId: number;
  IsShowPopUp: boolean;
  isSendEmail: boolean;
}

export enum EAlertType {
  EMPLOYEE = 1,
  GUARD_TERM,
  DISCIPLINE,
  LICENSE,
  TICKET,
  INCIDENT,
  PAYCHECK
}

export interface IParsedAlertRoute {
  app: { name: string };
  sidebar: {
    name: string;
    params: { [key: string]: string };
  };
}
