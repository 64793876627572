import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { dropShipService, collectionsService } from '@/services'
import {
  IDropShip,
  IDropShipPartStatusType,
  IDropShipStatusType,
  IDropShipType
} from '@/types'

@Module({ name: 'DropShipModule', namespaced: true })
export default class DropShipModule extends VuexModule {
  dropShips: IDropShip[] = []
  dropShipPartStatusTypes: IDropShipPartStatusType[] = []
  dropShipStatusTypes: IDropShipStatusType[] = []
  dropShipsActiveStatusTypes: IDropShipStatusType[] = []
  loading = false
  dropShipTypes: any[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_LOADING (payload: boolean) {
    this.loading = payload
  }

  @Mutation
  SET_DROP_SHIP_TYPES (payload: IDropShipType[]) {
    this.dropShipTypes = payload
  }

  @Mutation
  SET_DROP_SHIPS (payload: IDropShip[]) {
    this.dropShips = payload
  }

  @Mutation
  SET_DROP_SHIP_PART_STATUS_TYPES (payload: IDropShipPartStatusType[]) {
    this.dropShipPartStatusTypes = payload
  }

  @Mutation
  SET_DROP_SHIP_STATUS_TYPES (payload: IDropShipStatusType[]) {
    this.dropShipStatusTypes = payload
  }

  @Mutation
  SET_DROP_SHIP_ACTIVE_STATUS_TYPES (payload: IDropShipStatusType[]) {
    this.dropShipsActiveStatusTypes = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_DROP_SHIPS', rawError: true })
  async getDropShips (activeToggleState: boolean) {
    return await dropShipService.getAll(activeToggleState)
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_DROP_SHIP_ACTIVE_STATUS_TYPES', rawError: true })
  async getDropShipsStatuses () {
    return await collectionsService.getCollection('dropShipStatusTypes_Active')
  }

  @Action({ commit: 'SET_DROP_SHIP_STATUS_TYPES', rawError: true })
  async getDropShipStatusTypes () {
    return await collectionsService.getCollection('dropShipStatusTypes')
  }

  @Action({ commit: 'SET_DROP_SHIP_PART_STATUS_TYPES', rawError: true })
  async getDropShipPartStatusTypes () {
    return await collectionsService.getCollection('dropShipPartStatusTypes')
  }

  @Action({ commit: 'SET_DROP_SHIP_TYPES', rawError: true })
  async getDropShipTypes () {
    return await collectionsService.getCollection('dropShipTypes')
  }
}
