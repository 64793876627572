const employeesSidebarRoutesNames = {
  userProfile: 'userProfile',
  userTermDetails: 'userTermDetails',
  employeeProfile: 'employeeProfile',
  employeeTermDetails: 'employeeTermDetails',
  employeeEditHistory: 'employeeEditHistory',
  employeeItemsAdd: 'employeeItemsAdd',
  employeeCheckOutItemsAdd: 'employeeCheckOutItemsAdd',
  employeeCheckInItemsAdd: 'employeeCheckInItemsAdd',
  employeeTermNew: 'employeeTermNew',
  employeeDocuSignPreview: 'employeeDocuSignPreview',
  employeeItemDetails: 'employeeItemDetails'
}
const employeesSidebarRoutes = [
  {
    name: employeesSidebarRoutesNames.userProfile,
    path: '/user-profile-:id',
    meta: { group: 'LDH_Me', width: '700px', minWidth: '600px' },
    component: () => import('./EmployeeProfile.vue')
  },
  {
    name: employeesSidebarRoutesNames.userTermDetails,
    path: '/user-profile/guard-term-:termId/details',
    meta: { group: 'LDH_GuardTerm_View', width: '700px', minWidth: '500px' },
    component: () => import('@/pages/human-resources/guard-terms/sidebar/GuardTermDetails.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeProfile,
    path: '/employee-profile-:id',
    meta: { group: 'LDH_Employee_View', width: '700px', minWidth: '55%' },
    component: () => import('./EmployeeProfile.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeTermDetails,
    path: '/employee-profile-:id/guard-term-:termId/details',
    meta: { group: 'LDH_GuardTerm_View', width: '700px', minWidth: '500px' },
    component: () => import('@/pages/human-resources/guard-terms/sidebar/GuardTermDetails.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeEditHistory,
    path: '/employee-edit-history',
    component: () => import('./EmployeeEditHistory.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeItemsAdd,
    path: '/employee-profile-:id/employee-items-add',
    meta: { width: '700px', minWidth: '600px' },
    component: () => import('@/pages/human-resources/employees/sidebar/EmployeeAddItems.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeCheckInItemsAdd,
    path: '/employee-profile-:id/employee-check-in-items',
    meta: { width: '700px', minWidth: '600px' },
    component: () => import('@/pages/human-resources/employees/sidebar/EmployeeAddCheckInItems.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeTermNew,
    path: '/employee-profile-:id/guard-term-new',
    meta: { width: '700px', group: 'LDH_GuardTerm_View', minWidth: '500px' },
    component: () => import('@/pages/human-resources/guard-terms/sidebar/GuardTermDetails.vue')
  },
  {
    name: employeesSidebarRoutesNames.employeeDocuSignPreview,
    path: '/employee-profile-:id/docu-sign-:documentId-:envelopeId',
    meta: { group: 'LDH_Employee_View', width: '700px', minWidth: '600px' },
    component: () => import('@/pages/human-resources/employees/sidebar/EmployeeProfileDocuSignPreview.vue')
  }
]

export {
  employeesSidebarRoutesNames,
  employeesSidebarRoutes
}
