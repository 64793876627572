import { TIndexedObject } from '@/types'
import Vue from 'vue'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IFilter, IFilterPayload, IFilterSettingUpdatePayload } from './table-header-filter.types'

@Module({ name: 'TableHeaderFilterModule', namespaced: true })
export default class TableHeaderFilterModule extends VuexModule {
  filters: TIndexedObject<IFilter> = {}

  @Mutation
  SET_TABLE_HEADER_FILTERS (payload: TIndexedObject<IFilter>) {
    Vue.set(this, 'filters', payload)
  }

  @Mutation
  ADD_TABLE_HEADER_FILTER (payload: IFilterPayload) {
    if (payload) {
      // Destroy and set to trigger @Watch on TableConstructor
      Vue.delete(this.filters, payload.filterName)
      Vue.set(this.filters, payload.filterName, payload.filter)
    }
  }

  @Mutation
  DESTROY_TABLE_HEADER_FILTER (filterName: string) {
    if (filterName) {
      Vue.delete(this.filters, filterName)
    }
  }

  @Mutation
  DESTROY_TABLE_HEADER_FILTERS (tableName: string) {
    for (const key in this.filters) {
      if (key.split('.')[0] === tableName) {
        Vue.delete(this.filters, key)
      }
    }
  }

  @Mutation
  UPDATE_SETTINGS_TABLE_HEADER_FILTERS (payload: IFilterSettingUpdatePayload) {
    if (payload) {
      for (const key in this.filters) {
        if (key.split('.')[0] === payload.tableName) {
          Vue.delete(this.filters, key)
        }
      }

      for (const key in payload.filters) {
        Vue.set(this.filters, key, payload.filters[key])
      }
    }
  }
}
