import { IBaseModelNamed, TNullableField } from '@/types'

export interface IGuardTermByEmployeeId extends IBaseModelNamed {
  employeeId: number;
  employeeFirstName: string;
  employeeLastName: string;
  employeePreferredName: string;
  employeeMiddleInitial: string;
  employeeSsn: string;
  employeeSignature: TNullableField<string>;
  employeeSignedDate: TNullableField<string>;
  employeeGuardTermStatusId: number;
  guardTermStatus: string;
  postingId: TNullableField<number>;
  postingEffectiveRate: TNullableField<number>;
  postingStartDate: TNullableField<string>;
  postingTotalHours: TNullableField<number>;
  postingShiftType: string;
  postingIsEffectiveRateOverride: TNullableField<boolean>;
  postingIsPrimary: TNullableField<boolean>;
  postingEmployeeStartDate: TNullableField<string>;
  postingUnavailableDates: string;
  postingGuardTermTypeId: TNullableField<number>;
  guardTermType: string;
  postingGuardTermNote: string;
  customerName: TNullableField<string>;
  customerStreet: TNullableField<string>;
  customerStreet2: TNullableField<string>;
  customerStreet3: TNullableField<string>;
  customerStreet4: TNullableField<string>;
  customerCity: TNullableField<string>;
  customerState: TNullableField<string>;
  customerZip: TNullableField<string>;
  customerZip4: TNullableField<string>;
  currentPayRate: TNullableField<number>;
  requisitionTitle: TNullableField<string>;
  employeeGuardTermToPostingId: TNullableField<number>;
  employeeGuardTermIsCurrent: TNullableField<boolean>;
  guardTermStatusTypeIsPendingStatus: TNullableField<boolean>;
  employeeGuardTermTotalHours: number;
  employeeGuardTermEmploymentTypeId: TNullableField<number>;
  employeeGuardTermEmploymentType: string;
  notes: TNullableField<string>;
  branchName: TNullableField<string>;
  guardTypeName: TNullableField<string>;
  schedule: IGuardTermSchedule[];
  employeeGuardTermStatuses: IGuardTermStatus[];
  hasRelatedGuardTerms: boolean;
  hasSignature: boolean;
}

export interface IGuardTermApproval extends IGuardTermByEmployeeId {
  isPrimary: boolean;
  isSelected?: boolean;
  isDeletedLocally?: boolean;
  orientationDate?: string;
  trainingConflicts?: string;
  newPosition: {
    postingId: number;
    orientationDate: string;
    orientationHour: string;
    orientationMinute: string;
    startDate: string;
    unavailableDates: string;
    trainingConflicts: string;
    positionNote: string;
  };
}

export interface IGuardTermSchedule {
  day: string;
  start: string;
  end: string;
}

export interface IGuardTermTypesType extends IBaseModelNamed {
  disabled?: boolean;
  defaultValue: boolean;
  employeeGuardTerms: IGuardTermByEmployeeId[] | null;
}

export interface IGuardTermUpdateTypeStatusPayload {
  id: number;
  guardTermStatusId: number;
  guardTermTypeId: number;
}

export interface IGuardTermStatus extends IBaseModelNamed {
  defaultValue: boolean;
  isAllowHire: boolean;
  isPendingStatus: TNullableField<boolean>;
  employeeGuardTerms: IGuardTermByEmployeeId[];
  isHireStatus: boolean;
  guardTermStatusTypeWorkFlowGuardTermStatusTypeIdFromNavigations: any[];
  guardTermStatusTypeWorkFlowGuardTermStatusTypeIdToNavigations: any[];
}

export interface IGuardTermSignature {
  signature: string;
  signedDate: string;
}

export interface IUpdateGuardTermPayload {
  id: number;
  guardTermStatusId: number;
  employeeId: number;
  addedPositions: string;
  removedPositions: string;
  guardTermTypeId: number;
  uniformDeposit: number;
  _90dayFee: number;
}

export enum EGuardTermStatus {
  CREATED = 1,
  SIGNED,
  COMPLETED,
  APPROVED,
  DECLINED,
  CANCELLED
}

export interface IGuardTerm {
  branchName: TNullableField<string>;
  closestStartDate: TNullableField<string>;
  created: TNullableField<string>;
  createdBy: TNullableField<string>;
  displayName: TNullableField<string>;
  firstName: TNullableField<string>;
  guardTermStatusTypeDisplayName: TNullableField<string>;
  guardTermStatusTypeIsPendingStatus: boolean;
  guardTermStatusTypeName: TNullableField<string>;
  guardTermStatusTypeId: number;
  guardTermTypeDisplayName: string;
  guardTermTypeId: number;
  guardTermTypeName: string;
  id: number;
  isRateOverride: boolean;
  lastName: TNullableField<string>;
  modified: TNullableField<string>;
  modifiedBy: TNullableField<string>;
  modifiedOn: TNullableField<string>;
  name: TNullableField<string>;
  obsolete: false;
  obsoleteDate: TNullableField<string>;
  primaryCustomerSite: TNullableField<string>;
  rateOverride: TNullableField<string>;
  rowColor: TNullableField<string>;
}

export interface IGuardTermDetailsPrint {
  profileInfo: any;
  profilePicture: TNullableField<string>;
  general: {
    title: string;
    description: string | TNullableField<number>;
  }[];
  positions: {
    customerName: TNullableField<string>;
    guardTypeName: TNullableField<string>;
    postingEffectiveRate: TNullableField<number>;
    postingIsEffectiveRateOverride: TNullableField<boolean>;
    currentPayRate: TNullableField<number>;
    postingStartDate: TNullableField<string>;
    postingShiftType: TNullableField<string>;
    postingTotalHours: TNullableField<number>;
    notes: TNullableField<string>;
  }[];
  signature: TNullableField<string>;
}

export enum EGuardTermType {
  NEW_HIRE = 1,
  ADDITIONAL_SITE,
  CHANGE_IN_STATUS,
  CHANGE_IN_DEPARTMENT,
  CHANGE_IN_AVAILABILITY,
  PROMOTION,
  PAY_INCREASE,
  INTEGRATION
}

export interface IGuardTermType extends IBaseModelNamed {
  defaultValue: boolean;
  employeeGuardTerms: [];
}
