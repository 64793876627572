import { customersSidebarRoutes, customersSidebarRoutesNames } from './customers/sidebar/customers.sidebar.routes'
import { patrolReportsSidebarRoutes, patrolReportsSidebarRoutesNames } from './patrol-reports/sidebar/patrol-report.sidebar.routes'
import { postInspectionsSidebarRoutes, postInspectionsSidebarRoutesNames } from './post-inspections/sidebar/post-inspections.sidebar.routes'
import { customerContactsSidebarRoutes, customerContactsSidebarRoutesNames } from './customer-contacts/sidebar/customer-contacts.sidebar.routes'
import { vendorSidebarRoutes, vendorSidebarRoutesNames } from './vendors/sidebar/vendors.sidebar.routes'
import { addressesSidebarRoutes, addressesSidebarRoutesNames } from './addresses/sidebar/addresses.sidebar.routes'

const clientServicesSidebarRoutesNames = {
  ...customersSidebarRoutesNames,
  ...patrolReportsSidebarRoutesNames,
  ...postInspectionsSidebarRoutesNames,
  ...customerContactsSidebarRoutesNames,
  ...vendorSidebarRoutesNames,
  ...addressesSidebarRoutesNames
}
const clientServicesSidebarRoutes = [
  ...customersSidebarRoutes,
  ...patrolReportsSidebarRoutes,
  ...postInspectionsSidebarRoutes,
  ...customerContactsSidebarRoutes,
  ...vendorSidebarRoutes,
  ...addressesSidebarRoutes
]

export {
  clientServicesSidebarRoutesNames,
  clientServicesSidebarRoutes
}
