import { httpService } from '@/services'
import { INote, INotesOptions } from '@/types'

class NotesService {
  getNotes ({ from, to, searchBy }: INotesOptions): Promise<INote[]> {
    return httpService.get('/Reports/GetNotes', { params: { from, to, searchBy } })
  }
}

export const notesService = new NotesService()
