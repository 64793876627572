import { IBaseModelNamed, IScheduleTechnician, TIndexedObject, TNullableField } from '@/types'

export interface ISchedule extends Omit<IBaseModelNamed, 'id'> {
  id: string | number;
  customerSite: {
    address1: string;
    address2: TNullableField<string>;
    city: string;
    country: string;
    county: TNullableField<string>;
    displayName: string;
    id: number;
    name: TNullableField<string>;
    zip: string;
    zip4: TNullableField<string>;
  };
  employeeFirstName: string;
  employeeId: number;
  employeeLastName: string;
  lastNoteContent: TNullableField<string>;
  projectDisplayName: TNullableField<string>;
  projectId: number;
  projectName: TNullableField<string>;
  scheduleEventTypeDisplayName: string;
  scheduleEventTypeId: number;
  scheduleEventTypeName: string;
  serviceCallId: number;
  endDateDayOfWeek: string;
  endDateTime: string;
  startDateDayOfWeek: string;
  startDateTime: string;
  unbillableId: TNullableField<number>;
  unbillableNote: TNullableField<string>;
  scheduleTechnician: TNullableField<IScheduleTechnician>;
}

export interface IScheduleForCalendar extends ISchedule {
  date: string;
  startTime: string;
  endTime: string;
  title: string;
  content: string;
  deletable?: boolean;
  resizable?: boolean;
  draggable?: boolean;
  splitClass?: string;
  titleEditable?: boolean;
  class?: string;
  split?: number;
  splitId?: number;
  label?: string;
}

export interface IScheduleEvent extends IScheduleForCalendar {
  start: string;
  end: string;
}

export enum EScheduleTypes {
  SERVICE_CALL = 1,
  PROJECT = 2,
  UNBILLABLE = 3
}

export interface IScheduleFilterParams {
  startDate: string;
  endDate: string;
  employeeIds?: number[];
}

export interface IScheduleTechnicianAvailability {
  date: string;
  employeeId: number;
  firstName: string;
  isAvailable: boolean;
  lastName: string;
}

type TWeekDataAvailability = { busy: boolean; selected: boolean; date: string }

export interface IScheduleMappedTechnicianAvailability {
  employeeId: number;
  technicianName: string;
  busy?: boolean;
  id?: number;
  weekData: {
    monday: TWeekDataAvailability;
    tuesday: TWeekDataAvailability;
    wednesday: TWeekDataAvailability;
    thursday: TWeekDataAvailability;
    friday: TWeekDataAvailability;
    saturday: TWeekDataAvailability;
    sunday: TWeekDataAvailability;
  } & TIndexedObject;
}

export interface ISelectedScheduleTechnicians {
  date: string;
  technicians: {
    firstName: string;
    lastName: string;
    employeeId: number;
    issues: number[];
    services: number[];
    servicesParts: Record<string, number[]>;
    schedule: (IScheduleForCalendar & { busy?: boolean })[];
  }[];
}

export interface IScheduleServiceCallUpdatePayload {
  id: number;
  scheduleId: number;
  serviceCallId: number;
  obsolete: boolean;
  schedule: ISchedule;
  serviceCallIssues: any[];
}

export interface IScheduleServiceCallSavePayload {
  id?: number;
  scheduleId?: number;
  serviceCallId: number;
  technicians: {
    employeeId: number;
    issues: number[];
    startDateTime: string;
    endDateTime: string;
  }[];
}

export interface IScheduleProjectPayloadArea {
  contractAreaId: number;
  services: Array<{
    serviceId: number;
    parts: Array<{
      id: number;
      quantity: number;
    }>;
  }>;
}
export interface IScheduleProjectPayload {
  id?: number;
  siteContractId: number;
  technicians: {
    employeeId: number;
    startDateTime: string;
    endDateTime: string;
    areas: Array<IScheduleProjectPayloadArea>;
  }[];
}

export interface IScheduleUnbillableCreatePayload {
  categoryId: number;
  note: TNullableField<string>;
  technicians: {
    employeeId: number;
    schedules: {
      endDateTime: string;
      startDateTime: string;
    }[];
  }[];
}

export interface IScheduleUnbillableUpdatePayload {
  id: number;
  unbillableCategoryId: number;
  note: string;
  scheduleId: number;
  schedule: {
    employeeId: number;
    startDateTime: string;
    endDateTime: string;
  };
}
