











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Skeleton'
})
export default class Skeleton extends Vue {
  @Prop() count!: number
  @Prop({ default: 4 }) gap!: number
  @Prop({ default: 'basic-500-48' }) color!: string
  @Prop() itemClass!: string
}
