

















import { Component, Vue } from 'vue-property-decorator'
import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'AuthLayout',
  components: {
    Icon
  }
})
export default class AuthLayout extends Vue {}
