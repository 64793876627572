

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors } from '@/types'

import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'CheckboxModule',
  model: { prop: 'model' },
  components: {
    Icon
  }
})
export default class CheckboxModule extends Vue {
  /* P R O P S */
  @Prop() model!: boolean | any[] | undefined
  @Prop() value!: boolean
  @Prop() label!: string
  @Prop() indeterminate!: boolean
  @Prop({ default: false }) reversed!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: false }) iconCheckmarkTable!: boolean
  @Prop({ default: 'basic' }) color!: TColors

  /* D A T A */
  focused = false

  /* C O M P U T E D */
  get checked (): boolean {
    if (Array.isArray(this.model)) {
      return this.model.includes(this.value)
    } else if (this.model !== undefined) {
      return this.model
    }
    return this.value
  }

  /* M E T H O D S */
  onInput ($event: any) {
    this.focused = true
    if (Array.isArray(this.model)) {
      const model = [...this.model]
      if (model.includes(this.value)) {
        model.splice(model.indexOf(this.value), 1)
        this.$emit('unchecked', this.value)
      } else {
        model.push(this.value)
        this.$emit('checked', this.value)
      }
      this.$emit('input', model)
    } else {
      const value = $event.target.checked
      this.$emit(value ? 'checked' : 'unchecked', value)
      this.$emit('input', value)
    }
  }
}
