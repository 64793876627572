import { httpService } from '@/services/http'
import { IPart, IPartReturnConfiditionStatusType, IPartSearch } from '@/types'

class PartsService {
  getParts (): Promise<IPart[]> {
    return httpService.get('/Part/GetParts')
  }

  getPartById (id: string): Promise<IPart> {
    return httpService.get('/Part/GetPartById', { params: { id } })
  }

  getAegisRetailPrice (cost: number, defaultProjectPriceBookMargin: number): Promise<number> {
    return httpService.get('/Part/GetAegisRetailPrice', { params: { cost, defaultProjectPriceBookMargin } })
  }

  search (term: string): Promise<IPartSearch[]> {
    return httpService.get('/Part/Search', { params: { term } })
  }

  getAllActivePartConfitionStatusType (): Promise<IPartReturnConfiditionStatusType[]> {
    return httpService.get('/PartReturnConditionStatusType/GetAllActive')
  }

  createPart (payload: any): Promise<''> {
    return httpService.post('/Part/SavePart', payload)
  }

  updatePart (payload: any): Promise<''> {
    return httpService.post('/Part/UpdatePart', payload)
  }

  togglePart (id: number): Promise<''> {
    return httpService.post('/Part/TogglePart', {}, { params: { id } })
  }
}

export const partsService = new PartsService()
