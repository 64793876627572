import { authRoutesNames } from '@/pages/auth/auth.routes'
import { dashboardRouteNames } from '@/pages/dashboard/dashboard.routes'
import { clientServicesRouteNames } from '@/pages/client-services/client-services.routes'
import { commandCenterRouteNames } from '@/pages/command-center/command-center.routes'
import { humanResourcesRouteNames } from '@/pages/human-resources/human-resources.routes'
import { knowledgeBaseRouteNames } from '@/pages/knowledge-base/knowledge-base.routes'
import { maintenanceRouteNames } from '@/pages/maintenance/maintenance.routes'
import { reportsRouteNames } from '@/pages/reports/reports.routes'
import { salesRouteNames } from '@/pages/sales/sales.routes'
import { techOpsRouteNames } from '@/pages/tech-ops/tech-ops.routes'
import { ticketsIncidentsRouteNames } from '@/pages/tickets-incidents/tickets-incidents.routes'

export const routeNames = {
  ...authRoutesNames,
  ...clientServicesRouteNames,
  ...commandCenterRouteNames,
  ...dashboardRouteNames,
  ...humanResourcesRouteNames,
  ...knowledgeBaseRouteNames,
  ...maintenanceRouteNames,
  ...reportsRouteNames,
  ...salesRouteNames,
  ...techOpsRouteNames,
  ...ticketsIncidentsRouteNames
}
