import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { IAddressNonEmployee } from '@/types'
import { addressNonEmployeeService } from '@/services'

@Module({ name: 'AddressesModule', namespaced: true })
export default class AddressesModule extends VuexModule {
  addresses: readonly IAddressNonEmployee[] = []
  address = {} as IAddressNonEmployee

  /* L O A D I N G S */
  addressesLoading = false
  addressCreateLoading = false
  addressDetailsLoading = false

  /* M U T A T I O N S */
  @Mutation
  SET_ADDRESSES_LOADING (payload: boolean) {
    this.addressesLoading = payload
  }

  @Mutation
  SET_ADDRESS_CREATE_LOADING (payload: boolean) {
    this.addressCreateLoading = payload
  }

  @Mutation
  SET_ADDRESS_DETAILS_LOADING (payload: boolean) {
    this.addressDetailsLoading = payload
  }

  @Mutation
  SET_ADDRESSES (payload: IAddressNonEmployee[]) {
    this.addresses = Object.freeze(
      payload.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
    )
  }

  @Mutation
  SET_ADDRESS_INFORMATION (payload: IAddressNonEmployee) {
    this.address = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_ADDRESSES', rawError: true })
  async getAddresses (activeToggleState: boolean) {
    return activeToggleState
      ? await addressNonEmployeeService.getAllActive()
      : await addressNonEmployeeService.getAll()
  }

  @Action({ commit: 'SET_ADDRESS_INFORMATION', rawError: true })
  async getAddressInformation (addressId: string | number) {
    return await addressNonEmployeeService.getById(addressId)
  }
}
