import { ICollectionConfig } from '@/types'
import { date, booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming, checkboxFormatter } from './collections-general.config'

export const collectionsReimbursements: ICollectionConfig[] = [{
  label: 'Reimbursement Account',
  group: 'ReimbursementAccount',
  getEndpoint: '/ReimbursementAccount/GetAll',
  getAllActive: '/ReimbursementAccount/GetAllActive',
  saveEndpoint: '/ReimbursementAccount/Create',
  updateEndpoint: '/ReimbursementAccount/Update',
  toggleEndpoint: '/ReimbursementAccount/Toggle',
  collectionName: collectionNames.reimbursementAccounts,
  form: [...naming]
},

{
  label: 'Reimbursement Type',
  group: 'ReimbursementType',
  getEndpoint: '/ReimbursementType/GetAll',
  getAllActive: '/ReimbursementType/GetAllActive',
  saveEndpoint: '/ReimbursementType/Create',
  updateEndpoint: '/ReimbursementType/Update',
  toggleEndpoint: '/ReimbursementType/Toggle',
  collectionName: collectionNames.reimbursementTypes,
  cellClass: ['defaultValue'],
  headings: [
    { label: 'Default', ...booleanFormatter('defaultValue') },
    { label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 200 }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'securityGroupName',
    required: true,
    props: { label: 'Security Group Name', placeholder: 'Name', class: 'w-full mt-4' }
  }, ...checkboxFormatter(['defaultValue_Default'])]
},

{
  label: 'Reimbursement Status Type',
  group: 'ReimbursementStatusType',
  getEndpoint: '/ReimbursementStatusType/GetAll',
  getAllActive: '/ReimbursementStatusType/GetAllActive',
  saveEndpoint: '/ReimbursementStatusType/Create',
  updateEndpoint: '/ReimbursementStatusType/Update',
  toggleEndpoint: '/ReimbursementStatusType/Toggle',
  collectionName: collectionNames.reimbursementStatusTypes,
  cellClass: ['defaultValue'],
  headings: [
    { label: 'Default', ...booleanFormatter('defaultValue') },
    { label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 200 }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'securityGroupName',
    required: true,
    props: { label: 'Security Group Name', placeholder: 'Name', class: 'w-full mt-4' }
  }, ...checkboxFormatter(['defaultValue_Default'])]
},

{
  label: 'Reimbursement Status Type Work Flow',
  group: 'ReimbursementStatusTypeWorkFlow',
  getEndpoint: '/ReimbursementStatusTypeWorkFlow/GetAll',
  getAllActive: '/ReimbursementStatusTypeWorkFlow/GetAllActive',
  saveEndpoint: '/ReimbursementStatusTypeWorkFlow/Create',
  updateEndpoint: '/ReimbursementStatusTypeWorkFlow/Update',
  toggleEndpoint: '/ReimbursementStatusTypeWorkFlow/Toggle',
  collectionName: collectionNames.reimbursementStatusTypeWorkFlows,
  noName: true,
  headings: [
    { label: 'Reimbursement Status Type Id From', value: 'reimbursementStatusTypeIdFrom', sortable: true, minWidth: 200 },
    { label: 'Reimbursement Status Type Id To', value: 'reimbursementStatusTypeIdTo', sortable: true, minWidth: 200 }
  ],
  form: [{
    component: 'InputModule',
    model: 'reimbursementStatusTypeIdFrom',
    props: { type: 'number', label: 'From', placeholder: 'From', class: 'w-full' }
  }, {
    component: 'InputModule',
    model: 'reimbursementStatusTypeIdTo',
    props: { type: 'number', label: 'To', placeholder: 'To', class: 'w-full mt-4' }
  }]
},

{
  label: 'Reimbursement Mile Rate',
  group: 'ReimbursementMileRate',
  getEndpoint: '/ReimbursementMileRate/GetAll',
  saveEndpoint: '/ReimbursementMileRate/Create',
  updateEndpoint: '/ReimbursementMileRate/Update',
  collectionName: collectionNames.reimbursementMileRate,
  noName: true,
  headings: [{
    label: 'Start date',
    value: 'startDate',
    minWidth: 100,
    sortable: true,
    formatter: i => date(i.startDate)
  }, {
    label: 'End date',
    value: 'endDate',
    minWidth: 100,
    sortable: true,
    formatter: i => date(i.endDate)
  }, {
    label: 'Per Mile Rate',
    value: 'perMileRate',
    minWidth: 100,
    sortable: true
  }],
  form: [{
    component: 'DatePickerModule',
    model: 'startDate',
    required: true,
    props: { label: 'Start date', class: 'w-full' }
  }, {
    component: 'InputModule',
    model: 'perMileRate',
    required: true,
    props: { type: 'decimal', label: 'Per Mile Rate', placeholder: 'Rate', class: 'w-full mt-4' }
  }]
}]
