import { httpService } from '@/services'
import { IDependent, IDependentItem, IEmployeeBeneficiary, IEmployeeBeneficiaryItem } from '@/types'

class BenefitEmployeeService {
  private apiUrls = {
    /* B E N E F I T   E M P L O Y E E   D E P E N D E N T */
    getBenefitEmployeeDependents: '/BenefitEmployeeDependent/Get',
    saveBenefitEmployeeDependent: '/BenefitEmployeeDependent/Save',
    updateBenefitEmployeeDependent: '/BenefitEmployeeDependent/Update',
    updateBeneficiaryPercentages: '/BenefitEmployeeBeneficiary/UpdateBeneficiaryPercentages',
    removeBenefitEmployeeDependent: '/BenefitEmployeeDependent/Remove',

    /* B E N E F I T   E M P L O Y E E   B E N E F I C I A R Y */
    getBenefitEmployeeBeneficiary: '/BenefitEmployeeBeneficiary/Get',
    saveBenefitEmployeeBeneficiary: '/BenefitEmployeeBeneficiary/Save',
    updateBenefitEmployeeBeneficiary: '/BenefitEmployeeBeneficiary/Update',
    removeBenefitEmployeeBeneficiary: '/BenefitEmployeeBeneficiary/Remove'
  }

  /* B E N E F I T   E M P L O Y E E   D E P E N D E N T */
  getBenefitEmployeeDependents (): Promise<IDependent[]> {
    return httpService.get(this.apiUrls.getBenefitEmployeeDependents)
  }

  saveBenefitEmployeeDependent (payload: IDependentItem): Promise<''> {
    return httpService.post(this.apiUrls.saveBenefitEmployeeDependent, payload)
  }

  updateBenefitEmployeeDependent (payload: IDependentItem): Promise<''> {
    return httpService.post(this.apiUrls.updateBenefitEmployeeDependent, payload)
  }

  updateBeneficiaryPercentages (payload: any[]): Promise<''> {
    return httpService.post(this.apiUrls.updateBeneficiaryPercentages, payload)
  }

  removeBenefitEmployeeDependent (id: number): Promise<''> {
    return httpService.post(this.apiUrls.removeBenefitEmployeeDependent, {}, { params: { id } })
  }

  /* B E N E F I T   E M P L O Y E E   B E N E F I C I A R Y */
  getBenefitEmployeeBeneficiary (): Promise<IEmployeeBeneficiary[]> {
    return httpService.get(this.apiUrls.getBenefitEmployeeBeneficiary)
  }

  saveBenefitEmployeeBeneficiary (payload: IEmployeeBeneficiaryItem): Promise<''> {
    return httpService.post(this.apiUrls.saveBenefitEmployeeBeneficiary, payload)
  }

  updateBenefitEmployeeBeneficiary (payload: IEmployeeBeneficiaryItem): Promise<''> {
    return httpService.post(this.apiUrls.updateBenefitEmployeeBeneficiary, payload)
  }

  removeBenefitEmployeeBeneficiary (id: number): Promise<''> {
    return httpService.post(this.apiUrls.removeBenefitEmployeeBeneficiary, {}, { params: { id } })
  }
}

export const benefitEmployeeService = new BenefitEmployeeService()
