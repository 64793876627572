import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming, checkboxFormatter } from './collections-general.config'

export const collectionsLicense: ICollectionConfig[] = [{
  label: 'License Status Type',
  group: 'LicenseStatusType',
  getEndpoint: '/LicenseStatusType/GetLicenseStatusTypes',
  saveEndpoint: '/LicenseStatusType/SaveLicenseStatusType',
  updateEndpoint: '/LicenseStatusType/UpdateLicenseStatusType',
  toggleEndpoint: '/LicenseStatusType/ToggleLicenseStatusType',
  collectionName: collectionNames.licenseStatusTypes,
  cellClass: ['isDefault', 'isSigned', 'isSubmitted', 'isReceived', 'isFingerPrinted', 'isTerminated', 'isTerminatedInPisgs'],
  headings: [
    { label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 200 },
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Signed', ...booleanFormatter('isSigned') },
    { label: 'Submitted', ...booleanFormatter('isSubmitted') },
    { label: 'Received', ...booleanFormatter('isReceived') },
    { label: 'Finger Printed', ...booleanFormatter('isFingerPrinted') },
    { label: 'Terminated', ...booleanFormatter('isTerminated') },
    { label: 'PISGS Term', ...booleanFormatter('isTerminatedInPisgs') }
  ],
  form: [...naming,
    {
      component: 'InputModule',
      model: 'securityGroupName',
      required: true,
      props: { label: 'Security Group Name', placeholder: 'Name', class: 'w-full mt-4' }
    },
    ...checkboxFormatter([
      'isDefault_Default',
      'isSigned_Signed',
      'isSubmitted_Submitted',
      'isReceived_Received',
      'isFingerPrinted_Finger Printed',
      'isTerminated_Terminated',
      'isTerminatedInPisgs_PISGS Term'
    ])
  ]
},

{
  label: 'License Type',
  group: 'LicenseType',
  getEndpoint: '/LicenseType/GetLicenseTypes',
  saveEndpoint: '/LicenseType/SaveLicenseType',
  updateEndpoint: '/LicenseType/UpdateLicenseType',
  toggleEndpoint: '/LicenseType/ToggleLicenseType',
  collectionName: collectionNames.licenseTypes,
  cellClass: ['isArmed', 'isClearance', 'isVehicle', 'isTsa'],
  headings: [
    { label: 'Armed', ...booleanFormatter('isArmed') },
    { label: 'Clearance', ...booleanFormatter('isClearance') },
    { label: 'Vehicle', ...booleanFormatter('isVehicle') },
    { label: 'TSA', ...booleanFormatter('isTsa') }
  ],
  form: [...naming, ...checkboxFormatter(['isArmed_Armed', 'isClearance_Clearance', 'isVehicle_Vehicle', 'isTsa_TSA'])]
},

{
  label: 'License Status Type Work Flow',
  group: 'LicenseStatusTypeWorkFlow',
  getEndpoint: '/LicenseStatusTypeWorkFlow/GetAll',
  saveEndpoint: '/LicenseStatusTypeWorkFlow/Create',
  updateEndpoint: '/LicenseStatusTypeWorkFlow/Update',
  toggleEndpoint: '/LicenseStatusTypeWorkFlow/Toggle',
  collectionName: collectionNames.licenseStatusTypeWorkFlow,
  noName: true,
  headings: [
    { label: 'License Status Type Id From', value: 'licenseStatusTypeIdFrom', sortable: true, minWidth: 200 },
    { label: 'License Status Type Id To', value: 'licenseStatusTypeIdTo', sortable: true, minWidth: 200 }
  ],
  form: [{
    component: 'InputModule',
    model: 'licenseStatusTypeIdFrom',
    props: { type: 'number', label: 'From', placeholder: 'From', class: 'w-full' }
  }, {
    component: 'InputModule',
    model: 'licenseStatusTypeIdTo',
    props: { type: 'number', label: 'To', placeholder: 'To', class: 'w-full mt-4' }
  }]
}]
