import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { candidateService, collectionsService, documentService, employeeService } from '@/services'
import {
  IFileData,
  IBaseModelNamed,
  ICandidate,
  ICandidateProfile,
  ICandidateStatusType,
  ICandidateType,
  ITransportationType,
  INewCandidateForm,
  IEmployeeContact,
  IEmployeeIntegrations,
  ICandidatePersonal,
  ICandidateEmployement,
  IEmployeeEmployementGuardTerm,
  IDocuSign,
  IDocuSignDocument
} from '@/types'
import { modules } from '@/store'

@Module({ name: 'CandidateModule', namespaced: true })
export default class CandidateModule extends VuexModule {
  candidatesLoading = false
  candidatesLoaded = false
  candidates = [] as readonly ICandidate[]
  candidateProfileLoading = false
  candidateProfile: ICandidateProfile = {} as ICandidateProfile
  candidatePictureData: IFileData | null = null
  guardTerms: IEmployeeEmployementGuardTerm[] = []

  candidateStatusTypes: ICandidateStatusType[] = []
  candidateTypes: ICandidateType[] = []
  interviewTypes: IBaseModelNamed[] = []
  transportationTypes: ITransportationType[] = []
  createForm: INewCandidateForm = {
    candidateTypeId: '',
    requisitionId: '',
    legalFirstName: '',
    lastName: '',
    dob: '',
    ssn: '',
    emails: [{ emailAddress: null, emailTypeId: null }],
    phones: [{ phoneNumber: null, phoneTypeId: null }],
    addresses: [],
    emergencies: [],
    isDoNotHire: false
  }

  docuSignById: IDocuSign[] = []
  candidateDocuSign: (IDocuSignDocument & { loading: boolean })[] = []
  envelopeId: any
  currentCandidateId: any

  /* C O M P U T E D */
  get candidatePicture () {
    return this.candidatePictureData ? this.candidatePictureData.fileData : null
  }

  /* M U T A T I O N S */
  @Mutation
  SET_CANDIDATES_LOADING (state: boolean) {
    this.candidatesLoading = state
  }

  @Mutation
  SET_CANDIDATES (payload: readonly ICandidate[]) {
    this.candidates = payload
    this.candidatesLoaded = true
  }

  @Mutation
  SET_CANDIDATE_PROFILE_LOADING (payload: boolean) {
    this.candidateProfileLoading = payload
  }

  @Mutation
  SET_CANDIDATE_PROFILE (payload: ICandidateProfile) {
    this.candidateProfile = payload
  }

  @Mutation
  SET_CANDIDATE_PICTURE (payload: IFileData | null) {
    this.candidatePictureData = payload
  }

  @Mutation
  SET_CANDIDATE_STATUS_TYPES (payload: ICandidateStatusType[]) {
    this.candidateStatusTypes = payload
  }

  @Mutation
  SET_CANDIDATE_TYPES (payload: ICandidateType[]) {
    this.candidateTypes = payload
  }

  @Mutation
  SET_INTERVIEW_TYPES (payload: IBaseModelNamed[]) {
    this.interviewTypes = payload
  }

  @Mutation
  SET_TRANSPORTATION_TYPES (payload: ITransportationType[]) {
    this.transportationTypes = payload
  }

  @Mutation
  SET_CREATE_FORM (payload: any) {
    this.createForm = payload
  }

  @Mutation
  DESTROY_CANDIDATE () {
    this.candidateProfile = {} as ICandidateProfile
  }

  @Mutation
  SET_CANDIDATE_PERSONAL (payload: ICandidatePersonal) {
    this.candidateProfile.id = payload.id
    this.candidateProfile.titleId = payload.titleId
    this.candidateProfile.suffixId = payload.suffixId
    this.candidateProfile.firstName = payload.firstName
    this.candidateProfile.lastName = payload.lastName
    this.candidateProfile.preferredName = payload.preferredName
    this.candidateProfile.middleInitial = payload.middleInitial
    this.candidateProfile.maidenName = payload.maidenName
    this.candidateProfile.dob = payload.dob
    this.candidateProfile.genderId = payload.genderId
    this.candidateProfile.raceId = payload.raceId
    this.candidateProfile.maritalStatusId = payload.maritalStatusId
    this.candidateProfile.ssn = payload.ssn
    this.candidateProfile.heightFeet = payload.heightFeet
    this.candidateProfile.heightInches = payload.heightInches
    this.candidateProfile.weight = payload.weight
    this.candidateProfile.hairColor = payload.hairColor
    this.candidateProfile.eyeColor = payload.eyeColor
    this.candidateProfile.disability = payload.disability
    this.candidateProfile.tobacco = payload.tobacco
    this.candidateProfile.veteran = payload.veteran
    this.candidateProfile.veteranDischargeDate = payload.veteranDischargeDate
    this.candidateProfile.referralId = payload.referralId
    this.candidateProfile.referralOther = payload.referralOther
    this.candidateProfile.dependents = payload.dependents
    this.candidateProfile.eligibleToWorkInUsa = payload.eligibleToWorkInUsa
    this.candidateProfile.haveBeenConvicted = payload.haveBeenConvicted
    this.candidateProfile.isHighSchoolOrGed = payload.isHighSchoolOrGed
    this.candidateProfile.uniformDeposit = payload.uniformDeposit
    this.candidateProfile._90dayFee = payload._90dayFee
    this.candidateProfile.tags = payload.tags
    this.candidateProfile.isDoNotHire = payload.isDoNotHire
    this.candidateProfile.obsolete = payload.obsolete
    this.candidateProfile.terminated = payload.terminated
    this.candidateProfile.modified = payload.modified
    this.candidateProfile.modifiedBy = payload.modifiedBy
    this.candidateProfile.startDate = payload.startDate
    this.candidateProfile.processingDate = payload.processingDate
    this.candidateProfile.guidId = payload.guidId
  }

  @Mutation
  SET_CANDIDATE_CONTACT (payload: IEmployeeContact) {
    this.candidateProfile.phones = payload.phones
    this.candidateProfile.emails = payload.emails
    this.candidateProfile.emergencyContact = payload.emergencyContact
    modules.employee.employeeProfile.emergencyContact = payload.emergencyContact
    this.candidateProfile.addresses = payload.addresses
  }

  @Mutation
  SET_CANDIDATE_EMPLOYMENT (payload: ICandidateEmployement) {
    this.candidateProfile.jobTitle = payload.jobTitle
    this.candidateProfile.managerId = payload.managerId
    this.candidateProfile.managerName = payload.managerName
    this.candidateProfile.candidateStatusTypeId = payload.candidateStatusTypeId
    this.candidateProfile.candidateTypeId = payload.candidateTypeId
    this.candidateProfile.employeeTypeId = payload.employeeTypeId
    this.candidateProfile.terminateDate = payload.terminateDate
    this.candidateProfile.startDate = payload.startDate
    this.candidateProfile.processingDate = payload.processingDate
    this.guardTerms = payload.guardTerms
  }

  @Mutation
  SET_CANDIDATE_INTEGRATIONS (payload: IEmployeeIntegrations) {
    this.candidateProfile.activeDirectoryPrincipal = payload.activeDirectoryPrincipal
    this.candidateProfile.celayixEmployeeId = payload.celayixEmployeeId
    this.candidateProfile.flowFinityId = payload.flowFinityId
    this.candidateProfile.paycorId = payload.paycorId
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CANDIDATES', rawError: true })
  async getCandidates (activeToggleState: boolean) {
    return await candidateService.getCandidates(activeToggleState)
  }

  @Mutation
  SET_DOCUSIGN_BY_ID (payload: IDocuSign[]) {
    this.docuSignById = payload
  }

  @Mutation
  SET_CANDIDATE_DOCUSIGN (payload: (IDocuSignDocument & { loading: boolean })[]) {
    this.candidateDocuSign = payload
  }

  @Action({ commit: 'SET_CANDIDATE_PROFILE', rawError: true })
  async getCandidateProfile (candidateId: number) {
    return await candidateService.getCandidateById(candidateId)
  }

  @Action({ commit: 'SET_CANDIDATE_PICTURE', rawError: true })
  async getCandidateImage (candidateId: number) {
    return await employeeService.getEmployeeImage(candidateId)
  }

  @Action({ commit: 'SET_CANDIDATE_STATUS_TYPES', rawError: true })
  async getCandidateStatusTypes () {
    return await collectionsService.getCollection('candidateStatusTypes')
  }

  @Action({ commit: 'SET_CANDIDATE_TYPES', rawError: true })
  async getCandidateTypes () {
    return await collectionsService.getCollection('candidateTypes')
  }

  @Action({ commit: 'SET_INTERVIEW_TYPES', rawError: true })
  async getInterviewTypes () {
    return await collectionsService.getCollection('interviewTypes')
  }

  @Action({ commit: 'SET_TRANSPORTATION_TYPES', rawError: true })
  async getTransportationTypes () {
    return await collectionsService.getCollection('transportationTypes')
  }

  @Action({ commit: 'SET_CANDIDATE_PERSONAL', rawError: true })
  async GetCandidatePersonal (employeeId: number) {
    return await candidateService.getCandidatePersonalById(employeeId)
  }

  @Action({ commit: 'SET_CANDIDATE_CONTACT', rawError: true })
  async GetCandidateContact (employeeId: number) {
    return await candidateService.getCandidateContactById(employeeId)
  }

  @Action({ commit: 'SET_CANDIDATE_EMPLOYMENT', rawError: true })
  async GetCandidateEmployment (employeeId: number) {
    return await candidateService.getCandidateEmploymentById(employeeId)
  }

  @Action({ commit: 'SET_CANDIDATE_INTEGRATIONS', rawError: true })
  async GetCandidateIntegrations (employeeId: number) {
    return await candidateService.getCandidateIntegrationsById(employeeId)
  }

  @Action({ commit: 'SET_DOCUSIGN_BY_ID', rawError: true })
  async GetDocuSignById (ownerId: any) {
    return await documentService.getDocuSignInformationById(Number(ownerId))
  }

  @Action({ commit: 'SET_CANDIDATE_DOCUSIGN', rawError: true })
  async GetCandidateDocuSign (id: string) {
    this.currentCandidateId = id
    const docuSignById = await this.GetDocuSignById(id)
    if (docuSignById.length > 0) {
      this.envelopeId = docuSignById[0].id
      return (await documentService.getDocuSignList(this.envelopeId)).map(d => ({
        ...d,
        loading: false
      }))
    } else {
      return false
    }
  }
}
