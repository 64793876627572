const requisitionTabSidebarRoutesNames = {
  requisitionDetails: 'requisitionDetails',
  requisitionPreview: 'requisitionPreview',
  requisitionCreate: 'requisitionCreate'
}

const requisitionTabSidebarRoutes = [
  {
    name: requisitionTabSidebarRoutesNames.requisitionDetails,
    path: '/requisition-:id/details',
    meta: { width: '800px', group: 'LDH_Requisition_View' },
    component: () => import('./RequisitionDetails.vue')
  },
  {
    name: requisitionTabSidebarRoutesNames.requisitionPreview,
    path: '/requisition-:id/preview',
    meta: { width: '800px', group: 'LDH_Requisition_View' },
    component: () => import('./RequisitionPreview.vue')
  },
  {
    name: requisitionTabSidebarRoutesNames.requisitionCreate,
    path: '/requisition-create',
    meta: { width: '800px', group: 'LDH_Requisition_Add' },
    component: () => import('./RequisitionCreate.vue')
  }
]

export {
  requisitionTabSidebarRoutesNames,
  requisitionTabSidebarRoutes
}
