import Vue from 'vue'
import {
  date,
  dateTime,
  parsedValue,
  truncate,
  time,
  timeNoSeconds,
  getPlainInnerText,
  getShiftType,
  currencyFormat
} from '@/helpers'

Vue.filter('date', date)
Vue.filter('time', time)
Vue.filter('dateTime', dateTime)
Vue.filter('parsedValue', parsedValue)
Vue.filter('truncate', truncate)
Vue.filter('getPlainInnerText', getPlainInnerText)
Vue.filter('shiftType', getShiftType)
Vue.filter('timeNoSeconds', timeNoSeconds)
Vue.filter('currencyFormat', currencyFormat)
