import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { postInspectionsService } from '@/services'
import { IPostInspection } from '@/types'

@Module({ name: 'PostInspectionsModule', namespaced: true })
export default class PostInspectionsModule extends VuexModule {
  postInspectionsLoading = false
  postInspectionViewLoading = false
  postInspections: readonly IPostInspection[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_POST_INSPECTIONS_LOADING (payload: boolean) {
    this.postInspectionsLoading = payload
  }

  @Mutation
  SET_POST_INSPECTION_VIEW_LOADING (payload: boolean) {
    this.postInspectionViewLoading = payload
  }

  @Mutation
  SET_POST_INSPECTIONS (payload: readonly IPostInspection[]) {
    this.postInspections = Object.freeze(payload)
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_POST_INSPECTIONS', rawError: true })
  async getPostInspections (activeToggleState: boolean) {
    return await postInspectionsService.getPostInspections(activeToggleState)
  }
}
