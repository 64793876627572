const collectionGeneralNames = {
  addressStates: 'addressStates',
  assetTypes: 'assetTypes',
  commentTypes: 'commentTypes',
  creditCardTypes: 'creditCardTypes',
  departments: 'departments',
  directDepositTypes: 'directDepositTypes',
  eeoTypes: 'eeoTypes',
  emailTypes: 'emailTypes',
  facilityTypes: 'facilityTypes',
  genders: 'genders',
  interviewTypes: 'interviewTypes',
  maritalStatusTypes: 'maritalStatusTypes',
  textingMessageTemplates: 'textingMessageTemplates',
  notificationEmails: 'notificationEmails',
  phoneTypes: 'phoneTypes',
  postingStatuses: 'postingStatuses',
  priorityTypes: 'priorityTypes',
  raceTypes: 'raceTypes',
  referralTypes: 'referralTypes',
  requisitionTypes: 'requisitionTypes',
  suffix: 'suffix',
  taxRates: 'taxRates',
  titles: 'titles',
  transportationTypes: 'transportationTypes',
  unbillableCategories: 'unbillableCategories',
  divisionTypes: 'divisionTypes',
  billingLevelTypes: 'billingLevelTypes',
  lossReasonTypes: 'lossReasonTypes',
  opportunityStages: 'opportunityStages',
  contractApprovalTypes: 'contractApprovalTypes',
  contractApprovalTypesToRole: 'contractApprovalTypesToRole',
  importDocumentTypes: 'importDocumentTypes',
  documentStatuses: 'documentStatuses'
}

const collectionBenefitNames = {
  benefitCarriers: 'benefitCarriers',
  benefitCoverages: 'benefitCoverages',
  benefitCoverageStatusTypes: 'benefitCoverageStatusTypes',
  benefitDependentTypes: 'benefitDependentTypes',
  benefitEnrollmentStatusTypes: 'benefitEnrollmentStatusTypes',
  benefitEnrollmentTypes: 'benefitEnrollmentTypes',
  benefitEnrollmentYears: 'benefitEnrollmentYears',
  benefitPlanCoverages: 'benefitPlanCoverages',
  benefitPlanTypes: 'benefitPlanTypes',
  benefitPlans: 'benefitPlans'
}

const collectionCandidateNames = {
  candidateStatusTypes: 'candidateStatusTypes',
  candidateTypes: 'candidateTypes'
}

const collectionCustomerNames = {
  customerBillingTypes: 'customerBillingTypes',
  customerCommentTypes: 'customerCommentTypes',
  customerDocumentTypes: 'customerDocumentTypes',
  customerServiceLevelAgreements: 'customerServiceLevelAgreements',
  customerSiteContactTypes: 'customerSiteContactTypes',
  customerSiteContractTypes: 'customerSiteContractTypes',
  customerSitePositionRequirements: 'customerSitePositionRequirements',
  customerStatusRiskTypes: 'customerStatusRiskTypes',
  customerStatusTypes: 'customerStatusTypes',
  customerRelationshipStatus: 'customerRelationshipStatus',
  contactType: 'contactType',
  competitor: 'competitor',
  competitorToServiceLine: 'competitorToServiceLine'
}

const collectionDropShipNames = {
  dropShipPartStatusTypes: 'dropShipPartStatusTypes',
  dropShipStatusTypes: 'dropShipStatusTypes',
  dropShipTypes: 'dropShipTypes'
}

const collectionEmployeeNames = {
  employeeRelationshipTypes: 'employeeRelationshipTypes',
  employeeStatusTypes: 'employeeStatusTypes',
  employmentTypes: 'employmentTypes'
}

const collectionEngagementNames = {
  engagementInteractionTypes: 'engagementInteractionTypes',
  engagementPhaseTypes: 'engagementPhaseTypes',
  engagementQuestions: 'engagementQuestions'
}

const collectionGuardNames = {
  guardBranches: 'guardBranches',
  guardLevels: 'guardLevels',
  guardLocations: 'guardLocations',
  guardRepresentatives: 'guardRepresentatives',
  guardRepresentativeTypes: 'guardRepresentativeTypes',
  guardTags: 'guardTags',
  guardTermStatusTypes: 'guardTermStatusTypes',
  guardTermTypes: 'guardTermTypes',
  guardTypes: 'guardTypes'
}

const collectionsHistoricalNames = {
  historicalTableFieldTypes: 'historicalTableFieldTypes',
  historicalTableGroupTypes: 'historicalTableGroupTypes',
  historicalTableFieldTypeToGroupTypes: 'historicalTableFieldTypeToGroupTypes'
}

const collectionsImportantNames = {
  importantDates: 'importantDates',
  importantDateTypes: 'importantDateTypes'
}

const collectionsIncidentNames = {
  incidentInvolvedPartyTypes: 'incidentInvolvedPartyTypes',
  incidentNoteTypes: 'incidentNoteTypes',
  incidentTypes: 'incidentTypes'
}

const collectionsJobNames = {
  jobTypes: 'jobTypes',
  jobTitleTypes: 'jobTitleTypes',
  jobStatusTypes: 'jobStatusTypes',
  jobTypeParameters: 'jobTypeParameters'
}

const collectionsLicenseNames = {
  licenseTypes: 'licenseTypes',
  licenseStatusTypes: 'licenseStatusTypes',
  licenseStatusTypeWorkFlow: 'licenseStatusTypeWorkFlow'
}

const collectionsPartNames = {
  partCategoryTypes: 'partCategoryTypes',
  partManufacturers: 'partManufacturers',
  partReturnConditionStatusType: 'partReturnConditionStatusType'
}

const collectionsPaycheckNames = {
  paycheckStatusTypes: 'paycheckStatusTypes',
  paycheckTypes: 'paycheckTypes'
}

const collectionsPaymentNames = {
  paymentTermTypes: 'paymentTermTypes',
  paymentTypes: 'paymentTypes'
}

const collectionsPostInspectionNames = {
  postInspectionNoteTypes: 'postInspectionNoteTypes',
  postInspectionQuestions: 'postInspectionQuestions',
  postInspectionStatusTypes: 'postInspectionStatusTypes'
}

const collectionsReimbursementNames = {
  reimbursementAccounts: 'reimbursementAccounts',
  reimbursementTypes: 'reimbursementTypes',
  reimbursementStatusTypes: 'reimbursementStatusTypes',
  reimbursementStatusTypeWorkFlows: 'reimbursementStatusTypeWorkFlows',
  reimbursementMileRate: 'reimbursementMileRate'
}

const collectionsServiceNames = {
  serviceCallIssueNoteTypes: 'serviceCallIssueNoteTypes',
  serviceCallStatusTypes: 'serviceCallStatusTypes',
  serviceCallTypes: 'serviceCallTypes',
  serviceCategories: 'serviceCategories',
  serviceLines: 'serviceLines',
  services: 'services'
}

const collectionsTicketNames = {
  ticketSeverities: 'ticketSeverities',
  ticketSubCategories: 'ticketSubCategories'
}

const collectionsVendorNames = {
  vendorDocumentTypes: 'vendorDocumentTypes',
  vendorNoteTypes: 'vendorNoteTypes',
  vendorTypes: 'vendorTypes'
}

const collectionsUniformTypesNames = {
  uniformTypes: 'uniformTypes'
}

export const collectionNames = {
  ...collectionGeneralNames,
  ...collectionBenefitNames,
  ...collectionCandidateNames,
  ...collectionCustomerNames,
  ...collectionDropShipNames,
  ...collectionEmployeeNames,
  ...collectionEngagementNames,
  ...collectionGuardNames,
  ...collectionsHistoricalNames,
  ...collectionsImportantNames,
  ...collectionsIncidentNames,
  ...collectionsJobNames,
  ...collectionsLicenseNames,
  ...collectionsPartNames,
  ...collectionsPaycheckNames,
  ...collectionsPaymentNames,
  ...collectionsPostInspectionNames,
  ...collectionsReimbursementNames,
  ...collectionsServiceNames,
  ...collectionsTicketNames,
  ...collectionsVendorNames,
  ...collectionsUniformTypesNames
}
