const postingsSidebarRoutesNames = {
  postingNewComment: 'postingNewComment',
  postingCreate: 'postingCreate',
  postingEdit: 'postingEdit',
  postingDetails: 'postingDetails'
}

const postingsSidebarRoutes = [
  {
    name: postingsSidebarRoutesNames.postingNewComment,
    path: '/posting-:id/new-comment',
    meta: { group: 'LDH_Posting_Update' },
    component: () => import('./PostingNewComment.vue')
  },
  {
    name: postingsSidebarRoutesNames.postingCreate,
    path: '/posting-create',
    meta: { width: { default: '500px', 'shift-time': '800px' }, group: 'LDH_Posting_Add' },
    component: () => import('./PostingCreate.vue')
  },
  {
    name: postingsSidebarRoutesNames.postingEdit,
    path: '/posting-:id/edit',
    meta: { width: { default: '500px', 'shift-time': '800px' }, group: 'LDH_Posting_Update' },
    component: () => import('./PostingEdit.vue')
  },
  {
    name: postingsSidebarRoutesNames.postingDetails,
    path: '/posting-:id/details',
    meta: { width: '950px', group: 'LDH_Posting_View' },
    component: () => import('./PostingDetails.vue')
  }
]

export {
  postingsSidebarRoutesNames,
  postingsSidebarRoutes
}
