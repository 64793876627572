import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsDropShip: ICollectionConfig[] = [{
  label: 'Drop Ship Part Status Type',
  group: 'DropShipPartStatus',
  getEndpoint: '/DropShipPartStatus/GetAll',
  saveEndpoint: '/DropShipPartStatus/Save',
  updateEndpoint: '/DropShipPartStatus/Update',
  toggleEndpoint: '/DropShipPartStatus/Toggle',
  collectionName: collectionNames.dropShipPartStatusTypes,
  cellClass: ['isDefault'],
  headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Drop Ship Status Type',
  group: 'DropShipStatusType',
  getEndpoint: '/DropShipStatusType/GetAll',
  getAllActive: '/DropShipStatusType/GetAllActive',
  saveEndpoint: '/DropShipStatusType/Save',
  updateEndpoint: '/DropShipStatusType/Update',
  toggleEndpoint: '/DropShipStatusType/Toggle',
  collectionName: collectionNames.dropShipStatusTypes,
  cellClass: ['isDefault'],
  headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Drop Ship Type',
  group: 'DropShip',
  getEndpoint: '/DropShipType/GetAll',
  saveEndpoint: '/DropShipType/Save',
  updateEndpoint: '/DropShipType/Update',
  toggleEndpoint: '/DropShipType/Toggle',
  collectionName: collectionNames.dropShipTypes,
  cellClass: ['isDefault'],
  headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
}]
