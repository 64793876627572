import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { collectionsService } from '@/services'

import {
  TIndexedObject,
  IGuardTag,
  ITagBlank,
  ITagBlanksRepresentative,
  ILocationsForTagAssignment
} from '@/types'
import { modules } from '@/store'

let tagsPromise: null | Promise<any> = null

@Module({ name: 'CollectionsModule', namespaced: true })
export default class CollectionsNewModule extends VuexModule {
  collections: TIndexedObject = {}

  tagBlanksRepresentatives: ITagBlanksRepresentative[] = []
  guardTagsForLocation: any = {}
  availableLocations: any = []
  assignedLocations: any = []
  taxRateBlanks: any[] = []
  divisionTypes: any[] = []
  contractApprovalTypes: any[] = []
  tags: IGuardTag[] = []
  departments: any[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_ITEMS (collections: []) {
    collections.forEach(i => Vue.set(this.collections, Object.keys(i)[0].split('_')[0], Object.values(i)[0]))
  }

  @Mutation
  SET_TAGS (payload: IGuardTag[]) {
    this.tags = payload
  }

  @Mutation
  SET_TAG_BLANKS (payload: ITagBlank) {
    this.tagBlanksRepresentatives = payload.representatives
  }

  @Mutation
  SET_TAGS_FOR_LOCATION (payload: any) {
    this.guardTagsForLocation = payload.tags
  }

  @Mutation
  SET_LOCATIONS_FOR_TAG_ASSIGNMENT (payload: ILocationsForTagAssignment) {
    this.availableLocations = payload.availableLocations
    this.assignedLocations = payload.assignedLocations
  }

  @Mutation
  SET_TAX_RATE_BLANKS (payload: any) {
    this.taxRateBlanks = payload.states
  }

  @Mutation
  SET_DEPARTMENTS (payload: any) {
    this.departments = payload
  }

  @Mutation
  SET_DIVISION_TYPES (payload: any) {
    this.divisionTypes = payload
  }

  @Mutation
  SET_CONTRACT_APPROVAL_TYPES (payload: any) {
    this.contractApprovalTypes = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_ITEMS', rawError: true })
  async getCollections (collections: string[]) {
    const requestArray = []
    for (let i = 0; i < collections.length; i++) {
      if (!modules.collections.collections[collections[i].split('_')[0]]) requestArray.push(collections[i])
    }
    const requests = requestArray.map(async i => ({ [i]: await collectionsService.getCollection(i) }))
    return await Promise.all(requests)
  }

  @Action({ commit: 'SET_ITEMS', rawError: true })
  async reloadCollections (collections: string[]) {
    const requests = collections.map(async i => ({ [i]: await collectionsService.getCollection(i) }))
    return await Promise.all(requests)
  }

  @Action({ commit: 'SET_TAGS', rawError: true })
  async getGuardTags () {
    if (tagsPromise) {
      const res = await tagsPromise
      tagsPromise = null
      return res
    }
    tagsPromise = collectionsService.getCollection('guardTags')
    return await tagsPromise
  }

  @Action({ commit: 'SET_TAG_BLANKS', rawError: true })
  async getGuardTagBlank () {
    return await collectionsService.getGuardTagBlank()
  }

  @Action({ commit: 'SET_TAGS_FOR_LOCATION', rawError: true })
  async getGuardTagsForLocation () {
    return await collectionsService.getGuardTagsForLocation()
  }

  @Action({ commit: 'SET_LOCATIONS_FOR_TAG_ASSIGNMENT', rawError: true })
  async getLocationsForTagAssignment (id: number) {
    return await collectionsService.getLocationsForTagAssignment(id)
  }

  @Action({ commit: 'SET_TAX_RATE_BLANKS', rawError: true })
  async getTaxRateBlank () {
    return await collectionsService.getTaxRateBlank()
  }

  @Action({ commit: 'SET_DEPARTMENTS', rawError: true })
  async getDepartments () {
    return await collectionsService.getDepartmentsActive()
  }

  @Action({ commit: 'SET_DIVISION_TYPES', rawError: true })
  async getDivisionTypes () {
    return await collectionsService.getDivisionTypes()
  }

  @Action({ commit: 'SET_CONTRACT_APPROVAL_TYPES', rawError: true })
  async getContractApprovalTypes () {
    return await collectionsService.getContractApprovalTypes()
  }
}
