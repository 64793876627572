import { ICollectionConfig } from '@/types'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'
import { date } from '@/helpers'

export const collectionsImportantDate: ICollectionConfig[] = [
  {
    label: 'Important Date',
    group: 'ImportantDate',
    getEndpoint: '/ImportantDate/GetAll',
    saveEndpoint: '/ImportantDate/Create',
    updateEndpoint: '/ImportantDate/Update',
    toggleEndpoint: '/ImportantDate/Toggle',
    collectionName: collectionNames.importantDates,
    headings: [
      { label: 'Important Date Type', value: 'importantDateTypeId', sortable: true, minWidth: 150 },
      { label: 'Period Start', value: 'periodStart', sortable: true, width: 140, align: 'center', formatter: i => date(i.periodStart) },
      { label: 'Period End', value: 'periodEnd', sortable: true, width: 120, align: 'center', formatter: i => date(i.periodEnd) }
    ],
    form: [...naming, {
      component: 'SelectModule',
      model: 'importantDateTypeId',
      options: 'importantDateTypes',
      props: {
        optionKey: 'displayName',
        valueKey: 'id',
        label: 'Important Date Type',
        placeholder: 'Select type',
        class: 'w-full mt-4'
      }
    }, {
      component: 'DatePickerModule',
      model: 'periodStart',
      props: { label: 'Period Start', class: 'w-1/2 mt-4 pr-2' }
    }, {
      component: 'DatePickerModule',
      model: 'periodEnd',
      props: { label: 'Period End', class: 'w-1/2 mt-4 pl-2' }
    }]
  },

  {
    label: 'Important Date Type',
    group: 'ImportantDateType',
    getEndpoint: '/ImportantDateType/GetAll',
    saveEndpoint: '/ImportantDateType/Create',
    updateEndpoint: '/ImportantDateType/Update',
    toggleEndpoint: '/ImportantDateType/Toggle',
    collectionName: collectionNames.importantDateTypes,
    form: [...naming]
  }
]
