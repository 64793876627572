import { date } from '@/helpers'
import { naming } from './collections-general.config'
import { collectionNames } from '../collectionNames'
import { ICollectionConfig } from '@/types'

export const collectionsBenefit: ICollectionConfig[] = [{
  label: 'Benefit Carrier',
  group: 'BenefitCarrier',
  getEndpoint: '/BenefitCarrier/Get',
  getAllActive: '/BenefitCarrier/GetAllActive',
  saveEndpoint: '/BenefitCarrier/Save',
  updateEndpoint: '/BenefitCarrier/Update',
  toggleEndpoint: '/BenefitCarrier/Toggle',
  collectionName: collectionNames.benefitCarriers,
  form: [...naming]
},

{
  label: 'Benefit Coverage',
  group: 'BenefitCoverage',
  getEndpoint: '/BenefitCoverage/Get',
  saveEndpoint: '/BenefitCoverage/Save',
  updateEndpoint: '/BenefitCoverage/Update',
  toggleEndpoint: '/BenefitCoverage/Toggle',
  collectionName: collectionNames.benefitCoverages,
  headings: [{ label: 'Play Type', value: 'planTypeDisplayName', sortable: true, minWidth: 150 }],
  form: [...naming, {
    component: 'SelectModule',
    model: 'planTypeId',
    options: 'benefitPlanTypes',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Plan Type',
      placeholder: 'Select type',
      class: 'w-full mt-4'
    }
  }]
},

{
  label: 'Benefit Coverage Status Type',
  group: 'BenefitCoverageStatusType',
  getEndpoint: '/BenefitCoverageStatusType/Get',
  saveEndpoint: '/BenefitCoverageStatusType/Save',
  updateEndpoint: '/BenefitCoverageStatusType/Update',
  toggleEndpoint: '/BenefitCoverageStatusType/Toggle',
  collectionName: collectionNames.benefitCoverageStatusTypes,
  form: [...naming]
},

{
  label: 'Benefit Dependent Type',
  group: 'BenefitDependentType',
  getEndpoint: '/BenefitDependentType/Get',
  saveEndpoint: '/BenefitDependentType/Save',
  updateEndpoint: '/BenefitDependentType/Update',
  toggleEndpoint: '/BenefitDependentType/Toggle',
  collectionName: collectionNames.benefitDependentTypes,
  form: [...naming]
},

{
  label: 'Benefit Enrollment Status Type',
  group: 'BenefitEnrollmentStatusType',
  getEndpoint: '/BenefitEnrollmentStatusType/Get',
  saveEndpoint: '/BenefitEnrollmentStatusType/Save',
  updateEndpoint: '/BenefitEnrollmentStatusType/Update',
  toggleEndpoint: '/BenefitEnrollmentStatusType/Toggle',
  collectionName: collectionNames.benefitEnrollmentStatusTypes,
  form: [...naming]
},

{
  label: 'Benefit Enrollment Type',
  group: 'BenefitEnrollmentType',
  getEndpoint: '/BenefitEnrollmentType/Get',
  getAllActive: '/BenefitEnrollmentType/GetAllActive',
  saveEndpoint: '/BenefitEnrollmentType/Save',
  updateEndpoint: '/BenefitEnrollmentType/Update',
  toggleEndpoint: '/BenefitEnrollmentType/Toggle',
  collectionName: collectionNames.benefitEnrollmentTypes,
  form: [...naming]
},

{
  label: 'Benefit Enrollment Year',
  getEndpoint: '/BenefitEnrollmentYear/Get',
  getAllActive: '/BenefitEnrollmentYear/GetAllActive',
  saveEndpoint: '/BenefitEnrollmentYear/Save',
  updateEndpoint: '/BenefitEnrollmentYear/Update',
  toggleEndpoint: '/BenefitEnrollmentYear/Toggle',
  group: 'BenefitEnrollmentYear',
  collectionName: collectionNames.benefitEnrollmentYears,
  noName: true,
  headings: [{
    label: 'Year',
    value: 'effectiveYear',
    minWidth: 100,
    sortable: true
  }, {
    label: 'Start date',
    value: 'effectiveStartDate',
    minWidth: 100,
    sortable: true,
    formatter: i => date(i.effectiveStartDate)
  }, {
    label: 'End date',
    value: 'effectiveEndDate',
    minWidth: 100,
    sortable: true,
    formatter: i => date(i.effectiveEndDate)
  }],
  form: [{
    component: 'InputModule',
    model: 'effectiveYear',
    props: { type: 'number', label: 'Year', placeholder: 'Year', class: 'w-full', maxlength: 4 }
  }, {
    component: 'DatePickerModule',
    model: 'effectiveStartDate',
    props: { label: 'Start date', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'DatePickerModule',
    model: 'effectiveEndDate',
    props: { label: 'Year', class: 'w-1/2 mt-4 pl-2' }
  }]
},

{
  label: 'Benefit Plan Coverage',
  group: 'BenefitPlanCoverage',
  getEndpoint: '/BenefitPlanCoverage/Get',
  saveEndpoint: '/BenefitPlanCoverage/Save',
  updateEndpoint: '/BenefitPlanCoverage/Update',
  toggleEndpoint: '/BenefitPlanCoverage/Toggle',
  collectionName: collectionNames.benefitPlanCoverages,
  noName: true,
  headings: [
    { label: 'Plan', value: 'planDisplayName', sortable: true, minWidth: 150 },
    { label: 'Coverage', value: 'coverageDisplayName', sortable: true, minWidth: 150 }
  ],
  form: [{
    component: 'SelectModule',
    model: 'planId',
    options: 'benefitPlans',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Plan',
      placeholder: 'Select plan',
      class: 'w-full'
    }
  }, {
    component: 'SelectModule',
    model: 'coverageId',
    options: 'benefitCoverages',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Coverage',
      placeholder: 'Select coverage',
      class: 'w-full mt-4'
    }
  }]
},

{
  label: 'Benefit Plan Type',
  group: 'BenefitPlanType',
  getEndpoint: '/BenefitPlanType/Get',
  saveEndpoint: '/BenefitPlanType/Save',
  updateEndpoint: '/BenefitPlanType/Update',
  toggleEndpoint: '/BenefitPlanType/Toggle',
  collectionName: collectionNames.benefitPlanTypes,
  form: [...naming]
},

{
  label: 'Benefit Plan',
  group: 'BenefitPlan',
  getEndpoint: '/BenefitPlan/Get',
  saveEndpoint: '/BenefitPlan/Save',
  updateEndpoint: '/BenefitPlan/Update',
  toggleEndpoint: '/BenefitPlan/Toggle',
  collectionName: collectionNames.benefitPlans,
  headings: [
    { label: 'Carrier', value: 'carrierDisplayName', sortable: true, minWidth: 150 },
    { label: 'Plan Type', value: 'planTypeDisplayName', sortable: true, minWidth: 150 }
  ],
  form: [...naming, {
    component: 'SelectModule',
    model: 'planTypeId',
    options: 'benefitPlanTypes',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Plan Type',
      placeholder: 'Select type',
      class: 'w-full mt-4'
    }
  }, {
    component: 'SelectModule',
    model: 'carrierId',
    options: 'benefitCarriers',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Carrier',
      placeholder: 'Select carrier',
      class: 'w-full mt-4'
    }
  }]
}]
