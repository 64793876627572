import { AxiosRequestConfig } from 'axios'
import { modules } from '@/store'

export const requestInterceptor = async (requestConfig: AxiosRequestConfig) => {
  const currentTime = new Date().getTime()
  const expiresOnTime = new Date(modules.user.tokenInfo.expiresOn).getTime()
  if (!expiresOnTime || currentTime > expiresOnTime) {
    await modules.user.acquireToken()
  }

  if (requestConfig.headers) {
    requestConfig.headers.Authorization = `Bearer ${modules.user.tokenInfo.accessToken}`
  }

  return requestConfig
}
