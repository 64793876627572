
const authRoutesNames = {
  login: 'Login',
  404: '404',
  accessDenied: 'AccessDenied',
  verifyIdentity: 'VerifyIdentity',
  passwordReset: 'PasswordReset',
  teamsAuth: 'TeamsAuth',
  teamsAuthEnd: 'TeamsAuthEnd'
}

const authRoutes = [
  {
    path: '/auth',
    redirect: '/'
  },
  {
    path: 'login',
    name: authRoutesNames.login,
    meta: { requireAuth: false },
    component: () => import('@/pages/auth/Login.vue')
  },
  {
    path: 'teams-auth',
    name: authRoutesNames.teamsAuth,
    meta: { requireAuth: false, notFound: true },
    component: () => import('@/pages/auth/TeamsAuth.vue')
  },
  {
    path: 'teams-auth-end',
    name: authRoutesNames.teamsAuthEnd,
    meta: { requireAuth: false, notFound: true },
    component: () => import('@/pages/auth/TeamsAuthEnd.vue')
  },
  {
    path: '404',
    name: authRoutesNames['404'],
    meta: { requireAuth: false, notFound: true },
    component: () => import('@/pages/auth/AuthGeneral.vue')
  },
  {
    path: 'access-denied',
    name: authRoutesNames.accessDenied,
    meta: { requireAuth: false, accessDenied: true },
    component: () => import('@/pages/auth/AuthGeneral.vue')
  },
  {
    path: 'verify-identity',
    name: authRoutesNames.verifyIdentity,
    meta: { requireAuth: false },
    component: () => import('@/pages/auth/VerifyIdentity.vue')
  },
  {
    path: 'password-reset',
    name: authRoutesNames.passwordReset,
    meta: { requireAuth: false },
    component: () => import('@/pages/auth/PasswordReset.vue')
  }
]

export {
  authRoutesNames,
  authRoutes
}
