import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { employeeService } from '@/services'
import { IEmployeeItemSummary, IEmployeeItemsOptions, TIndexedObject, IEmployeeStatusTypes } from '@/types'

@Module({ name: 'EmployeeItemsModule', namespaced: true })
export default class EmployeeItemsModule extends VuexModule {
  employeeItems: readonly IEmployeeItemSummary[] = []
  employeeStatusTypes: readonly IEmployeeStatusTypes[] = []
  employeeItemsLoading = false
  selectedEmployeeItemsOptions: IEmployeeItemsOptions = { payrollTermOnly: null }

  get indexedEmployeeItems () {
    return this.employeeItems.reduce((acc, cur) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      acc[cur.id!] = cur
      return acc
    }, {} as TIndexedObject<IEmployeeItemSummary>)
  }

  /* M U T A T I O N S */
  @Mutation
  SET_EMPLOYEE_ITEMS (payload: readonly IEmployeeItemSummary[]) {
    this.employeeItems = payload
  }

  @Mutation
  SET_EMPLOYEE_STATUS_TYPES (payload: readonly IEmployeeStatusTypes[]) {
    this.employeeStatusTypes = payload
  }

  @Mutation
  SET_LOADING (payload: boolean) {
    this.employeeItemsLoading = payload
  }

  @Mutation
  CLEAR_SELECTED_EMPLOYEE_ITEMS_OPTIONS () {
    this.selectedEmployeeItemsOptions = { payrollTermOnly: false }
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_EMPLOYEE_ITEMS', rawError: true })
  async getEmployeeItemsSummary (employeeItemsOptions: IEmployeeItemsOptions = { payrollTermOnly: null }) {
    return await employeeService.getEmployeeItems(employeeItemsOptions)
  }

  @Action({ commit: 'SET_EMPLOYEE_STATUS_TYPES', rawError: true })
  async getEmployeeStatusTypes () {
    return await employeeService.getEmployeeStatusTypes()
  }
}
