import { Component, Vue, Watch } from 'vue-property-decorator'
import { modules } from '@/store'
import { parseToStrings } from '@/helpers'

@Component
export class ConfirmChangesMixin extends Vue {
  /* D A T A */
  form: any = null

  editMode = false

  closeSidebar = true

  /* C O M P U T E D */
  get cancelConfirmed () {
    return modules.general.cancelConfirmed
  }

  get shouldBack () {
    return this.$sidebarRoute.params.shouldBack === 'true'
  }

  /* M E T H O D S */
  setEntryData (data: any) {
    modules.general.SET_ENTRY_DATA(parseToStrings(data))
  }

  setChangedEntryData (data: any) {
    modules.general.SET_CHANGED_ENTRY_DATA(parseToStrings(data))
  }

  clearEntryData (close = true) {
    this.editMode && (this.editMode = false)
    modules.general.SET_ENTRY_DATA(null)
    modules.general.SET_CHANGED_ENTRY_DATA(null)
    this.$emit('update:edit', false)
    this.closeSidebar && close && this.$sidebar.push('/')
  }

  cancel () {
    if (modules.general.isAnyChanges) modules.general.SET_CHANGES_CONFIRM_OPENED(true)
    else this.cancelEdit()
  }

  cancelEdit () {
    this.clearEntryData()
  }

  /* H O O K S */
  created () {
    this.setEntryData(this.form)
  }

  beforeDestroy () {
    this.clearEntryData(false)
  }

  /* W A T C H E R S */
  @Watch('form', { deep: true })
  onFormChanged () {
    this.setChangedEntryData(this.form)
  }

  /* W A T C H E R S */
  @Watch('cancelConfirmed')
  onCancelConfirmedChanged (value: boolean) {
    if (value) {
      this.cancelEdit()
      modules.general.SET_CANCEL_CONFIRMED(false)
    }
  }

  /* W A T C H E R S */
  @Watch('editMode')
  onEditModeChanged (value: boolean) {
    if (!value) {
      this.setEntryData(null)
      this.setChangedEntryData(null)
    }
  }
}
