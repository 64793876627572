import { httpService } from '@/services'

class PhoneService {
  private apiUrls = {
    savePhone: '/Phone/SavePhone',
    updatePhone: '/Phone/UpdatePhone',
    deletePhone: '/Phone/TogglePhone'
  }

  createPhone (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.savePhone, payload)
  }

  updatePhone (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.updatePhone, payload)
  }

  deletePhone (id: number): Promise<''> {
    return httpService.post(this.apiUrls.deletePhone, {}, {
      params: { id }
    })
  }
}

export const phoneService = new PhoneService()
