import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { IPart } from '@/types'
import { partsService } from '@/services'

@Module({ name: 'PartsModule', namespaced: true })
export default class PartsModule extends VuexModule {
  parts: readonly IPart[] = []
  partsLoading = false

  /* M U T A T I O N S */
  @Mutation
  SET_PARTS (payload: readonly IPart[]) {
    this.parts = payload
  }

  @Mutation
  SET_PARTS_LOADING (payload: boolean) {
    this.partsLoading = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_PARTS', rawError: true })
  async getParts () {
    return await partsService.getParts()
  }
}
