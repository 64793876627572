








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Backdrop'
})
export default class Backdrop extends Vue {
  @Prop() visible!: boolean
  @Prop({ default: 'fixed' }) position!: 'fixed' | 'absolute' | 'sticky'
}
