import { httpService } from '@/services'
import { IService } from '@/types'

class ServicesService {
  getAll (): Promise<IService[]> {
    return httpService.get('/Service/GetServices')
  }

  getLaborServices (): Promise<IService[]> {
    return httpService.get('/Service/GetLaborServices')
  }
}

export const servicesService = new ServicesService()
