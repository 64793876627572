const massMailingSidebarRoutesNames = {
  massMailingCreate: 'massMailingCreate',
  massMailingDetails: 'massMailingDetails',
  massMailingEdit: 'massMailingEdit'
}

const massMailingSidebarRoutes = [
  {
    name: massMailingSidebarRoutesNames.massMailingCreate,
    path: '/mass-mailing-create',
    meta: { group: 'LDH_MassEmail_SaveDraft' },
    component: () => import('./MassMailingCreate.vue')
  },
  {
    name: massMailingSidebarRoutesNames.massMailingDetails,
    path: '/mass-mailing-:id/details',
    meta: { group: 'LDH_MassEmail_SaveDraft' },
    component: () => import('./MassMailingDetails.vue')
  }
  // {
  //   name: 'mass-mailing-edit',
  //   path: '/mass-mailing-edit-:id/edit',
  //   meta: { group: 'LDH_EmployeeEmail_Update' },
  //   component: () => import('@/pages/command-center/mass-mailings/sidebar/MassMailingsEdit.vue')
  // }
]

export {
  massMailingSidebarRoutesNames,
  massMailingSidebarRoutes
}
