import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { importsService } from '@/services'
import { IImportType } from '@/types'
import { IImport } from './imports.types'

@Module({ name: 'ImportsModule', namespaced: true })
export default class ImportsModule extends VuexModule {
  loading = false
  imports: readonly IImport[] = []
  importTypes: IImportType[] = []

  // M U T A T I O N S
  @Mutation
  SET_LOADING (loading = false) {
    this.loading = loading
  }

  @Mutation
  SET_IMPORTS (value: readonly IImport[]) {
    this.imports = value
  }

  @Mutation
  SET_IMPORT_TYPES (value: IImportType[]) {
    this.importTypes = value
  }

  // A C T I O N S
  @Action({ commit: 'SET_IMPORTS', rawError: true })
  async getImports (activeToggleState: boolean) {
    const imports = await importsService.getImports(activeToggleState)
    return imports.sort((a: IImport, b: IImport) => {
      return Number(new Date(b.modified)) - Number(new Date(a.modified))
    })
  }

  @Action({ commit: 'SET_IMPORT_TYPES', rawError: true })
  async getImportTypes () {
    return [{ displayName: 'Part Cost Update', name: 'PartCostUpdate', id: 1 }]
  }
}
