















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICandidateProfile, IEmployee } from '@/types'
import { modules } from '@/store'
import { sidebarRouteNames } from '@/sidebar/router/sidebarRouteNames'

import CircleMarker from '@/components/shared/CircleMarker.vue'
import Skeleton from '@/components/shared/Skeleton.vue'
import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'UserInfo',
  components: {
    Skeleton,
    CircleMarker,
    Icon
  }
})
export default class UserInfo extends Vue {
  @Prop() profileInfo!: IEmployee | ICandidateProfile
  @Prop() picture?: string
  @Prop() pictureClass?: any
  @Prop() loading!: boolean
  @Prop() pictureLoading!: boolean
  @Prop() size?: 'sm' | 'md'
  @Prop({ default: true }) showId!: boolean
  @Prop({ default: false }) showEmail!: boolean
  @Prop() reversed?: string | boolean
  @Prop() isEmployeeProfile !: boolean
  @Prop() isCandidateProfile !: boolean

  /* C O M P U T E D */
  get propSize () {
    return {
      sm: this.size === 'sm',
      md: this.size === 'md' || !this.size
    }
  }

  get propReversed () {
    return this.reversed === '' || !!this.reversed
  }

  get profileName () {
    if (this.profileInfo && this.profileInfo.firstName && this.profileInfo.lastName) {
      return {
        fullName: this.profileInfo.firstName + ' ' + this.profileInfo.lastName,
        abbrv: this.profileInfo.firstName.slice(0, 1) + this.profileInfo.lastName.slice(0, 1)
      }
    }
    return {
      fullName: '',
      abbrv: ''
    }
  }

  get userProfileEmail () {
    if (this.isEmployeeProfile || this.isCandidateProfile) {
      return ((this.profileInfo as IEmployee).emails[0] || { emailAddress: '' }).emailAddress
    }
    return modules.user.userPrimaryEmail
  }

  get employeeProfileTags () {
    return this.profileInfo.tags
  }

  get otherEmployeeIds () {
    return (this.profileInfo as IEmployee).otherIds || []
  }

  /* M E T H O D S */
  getOtherEmployee (id: number) {
    this.$sidebar.push({
      name: this.isCandidateProfile ? sidebarRouteNames.candidateProfile : sidebarRouteNames.employeeProfile,
      params: { id: String(id) }
    })
  }
}
