import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { securityService } from '@/services'
import { IPermission, IRole, IAuditReport } from '@/types'

@Module({ name: 'SecurityModule', namespaced: true })
export default class SecurityModule extends VuexModule {
  permissions: readonly IPermission[] = []
  auditReports: readonly IAuditReport[] = []
  permissionsLoading = false
  auditReportLoading = false

  roles: readonly IRole[] = []
  role: IRole = {} as IRole

  employeeRoles: number[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_PERMISSIONS (payload: readonly IPermission[]) {
    this.permissions = payload
  }

  @Mutation
  SET_AUDIT_REPORTS (payload: readonly IAuditReport[]) {
    this.auditReports = payload
  }

  @Mutation
  SET_PERMISSIONS_LOADING (payload: boolean) {
    this.permissionsLoading = payload
  }

  @Mutation
  SET_AUDIT_REPORT_LOADING (payload: boolean) {
    this.auditReportLoading = payload
  }

  @Mutation
  SET_ROLES (payload: readonly IRole[]) {
    this.roles = payload
  }

  @Mutation
  SET_ROLE (payload: IRole) {
    this.role = payload
  }

  @Mutation
  SET_EMPLOYEE_ROLES (payload: number[]) {
    this.employeeRoles = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_PERMISSIONS', rawError: true })
  async getGroupedPermissionsByRole (id: number) {
    return await securityService.getGroupedPermissionsByRole(id)
  }

  @Action({ commit: 'SET_AUDIT_REPORTS', rawError: true })
  async getAuditReports () {
    return await securityService.getAuditReports()
  }

  @Action({ rawError: true })
  async getSecurityByEmployeeList (id: number) {
    const securityList = await securityService.getSecurityByEmployee(id)

    this.context.commit('SET_EMPLOYEE_ROLES', securityList.roles.map((role: IRole) => role.id))

    this.context.commit('SET_PERMISSIONS', securityList.permissions)
  }

  @Action({ commit: 'SET_ROLES', rawError: true })
  async getRoles () {
    return await securityService.getActiveRoles()
  }
}
