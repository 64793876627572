import { httpService } from '@/services'

import {
  IRequisitionMain,
  IRequisitionById,
  IJobById,
  IUnattachedPosting
} from '@/types'

class RequisitionService {
  /* R E Q U I S I T I O N S */
  getRequisitions (activeOnly: boolean): Promise<IRequisitionMain[]> {
    return httpService.get('/Requisition/GetRequisitions', { params: { activeOnly } })
  }

  getActiveRequisitionsAsList (): Promise<IRequisitionMain[]> {
    return httpService.get('/Requisition/GetActiveRequisitionsAsList')
  }

  getRequisitionBlank (): Promise<any> {
    return httpService.get('/Requisition/GetRequisitionBlank')
  }

  getRequisitionById (id: string): Promise<IRequisitionById> {
    return httpService.get('/Requisition/GetRequisitionById', { params: { id } })
  }

  getJobById (id: string): Promise<IJobById> {
    return httpService.get('/Requisition/GetJobById', { params: { id } })
  }

  getNextSequenceForLocation (id: number): Promise<number> {
    return httpService.get('/Requisition/GetNextSequenceForLocation', { params: { id } })
  }

  getPostingsByRequisitionId (id: string): Promise<IUnattachedPosting[]> {
    return httpService.get('/Requisition/GetPostingsByRequisitionIdForRequisition', { params: { id } })
  }

  getRequisitionByCandidateId (id: string): Promise<IJobById> {
    return httpService.get('/Requisition/GetByCandidateId', { params: { id } })
  }

  saveRequisition (requisition: IRequisitionById): Promise<''> {
    return httpService.post('/Requisition/SaveRequisition', requisition)
  }

  updateRequisition (requisition: IRequisitionById): Promise<''> {
    return httpService.post('/Requisition/UpdateRequisition', requisition)
  }

  toggleRequisition (id: number): Promise<''> {
    return httpService.post('/Requisition/ToggleRequisition', {}, { params: { id } })
  }

  togglePublishRequisition (id: number): Promise<''> {
    return httpService.post('/Requisition/TogglePublishRequisition', {}, { params: { id } })
  }

  attachPostingToRequisition (payload: any): Promise<''> {
    return httpService.post('/Requisition/AttachPostingToRequisition', payload)
  }

  detachPostingFromRequisition (postingId: number): Promise<''> {
    return httpService.post('/Requisition/DetachPostingFromRequisition', {}, { params: { postingId } })
  }
}

export const requisitionService = new RequisitionService()
