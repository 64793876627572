import { httpService } from '@/services'
import { ITextControlRefreshToken } from '@/types/text-control.types'

class TextControlService {
  async getToken (): Promise<string> {
    const refreshToken: ITextControlRefreshToken = await httpService.post(
      '/TextControlToken/RefreshToken'
    )
    return refreshToken.access_token
  }
}

export const textControlService = new TextControlService()
