import * as Msal from 'msal'
// import * as microsoftTeams from '@microsoft/teams-js'

import { modules } from '@/store'
import { IEmployee } from '@/types'
import { httpService } from '@/services'
import { appRouter } from '@/router'
import { sidebarRouter } from '@/sidebar/router'
import { dashboardRouteNames } from '@/pages/dashboard/dashboard.routes'

class AuthService {
  constructor () {
    this.msalInstance.handleRedirectCallback(this.loginRedirectCallback)
  }

  securityGroupsStorageName = 'Aegis-Security-Groups'
  aegisRedirectionUrl = 'Aegis-Redirection-Url'
  localStorageTokenKey = 'Aegis-Token'
  // isTeamsTabInstance = window.name.includes('extension')

  private msalConfig = {
    auth: {
      clientId: process.env.VUE_APP_CLIENT_ID as string,
      authority: process.env.VUE_APP_AUTHORITY_BASE_URL + '/' + process.env.VUE_APP_TENANT_ID,
      redirectUri: this.getOrigin(),
      postLogoutRedirectUri: this.getOrigin() + '/' + process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
      clientSecret: process.env.VUE_APP_CLIENT_ID,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: 'sessionStorage' as 'sessionStorage',
      storeAuthStateInCookie: false
    }
  }

  authConfig = {
    scopes: ['user.read']
  }

  webApiConfig = {
    scopes: [`api://${process.env.VUE_APP_WEB_API_CLIENT_ID}/access_as_user`]
  }

  msalInstance = new Msal.UserAgentApplication(this.msalConfig)

  async isAuthenticated () {
    // if (!this.msalInstance) {
    //   this.msalInstance = new Msal.UserAgentApplication(this.msalConfig)
    // }
    return await this.msalInstance.getAccount()
    // this.isTeamsTabInstance ? !!modules.user.tokenInfo.accessToken : await this.msalInstance.getAccount()
  }

  isInGroup (group: (string | string[]), includesAll = true) {
    if (Array.isArray(group)) {
      if (includesAll) {
        return group.every(groupItem => {
          return modules.user.groups.includes(groupItem)
        })
      } else {
        return group.some(groupItem => {
          return modules.user.groups.includes(groupItem)
        })
      }
    } else {
      return modules.user.groups.includes(group)
    }
  }

  get isManager () {
    return this.isInGroup('LDH_Technician_Manager')
  }

  acquireToken () {
    // if (this.isTeamsTabInstance) {
    //   return this.teamsLogin()
    // } else {
    return this.msalInstance.acquireTokenSilent(this.webApiConfig)
    // }
  }

  async loginRedirectCallback (err: Msal.AuthError, res: Msal.AuthResponse | undefined) {
    if (err) console.error(err)
    else if (res) {
      if (modules) {
        await modules.user.SET_TOKEN({ accessToken: res.accessToken, expiresOn: res.expiresOn })
      }

      const redirectionUrl = localStorage.getItem(this.aegisRedirectionUrl)
      if (redirectionUrl) {
        const routes = redirectionUrl.split('#/')
        const appRoute = routes[0].replace(window.origin, '')
        const sidebarRoute = routes[1]
        await appRouter.push(appRoute)
        await sidebarRouter.push(sidebarRoute)
        localStorage.removeItem(this.aegisRedirectionUrl)
      } else await appRouter.push({ name: dashboardRouteNames.dashboard })
    }
  }

  async afterLoginProcess (accessToken: string, expiresOn: Date) {
    modules.user.SET_TOKEN({ accessToken, expiresOn })
    const redirectionUrl = localStorage.getItem(this.aegisRedirectionUrl)
    if (redirectionUrl) {
      const routes = redirectionUrl.split('#/')
      const appRoute = routes[0].replace(window.origin, '')
      const sidebarRoute = routes[1]
      await appRouter.push(appRoute)
      await sidebarRouter.push(sidebarRoute)
      localStorage.removeItem(this.aegisRedirectionUrl)
    } else await appRouter.push({ name: dashboardRouteNames.dashboard })
  }

  async login () {
    // if (this.isTeamsTabInstance) {
    //   return this.teamsLogin()
    // } else {
    modules.general.SET_LOADING(true)
    modules.general.SET_LOADING_TEXT('Logging in...')
    localStorage.clear()
    return this.webLogin()
    // }
  }

  async webLogin () {
    await this.msalInstance.loginRedirect(this.authConfig)
    // const res = await this.msalInstance.acquireTokenSilent(this.webApiConfig)
    // return this.afterLoginProcess(res.accessToken, res.expiresIn)
  }

  // async teamsLogin () {
  //   return new Promise((resolve, reject) => {
  //     microsoftTeams.initialize(() => {
  //       microsoftTeams.authentication.authenticate({
  //         url: process.env.VUE_APP_ORIGIN + '/auth/teams-auth',
  //         width: 600,
  //         height: 535,
  //         successCallback: (res: any) => this.afterLoginProcess(res.accessToken, res.expiresIn),
  //         failureCallback: (e: any) => reject(e)
  //       })
  //     })
  //   })
  // }

  getUser (): Promise<{ employee: IEmployee; securityGroups: string[] }> {
    return httpService.post('/ActiveDirectory/GetProfile')
  }

  logout () {
    modules.user.CLEAR_USER()
    sessionStorage.clear()
    localStorage.clear()
    this.msalInstance.logout()
  }

  getOrigin () {
    return window.location.origin
  }
}

export const authService = new AuthService()
