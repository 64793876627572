import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { IVendor } from '@/types'
import { vendorsService } from '@/services'

@Module({ name: 'VendorsModule', namespaced: true })
export default class VendorsModule extends VuexModule {
  vendors: readonly IVendor[] = []
  vendorsLoading = false

  /* M U T A T I O N S */
  @Mutation
  SET_VENDORS (payload: readonly IVendor[]) {
    this.vendors = payload
  }

  @Mutation
  SET_VENDORS_LOADING (payload: boolean) {
    this.vendorsLoading = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_VENDORS', rawError: true })
  async getVendors () {
    return await vendorsService.getVendors()
  }
}
