const exportsSidebarRoutesNames = {
  exportCreate: 'exportCreate',
  exportDetails: 'exportDetails'
}

const exportsSidebarRoutes = [
  {
    name: exportsSidebarRoutesNames.exportCreate,
    path: '/export-create',
    component: () => import('./export-create/ExportCreate.vue'),
    meta: { group: '' }
  },
  {
    name: exportsSidebarRoutesNames.exportDetails,
    path: '/export-:id',
    component: () => import('./ExportDetails.vue'),
    meta: { group: 'LDH_ExportReportDetail_View', width: 'calc(100% - 250px)', minWidth: 'calc(100% - 250px)' }
  }
]

export {
  exportsSidebarRoutesNames,
  exportsSidebarRoutes
}
