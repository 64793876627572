import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { activeDirectoryService, collectionsService, documentService, employeeService } from '@/services'
import { modules } from '@/store'
import { date } from '@/helpers'

import {
  IGuardEmployee,
  TIndexedObject,
  IEmployee,
  IEmployeeSearch,
  IFileData,
  IAccountStatus,
  ISalesPerson,
  IEmployeeCollections,
  IEmployeePersonal,
  IEmployeeContact,
  IEmployeeEmployement,
  IEmployeeIntegrations,
  IEmployeeEmployementGuardTerm,
  IDocuSignDocument,
  IDocuSign
} from '@/types'

@Module({ name: 'EmployeeModule', namespaced: true })
export default class EmployeeModule extends VuexModule {
  employees = [] as IGuardEmployee[]
  employeeProfile: IEmployee = {} as IEmployee
  employeeProfileLoading = false
  employeesLoading = false
  employeePictureData: IFileData | null = null
  searchEmployees: IEmployeeSearch[] = []
  employeeAccountStatus: IAccountStatus = {} as IAccountStatus
  salesPersons: ISalesPerson[] = []
  guardTerms: IEmployeeEmployementGuardTerm[] = []

  profileIsSetup = false

  genderItems: any[] =[]
  referralItems: any[] =[]
  suffixItems: any[] =[]
  titleItems: any[] =[]
  raceItems: any[] =[]
  phoneTypeItems: any[] =[]
  emailTypeItems: any[] =[]
  maritalStatusItems: any[] =[]
  relationshipTypeItems: any[] =[]
  accountTypeItems: any[] =[]
  employeeTypeItems: any[] = []
  employeeStatusTypeItems: any[] =[]
  employmentTypeItems: any[] =[]
  candidateStatusTypeItems: any[] =[]
  candidateTypeItems: any[] =[]
  docuSignById: IDocuSign[] = []
  employeeDocuSign: (IDocuSignDocument & { loading: boolean })[] = []
  envelopeId: any
  currentEmployeeId: any

  // G E T T E R S
  get employeePicture () {
    return this.employeePictureData ? this.employeePictureData.fileData : null
  }

  get employeeSearchIndex () {
    return this.searchEmployees.reduce((acc, searchItem) => {
      acc[searchItem.value] = searchItem
      return acc
    }, {} as TIndexedObject<IEmployeeSearch>)
  }

  // M U T A T I O N S
  @Mutation
  SET_EMPLOYEES (payload: IGuardEmployee[]) {
    this.employees = payload
  }

  @Mutation
  SET_EMPLOYEE_PROFILE_LOADING (payload: boolean) {
    this.employeeProfileLoading = payload
  }

  @Mutation
  SET_EMPLOYEES_LOADING (payload: boolean) {
    this.employeesLoading = payload
  }

  @Mutation
  SET_EMPLOYEE_PICTURE (payload: IFileData | null) {
    this.employeePictureData = payload
  }

  @Mutation
  SET_EMPLOYEE_PROFILE (payload: IEmployee) {
    this.employeeProfile = payload
  }

  @Mutation
  ADD_SEARCH_EMPLOYEE_ITEMS (employees: IEmployeeSearch[] | IEmployee[]) {
    if (employees.length === 0) return

    let searchItems: IEmployeeSearch[]
    if (
      (employees[0] as IEmployee).id && (employees[0] as IEmployee).lastName &&
      (employees[0] as IEmployee).firstName
    ) {
      searchItems = (employees as IEmployee[]).map(employee => ({
        value: String(employee.id),
        text: `${employee.id} - ${employee.lastName}, ${employee.firstName} (${employee.dob ? date(employee.dob) : ((employee as any).dateOfBirthd || '-')})`,
        placeholder: `${employee.id} - ${employee.lastName}, ${employee.firstName}`
      })) as IEmployeeSearch[]
    } else {
      searchItems = employees as IEmployeeSearch[]
    }

    searchItems.forEach(searchItem => {
      if (!modules.employee.employeeSearchIndex[searchItem.value]) {
        this.searchEmployees.push(searchItem)
      }
    })
  }

  @Mutation
  SET_EMPLOYEE_ACCOUNT_STATUS (payload: IAccountStatus) {
    this.employeeAccountStatus = payload
  }

  @Mutation
  SET_SALES_PERSONS (payload: ISalesPerson[]) {
    this.salesPersons = payload
  }

  @Mutation
  SETUP_EMPLOYEE_PROFILE (payload: IEmployeeCollections) {
    this.maritalStatusItems = payload.maritalStatusItems
    this.genderItems = payload.genderItems
    this.suffixItems = payload.suffixItems
    this.titleItems = payload.titleItems
    this.raceItems = payload.raceItems
    this.phoneTypeItems = payload.phoneTypeItems
    this.emailTypeItems = payload.emailTypeItems
    this.referralItems = payload.referralItems
    this.relationshipTypeItems = payload.relationshipTypeItems
    this.accountTypeItems = payload.accountTypeItems
    this.employeeTypeItems = payload.employeeTypeItems
    this.employeeStatusTypeItems = payload.employeeStatusTypeItems
    this.employmentTypeItems = payload.employmentTypeItems
    this.candidateStatusTypeItems = payload.candidateStatusTypeItems
    this.candidateTypeItems = payload.candidateTypeItems
    this.profileIsSetup = true
  }

  @Mutation
  SET_EMPLOYEE_PERSONAL (payload: IEmployeePersonal) {
    this.employeeProfile.id = payload.id
    this.employeeProfile.titleId = payload.titleId
    this.employeeProfile.suffixId = payload.suffixId
    this.employeeProfile.firstName = payload.firstName
    this.employeeProfile.lastName = payload.lastName
    this.employeeProfile.preferredName = payload.preferredName
    this.employeeProfile.middleInitial = payload.middleInitial
    this.employeeProfile.maidenName = payload.maidenName
    this.employeeProfile.dob = payload.dob
    this.employeeProfile.genderId = payload.genderId
    this.employeeProfile.raceId = payload.raceId
    this.employeeProfile.maritalStatusId = payload.maritalStatusId
    this.employeeProfile.ssn = payload.ssn
    this.employeeProfile.heightFeet = payload.heightFeet
    this.employeeProfile.heightInches = payload.heightInches
    this.employeeProfile.weight = payload.weight
    this.employeeProfile.hairColor = payload.hairColor
    this.employeeProfile.eyeColor = payload.eyeColor
    this.employeeProfile.disability = payload.disability
    this.employeeProfile.tobacco = payload.tobacco
    this.employeeProfile.veteran = payload.veteran
    this.employeeProfile.veteranDischargeDate = payload.veteranDischargeDate
    this.employeeProfile.referralId = payload.referralId
    this.employeeProfile.referralOther = payload.referralOther
    this.employeeProfile.dependents = payload.dependents
    this.employeeProfile.eligibleToWorkInUsa = payload.eligibleToWorkInUsa
    this.employeeProfile.haveBeenConvicted = payload.haveBeenConvicted
    this.employeeProfile.isHighSchoolOrGed = payload.isHighSchoolOrGed
    this.employeeProfile.uniformDeposit = payload.uniformDeposit
    this.employeeProfile._90dayFee = payload._90dayFee
    this.employeeProfile.tags = payload.tags
    this.employeeProfile.isDoNotHire = payload.isDoNotHire
    this.employeeProfile.obsolete = payload.obsolete
    this.employeeProfile.terminated = payload.terminated
    this.employeeProfile.modified = payload.modified
    this.employeeProfile.modifiedBy = payload.modifiedBy
  }

  @Mutation
  SET_EMPLOYEE_CONTACT (payload: IEmployeeContact) {
    this.employeeProfile.phones = payload.phones
    this.employeeProfile.emails = payload.emails
    this.employeeProfile.emergencyContact = payload.emergencyContact
    this.employeeProfile.addresses = payload.addresses
  }

  @Mutation
  SET_EMPLOYEE_EMPLOYMENT (payload: IEmployeeEmployement) {
    this.employeeProfile.managerId = payload.managerId
    this.employeeProfile.managerName = payload.managerName
    this.employeeProfile.jobTitle = payload.jobTitle
    this.employeeProfile.employeeTypeId = payload.employeeTypeId
    this.employeeProfile.employmentTypeId = payload.employmentTypeId
    this.employeeProfile.employeeStatusTypeId = payload.employeeStatusTypeId
    this.employeeProfile.terminateDate = payload.terminateDate
    this.employeeProfile.startDate = payload.startDate
    this.employeeProfile.processingDate = payload.processingDate
    this.guardTerms = payload.guardTerms
  }

  @Mutation
  SET_EMPLOYEE_INTEGRATIONS (payload: IEmployeeIntegrations) {
    this.employeeProfile.activeDirectoryPrincipal = payload.activeDirectoryPrincipal
    this.employeeProfile.celayixEmployeeId = payload.celayixEmployeeId
    this.employeeProfile.flowFinityId = payload.flowFinityId
    this.employeeProfile.paycorId = payload.paycorId
    this.employeeProfile.netSuiteId = payload.netSuiteId
  }

  @Mutation
  SET_DOCUSIGN_BY_ID (payload: IDocuSign[]) {
    this.docuSignById = payload
  }

  @Mutation
  SET_EMPLOYEE_DOCUSIGN (payload: (IDocuSignDocument & { loading: boolean })[]) {
    this.employeeDocuSign = payload
  }

  // A C T I O N S
  @Action({ commit: 'SET_EMPLOYEES', rawError: true })
  async getEmployees (activeToggleState: boolean) {
    return await employeeService.getEmployees(activeToggleState)
  }

  @Action({ commit: 'SET_EMPLOYEE_PICTURE', rawError: true })
  async getEmployeePicture (employeeId: number) {
    return await employeeService.getEmployeeImage(employeeId)
  }

  @Action({ commit: 'SET_EMPLOYEE_PROFILE', rawError: true })
  async getEmployeeProfile (employeeId: number) {
    return await employeeService.getEmployeeById(employeeId)
  }

  @Action({ commit: 'SET_EMPLOYEE_STATUS_TYPES', rawError: true })
  async getEmployeeStatusTypes () {
    return await collectionsService.getCollection('employeeStatusTypes')
  }

  @Action({ commit: 'SET_EMPLOYEE_RELATIONSHIP_TYPES', rawError: true })
  async getEmployeeRelationshipTypes () {
    return await collectionsService.getCollection('employeeRelationshipTypes')
  }

  @Action({ commit: 'SET_EMPLOYMENT_TYPES', rawError: true })
  async getEmploymentTypes () {
    return await collectionsService.getCollection('employmentTypes')
  }

  @Action({ commit: 'SET_EMPLOYEE_ACCOUNT_STATUS', rawError: true })
  async getAccountStatus (employeeId: number) {
    return await activeDirectoryService.getAccountStatusByEmployeeId(employeeId)
  }

  @Action({ commit: 'SET_SALES_PERSONS', rawError: true })
  async getSalesPersons () {
    return await employeeService.getSalesPersons()
  }

  @Action({ commit: 'SETUP_EMPLOYEE_PROFILE', rawError: true })
  async setupEmployeeProfile () {
    return await employeeService.setupEmployeeProfile()
  }

  @Action({ commit: 'SET_EMPLOYEE_PERSONAL', rawError: true })
  async GetEmployeePersonal (employeeId: number) {
    return await employeeService.getEmployeePersonalById(employeeId)
  }

  @Action({ commit: 'SET_EMPLOYEE_CONTACT', rawError: true })
  async GetEmployeeContact (employeeId: number) {
    return await employeeService.getEmployeeContactById(employeeId)
  }

  @Action({ commit: 'SET_EMPLOYEE_EMPLOYMENT', rawError: true })
  async GetEmployeeEmployment (employeeId: number) {
    return await employeeService.getEmployeeEmploymentById(employeeId)
  }

  @Action({ commit: 'SET_EMPLOYEE_INTEGRATIONS', rawError: true })
  async GetEmployeeIntegrations (employeeId: number) {
    return await employeeService.getEmployeeIntegrationsById(employeeId)
  }

  @Action({ commit: 'SET_DOCUSIGN_BY_ID', rawError: true })
  async GetDocuSignById (ownerId: any) {
    return await documentService.getDocuSignInformationById(Number(ownerId))
  }

  @Action({ commit: 'SET_EMPLOYEE_DOCUSIGN', rawError: true })
  async GetEmployeeDocuSign (id: string) {
    this.currentEmployeeId = id
    const docuSignById = await this.GetDocuSignById(id)
    if (docuSignById.length > 0) {
      this.envelopeId = docuSignById[0].id
      return (await documentService.getDocuSignList(this.envelopeId)).map(d => ({
        ...d,
        loading: false
      }))
    }
  }
}
