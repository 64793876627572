









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { authService } from '@/services'
import { routeNames } from '@/router/routeNames'
import { modules } from '@/store'
import { sidebarRouteNames } from '@/sidebar/router/sidebarRouteNames'

import Backdrop from '@/components/shared/Backdrop.vue'
import UserInfo from '@/components/shared/UserInfo.vue'
import MenuList from '@/components/shared/menu/MenuList.vue'
import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'MobileMenu',
  components: {
    Backdrop,
    UserInfo,
    MenuList,
    Icon
  }
})
export default class MobileMenu extends Vue {
  /* P R O P S */
  @Prop() webApiVersion!: number
  @Prop() version!: number

  /* R E F S */
  $refs!: {
    menuList: MenuList & {
      openActiveRouteCollapse: (key: string) => void;
    };
  }

  /* C O M P U T E D */
  get mobileMenuOpened () {
    return modules.general.mobileMenuOpened
  }

  get userProfileVisible () {
    return authService.isInGroup('LDH_Me')
  }

  get userProfile () {
    return modules.user.userProfile
  }

  get userPicture () {
    return modules.user.userPicture
  }

  get userProfileLoading () {
    return modules.user.userProfileLoading
  }

  get userPictureLoading () {
    return modules.user.userPictureLoading
  }

  /* M E T H O D S */
  // Closes mobile menu
  closeMobileMenu () {
    modules.general.SET_MOBILE_MENU_STATE(false)
    if (this.$route.meta) {
      this.$refs.menuList.openActiveRouteCollapse(this.$route.meta.parentName)
    }
  }

  goToUserProfile () {
    if (this.$sidebarRoute.name !== sidebarRouteNames.userProfile) {
      this.$sidebar.push({
        name: sidebarRouteNames.userProfile,
        params: {
          id: String(modules.user.userProfile.id)
        }
      })
    }
    this.closeMobileMenu()
  }

  logout () {
    authService.logout()
  }

  onLogoClick () {
    if (this.$sidebarRoute.path !== '/') {
      this.$sidebar.push('/')
    }
    if (this.$appRoute.name !== routeNames.dashboard) {
      this.$appRouter.push({ name: routeNames.dashboard })
    }
    this.closeMobileMenu()
  }
}
