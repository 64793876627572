const transactionsSidebarRouteNames = {
  transactionDetails: 'transactionDetails',
  transactionCreate: 'transactionCreate'
}

const transactionsSidebarRoutes = [
  {
    name: transactionsSidebarRouteNames.transactionDetails,
    path: '/transaction-:id/details',
    props: true,
    component: () => import('./TransactionDetails.vue'),
    meta: { group: 'LDH_IntegrationTransaction_View' }
  },
  {
    name: transactionsSidebarRouteNames.transactionCreate,
    path: '/transaction-create',
    component: () => import('./TransactionCreate.vue'),
    meta: { group: 'LDH_IntegrationTransaction_Add' }
  }
]
export {
  transactionsSidebarRouteNames,
  transactionsSidebarRoutes
}
