import { ICollectionConfig } from '@/types'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsUniformTypes: ICollectionConfig[] = [
  {
    label: 'Uniform Types',
    group: 'UniformType',
    getEndpoint: '/UniformType/GetAllActive',
    saveEndpoint: '/UniformType/Create',
    updateEndpoint: '/UniformType/Update',
    toggleEndpoint: '/UniformType/Toggle',
    collectionName: collectionNames.uniformTypes,
    headings: [
      { label: 'Damage Fee', value: 'damageFee', sortable: true, width: 150, align: 'center' }
    ],
    form: [
      ...naming,
      {
        component: 'InputModule',
        model: 'damageFee',
        props: { label: 'Damage Fee', placeholder: 'Damage Fee', type: 'decimal', class: 'w-full mt-4' }
      }
    ]
  }
]
