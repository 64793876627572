import { RouteConfig } from 'vue-router'
import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.techOps

const techOpsRouteNames = {
  dropShip: 'DropShip',
  parts: 'Part',
  projects: 'Project',
  serviceCalls: 'ServiceCall',
  schedule: 'Schedule',
  unbillable: 'Unbillable',
  contractApprovalType: 'ContractApprovalType'
}

const techOpsRoutes: RouteConfig[] = [
  {
    name: techOpsRouteNames.dropShip,
    path: 'drop-ship',
    meta: {
      parentName,
      group: 'LDH_Nav_Drop_Ship_View',
      label: 'Drop Ship'
    },
    component: () => import('@/pages/tech-ops/drop-ship/DropShip.vue')
  },
  {
    name: techOpsRouteNames.parts,
    path: 'parts',
    meta: {
      parentName,
      group: 'LDH_Nav_Parts_View',
      label: 'Parts'
    },
    component: () => import('@/pages/tech-ops/parts/Parts.vue')
  },
  {
    name: techOpsRouteNames.projects,
    path: 'projects',
    meta: {
      parentName,
      group: 'LDH_Nav_Projects_View',
      label: 'Projects'
    },
    component: () => import('@/pages/tech-ops/projects/Projects.vue')
  },
  {
    name: techOpsRouteNames.serviceCalls,
    path: 'service-calls',
    meta: {
      parentName,
      group: 'LDH_Nav_Service_Calls_View',
      label: 'Service Calls'
    },
    component: () => import('@/pages/tech-ops/service-calls/ServiceCalls.vue')
  },
  {
    name: techOpsRouteNames.schedule,
    path: 'schedule',
    meta: {
      parentName,
      group: 'LDH_Nav_Schedule_View',
      label: 'Schedule'
    },
    component: () => import('@/pages/tech-ops/schedule/Schedule.vue')
  },
  {
    name: techOpsRouteNames.unbillable,
    path: 'unbillable',
    meta: {
      parentName,
      group: 'LDH_Nav_Unbillable_View',
      label: 'Unbillable'
    },
    component: () => import('@/pages/tech-ops/unbillable/Unbillable.vue')
  },
  {
    name: techOpsRouteNames.contractApprovalType,
    path: 'contractApprovals',
    meta: {
      parentName,
      label: 'Contract Approvals'
    },
    component: () => import('@/pages/tech-ops/contract-approval-types/ContractApprovalTypes.vue')
  }
]

export {
  techOpsRouteNames,
  techOpsRoutes
}
