import { httpService } from '@/services'
import {
  IFileData,
  IServiceCall,
  IServiceCallAttachmentPayload,
  IServiceCallById,
  IServiceCallIssueNoteType,
  IServiceCallIssueUpdatePayload,
  IServiceCallPart,
  IServiceCallPartPayload,
  IServiceCallSearch,
  IServiceCallStatusType,
  IServiceCallType,
  IServiceCallVendor
} from '@/types'

class ServiceCallsService {
  getAll (activeToggleState: boolean): Promise<IServiceCall[]> {
    return httpService.get(`/ServiceCall/GetAll${activeToggleState ? 'Active' : ''}`)
  }

  getById (id: number | string): Promise<IServiceCallById> {
    return httpService.get('/ServiceCall/GetById', { params: { id } })
  }

  save (payload: any): Promise<{ id: number }> {
    return httpService.post('/ServiceCall/Save', payload)
  }

  update (payload: { id: number; customerSiteId: number; contacts: any }): Promise<''> {
    return httpService.post('/ServiceCall/Update', payload)
  }

  search (pattern: string): Promise<IServiceCallSearch[]> {
    return httpService.get('/ServiceCall/search', { params: { pattern } })
  }

  toggle (id: number): Promise<''> {
    return httpService.post('/ServiceCall/Toggle', {}, { params: { id } })
  }

  // S E R V I C E  C A L L  I S S U E
  saveIssue (issue: any): Promise<''> {
    return httpService.post('/ServiceCallIssue/Save', issue)
  }

  updateIssue (issue: IServiceCallIssueUpdatePayload): Promise<''> {
    return httpService.post('/ServiceCallIssue/Update', issue)
  }

  toggleIssue (id: number): Promise<''> {
    return httpService.post('/ServiceCallIssue/Toggle', {}, { params: { id } })
  }

  toggleIssueLock (id: string, isLocked: boolean): Promise<''> {
    return httpService.post(`/ServiceCallIssue/${isLocked ? 'Unlock' : 'Lock'}`, {}, { params: { id } })
  }

  // I S S U E  P A R T
  getPartsByIssueId (issueId: number): Promise<IServiceCallPart[]> {
    return httpService.get('/ServiceCallIssuePart/GetByIssueId', { params: { issueId } })
  }

  removeIssuePart (id: number): Promise<''> {
    return httpService.post('/ServiceCallIssuePart/Remove', {}, { params: { id } })
  }

  addIssueParts (vendors: { issueId: number; parts: { partId: number; quantity: number }[] }): Promise<''> {
    return httpService.post('/ServiceCallIssuePart/AddRange', vendors)
  }

  updateIssuePart (payload: IServiceCallPartPayload): Promise<''> {
    return httpService.post('/ServiceCallIssuePart/Update', payload)
  }

  //  I S S U E  V E N D O R
  removeIssueVendor (id: number): Promise<''> {
    return httpService.post('/ServiceCallIssueVendor/Remove', {}, { params: { id } })
  }

  addIssueVendors (vendors: { issueId: number; vendors: { vendorId: number }[] }): Promise<''> {
    return httpService.post('/ServiceCallIssueVendor/AddRange', vendors)
  }

  getVendorsByIssueId (issueId: number): Promise<IServiceCallVendor[]> {
    return httpService.get('/ServiceCallIssueVendor/GetAllForIssue', { params: { issueId } })
  }

  //  I S S U E  A T T A C H M E N T
  removeIssueAttachment (id: number): Promise<''> {
    return httpService.post('/ServiceCallIssueAttachment/Remove', {}, { params: { id } })
  }

  addIssueManyAttachments (payload: IServiceCallAttachmentPayload): Promise<''> {
    return httpService.post('/ServiceCallIssueAttachment/AddRange', payload)
  }

  getAttachmentsByIssueId (issueId: number): Promise<IFileData[]> {
    return httpService.get('/ServiceCallIssueAttachment/GetByIssueId', { params: { issueId } })
  }

  // T Y P E S
  getAllTypes (): Promise<IServiceCallType[]> {
    return httpService.get('/ServiceCallType/GetAll')
  }

  saveType (serviceCallType: IServiceCallType): Promise<''> {
    return httpService.post('/ServiceCallType/Save', serviceCallType)
  }

  updateType (serviceCallType: IServiceCallType): Promise<''> {
    return httpService.post('/ServiceCallType/Update', serviceCallType)
  }

  toggleType (id: number): Promise<''> {
    return httpService.post('/ServiceCallType/Toggle', {}, { params: { id } })
  }

  // S T A T U S  T Y P E S
  getAllStatusTypes (): Promise<IServiceCallStatusType[]> {
    return httpService.get('/ServiceCallStatusType/GetAll')
  }

  saveStatusType (serviceCallType: IServiceCallStatusType): Promise<''> {
    return httpService.post('/ServiceCallStatusType/Save', serviceCallType)
  }

  updateStatusType (serviceCallType: IServiceCallStatusType): Promise<''> {
    return httpService.post('/ServiceCallStatusType/Update', serviceCallType)
  }

  toggleStatusType (id: number): Promise<''> {
    return httpService.post('/ServiceCallStatusType/Toggle', {}, { params: { id } })
  }

  // I S S U E  N O T E  T Y P E S
  getAllIssueNoteTypes (): Promise<IServiceCallStatusType[]> {
    return httpService.get('/ServiceCallIssueNoteType/GetAll')
  }

  saveIssueNoteType (serviceCallType: IServiceCallIssueNoteType): Promise<''> {
    return httpService.post('/ServiceCallIssueNoteType/Save', serviceCallType)
  }

  updateIssueNoteType (serviceCallType: IServiceCallIssueNoteType): Promise<''> {
    return httpService.post('/ServiceCallIssueNoteType/Update', serviceCallType)
  }

  toggleIssueNoteType (id: number): Promise<''> {
    return httpService.post('/ServiceCallIssueNoteType/Toggle', {}, { params: { id } })
  }
}

export const serviceCallsService = new ServiceCallsService()
