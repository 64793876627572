











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Icon from '@/components/shared/Icon.vue'
import CollapseTransition from '@/components/shared/collapse/CollapseTransition.vue'

interface IItem {
  disabled: boolean;
  [key: string]: any;
}

type TItemClass = ((item: any, index: number) => string) | string

@Component({
  name: 'Collapse',
  components: {
    Icon,
    CollapseTransition
  }
})
export default class Collapse extends Vue {
  /* P R O P S */
  @Prop() items!: IItem[]
  @Prop({ default: 'id' }) uniqueKey!: string
  @Prop() accordion?: boolean
  @Prop() openedBg?: string
  @Prop() disabled?: boolean
  @Prop() defaultOpen?: string
  @Prop() activatorClass?: string
  @Prop({ default: '0.3' }) speed?: string
  @Prop({ default: true }) calculateHeight!: boolean
  @Prop() itemClass?: TItemClass | TItemClass[]
  @Prop({ default: false }) sticky?: boolean

  /* D A T A */
  opened: any = {}

  /* M E T H O D S */
  // Opens specific collapse item
  open (key: string) {
    if (!this.opened[key]) {
      this.toggle(key)
    }
  }

  // Opens or closes specific collapse item
  toggle (key: string) {
    if (this.accordion) {
      this.opened = {
        [key]: this.opened[key]
      }
    }
    this.$set(this.opened, key, !this.opened[key])
    this.$emit('toggle', key)
  }

  closeAll () {
    this.opened = {}
  }

  openAll () {
    if (this.items.length > 0 && !this.accordion) {
      this.items.map(i => i[this.uniqueKey]).forEach(key => this.open(key))
    }
  }

  parseItemClass (item: any, index: number) {
    if (this.itemClass) {
      let classes: TItemClass | TItemClass[] = this.itemClass
      if (!Array.isArray(classes)) {
        classes = [classes]
      }
      return classes.map(classItem => {
        if (typeof classItem === 'string') {
          return classItem
        } else {
          return classItem(item, index)
        }
      }).join(' ')
    } else {
      return []
    }
  }

  /* W A T C H E R S */
  @Watch('defaultOpen', { immediate: true })
  onDefaultOpenChanged () {
    if (this.defaultOpen) {
      this.closeAll()
      this.$set(this.opened, this.defaultOpen, true)
    }
  }
}
