import Vue from 'vue'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'

class SidebarServices {
  // get the confirmation dialog instance
  getConfirmationElement () {
    const div = document.createElement('div')
    const app = document.getElementById('app')

    app && app.appendChild(div)

    const componentInstance = new Vue({
      render: (h) => h(ConfirmModal, {
        ref: 'confirm',
        props: {
          title: 'Close confirmation',
          message: 'Are you sure you want to proceed?'
        }
      })
    }).$mount(div)

    return componentInstance
  }
}

export const sidebarService = new SidebarServices()
