import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { employeeService } from '@/services'
import { IEmployeeAnniversary, IEmployeeBirthday, TIndexedObject } from '@/types'

@Module({ name: 'DashboardModule', namespaced: true })
export default class DashboardModule extends VuexModule {
  birthdays: TIndexedObject<IEmployeeBirthday> = {}
  anniversaries: TIndexedObject<IEmployeeAnniversary> = {}

  // M U T A T I O N S
  @Mutation
  APPEND_BIRTHDAYS (birthdays: IEmployeeBirthday[]) {
    birthdays.forEach(birthday => {
      this.birthdays[birthday.employeeId] = birthday
    })
  }

  @Mutation
  APPEND_ANNIVERSARIES (anniversaries: IEmployeeAnniversary[]) {
    anniversaries.forEach(anniversary => {
      this.anniversaries[anniversary.employeeId] = anniversary
    })
  }

  @Mutation
  APPEND_DASHBOARD_PUBLIC_DOCUMENTS (anniversaries: IEmployeeAnniversary[]) {
    anniversaries.forEach(anniversary => {
      this.anniversaries[anniversary.employeeId] = anniversary
    })
  }

  // A C T I O N S
  @Action({ rawError: true })
  async getBirthdays () {
    let birthdays = Object.values(this.birthdays)
    if (birthdays.length === 0) {
      try {
        birthdays = await employeeService.getBirthdays()
      } finally {
      }
      this.context.commit('APPEND_BIRTHDAYS', birthdays)
    }
    return birthdays.sort((a, b) => a.dob.localeCompare(b.dob))
  }

  @Action({ rawError: true })
  async getAnniversaries () {
    let anniversaries = Object.values(this.anniversaries)
    if (anniversaries.length === 0) {
      try {
        anniversaries = await employeeService.getAnniversaries()
      } finally {
      }
      this.context.commit('APPEND_ANNIVERSARIES', anniversaries)
    }
    return anniversaries.sort((a, b) => a.startDate.localeCompare(b.startDate))
  }
}
