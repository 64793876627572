import { httpService } from '@/services'
import {
  IDropShip,
  IDropShipById,
  IDropShipPayload
} from '@/types'

class DropShipService {
  getAll (activeToggleState: boolean): Promise<IDropShip[]> {
    return httpService.get(`/DropShip/GetAll${activeToggleState ? 'Active' : ''}`)
  }

  getDetails (dropShipId: string): Promise<IDropShipById> {
    return httpService.get('/DropShip/GetDetails', { params: { dropShipId } })
  }

  save (payload: IDropShipPayload): Promise<''> {
    return httpService.post('/DropShip/Save', payload)
  }
}

export const dropShipService = new DropShipService()
