import { ICollectionConfig } from '@/types'
import { dateTime, booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsPart: ICollectionConfig[] = [
  {
    label: 'Part Category Type',
    group: 'PartCategoryType',
    getEndpoint: '/PartCategoryType/GetPartCategoryTypes',
    saveEndpoint: '/PartCategoryType/SavePartCategoryType',
    updateEndpoint: '/PartCategoryType/UpdatePartCategoryType',
    toggleEndpoint: '/PartCategoryType/TogglePartCategoryType',
    collectionName: collectionNames.partCategoryTypes,
    cellClass: ['isDefault'],
    headings: [
      { label: 'Net Suite Id', value: 'netSuiteId', sortable: true, minWidth: 130, align: 'center' },
      { label: 'Sales Force Id', value: 'salesForceId', sortable: true, minWidth: 150, align: 'center' },
      { label: 'Default Install Scope', value: 'defaultInstallScope', sortable: true, minWidth: 200, align: 'center' },
      { label: 'Default Margin', value: 'defaultProjectMargin', sortable: true, minWidth: 170, align: 'center' },
      { label: 'Default Price Book Margin', value: 'defaultProjectPriceBookMargin', sortable: true, minWidth: 230, align: 'center' },
      { label: 'Service Line Id', value: 'serviceLineId', sortable: true, minWidth: 140, align: 'center' },
      { label: 'Default', ...booleanFormatter('isDefault') },
      {
        label: 'Sales Force Last Update',
        value: 'salesForceLastUpdate',
        sortable: true,
        minWidth: 220,
        align: 'center',
        formatter: i => dateTime(i.salesForceLastUpdate)
      }
    ],
    form: [...naming, {
      component: 'InputModule',
      model: 'netSuiteId',
      props: { label: 'Net Suite Id', placeholder: 'ID', class: 'w-1/2 mt-4 pr-2', type: 'number' }
    }, {
      component: 'InputModule',
      model: 'salesForceId',
      props: { label: 'Sales Force Id', placeholder: 'ID', class: 'w-1/2 mt-4 pl-2' }
    }, {
      component: 'InputModule',
      model: 'defaultProjectMargin',
      props: { label: 'Default Project Margin', placeholder: 'Project margin', class: 'w-1/2 mt-4 pr-2' }
    }, {
      component: 'InputModule',
      model: 'defaultProjectPriceBookMargin',
      props: { label: 'Default Price Book Margin', placeholder: 'Price book margin', class: 'w-1/2 mt-4 pl-2' }
    }, {
      component: 'InputModule',
      model: 'defaultInstallScope',
      props: { label: 'Default Install Scope', placeholder: 'Install scope', class: 'w-full mt-4' }
    }, {
      component: 'SelectModule',
      model: 'serviceLineId',
      options: 'serviceLines',
      props: {
        optionKey: 'displayName',
        valueKey: 'id',
        label: 'Service Lines',
        placeholder: 'Select service line',
        class: 'w-full mt-4',
        clearable: true
      }
    }, {
      component: 'CheckboxModule',
      model: 'isDefault',
      props: { label: 'Default', class: 'w-1/2 mt-4' }
    }]
  },

  {
    label: 'Part Manufacturer',
    group: 'PartManufacturer',
    getEndpoint: '/PartManufacturer/GetPartManufacturers',
    saveEndpoint: '/PartManufacturer/SavePartManufacturer',
    updateEndpoint: '/PartManufacturer/UpdatePartManufacturer',
    toggleEndpoint: '/PartManufacturer/TogglePartManufacturer',
    collectionName: collectionNames.partManufacturers,
    cellClass: ['isDefault', 'isContracted'],
    headings: [
      { label: 'Phone', value: 'phone', sortable: true, minWidth: 200 },
      { label: 'Email', value: 'email', sortable: true, minWidth: 200 },
      { label: 'Net Suite Id', value: 'netSuiteId', sortable: true, minWidth: 130 },
      { label: 'Sales Force Id', value: 'salesForceId', sortable: true, minWidth: 200 },
      { label: 'Default', ...booleanFormatter('isDefault') },
      { label: 'Contracted', ...booleanFormatter('isContracted') },
      {
        label: 'Sales Force Last Update',
        value: 'salesForceLastUpdate',
        sortable: true,
        width: 210,
        align: 'center',
        formatter: (i: any) => dateTime(i.salesForceLastUpdate)
      }
    ],
    form: [...naming, {
      component: 'InputModule',
      model: 'phone',
      props: { label: 'Phone', placeholder: 'Phone', class: 'w-full mt-4' }
    }, {
      component: 'InputModule',
      model: 'email',
      props: { label: 'Email', placeholder: 'Email', class: 'w-full mt-4' }
    }, {
      component: 'InputModule',
      model: 'netSuiteId',
      props: { label: 'Net Suite Id', placeholder: 'ID', class: 'w-1/2 mt-4 pr-4', type: 'number' }
    }, {
      component: 'InputModule',
      model: 'salesForceId',
      props: { label: 'Sales Force Id', placeholder: 'ID', class: 'w-1/2 mt-4 pl-2', type: 'number' }
    },
    { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4 pr-2' } },
    { component: 'CheckboxModule', model: 'isContracted', props: { label: 'Contracted', class: 'w-1/2 mt-4 pl-2' } }]
  },

  {
    label: 'Part Return Condition Status Type',
    group: 'PartReturnConditionStatusType',
    getEndpoint: '/PartReturnConditionStatusType/GetAll',
    saveEndpoint: '/PartReturnConditionStatusType/Create',
    updateEndpoint: '/PartReturnConditionStatusType/Update',
    toggleEndpoint: '/PartReturnConditionStatusType/Toggle',
    collectionName: collectionNames.partReturnConditionStatusType,
    headings: [
      { label: 'Replacement Fee Status', ...booleanFormatter('isReplacementFeeStatus'), sortable: true },
      { label: 'Damage Fee Status', ...booleanFormatter('isDamageFeeStatus'), sortable: true }
    ],
    form: [...naming]
  }
]
