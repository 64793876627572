import { httpService } from '@/services'
import { ITextUsMessage, ITextUsSendMessagePayload } from '@/types'

class TextUsService {
  getMessages (personId: string): Promise<ITextUsMessage[]> {
    return httpService.get('/TextUs/GetMessages', { params: { id: personId } })
  }

  sendMessage (payload: ITextUsSendMessagePayload): Promise<ITextUsMessage> {
    return httpService.post('/TextUs/SendMessage', payload)
  }
}

export const textUsService = new TextUsService()
