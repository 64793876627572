const publicDocumentSidebarRoutesNames = {
  publicDocumentCreate: 'publicDocumentCreate',
  publicDocumentEdit: 'publicDocumentEdit',
  publicDocumentDetails: 'publicDocumentDetails'
}

const publicDocumentSidebarRoutes = [
  {
    name: publicDocumentSidebarRoutesNames.publicDocumentCreate,
    path: '/public-document-create',
    component: () => import('./PublicDocumentCreate.vue'),
    meta: { group: 'LDH_DocumentPublic_Upload' }
  },
  {
    name: publicDocumentSidebarRoutesNames.publicDocumentEdit,
    path: '/public-document-:id/edit',
    component: () => import('./PublicDocumentEdit.vue'),
    meta: { group: 'LDH_DocumentPublic_Upload' }
  }
]

export {
  publicDocumentSidebarRoutesNames,
  publicDocumentSidebarRoutes
}
