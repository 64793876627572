const vendorSidebarRoutesNames = {
  vendorCreate: 'vendorCreate',
  vendorEdit: 'vendorEdit'
}

const vendorSidebarRoutes = [
  {
    name: vendorSidebarRoutesNames.vendorCreate,
    path: '/vendor-create',
    component: () => import('./VendorCreate.vue'),
    meta: { group: 'LDH_Vendor_Add' }
  },
  {
    name: vendorSidebarRoutesNames.vendorEdit,
    path: '/vendor-:id/edit',
    component: () => import('./VendorEdit.vue'),
    meta: { group: 'LDH_Vendor_Update' }
  }
]

export {
  vendorSidebarRoutesNames,
  vendorSidebarRoutes
}
