import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { exportsService } from '@/services'
import { IExport, IReportName } from '@/types'
@Module({ name: 'ExportsModule', namespaced: true })
export default class ExportsModule extends VuexModule {
  loading = false
  reportNames: IReportName[] = []
  exportReportDetails: readonly IExport[] = []

  // M U T A T I O N S
  @Mutation
  SET_LOADING (loading = false) {
    this.loading = loading
  }

  @Mutation
  SET_REPORT_NAMES (value: IReportName[]) {
    this.reportNames = value
  }

  @Mutation
  SET_EXPORT_REPORT_DETAIL (value: readonly IExport[]) {
    this.exportReportDetails = value
  }

  // A C T I O N S
  @Action({ commit: 'SET_REPORT_NAMES', rawError: true })
  async getExportReportName () {
    return await exportsService.getExportReportName()
  }

  @Action({ commit: 'SET_EXPORT_REPORT_DETAIL', rawError: true })
  async getExportReportDetails (activeToggleState: boolean) {
    return await exportsService.getExportReportDetails(activeToggleState)
  }
}
