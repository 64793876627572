import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { patrolReportsService } from '@/services'
import { IPatrolReport } from '@/types'

@Module({ name: 'PatrolReportsModule', namespaced: true })
export default class PatrolReportsModule extends VuexModule {
  patrolReports = [] as readonly IPatrolReport[]
  patrolReportsLoading = false

  /* P A T R O L   R E P O R T S   M U T A T I O N S */
  @Mutation
  SET_PATROL_REPORTS_LOADING (payload: boolean) {
    this.patrolReportsLoading = payload
  }

  @Mutation
  SET_PATROL_REPORTS (payload: readonly IPatrolReport[]) {
    this.patrolReports = Object.freeze(payload)
  }

  /* P A T R O L   R E P O R T S   A C T I O N S */
  @Action({ commit: 'SET_PATROL_REPORTS', rawError: true })
  async getPatrolReports () {
    return await patrolReportsService.getPatrolReports()
  }
}
