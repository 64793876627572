import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { modules } from '@/store'
import { messagesService } from '@/services'
import { ITextingConversation, ITextingMessage, TNullableField, ITextingConversationEmployee } from '@/types'
import { deepClone } from '@/helpers'

@Module({ name: 'MessagesModule', namespaced: true })
export default class MessagesModule extends VuexModule {
  messagesLoading = false
  activeConversationId: TNullableField<number> = null
  activeConversationIndex: TNullableField<number> = null
  conversations: ITextingConversation[] | null = null
  messages: ITextingMessage[] | null = null
  template: TNullableField<string> = null
  activeToggleState = false

  /* M U T A T I O N S */
  @Mutation
  SET_ACTIVE_TOGGLE_STATE (payload: boolean) {
    this.activeToggleState = payload
  }

  @Mutation
  SET_CONVERSATIONS (payload: ITextingConversation[] | null) {
    this.conversations = payload
  }

  @Mutation
  SET_ACTIVE_CONVERSATION_ID (payload: number) {
    this.activeConversationId = payload
  }

  @Mutation
  SET_MESSAGES (payload: ITextingMessage[] | null) {
    this.messages = payload
  }

  @Mutation
  SET_TEMPLATE (payload: TNullableField<string>) {
    this.template = payload
  }

  @Mutation
  SET_ACTIVE_CONVERSATION_INDEX (payload: TNullableField<number>) {
    this.activeConversationIndex = payload
  }

  @Mutation
  SET_MESSAGES_LOADING (payload: boolean) {
    this.messagesLoading = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CONVERSATIONS', rawError: true })
  async getTextingConversations () {
    return await messagesService.getTextingConversations()
  }

  @Action({ commit: 'SET_CONVERSATIONS', rawError: true })
  async getAllTextingConversationsForManager () {
    return await messagesService.getAllTextingConversationsForManager()
  }

  @Action({ commit: 'SET_MESSAGES', rawError: true })
  async getTextingMessageByConversationId (conversationId: number) {
    return await messagesService.getTextingMessageByConversationId(conversationId)
  }

  @Action({ commit: 'SET_MESSAGES', rawError: true })
  async getAllTextingMessageForEmployee (employeeId: number) {
    return await messagesService.getAllTextingMessageForEmployee(employeeId)
  }

  @Action({ rawError: true })
  async updateMessages (conversationId: number) {
    if (this.conversations) {
      if (
        this.activeConversationIndex !== null &&
        this.conversations[this.activeConversationIndex].id === conversationId
      ) {
        const updatedMessages = await messagesService.getTextingMessageByConversationId(conversationId)
        const messages = deepClone(this.messages) as ITextingMessage[]
        messages && messages.push(updatedMessages[updatedMessages.length - 1])
        this.SET_MESSAGES(messages)

        await messagesService.toggleIsReadTextingConversationEmployee({
          employeeId: Number(modules.user.userProfile.id),
          conversationId
        } as ITextingConversationEmployee)
      }

      this.activeToggleState
        ? this.getAllTextingConversationsForManager()
        : this.getTextingConversations()
    }
  }
}
