import { httpService } from '@/services'
import { modules } from '@/store'
import {
  IKnowledgeBaseArticle,
  IShortTicket,
  ITicketCreatePayload,
  ITicketDocumentPayload,
  ITicketEmployeePayload,
  ITicket,
  ITicketUpdatePayload,
  ITicketDocument
} from '@/types'

class TicketsService {
  // private apiUrls = {
  //   getTickets: '/Ticket/GetTickets',
  //   getClosedTickets: '/Ticket/GetClosedTickets',
  //   updateKnowledgeBaseArticleToTicket: '/KnowledgeBaseArticleToTicket/UpdateKnowledgeBaseArticleToTicket',
  //   toggleKnowledgeBaseArticleToTicket: '/KnowledgeBaseArticleToTicket/ToggleKnowledgeBaseArticleToTicket',
  //   saveTicket: '/Ticket/SaveTicket',
  //   getTicketRequestors: '/TicketRequestor/GetTicketRequestors',
  //   getTicketCategories: '/TicketCategory/GetTicketCategories',
  //   getTicketCategoriesByEmployee: '/TicketCategory/GetTicketCategoriesByEmployee'
  // }

  /* T I C K E T   R E L A T I O N */
  saveTicketRelation (payload: any) {
    return httpService.post('/TicketRelation/Save', payload)
  }

  toggleTicketRelation (id: number) {
    return httpService.post('/TicketRelation/Toggle', {}, { params: { id } })
  }

  // T I C K E T S
  createNewTicket (payload: ITicketCreatePayload) {
    return httpService.post('/Ticket/CreateNewTicket', payload)
  }

  getTicketsAssignedByCurrentUser (isComplete: boolean) {
    return httpService.get<IShortTicket[]>('/Ticket/GetTicketsAssignedByCurrentUser', { params: { isComplete } })
  }

  getTicketsRequestedByCurrentUser (isComplete: boolean) {
    return httpService.get<IShortTicket[]>('/Ticket/GetTicketsRequestedByCurrentUser', { params: { isComplete } })
  }

  updateTicket (payload: ITicketUpdatePayload) {
    return httpService.post('/Ticket/UpdateTicket', payload)
  }

  getAssignedTickets () {
    return httpService.get<IShortTicket[]>('/Ticket/GetAssignedTickets')
  }

  getUnassignedTickets () {
    return httpService.get<IShortTicket[]>('/Ticket/GetUnassignedTickets')
  }

  deleteTicketRequestor (id: number) {
    return httpService.post('/TicketRequestor/DeleteTicketRequestor', {}, { params: { id } })
  }

  getTicketById (id: number) {
    return httpService.get<ITicket>('/Ticket/GetTicketById', { params: { id } })
  }

  searchTickets (term: string, incidentId: number) {
    return httpService.get<IShortTicket[]>('/Ticket/Search', { params: { term, incidentId } })
  }

  // A R T I C L E S
  searchKnowledgeBaseArticles (term: string) {
    return httpService.get<IKnowledgeBaseArticle[]>('/KnowledgeBaseArticle/Search', { params: { term } })
  }

  saveKnowledgeBaseArticleToTickets (ticketId: number, knowledgeBaseArticleId: number) {
    return httpService.post('/KnowledgeBaseArticleToTicket/SaveKnowledgeBaseArticleToTicket', { ticketId, knowledgeBaseArticleId })
  }

  removeKnowledgeBaseArticleByTicketId (ticketId: number, articleId: number) {
    return httpService.post('/KnowledgeBaseArticleToTicket/RemoveByArticleIdTicketId', {}, { params: { ticketId, articleId } })
  }

  // A S S I G N M E N T S
  assignTicket (ticketId: number, employeeId: number) {
    return httpService.post('/TicketAssignment/SaveTicketAssignment', { employeeId, ticketId } as ITicketEmployeePayload)
  }

  deassignTicket (id: number) {
    return httpService.post('/TicketAssignment/DeleteTicketAssignment', {}, { params: { id } })
  }

  getTicketAssignmentsByTicketId (ticketId: number) {
    return httpService.get<any[]>('/TicketAssignment/GetTicketAssignmentsByTicketId', { params: { ticketId } })
  }

  // A U T O   A S S I G N M E N T S
  getTicketAutoAssignmentsByDepartmentId (departmentId: number) {
    return httpService.get<any[]>('/TicketAutoAssignment/GetTicketAutoAssignmentsByDepartmentId', { params: { departmentId } })
  }

  saveTicketAutoAssignment (departmentId: number, employeeId: number) {
    return httpService.post('/TicketAutoAssignment/SaveTicketAutoAssignment', { departmentId, employeeId })
  }

  deleteTicketAutoAssignmentByDepartmentAndEmployeeId (departmentId: number, employeeId: number) {
    return httpService.post('/TicketAutoAssignment/DeleteTicketAutoAssignmentByDepartmentAndEmployeeId', {}, {
      params: { departmentId, employeeId }
    })
  }

  // O T H E R
  saveTicketNote (ticketId: number, data: string, isFinal = false) {
    return httpService.post('/TicketNote/SaveTicketNote', { ticketId, data, isFinal })
  }

  saveTicketDocument (payload: ITicketDocumentPayload) {
    return httpService.post('/TicketDocument/SaveTicketDocument', payload)
  }

  deleteTicketDocument (id: number) {
    return httpService.post('/TicketDocument/DeleteTicketDocument', {}, { params: { id } })
  }

  getTicketDocumentById (id: number): Promise<ITicketDocument> {
    return httpService.get('/TicketDocument/GetTicketDocumentById', { params: { id } })
  }

  markTicketComplete (id: number) {
    const employeeId = modules.user.userProfile.id
    return httpService.post('/Ticket/MarkTicketComplete', {}, { params: { id, employeeId } })
  }
}

export const ticketsService = new TicketsService()
