import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsPaycheck: ICollectionConfig[] = [{
  label: 'Paycheck Status Type',
  group: 'PaycheckStatusType',
  getEndpoint: '/PaycheckStatusType/GetPaycheckStatusTypes',
  saveEndpoint: '/PaycheckStatusType/SavePaycheckStatusType',
  updateEndpoint: '/PaycheckStatusType/UpdatePaycheckStatusType',
  toggleEndpoint: '/PaycheckStatusType/TogglePaycheckStatusType',
  collectionName: collectionNames.paycheckStatusTypes,
  cellClass: ['isDefault', 'isCompleted', 'isSigned', 'isReDeposited'],
  headings: [
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Completed', ...booleanFormatter('isCompleted') },
    { label: 'Signed', ...booleanFormatter('isSigned') },
    { label: 'ReDeposit', ...booleanFormatter('isReDeposited') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isCompleted', props: { label: 'Completed', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isSigned', props: { label: 'Signed', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isReDeposited', props: { label: 'ReDeposit', class: 'w-1/2 mt-4' } }
  ]
},

{
  label: 'Paycheck Type',
  group: 'PaycheckType',
  getEndpoint: '/PaycheckType/GetPaycheckTypes',
  saveEndpoint: '/PaycheckType/SavePaycheckType',
  updateEndpoint: '/PaycheckType/UpdatePaycheckType',
  toggleEndpoint: '/PaycheckType/TogglePaycheckType',
  collectionName: collectionNames.paycheckTypes,
  cellClass: ['isDefault', 'isSignable'],
  headings: [
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Can Sign', ...booleanFormatter('isSignable') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isSignable', props: { label: 'Can Sign', class: 'w-1/2 mt-4' } }
  ]
}]
