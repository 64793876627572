









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors } from '@/types'

import ButtonGhost from '@/components/shared/form-controls/ButtonGhost.vue'
import ButtonModule from '@/components/shared/form-controls/ButtonModule.vue'

@Component({
  name: 'CancelSave',
  components: {
    ButtonGhost,
    ButtonModule
  }
})
export default class CancelSave extends Vue {
  @Prop() saveButtonDisabled!: boolean
  @Prop() cancelButtonDisabled!: boolean
  @Prop({ default: true }) showSaveButton!: boolean
  @Prop({ default: 'Cancel' }) cancelText!: string
  @Prop({ default: 'Save' }) saveText!: string
  @Prop({ default: 'primary' }) saveColor!: TColors
  @Prop({ default: false }) noTopBorder!: boolean
  @Prop({ default: false }) borderBottom!: boolean
  @Prop() saveBtnDisabledTooltip?: string
  @Prop() padding?: any
}
