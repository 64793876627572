import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsTicket: ICollectionConfig[] = [
  {
    label: 'Ticket Sub Category',
    group: 'TicketSubCategory',
    getEndpoint: '/TicketSubCategory/GetTicketSubCategories',
    saveEndpoint: '/TicketSubCategory/SaveTicketSubCategory',
    updateEndpoint: '/TicketSubCategory/UpdateTicketSubCategory',
    toggleEndpoint: '/TicketSubCategory/ToggleTicketSubCategory',
    collectionName: collectionNames.ticketSubCategories,
    cellClass: ['isDefault'],
    headings: [
      { label: 'Department', value: 'departmentDisplayName', sortable: true, minWidth: 200 },
      { label: 'Default', ...booleanFormatter('isDefault') }
    ],
    form: [...naming,
      { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } },
      { component: 'SelectModule', model: 'departmentId', options: 'departments', props: { label: 'Department Search', class: 'w-full mt-4', optionKey: 'displayName', valueKey: 'id' } }
    ]
  },

  {
    label: 'Ticket Severity',
    group: 'TicketSeverity',
    getEndpoint: '/TicketSeverity/GetTicketSeverities',
    getAllActive: '/TicketSeverity/GetTicketSeveritiesActive',
    saveEndpoint: '/TicketSeverity/SaveTicketSeverity',
    updateEndpoint: '/TicketSeverity/UpdateTicketSeverity',
    toggleEndpoint: '/TicketSeverity/ToggleTicketSeverity',
    collectionName: collectionNames.ticketSeverities,
    cellClass: ['isDefault'],
    headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
    form: [...naming,
      { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }
    ]
  }
]
