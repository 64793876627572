const configurationSidebarRouteNames = {
  configurationCreate: 'configurationCreate',
  configurationEdit: 'configurationEdit'
}

const configurationSidebarRoutes = [
  {
    name: configurationSidebarRouteNames.configurationCreate,
    path: '/configuration-create',
    component: () => import('./ConfigurationCreate.vue')
  },
  {
    name: configurationSidebarRouteNames.configurationEdit,
    path: '/configuration-:id/edit',
    component: () => import('./ConfigurationEdit.vue')
  }
]

export {
  configurationSidebarRouteNames,
  configurationSidebarRoutes
}
