const partSidebarRoutesNames = {
  partCreate: 'partCreate',
  partEdit: 'partEdit'
}

const partSidebarRoutes = [
  {
    name: partSidebarRoutesNames.partCreate,
    path: '/part-create',
    component: () => import('./PartCreate.vue'),
    meta: { group: 'LDH_Part_Add' }
  },
  {
    name: partSidebarRoutesNames.partEdit,
    path: '/part-:id/edit',
    component: () => import('./PartEdit.vue'),
    meta: { group: 'LDH_Part_Update' }
  }
]

export {
  partSidebarRoutesNames,
  partSidebarRoutes
}
