import { httpService } from '@/services'

//     getEndpoint: '/Collection/ContractApprovalType/GetAll',
//     saveEndpoint: 'Collection/ContractApprovalType/Save',
//     updateEndpoint: '/Collection/ContractApprovalType/Update',
//     toggleEndpoint: '/Collection/ContractApprovalType/Toggle',

class ContractApprovalTypesService {
  getContractApprovalTypes (): Promise<any[]> {
    return httpService.get('/Collection/ContractApprovalType/GetAll')
  }

  getContractApprovalTypeTable (): Promise<any[]> {
    return httpService.get('/Collection/ContractApprovalType/GetTable')
  }

  toggleContractApprovalTypes (id: number): Promise<''> {
    return httpService.post('/Collection/ContractApprovalType/Toggle', {}, { params: { id } })
  }
}

export const contractApprovalTypesService = new ContractApprovalTypesService()
