import { httpService } from '@/services'
import { IConfiguration, IDocuSignType } from '@/types'

class ConfigurationsService {
  /* S E T T I N G S */
  getAllSettings (): Promise<IConfiguration[]> {
    return httpService.get('/Setting/GetAll')
  }

  getSettingsById (id: number): Promise<any> {
    return httpService.get('/Setting/GetById', { params: { id } })
  }

  saveSetting (payload: IConfiguration): Promise<''> {
    return httpService.post('/Setting/Save', payload)
  }

  updateSetting (payload: IConfiguration): Promise<''> {
    return httpService.post('/Setting/Update', payload)
  }

  toggleSetting (id: number): Promise<''> {
    return httpService.post('/Setting/Toggle', {}, { params: { id } })
  }

  salesForceAuthCode (code: string): Promise<any> {
    return httpService.post('/Setting/SalesForceAuthorizationCode', {}, { params: { code } })
  }

  salesForceRefreshToken (): Promise<any> {
    return httpService.post('/Setting/SalesForceRefreshToken')
  }

  /* I N T E G R A T I O N */
  getDocuSignData (): Promise<IDocuSignType> {
    return httpService.get('/DocuSign/LoadDocuSignData')
  }

  getSalesForceData (): Promise<any> {
    return httpService.get('/DocuSign/LoadSalesForceData')
  }

  saveDocuSignData (clientId: string, secretKey: string): Promise<''> {
    return httpService.post('/DocuSign/SaveDocuSignData', {}, { params: { clientId, secretKey } })
  }

  updateDocuSignTokenCode (code: string): Promise<''> {
    return httpService.post('/DocuSign/UpdateTokenCode', {}, { params: { code } })
  }

  refreshDocuSignToken (): Promise<''> {
    return httpService.post('/DocuSign/RefreshDocuSignToken')
  }
}

export const configurationsService = new ConfigurationsService()
