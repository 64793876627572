import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { transactionService } from '@/services'
import { ITransaction } from '@/types'

@Module({ name: 'TransactionModule', namespaced: true })
export default class TransactionModule extends VuexModule {
  transactions: ITransaction[] = []
  transactionIntegrationsShow: string[] = []

  /* A S S E T S   M U T A T I O N S */
  @Mutation
  SET_TRANSACTIONS (payload: ITransaction[]) {
    this.transactions = payload
  }

  @Mutation
  SET_INTEGRATION_TYPES (payload: string[]) {
    this.transactionIntegrationsShow = payload
  }

  @Mutation
  UPSERT_TRANSACTION (transaction: string) {
    const parsedTransaction = JSON.parse(transaction)
    const existingTransactionIndex = this.transactions.findIndex(transaction => {
      return parsedTransaction.id === transaction.id
    })
    if (existingTransactionIndex > -1) {
      this.transactions.splice(existingTransactionIndex, 1, parsedTransaction)
    } else {
      this.transactions.unshift(parsedTransaction)
    }
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_TRANSACTIONS', rawError: true })
  async getTransactions () {
    return await transactionService.getTransactions()
  }

  @Action({ commit: 'SET_INTEGRATION_TYPES', rawError: true })
  async getIntegrationTypes () {
    return await transactionService.getIntegrationTypes()
  }
}
