const importsSidebarRoutesNames = {
  importCreate: 'importCreate',
  importDetails: 'importDetails'
}

const importsSidebarRoutes = [
  {
    name: importsSidebarRoutesNames.importCreate,
    path: '/import-create',
    component: () => import('./import-create/ImportCreate.vue'),
    meta: { group: 'LDH_ImportDocument_Add' }
  },
  {
    name: importsSidebarRoutesNames.importDetails,
    path: '/import-:id',
    component: () => import('./ImportDetails.vue'),
    meta: { group: 'LDH_ImportDocument_View', width: 'calc(100% - 250px)', minWidth: 'calc(100% - 250px)' }
  }
]

export {
  importsSidebarRoutesNames,
  importsSidebarRoutes
}
