import { IBaseModelNamed, TNullableField } from '@/types'

export interface ILicense {
  branchName: TNullableField<string>;
  color: TNullableField<string>;
  employeeFirstName: TNullableField<string>;
  employeeId: number;
  employeeLastName: TNullableField<string>;
  expiryDate: TNullableField<string>;
  fbiSubmissionId: TNullableField<string>;
  fbiSubmittedDate: TNullableField<string>;
  fingerPrintSubmissionId: TNullableField<string>;
  fingerPrintSubmittedDate: TNullableField<string>;
  startDate: TNullableField<string>;
  id: number;
  issueDate: TNullableField<string>;
  licenseNumber: TNullableField<string>;
  licenseStatusType: TNullableField<string>;
  licenseStatusTypeId: number;
  licenseType: TNullableField<string>;
  licenseTypeId: TNullableField<string>;
  created: string;
  createdBy: string;
  modified: TNullableField<string>;
  modifiedBy: TNullableField<string>;
  obsolete: false;
  receivedDate: TNullableField<string>;
  signature: TNullableField<string>;
  signedDate: TNullableField<string>;
  submittedDate: TNullableField<string>;
  positionOrientationDate: TNullableField<string>;
  positionStartDate: TNullableField<string>;
  employeeTerminateDate: TNullableField<string>;
  employeeStatusType: TNullableField<string>;
}

export interface ILicenseForm {
  id?: number;
  employeeId: string | number;
  licenseTypeId: string | number;
  licenseStatusTypeId: string | number;
  licenseNumber: TNullableField<string>;
  issueDate: TNullableField<string>;
  expiryDate: TNullableField<string>;
  submittedDate?: TNullableField<string>;
  receivedDate?: TNullableField<string>;
  signedDate?: TNullableField<string>;
  fingerPrintSubmissionId: string | number;
  fingerPrintSubmittedDate: TNullableField<string>;
  fbiSubmissionId: string | number;
  fbiSubmittedDate: TNullableField<string>;
}

export interface ILicenseStatus extends IBaseModelNamed {
  isDefault: boolean;
  isSubmitted: boolean;
  isReceived: TNullableField<boolean>;
  isSigned: TNullableField<boolean>;
  isFingerPrinted: TNullableField<boolean>;
  isTerminated: TNullableField<boolean>;
  isTerminatedInPisgs: TNullableField<boolean>;
  licenses: ILicense[];
}

export interface ILicenseType extends IBaseModelNamed {
  isArmed: TNullableField<boolean>;
  isClearance: TNullableField<boolean>;
  isVehicle: TNullableField<boolean>;
  isTsa: TNullableField<boolean>;
  licenses: ILicense[];
}

export interface ILicenseTransfer {
  hired: boolean;
  id: number;
  rehired: boolean;
  terminated: boolean;
}

export enum ELicenseStatus {
  CREATED = 1,
  SIGNED,
  SUBMITTED,
  RECEIVED,
  TERMINATED,
  PISGSTERM,
  FINGERPRINTED,
  RENEWAL
}

export interface ILicenseStatusWorkflow {
  licenseStatusTypeIdFrom: number;
  licenseStatusTypeIdTo: number;
}
