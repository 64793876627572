const securitySidebarRoutesNames = {
  securityCreate: 'securityCreate',
  securityEdit: 'securityEdit'
}

const securitySidebarRoutes = [
  {
    name: securitySidebarRoutesNames.securityCreate,
    path: '/security-create',
    component: () => import('./SecurityCreate.vue'),
    meta: {
      group: 'LDH_Permission_Add',
      width: '500px',
      minWidth: '500px'
    }
  },
  {
    name: securitySidebarRoutesNames.securityEdit,
    path: '/security-:id/edit',
    component: () => import('./SecurityEdit.vue'),
    meta: {
      group: 'LDH_Permission_Update',
      width: '500px',
      minWidth: '500px'
    },
    params: true
  }
]

export {
  securitySidebarRoutesNames,
  securitySidebarRoutes
}
