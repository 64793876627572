import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {
  ISchedule,
  IScheduleMappedTechnicianAvailability,
  ISelectedScheduleTechnicians,
  IServiceCallSearch,
  TIndexedObject,
  TNullableField
} from '@/types'
import Vue from 'vue'

@Module({ name: 'ScheduleModule', namespaced: true })
export default class ScheduleModule extends VuexModule {
  techniciansIssues = {} as TIndexedObject<number[]>
  techniciansAreas = {} as TIndexedObject<string[]>

  selectedServiceCallSearchId: string | number = ''
  foundServiceCalls: IServiceCallSearch[] = []
  isRecordScheduled: TNullableField<boolean> = null
  selectedScheduleTechnicians = {
    date: '',
    technicians: []
  } as ISelectedScheduleTechnicians

  selectedScheduleTableTechnicians = [] as IScheduleMappedTechnicianAvailability[]

  shouldUpdateSchedule = false

  unbillableData = {
    category: '' as string | number,
    note: ''
  }

  /* M U T A T I O N S */
  @Mutation
  SET_SERVICE_CALL_SCHEDULED (loaded: TNullableField<boolean>) {
    this.isRecordScheduled = loaded
  }

  @Mutation
  SET_TECHNICIANS_ISSUES (payload: { employeeId: number; issues: number[]} | null) {
    if (payload) {
      Vue.set(this.techniciansIssues, payload.employeeId, payload.issues)
      const currentTechnician = this.selectedScheduleTechnicians.technicians.find(t => {
        return t.employeeId === payload.employeeId
      })
      if (currentTechnician) {
        Vue.set(currentTechnician, 'issues', payload.issues)
      }
    } else {
      this.techniciansIssues = {}
      this.selectedScheduleTechnicians.technicians.forEach(t => {
        Vue.set(t, 'issues', [])
      })
    }
  }

  @Mutation
  SET_TECHNICIAN_AREAS (payload: { employeeId: number; areas: string[]} | null) {
    if (payload) {
      Vue.set(this.techniciansAreas, payload.employeeId, payload.areas)
    } else {
      this.techniciansAreas = {}
    }
  }

  @Mutation
  SET_SELECTED_SCHEDULE_TECHNICIANS (selectedScheduleTechnicians: { date: string; events: ISchedule[] }) {
    const { date, events } = selectedScheduleTechnicians
    const mappedTechnicians = events.reduce((acc, cur) => {
      acc[cur.employeeId] = {
        firstName: cur.employeeFirstName,
        lastName: cur.employeeLastName,
        employeeId: cur.employeeId,
        schedule: events.filter(e => e.employeeId === cur.employeeId && !!e.id),
        issues: this.techniciansIssues[cur.employeeId] || []
      }
      return acc
    }, {} as TIndexedObject & ISelectedScheduleTechnicians)

    this.selectedScheduleTechnicians = {
      date,
      technicians: Object.values(mappedTechnicians)
    }
  }

  @Mutation
  SET_SELECTED_SCHEDULE_TABLE_TECHNICIANS (selectedScheduleTableTechnicians: IScheduleMappedTechnicianAvailability[]) {
    this.selectedScheduleTableTechnicians = selectedScheduleTableTechnicians
  }

  @Mutation
  SET_SELECTED_SERVICE_CALL_SEARCH_ID (selectedServiceCallSearchId: string | number) {
    this.selectedServiceCallSearchId = selectedServiceCallSearchId
  }

  @Mutation
  SET_FOUND_SERVICE_CALLS (foundServiceCalls: IServiceCallSearch[]) {
    this.foundServiceCalls = foundServiceCalls
  }

  @Mutation
  SET_UNBILLABLE_DATA ({ category, note }: { category?: string | number; note?: string }) {
    if (category !== undefined) {
      this.unbillableData.category = category
    } else if (note !== undefined) {
      this.unbillableData.note = note
    }
  }

  @Mutation
  REMOVE_SCHEDULE_TECHNICIAN (employeeId: number) {
    this.selectedScheduleTechnicians.technicians = this.selectedScheduleTechnicians.technicians.filter(t => {
      return t.employeeId !== employeeId
    })
    this.selectedScheduleTableTechnicians = this.selectedScheduleTableTechnicians.filter(t => {
      return t.employeeId !== employeeId
    })
  }

  @Mutation
  RESET_SC_SCHEDULING () {
    this.techniciansIssues = {}
    this.isRecordScheduled = null
    this.selectedScheduleTechnicians = {
      date: '',
      technicians: []
    }
    this.selectedScheduleTableTechnicians = []
    this.selectedServiceCallSearchId = ''
    this.foundServiceCalls = []
  }

  @Mutation
  RESET_PROJECT_SCHEDULING () {
    this.isRecordScheduled = null
    this.selectedScheduleTechnicians = {
      date: '',
      technicians: []
    }
    this.selectedScheduleTableTechnicians = []
    this.techniciansAreas = {}
  }

  @Mutation
  RESET_UNBILLABLE_SCHEDULING () {
    this.unbillableData = {
      category: '',
      note: ''
    }
    this.selectedScheduleTechnicians = {
      date: '',
      technicians: []
    }
    this.selectedScheduleTableTechnicians = []
  }

  @Mutation
  SET_SHOULD_UPDATE_SCHEDULE (payload: boolean) {
    this.shouldUpdateSchedule = payload
  }
}
