
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { modules } from '@/store'
import { EPersonalizationSettings } from '@/types'
import { globalService } from '@/services'

@Module({ name: 'GeneralModule', namespaced: true })
export default class GeneralModule extends VuexModule {
  routerDirection = 'slide-left'
  loading = false
  modalIsOpen = false
  menuCollapsed = true
  activeToggleState = true
  showHeaderShadow = false
  mobileMenuOpened = false
  globalSearchOpened = false
  changesConfirmOpened = false
  cancelConfirmed = false
  // signalRConnectionStarted = false
  pageTitle = ''
  apiVersion = ''
  guiVersion = ''
  loadingText = ''

  /* C O N F I R M   M O D A L */
  entryData = null
  changedEntryData = null

  /* D O C U M E N T S */
  fileTypes = [
    { text: 'Drug Test results', value: 'drugTest' },
    { text: 'ID documents', value: 'idChecks' },
    { text: 'Onboarding results', value: 'onBoarding' },
    { text: 'Background check results', value: 'background' },
    { text: 'Employee Image', value: 'employeeImage' },
    { text: 'Resume document', value: 'resume' },
    { text: 'Employee Signature', value: 'signature' }
  ]

  /* G E T T E R S */
  get isAnyChanges () {
    if (this.entryData && this.changedEntryData) {
      return JSON.stringify(this.entryData) !== JSON.stringify(this.changedEntryData)
    } else {
      return false
    }
  }

  /* M U T A T I O N S */
  @Mutation
  SET_ROUTER_DIRECTION (routerDirection: string) {
    this.routerDirection = routerDirection
  }

  // @Mutation
  // SET_SIGNALR_CONNECTION (connectionStarted: boolean) {
  //   this.signalRConnectionStarted = connectionStarted
  // }

  @Mutation
  SET_MOBILE_MENU_STATE (mobileMenuState: boolean) {
    this.mobileMenuOpened = mobileMenuState
  }

  @Mutation
  SET_ACTIVE_ONLY_ITEMS (state: boolean) {
    this.activeToggleState = state
  }

  @Mutation
  SET_LOADING (loadingState: boolean) {
    this.loading = loadingState
  }

  @Mutation
  SET_HEADER_SHADOW (topOffset: number) {
    this.showHeaderShadow = topOffset > 0
  }

  @Mutation
  TOGGLE_MENU_COLLAPSE (val?: boolean) {
    this.menuCollapsed = val !== undefined ? val : !this.menuCollapsed
  }

  @Mutation
  SET_GLOBAL_SEARCH_OPENED (val: boolean) {
    this.globalSearchOpened = val
  }

  @Mutation
  SET_VERSIONS ({ api, gui }: { api: string; gui: string }) {
    this.apiVersion = api
    this.guiVersion = gui
  }

  @Mutation
  SET_MODAL_IS_OPEN (payload: boolean) {
    this.modalIsOpen = payload
  }

  @Mutation
  SET_PAGE_TITLE (pageTitle: string) {
    this.pageTitle = pageTitle
  }

  @Mutation
  SET_LOADING_TEXT (loadingText: string) {
    this.loadingText = loadingText
  }

  @Mutation
  SET_ENTRY_DATA (payload: any) {
    this.entryData = payload
  }

  @Mutation
  SET_CHANGED_ENTRY_DATA (payload: any) {
    this.changedEntryData = payload
  }

  @Mutation
  SET_CHANGES_CONFIRM_OPENED (payload: boolean) {
    this.changesConfirmOpened = payload
  }

  @Mutation
  SET_CANCEL_CONFIRMED (payload: boolean) {
    this.cancelConfirmed = payload
  }

  /* A C T I O N S */
  @Action
  toggleMenu (val?: boolean) {
    this.context.commit('TOGGLE_MENU_COLLAPSE', val)
    modules.personalization.update({
      setting: EPersonalizationSettings.SIDEBAR_COLLAPSED,
      value: String(this.menuCollapsed)
    })
  }

  @Action({ rawError: true })
  async fetchVersion () {
    const api = await globalService.getCurrentBuildVersion()
    const gui = await globalService.getGuiVersion()
    this.context.commit('SET_VERSIONS', { api, gui })
  }
}
