import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { IBaseModelNamed, IContractor, IOpportunity } from '@/types'
import { opportunitiesService } from '@/services'

@Module({ name: 'OpportunityModule', namespaced: true })
export default class OpportunitiesModule extends VuexModule {
  opportunities: readonly IOpportunity[] = []
  opportunityLoading = false
  contractors: IContractor[] = []
  customerContacts: any
  opportunityStages: any[] = []
  lossReasonTypes: any[] = []

  opportunityForm: any = {}
  opportunitiesForReports: IBaseModelNamed[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_OPPORTUNITY_LOADING (payload: boolean) {
    this.opportunityLoading = payload
  }

  @Mutation
  SET_OPPORTUNITY (payload: readonly IOpportunity[]) {
    this.opportunities = payload
  }

  @Mutation
  SET_CONTRACTORS (payload: IContractor[]) {
    this.contractors = payload
  }

  @Mutation
  SET_LOSS_REASON_TYPES (payload: any) {
    this.lossReasonTypes = payload
  }

  @Mutation
  DESTROY_LOSS_REASON_TYPES () {
    this.lossReasonTypes = []
  }

  @Mutation
  SET_OPPORTUNITY_STAGES (payload: any) {
    this.opportunityStages = payload
  }

  @Mutation
  SET_OPPORTUNITY_FORM (payload: any) {
    this.opportunityForm = payload
  }

  @Mutation
  SET_OPPORTUNITIES_FOR_REPORTS (payload: any) {
    this.opportunitiesForReports = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_OPPORTUNITY', rawError: true })
  async getOpportunityTable () {
    return await opportunitiesService.getOpportunityTable()
  }

  @Action({ commit: 'SET_OPPORTUNITY', rawError: true })
  async getOpportunities () {
    return await opportunitiesService.getOpportunities()
  }

  @Action({ commit: 'SET_CONTRACTORS', rawError: true })
  async getContractors () {
    return await opportunitiesService.getContractors()
  }

  @Action({ commit: 'SET_LOSS_REASON_TYPES', rawError: true })
  async getLossReasonTypes (divisionTypeId: number) {
    return await opportunitiesService.getLossReasonTypesByDivisionTypeId(divisionTypeId)
  }

  @Action({ commit: 'SET_OPPORTUNITY_STAGES', rawError: true })
  async getSelectableOpportunityStages () {
    return await opportunitiesService.getSelectableOpportunityStages()
  }

  @Action({ commit: 'SET_OPPORTUNITY_FORM', rawError: true })
  async setupOpportunityForm () {
    return await opportunitiesService.setupOpportunityForm()
  }

  @Action({ commit: 'SET_OPPORTUNITIES_FOR_REPORTS', rawError: true })
  async getOpportunitiesForReports () {
    return await opportunitiesService.getOpportunityNamesClosedWon()
  }
}
