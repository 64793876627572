import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.clientServices

export const clientServicesRouteNames = {
  addresses: 'Addresses',
  customers: 'Customer',
  patrolReport: 'PatrolReport',
  postInspections: 'PostInspection',
  customerContactInformation: 'Customer Contact Information',
  vendors: 'Vendor'
}

export const clientServicesRoutes = [
  {
    name: clientServicesRouteNames.addresses,
    path: 'addresses',
    meta: {
      parentName,
      group: 'LDH_Nav_Customers_View',
      label: 'Addresses'
    },
    component: () => import('@/pages/client-services/addresses/Addresses.vue')
  },
  {
    name: clientServicesRouteNames.customers,
    path: 'customers',
    meta: {
      parentName,
      group: 'LDH_Nav_Customers_View',
      label: 'Customers'
    },
    component: () => import('@/pages/client-services/customers/Customers.vue')
  },
  {
    name: clientServicesRouteNames.customerContactInformation,
    path: 'customer-contact-information',
    meta: {
      parentName,
      label: 'Customer Contacts',
      group: 'LDH_Nav_CustomerContacts_View'
    },
    component: () => import('@/pages/client-services/customer-contacts/Customers.vue')
  },
  {
    name: clientServicesRouteNames.patrolReport,
    path: 'patrol-report',
    meta: {
      parentName,
      group: 'LDH_Nav_Patrol_Report_View',
      label: 'Patrol Report'
    },
    component: () => import('@/pages/client-services/patrol-reports/PatrolReport.vue')
  },
  {
    name: clientServicesRouteNames.postInspections,
    path: 'post-inspections',
    meta: {
      parentName,
      label: 'Post Inspections',
      group: 'LDH_Nav_Post_Inspections_View'
    },
    component: () => import('@/pages/client-services/post-inspections/PostInspections.vue')
  },
  {
    name: clientServicesRouteNames.vendors,
    path: 'vendors',
    meta: {
      parentName,
      group: 'LDH_Nav_Vendors_View',
      label: 'Vendors'
    },
    component: () => import('@/pages/client-services/vendors/Vendors.vue')
  }
]
