




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors, TSizes } from '@/types'

import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'ButtonModule',
  components: {
    Icon
  }
})
export default class ButtonModule extends Vue {
  @Prop({ default: 'md' }) size!: TSizes
  @Prop({ default: 'basic' }) color!: TColors
  @Prop() disabled?: boolean
  @Prop() badge?: string
  @Prop() icon!: string
  @Prop() outline!: boolean

  get iconOnly () {
    return !this.$slots.default && !!this.icon
  }
}
