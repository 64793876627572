import { httpService } from '@/services'
import { IAssetReportsExport, IExport, IReportName, IExportJobReport } from '@/types'
import { downloadFile } from '@/helpers'

class ExportsService {
  getExportReportName (): Promise<IReportName[]> {
    return httpService.get('/ExportReportName/Get')
  }

  getExportReportDetails (activeOnly: boolean): Promise<IExport[]> {
    return httpService.get('/ExportReportDetail/Get', { params: { activeOnly } })
  }

  getExportReportDetailById (id: number): Promise<IExport> {
    return httpService.get('/ExportReportDetail/GetById', { params: { id } })
  }

  // E X P O R T   R E P O R T S
  exportAccountingReport (opportunityName: string): Promise<''> {
    return httpService.post('/Reports/ExportAccountingSalesOrderInfoReport', {}, { params: { opportunityName } })
  }

  exportAssetReport (type: string, payload: IAssetReportsExport): Promise<''> {
    return httpService.post(`/Reports/ExportAsset${type}Report`, {}, { params: payload })
  }

  exportDepositSetupReport (type: string): Promise<''> {
    return httpService.post(`/Reports/ExportDepositSetup${type}Report`)
  }

  exportEmployeeAccrualSetupReport (type: string): Promise<''> {
    return httpService.post(`/Reports/ExportEmployeeAccrualSetup${type}Report`)
  }

  exportEmployeeCustomFieldsReport (type: string): Promise<''> {
    return httpService.post(`/Reports/ExportEmployeeCustomFields${type}Report`)
  }

  exportEmployeeDirectDepositReport (type: string): Promise<''> {
    return httpService.post(`/Reports/ExportEmployeeDirectDeposit${type}Report`)
  }

  exportNewHireImportReport (type: string): Promise<''> {
    return httpService.post(`/Reports/ExportNewHireImport${type}Report`)
  }

  exportPayrollChangeReport (type: string, dateFrom: string): Promise<''> {
    return httpService.post(`/Reports/ExportPayrollChange${type}Report`, {}, { params: { dateFrom } })
  }

  exportTerminatingEmployeeReport (type: string): Promise<''> {
    return httpService.post(`/Reports/exportTerminatingEmployee${type}Report`)
  }

  exportPaycor (): Promise<''> {
    return httpService.post('/Reports/ExportPaycor')
  }

  downloadReport (reportId: number, reportType: number) {
    let url = ''

    switch (reportType) {
      case 1: url = `/Reports/GetAssetReportById?id=${reportId}`; break
      case 2: url = `/Reports/GetById?id=${reportId}`; break
      case 3: url = `/Reports/GetPayrollChangeById?id=${reportId}`; break
    }
    return downloadFile(url)
  }

  exportCelayixPayPeriodBillingReport (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportCelayixPayPeriodBillingReport', payload)
  }

  exportEndOfMonthBillingAccrualReport (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportEndOfMonthBillingAccrualReport', payload)
  }

  exportEndOfMonthPayrollAccrualReport (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportEndOfMonthPayrollAccrualReport', payload)
  }

  exportPayrollReports (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportPayrollReports', payload)
  }

  exportPayrollReportsByBranch (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportPayrollReportsByBranch', payload)
  }

  exportPayrollReportsByCustomer (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportPayrollReportsByCustomer', payload)
  }

  exportTimesheetFulfillment (payload: IExportJobReport): Promise<any> {
    return httpService.post('/Reports/ExportTimesheetFulfillment', payload)
  }

  createJournalEntries (reportId: any): Promise<any> {
    return httpService.post(`/NetSuiteSalesOrder/CreateJournalEntries?reportId=${reportId}`)
  }

  fulfillProjects (reportId: any): Promise<any> {
    return httpService.post(`/NetSuiteSalesOrder/FulfillProjects?reportId=${reportId}`)
  }

  fulfillServiceCalls (reportId: any): Promise<any> {
    return httpService.post(`/NetSuiteSalesOrder/FulfillServiceCalls?reportId=${reportId}`)
  }
}

export const exportsService = new ExportsService()
