import { clientServicesSidebarRoutesNames } from '@/pages/client-services/client-services.sidebar.routes'
import { commandCenterSidebarRoutesNames } from '@/pages/command-center/command-center.sidebar.routes'
import { humanResourcesSidebarRoutesNames } from '@/pages/human-resources/human-resources.sidebar.routes'
import { reportsSidebarRoutesNames } from '@/pages/reports/reports.sidebar.routes'
import { techOpsSidebarRoutesNames } from '@/pages/tech-ops/tech-ops.sidebar.routes'
import { ticketsIncidentsSidebarRoutesNames } from '@/pages/tickets-incidents/tickets-incidents.sidebar.routes'
import { dashboardSidebarRouteNames } from '@/pages/dashboard/sidebar/dashboard.sidebar.routes'
import { knowledgeBaseSidebarRoutesNames } from '@/pages/knowledge-base/knowledge-base.sidebar.routes'
import { maintenanceSidebarRouteNames } from '@/pages/maintenance/maintenance.sidebar.routes'
import { salesSidebarRouteNames } from '@/pages/sales/sales.sidebar.routes'

export const sidebarRouteNames = {
  ...clientServicesSidebarRoutesNames,
  ...commandCenterSidebarRoutesNames,
  ...humanResourcesSidebarRoutesNames,
  ...dashboardSidebarRouteNames,
  ...knowledgeBaseSidebarRoutesNames,
  ...maintenanceSidebarRouteNames,
  ...reportsSidebarRoutesNames,
  ...techOpsSidebarRoutesNames,
  ...ticketsIncidentsSidebarRoutesNames,
  ...salesSidebarRouteNames
}
