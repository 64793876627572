import { httpService } from '@/services'

import {
  IGuardTermByEmployeeId,
  IGuardTermStatus,
  IPosting,
  IUpdateGuardTermPayload,
  IGuardTerm,
  IGuardTermType
} from '@/types'

class GuardTermsService {
  getActiveGuardTerms (): Promise<IGuardTerm> {
    return httpService.get('/EmployeeGuardTerm/GetActiveGuardTermsQueue')
  }

  getAllStatusGuardTerms (): Promise<IGuardTerm> {
    return httpService.get('/EmployeeGuardTerm/GetAllStatusGuardTermsQueue')
  }

  getActiveGuardTermsByEmployeeId (id: number): Promise<IGuardTermByEmployeeId[]> {
    return httpService.get('/EmployeeGuardTerm/GetPrimaryEmployeeGuardTermByEmployeeIdFromView', { params: { id } })
  }

  getGuardTermStatuses (guardTermStatusTypeIdFrom: number): Promise<IGuardTermStatus[]> {
    return httpService.get('/EmployeeGuardTerm/GetGuardTermStatusTypes', { params: { guardTermStatusTypeIdFrom } })
  }

  getGuardTermTypes (includeId: number): Promise<{ guardTermTypes: IGuardTermType[] }> {
    return httpService.get('/EmployeeGuardTerm/GetGuardTermTypes', { params: { includeId } })
  }

  getGuardTermPostings (id: number): Promise<IGuardTermByEmployeeId[]> {
    return httpService.get('/EmployeeGuardTerm/GetEmployeeGuardTermById', { params: { id } })
  }

  getGuardTermPostingsAttached (): Promise<IPosting[]> {
    return httpService.get('/Posting/GetPostingsAttached')
  }

  getGuardTermByEmployeeId (id: number): Promise<IGuardTermByEmployeeId[]> {
    return httpService.get('/EmployeeGuardTerm/GetEmployeeGuardTermByEmployeeId', { params: { id } })
  }

  signGuardTerm (payload: { id: number; signature: string; isDeclined: boolean }): Promise<any> {
    return httpService.post('/EmployeeGuardTerm/SignGuardTerm', { ...payload, debug: true })
  }

  updateEmployeeGuardTermStatus ({ id, guardTermStatusId }: IUpdateGuardTermPayload): Promise<''> {
    return httpService.post('/EmployeeGuardTerm/UpdateEmployeeGuardTermStatus', {}, { params: { id, guardTermStatusId } })
  }

  createNewEmployeeGuardTerm ({ employeeId, addedPositions, removedPositions, guardTermTypeId, uniformDeposit, _90dayFee }: IUpdateGuardTermPayload): Promise<''> {
    return httpService.post('/EmployeeGuardTerm/CreateNewEmployeeGuardTerm', {}, {
      params: {
        employeeId,
        addedPositions,
        removedPositions,
        guardTermTypeId,
        uniformDeposit,
        _90dayFee: _90dayFee > 0 ? _90dayFee : null
      },
      paramsSerializer: params => Object.keys(params).map(key => key + '=' + params[key]).join('&')
    })
  }

  removeGuardTerm (id: number): Promise<''> {
    return httpService.post('/EmployeeGuardTerm/RemoveByEmployeeGuardTermId', {}, { params: { id } })
  }

  getGuardTermSignature (id: number): Promise<any> {
    return httpService.get('/EmployeeGuardTerm/GetEmployeeSignatureById', { params: { id } })
  }
}

export const guardTermsService = new GuardTermsService()
