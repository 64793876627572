import Vue from 'vue'
import VueMq from 'vue-mq'
import { Component } from 'vue-property-decorator'

// MQ plugin
Vue.use(VueMq, {
  breakpoints: {
    phone: 768,
    tablet: 1280,
    desktop: Infinity
  },
  defaultBreakpoint: 'phone'
})

// MQ wrapper mixin
@Component
class Breakpoints extends Vue {
  get $breakpoints () {
    return {
      mq: this.$mq,
      phone: this.$mq === 'phone',
      tablet: this.$mq === 'tablet',
      tabletAndUp: this.$mq === 'tablet' || this.$mq === 'desktop',
      desktop: this.$mq === 'desktop',
      width: window.innerWidth
    }
  }
}
Vue.mixin(Breakpoints)

// extend Vue types
type TMqBreakpoints = 'phone' | 'tablet' | 'desktop'
declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/interface-name-prefix
  interface Vue {
    $mq: TMqBreakpoints;
    $breakpoints: {
      mq: TMqBreakpoints;
      phone: boolean;
      tablet: boolean;
      tabletAndUp: boolean;
      desktop: boolean;
      width: number;
    };
  }
}
