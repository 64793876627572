const notesSidebarRoutesNames = {
  notesShow: 'notesShow',
  notesDetails: 'notesDetails'
}

const notesSidebarRoutes = [
  {
    name: notesSidebarRoutesNames.notesShow,
    path: '/notes-show',
    meta: { group: 'LDH_Reports_Notes_View' },
    component: () => import('./NotesShow.vue')
  },
  {
    name: notesSidebarRoutesNames.notesDetails,
    path: '/notes-:id/details',
    meta: { group: 'LDH_Reports_Notes_View' },
    component: () => import('./NotesDetails.vue')
  }
]

export {
  notesSidebarRoutesNames,
  notesSidebarRoutes
}
