import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.knowledgeBase

const knowledgeBaseRouteNames = {
  articles: 'KBArticle'
}

const knowledgeBaseRoutes = [
  {
    name: knowledgeBaseRouteNames.articles,
    path: 'articles',
    meta: {
      parentName,
      label: 'Articles',
      group: 'LDH_Nav_Articles_View'
    },
    component: () => import('@/pages/knowledge-base/articles/Articles.vue')
  }
]

export {
  knowledgeBaseRouteNames,
  knowledgeBaseRoutes
}
