import { massMailingSidebarRoutes, massMailingSidebarRoutesNames } from './mass-mailings/sidebar/mass-mailings.sidebar.routes'
import { detachedPostingsSidebarRoutes, detachedPostingsSidebarRoutesNames } from './detached-postings/sidebar/detached-postings.sidebar.routes'
import { terminatedEmployeesSidebarRoutes, terminatedEmployeesSidebarRoutesNames } from './terminated-employees/sidebar/terminated-employees.sidebar.routes'

const commandCenterSidebarRoutesNames = {
  ...massMailingSidebarRoutesNames,
  ...detachedPostingsSidebarRoutesNames,
  ...terminatedEmployeesSidebarRoutesNames
}

const commandCenterSidebarRoutes = [
  ...massMailingSidebarRoutes,
  ...detachedPostingsSidebarRoutes,
  ...terminatedEmployeesSidebarRoutes
]

export {
  commandCenterSidebarRoutesNames,
  commandCenterSidebarRoutes
}
