const reimbursementsSidebarRoutesNames = {
  reimbursementCreate: 'reimbursementCreate',
  reimbursementEdit: 'reimbursementEdit'
}

const reimbursementsSidebarRoutes = [
  {
    name: reimbursementsSidebarRoutesNames.reimbursementCreate,
    path: '/reimbursement-create',
    meta: { group: 'LDH_Reimbursement_Add', width: '600px' },
    component: () => import('./ReimbursementCreate.vue')
  },
  {
    name: reimbursementsSidebarRoutesNames.reimbursementEdit,
    path: '/reimbursement-:id/edit',
    meta: { group: 'LDH_Reimbursement_Update', width: '600px' },
    component: () => import('./ReimbursementUpdate.vue')
  }
]

export {
  reimbursementsSidebarRoutesNames,
  reimbursementsSidebarRoutes
}
