export interface IPersonalizationDetails {
  id: number;
  displayName: EPersonalizationSettings;
  employeeId: number;
  value: string;
}

export enum EPersonalizationSettings {
  SIDEBAR_COLLAPSED = 'Is Sidebar Collapsed',
  LAST_ROUTE = 'Last Route',
  FILTERS = 'Filters',
  TABLE_HEADINGS = 'Table Headings',
  TABLE_ITEMS_PER_PAGE = 'Table Items Per Page',
  TABLE_SORTING = 'Table Sorting',
  TABLE_PREDEFINED_SETTINGS = 'Table Settings',
  PREFERENCES = 'Preferences'
}

export enum EPersonalizationTables {
  CANDIDATES = 'Candidates',
  EMPLOYEES = 'Employees',
  MASS_MAILINGS = 'Mass Mailings',
  TERMINATED_EMPLOYEES = 'Terminated Employees',
  GUARD_TERMS = 'Guard Terms',
  LICENSES = 'Licenses',
  PAYCHECKS = 'Paychecks',
  REQUISITIONS = 'Requisitions',
  POSTING = 'Posting',
  POSTING_DETACHED = 'Posting Detached',
  TICKETS = 'Tickets',
  INCIDENTS = 'Incidents',
  PATROL_REPORT = 'Patrol Report',
  POSTING_UNATTACHED = 'Posting Unattached',
  NOTES = 'Notes',
  CELAYIX_SHIFTS = 'CelayixShifts',
  CUSTOMERS = 'Customers',
  CUSTOMER_CONTACTS = 'Customer Contacts',
  TRANSACTIONS = 'Transactions',
  EXPORTS = 'Exports',
  IMPORTS = 'Imports',
  PROJECTS = 'Projects',
  DROP_SHIPS = 'Drop Ships',
  POST_INSPECTIONS = 'Post Inspections',
  HR_ENROLLMENTS = 'benefit Enrollments',
  SERVICE_CALLS = 'Service Calls',
  PUBLIC_DOCUMENTS = 'Public Documents',
  PARTS = 'Parts',
  UNBILLABLE = 'Unbillable',
  VENDORS = 'Vendors',
  EMPLOYEE_ITEMS_SUMMARY = 'Employee Items Summary',
  ARTICLES = 'Articles',
  ASSIGNED_ASSETS = 'Assigned Assets',
  ASSETS = 'ASSETS',
  SECURITY = 'SECURITY',
  SECURITY_AUDIT_REPORT = 'Security Audit Report',
  REIMBURSEMENT = 'REIMBURSEMENT',
  OPPORTUNITY = 'OPPORTUNITY',
  CONTRACT_APPROVAL_TYPE = 'CONTRACT_APPROVAL_TYPE',
  ADDRESSES = 'ADDRESSES'
}
