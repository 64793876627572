<script>
export default {
  name: 'TransitionExpand',
  functional: true,
  props: {
    speed: {
      type: [Number, String]
    },
    calculateHeight: {
      type: Boolean
    },
    overflow: {
      type: Boolean,
      default: false
    }
  },
  render (createElement, context) {
    const data = {
      props: {
        name: 'expand'
      },
      on: {
        afterEnter (element) {
          if (context.props.overflow) {
            element.style.overflowY = 'hidden'
          }
          // eslint-disable-next-line no-param-reassign
          element.style.height = 'auto'
        },
        enter (element) {
          const speed = context.props.calculateHeight ? context.props.speed : 0
          const { width } = getComputedStyle(element)
          /* eslint-disable no-param-reassign */
          element.style.width = width
          element.style.position = 'absolute'
          element.style.visibility = 'hidden'
          element.style.height = 'auto'
          /* eslint-enable */
          const { height } = context.props.calculateHeight ? getComputedStyle(element) : 'auto'
          /* eslint-disable no-param-reassign */
          element.style.transitionDuration = speed + 's'
          element.style.width = null
          element.style.position = null
          element.style.visibility = null
          element.style.height = 0
          /* eslint-enable */
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            element.style.height = height
          })
        },
        leave (element) {
          const { height } = getComputedStyle(element)
          // eslint-disable-next-line no-param-reassign
          element.style.height = height
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            element.style.height = 0
          })
        }
      }
    }
    const el = context.props.group ? 'transition-group' : 'transition'

    return createElement(el, data, context.children)
  }
}
</script>

<style>
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
  transition-timing-function: ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
