import { httpService } from '@/services'
import { IPublicDocument, IPublicDocumentFormItem } from '@/types'

class PublicDocumentsService {
  getPublicDocuments (): Promise<IPublicDocument[]> {
    return httpService.get('/DocumentPublic/Get')
  }

  getPublicDocumentsByEmployeeTypeId (id: number): Promise<IPublicDocument[]> {
    return httpService.get('/DocumentPublic/GetByEmployeeTypeId', { params: { id } })
  }

  getPublicDocumentById (id: number): Promise<IPublicDocument> {
    return httpService.get('/DocumentPublic/GetById', { params: { id } })
  }

  uploadPublicDocuments (payload: IPublicDocumentFormItem[]): Promise<''> {
    return httpService.post('/DocumentPublic/UploadDocuments', payload)
  }

  updatePublicDocument (payload: IPublicDocument): Promise<''> {
    return httpService.post('/DocumentPublic/Update', payload)
  }

  removePublicDocument (id: number): Promise<''> {
    return httpService.post('/DocumentPublic/Remove', {}, { params: { id } })
  }
}

export const publicDocumentsService = new PublicDocumentsService()
