const hrEnrollmentsSidebarRouteNames = {
  benefitEnrollmentCreate: 'benefitEnrollmentCreate',
  benefitEnrollmentEdit: 'benefitEnrollmentEdit'
}

const hrEnrollmentsSidebarRoutes = [
  {
    name: hrEnrollmentsSidebarRouteNames.benefitEnrollmentCreate,
    path: '/benefit-enrollment-create',
    component: () => import('./BenefitEnrollmentCreate.vue')
  },
  {
    name: hrEnrollmentsSidebarRouteNames.benefitEnrollmentEdit,
    path: '/benefit-enrollment-:id/edit',
    component: () => import('./BenefitEnrollmentEdit.vue')
  }
]

export {
  hrEnrollmentsSidebarRouteNames,
  hrEnrollmentsSidebarRoutes
}
