import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsVendor: ICollectionConfig[] = [
  {
    label: 'Vendor Document Type',
    group: 'VendorDocumentType',
    getEndpoint: '/VendorDocumentType/GetVendorDocumentTypes',
    saveEndpoint: '/VendorDocumentType/SaveVendorDocumentType',
    updateEndpoint: '/VendorDocumentType/UpdateVendorDocumentType',
    toggleEndpoint: '/VendorDocumentType/ToggleVendorDocumentType',
    collectionName: collectionNames.vendorDocumentTypes,
    form: [...naming]
  },
  {
    label: 'Vendor Note Type',
    group: 'VendorNoteType',
    getEndpoint: '/VendorNoteType/GetVendorNoteTypes',
    saveEndpoint: '/VendorNoteType/SaveVendorNoteType',
    updateEndpoint: '/VendorNoteType/UpdateVendorNoteType',
    toggleEndpoint: '/VendorNoteType/ToggleVendorNoteType',
    collectionName: collectionNames.vendorNoteTypes,
    form: [...naming]
  },
  {
    label: 'Vendor Type',
    group: 'VendorType',
    getEndpoint: '/VendorType/GetVendorTypes',
    saveEndpoint: '/VendorType/SaveVendorType',
    updateEndpoint: '/VendorType/UpdateVendorType',
    toggleEndpoint: '/VendorType/ToggleVendorType',
    collectionName: collectionNames.vendorTypes,
    cellClass: ['isDefault'],
    headings: [
      { label: 'Net Suite Id', value: 'netSuiteId', sortable: true, width: 140, align: 'center' },
      { label: 'Sales Force Id', value: 'salesForceId', sortable: true, width: 140, align: 'center' },
      { label: 'Default', ...booleanFormatter('isDefault') }
    ],
    form: [...naming,
      { component: 'InputModule', model: 'netSuiteId', props: { label: 'Net Suite Id', placeholder: 'ID', class: 'w-full mt-4', type: 'number' } },
      { component: 'InputModule', model: 'salesForceId', props: { label: 'Sales Force Id', placeholder: 'ID', class: 'w-full mt-4' } },
      { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
  }
]
