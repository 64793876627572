import '@/plugins'
import Vue from 'vue'
import { store } from '@/store'
import Sidebar from '@/sidebar/Sidebar.vue'
import { sidebarRouter } from '@/sidebar/router'

Vue.observable(sidebarRouter)
Vue.prototype.$sidebar = sidebarRouter

export let sidebarApp: Vue | null = null

export function mountSidebar () {
  if (!sidebarApp) {
    sidebarApp = new Vue({
      el: '#sidebar',
      router: sidebarRouter,
      store,
      render: h => h(Sidebar)
    })
  }
}
