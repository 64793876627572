



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { modules } from '@/store'

import Backdrop from '@/components/shared/Backdrop.vue'
import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'Modal',
  components: {
    Backdrop,
    Icon
  },
  model: { prop: 'opened', event: 'closeModal' }
})
export default class Modal extends Vue {
  /* P R O P S */
  @Prop({ default: false }) opened!: boolean
  @Prop() bodyClass?: any
  @Prop({ default: false }) headerBorder!: boolean
  @Prop() modalClass?: string
  @Prop() modalBoxMaxWidth?: string
  @Prop() modalBoxMinWidth?: string
  @Prop() modalBoxWidth?: string
  @Prop() footerClass?: string
  @Prop() stickToBottom?: boolean
  @Prop() stickToTop?: boolean
  @Prop() stickToRight?: boolean
  @Prop() title!: string
  @Prop({ default: true }) showHeader?: boolean
  @Prop({ default: true }) showFooter?: boolean
  @Prop({ default: 'fade-shrink-in' }) animation!: string
  @Prop({ default: false }) fullScreen!: boolean
  @Prop() modalOrder!: number
  @Prop({ default: true }) backDropClose!: boolean
  @Prop({ default: true }) closeIcon!: boolean
  @Prop({ default: false }) modalFixed!: boolean
  @Prop({ default: false }) closeIconDisabled!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop() zIndex!: number

  /* D A T A */
  showModalHeaderShadow = false

  /* C O M P U T E D */
  get modalStick () {
    return this.stickToBottom || this.stickToTop || this.stickToRight
  }

  /* M E T H O D S */
  closeModal () {
    this.$emit('closeModal', false)
  }

  onEscHandler ({ key }: any) {
    key === 'Escape' && this.closeModal()
  }

  /* W A T C H E R S */
  @Watch('opened')
  onOpenedChanged (value: boolean) {
    if (value) {
      window.addEventListener('keydown', this.onEscHandler)
      modules.general.SET_MODAL_IS_OPEN(true)
    } else {
      window.removeEventListener('keydown', this.onEscHandler)
      modules.general.SET_MODAL_IS_OPEN(false)
    }
  }
}
