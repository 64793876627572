






















import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'sidebar' })
export default class Sidebar extends Vue {
  get opened () {
    return this.$sidebarRoute.path !== '/' &&
      this.$sidebar.resolve(this.$sidebarRoute.fullPath).resolved.matched.length > 0 &&
      this.$appRoute.fullPath !== 'auth'
  }

  get sidebarWidth () {
    if (this.$breakpoints.phone) {
      return { width: '100%' }
    }
    if (this.$route.meta) {
      const width = this.$route.meta.width
      const modal: any = this.$route.query.modal
      if (width && modal && modal in width) {
        return { width: width[modal] }
      }
      return { width: width && (width.default || width) }
    }
  }

  get sidebarMinWidth () {
    if (this.$route.meta) {
      const minWidth = this.$route.meta.minWidth
      if (minWidth && this.$breakpoints.tablet) {
        return { minWidth }
      }
    }
  }

  /* M E T H O D S */
  onHashChange (e: any) {
    const { oldURL, newURL } = e

    // PERHAPS SHOULD BE CHANGED TO BETTER SOLUTION
    if (oldURL.includes('#') && !newURL.includes('#')) {
      this.$sidebar.push('/')
      return false
    }
  }

  mounted () {
    window.addEventListener('hashchange', this.onHashChange, false)
  }

  beforeDestroy () {
    window.removeEventListener('hashchange', this.onHashChange, false)
  }
}
