import { ICollectionConfig } from '@/types'
import { dateTime, booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsService: ICollectionConfig[] = [{
  label: 'Service Call Issue Note Type',
  group: 'ServiceCallIssueNoteType',
  getEndpoint: '/ServiceCallIssueNoteType/GetAll',
  saveEndpoint: '/ServiceCallIssueNoteType/Save',
  updateEndpoint: '/ServiceCallIssueNoteType/Update',
  toggleEndpoint: '/ServiceCallIssueNoteType/Toggle',
  collectionName: collectionNames.serviceCallIssueNoteTypes,
  cellClass: ['isPublic'],
  headings: [{ label: 'Public', ...booleanFormatter('isPublic') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isPublic', props: { label: 'Public', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Service Call Status Type',
  group: 'ServiceCallStatusType',
  getEndpoint: '/ServiceCallStatusType/GetAll',
  saveEndpoint: '/ServiceCallStatusType/Save',
  updateEndpoint: '/ServiceCallStatusType/Update',
  toggleEndpoint: '/ServiceCallStatusType/Toggle',
  collectionName: collectionNames.serviceCallStatusTypes,
  cellClass: ['isDefault'],
  headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Service Call Type',
  group: 'ServiceCallType',
  getEndpoint: '/ServiceCallType/GetAll',
  saveEndpoint: '/ServiceCallType/Save',
  updateEndpoint: '/ServiceCallType/Update',
  toggleEndpoint: '/ServiceCallType/Toggle',
  collectionName: collectionNames.serviceCallTypes,
  cellClass: ['isBillable'],
  headings: [{ label: 'Billable', ...booleanFormatter('isBillable') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isBillable', props: { label: 'Billable', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Service Category',
  group: 'ServiceCategory',
  getEndpoint: '/ServiceCategory/GetServiceCategories',
  saveEndpoint: '/ServiceCategory/SaveServiceCategory',
  updateEndpoint: '/ServiceCategory/UpdateServiceCategory',
  toggleEndpoint: '/ServiceCategory/ToggleServiceCategory',
  collectionName: collectionNames.serviceCategories,
  cellClass: ['isDefault', 'isRecurring', 'reSold'],
  headings: [
    { label: 'Estimated Hours', value: 'estimatedHoursDefault', sortable: true, minWidth: 200 },
    { label: 'Sales Force Id', value: 'salesForceId', sortable: true, width: 140 },
    { label: 'Net Suite Id', value: 'netSuiteId', sortable: true, width: 130 },
    { label: 'Net Suite Parent Service Id', value: 'netSuiteParentServiceId', sortable: true, width: 230, align: 'center' },
    { label: 'Type', value: 'type', sortable: true, width: 100 },
    { label: 'Default Margin', value: 'defaultMargin', sortable: true, width: 160 },
    { label: 'Price Book Margin', value: 'priceBookMargin', sortable: true, width: 180 },
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Recurring', ...booleanFormatter('isRecurring') },
    { label: 'Resold', ...booleanFormatter('reSold') }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'estimatedHoursDefault',
    props: { label: 'Default Estimated Hours', placeholder: 'Hours', class: 'w-1/2 mt-4 pr-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'salesForceId',
    props: { label: 'Sales Force Id', placeholder: 'ID', class: 'w-1/2 mt-4 pl-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'netSuiteId',
    props: { label: 'Net Suite Id', placeholder: 'ID', class: 'w-1/2 mt-4 pr-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'netSuiteParentServiceId',
    props: { label: 'Net Suite Parent Service Id', placeholder: 'ID', class: 'w-1/2 mt-4 pl-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'defaultMargin',
    props: { label: 'Default Margin', placeholder: 'Margin', class: 'w-1/2 mt-4 pr-2', type: 'decimal' }
  }, {
    component: 'InputModule',
    model: 'priceBookMargin',
    props: { label: 'Price Book Margin', placeholder: 'Margin', class: 'w-1/2 mt-4 pl-2', type: 'decimal' }
  }, {
    component: 'InputModule',
    model: 'type',
    props: { label: 'Type', placeholder: 'Type', class: 'w-full mt-4' }
  }, {
    component: 'SelectModule',
    model: 'serviceLineId',
    options: 'serviceLines',
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Service Lines',
      placeholder: 'Select service line',
      class: 'w-full mt-4',
      clearable: true
    }
  }, {
    component: 'CheckboxModule',
    model: 'isDefault',
    props: { label: 'Default', class: 'w-1/3 mt-4' }
  }, {
    component: 'CheckboxModule',
    model: 'isRecurring',
    props: { label: 'Recurring', class: 'w-1/3 mt-4' }
  }, {
    component: 'CheckboxModule',
    model: 'reSold',
    props: { label: 'Resold', class: 'w-1/3 mt-4' }
  }]
},

{
  label: 'Service Line',
  group: 'ServiceLine',
  getEndpoint: '/ServiceLine/GetServiceLines',
  saveEndpoint: '/ServiceLine/SaveServiceLine',
  updateEndpoint: '/ServiceLine/UpdateServiceLine',
  toggleEndpoint: '/ServiceLine/ToggleServiceLine',
  formWrapClass: 'h-full flex-no-wrap flex-col',
  collectionName: collectionNames.serviceLines,
  cellClass: ['isDefault'],
  headings: [
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Description', value: 'description', sortable: true, width: 150 },
    { label: 'Abbreviation', value: 'abbreviation', sortable: true, width: 150 },
    { label: 'Net Suite Id', value: 'netSuiteId', sortable: true, width: 130, align: 'center' },
    { label: 'Sales Force Id', value: 'salesForceId', sortable: true, width: 200 },
    {
      label: 'Sales Force Last Update',
      value: 'salesForceLastUpdate',
      sortable: true,
      width: 210,
      align: 'center',
      formatter: i => dateTime(i.salesForceLastUpdate)
    }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'netSuiteId',
    props: { label: 'Net Suite Id', placeholder: 'ID', class: 'w-full mt-4', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'salesForceId',
    props: { label: 'Sales Force Id', placeholder: 'ID', class: 'w-full mt-4' }
  }, {
    component: 'TextareaModule',
    model: 'description',
    props: { label: 'Description', placeholder: 'Description', class: 'flex-1 w-full mt-4' }
  }, {
    component: 'CheckboxModule',
    model: 'isDefault',
    props: { label: 'Default', class: 'w-1/2 mt-4' }
  }]
},

{
  label: 'Service',
  group: 'Service',
  getEndpoint: '/Service/GetServices',
  saveEndpoint: '/Service/SaveService',
  updateEndpoint: '/Service/UpdateService',
  toggleEndpoint: '/Service/ToggleService',
  collectionName: collectionNames.services,
  cellClass: ['active', 'isDefault'],
  headings: [
    { label: 'Description', value: 'description', sortable: true, width: 150 },
    { label: 'Service Category Id', value: 'serviceCategoryId', sortable: true, width: 180, align: 'center' },
    { label: 'PickUp Drop Off Fees', value: 'pickUpDropOffFees', sortable: true, width: 190, align: 'center' },
    { label: 'Unit Cost', value: 'unitCost', sortable: true, width: 120, align: 'center' },
    { label: 'Technician Level Id', value: 'technicianLevelId', sortable: true, width: 180, align: 'center' },
    { label: 'Net Suite Id', value: 'netSuiteId', sortable: true, width: 130, align: 'center' },
    { label: 'Sales Force Id', value: 'salesForceId', sortable: true, width: 180 },
    { label: 'Active', ...booleanFormatter('active') },
    { label: 'Default', ...booleanFormatter('isDefault') },
    {
      label: 'Sales Force Last Update',
      value: 'salesForceLastUpdate',
      sortable: true,
      width: 210,
      align: 'center',
      formatter: i => dateTime(i.salesForceLastUpdate)
    }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'netSuiteId',
    props: { label: 'Net Suite Id', placeholder: 'ID', class: 'w-1/2 mt-4 pr-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'technicianLevelId',
    props: { label: 'Technician Level Id', placeholder: 'ID', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'pickupDropOffFees',
    props: { label: 'Pick Up Drop Off Fees', placeholder: 'Pick Up Drop Off Fees', class: 'w-1/2 mt-4 pr-2', type: 'decimal' }
  }, {
    component: 'InputModule',
    model: 'unitCost',
    props: { label: 'Unit Cost', placeholder: 'Unit Cost', class: 'w-1/2 mt-4 pl-2', type: 'decimal' }
  }, {
    component: 'CheckboxModule',
    model: 'active',
    props: { label: 'Active', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'CheckboxModule',
    model: 'isDefault',
    props: { label: 'Default', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'SelectModule',
    model: 'serviceCategoryId',
    options: 'serviceCategories',
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Service Category',
      placeholder: 'Select category',
      class: 'w-full mt-4'
    }
  }, {
    component: 'InputModule',
    model: 'salesForceId',
    props: { label: 'Sales Force Id', placeholder: 'ID', class: 'w-full mt-4' }
  }, {
    component: 'TextareaModule',
    model: 'description',
    props: { label: 'Description', placeholder: 'Description', class: 'w-full mt-4' }
  }]
}]
