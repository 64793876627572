import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { personalizationService } from '@/services'
import { modules } from '@/store'

import {
  TIndexedObject,
  IPersonalizationDetails,
  EPersonalizationTables,
  EPersonalizationSettings
} from '@/types'
import { debounce } from '@/helpers'
import { IFilter } from '@/components/shared/table-header-filter/table-header-filter.types'

const personalizationValidators: { [key in EPersonalizationSettings]?: Function } = {
  [EPersonalizationSettings.TABLE_HEADINGS]: (personalization: IPersonalizationDetails) => {
    return (typeof JSON.parse(personalization.value)) === 'object'
  }
}

const personalizationAppliers: { [key in EPersonalizationSettings]?: (setting: IPersonalizationDetails) => void } = {
  [EPersonalizationSettings.SIDEBAR_COLLAPSED]: (setting) => {
    const data = JSON.parse(setting.value)
    modules.general.TOGGLE_MENU_COLLAPSE(data === true || data === 'true')
  },
  [EPersonalizationSettings.FILTERS]: (setting) => {
    const data = JSON.parse(setting.value)
    modules.tableHeaderFilters.SET_TABLE_HEADER_FILTERS(data)
  },
  [EPersonalizationSettings.TABLE_HEADINGS]: (setting) => {
    const data = JSON.parse(setting.value)
    modules.tableHeadings.SET_ALL_TABLE_HEADINGS(data)
  }
}

@Module({ name: 'PersonalizationModule', namespaced: true })
export default class PersonalizationModule extends VuexModule {
  personalizations: IPersonalizationDetails[] = []

  /* G E T T E R S */
  get indexedPersonalizations () {
    return this.personalizations.reduce((acc, cur) => {
      acc[cur.displayName as string] = cur // todo change to name
      return acc
    }, {} as TIndexedObject<IPersonalizationDetails>)
  }

  /* M U T A T I O N S */
  @Mutation
  SET_PERSONALIZATIONS (personalizations: IPersonalizationDetails[]) {
    this.personalizations = personalizations
  }

  /* A C T I O N S */
  @debounce(1000)
  @Action({ rawError: true })
  async update ({ setting, value }: { setting: EPersonalizationSettings; value: any }) {
    const personalization = this.indexedPersonalizations[setting]
    if (personalization) {
      await personalizationService.updateHubPersonalization(personalization.id, JSON.stringify(value))
    } else {
      await personalizationService.createNewHubPersonalization(
        setting,
        setting,
        JSON.stringify(value)
      )
    }
    this.context.dispatch('fetch')
  }

  @Action({ rawError: true })
  async updateFilters ({ filters }: { filters: TIndexedObject<IFilter> }) {
    const personalization = this.indexedPersonalizations[EPersonalizationSettings.FILTERS]
    if (personalization) {
      await personalizationService.updateHubPersonalization(personalization.id, JSON.stringify(filters))
    } else {
      await personalizationService.createNewHubPersonalization(
        EPersonalizationSettings.FILTERS,
        EPersonalizationSettings.FILTERS,
        JSON.stringify({ filters })
      )
    }
    this.context.dispatch('fetch')
  }

  @Action({ rawError: true })
  async updateItemsPerPage ({ table, entries }: { table: EPersonalizationTables; entries: number }) {
    const personalization = this.indexedPersonalizations[EPersonalizationSettings.TABLE_ITEMS_PER_PAGE]
    if (personalization) {
      const value = JSON.parse(personalization.value)
      value[table] = entries
      await personalizationService.updateHubPersonalization(personalization.id, JSON.stringify(value))
    } else {
      await personalizationService.createNewHubPersonalization(
        EPersonalizationSettings.TABLE_ITEMS_PER_PAGE,
        EPersonalizationSettings.TABLE_ITEMS_PER_PAGE,
        JSON.stringify({ [table]: entries })
      )
    }
    this.context.dispatch('fetch')
  }

  @Action({ rawError: true })
  async updateSorting ({ table, sorting }: { table: EPersonalizationTables; sorting: object }) {
    const personalization = this.indexedPersonalizations[EPersonalizationSettings.TABLE_SORTING]
    if (personalization) {
      const value = JSON.parse(personalization.value)
      value[table] = sorting
      await personalizationService.updateHubPersonalization(personalization.id, JSON.stringify(value))
    } else {
      await personalizationService.createNewHubPersonalization(
        EPersonalizationSettings.TABLE_SORTING,
        EPersonalizationSettings.TABLE_SORTING,
        JSON.stringify({ [table]: sorting })
      )
    }
    this.context.dispatch('fetch')
  }

  @Action({ rawError: true })
  async updatePredefinedSettings ({ type, settings }: { type: EPersonalizationTables; settings: object }) {
    const personalization = this.indexedPersonalizations[EPersonalizationSettings.TABLE_PREDEFINED_SETTINGS]
    if (personalization) {
      const value = JSON.parse(personalization.value)
      if (value[type] && (Object.keys(value[type]).length <= Object.keys(settings).length)) {
        value[type] = { ...value[type], ...settings }
      } else {
        value[type] = { ...settings }
      }

      await personalizationService.updateHubPersonalization(personalization.id, JSON.stringify(value))
    } else {
      await personalizationService.createNewHubPersonalization(
        EPersonalizationSettings.TABLE_PREDEFINED_SETTINGS,
        EPersonalizationSettings.TABLE_PREDEFINED_SETTINGS,
        JSON.stringify({ [type]: settings })
      )
    }
    await this.context.dispatch('fetch')
  }

  @Action({ rawError: true })
  async create ({
    name,
    displayName,
    value
  }: { name: string; displayName: string; value: string; employeeId: number }) {
    await personalizationService.createNewHubPersonalization(name, displayName, value)
    this.context.dispatch('fetch')
    this.context.dispatch('applyPersonalizations')
  }

  @Action({ rawError: true })
  async cleanUpPersonalizations (personalizations: IPersonalizationDetails[]) {
    const reversed = [...personalizations].reverse()
    const existPersonalizationNames: string[] = []
    const uniquePersonalizations: IPersonalizationDetails[] = []

    reversed.forEach(personalization => {
      const validator = personalizationValidators[personalization.displayName]
      const isExist = existPersonalizationNames.includes(personalization.displayName)
      const isValid = validator ? validator(personalization) : true

      if (!isExist && isValid) {
        existPersonalizationNames.push(personalization.displayName)
        uniquePersonalizations.push(personalization)
      } else {
        personalizationService.deleteHubPersonalizations(personalization.id)
      }
    })
    return uniquePersonalizations
  }

  @Action({ rawError: true })
  async fetch () {
    if (modules.user.userProfile.id) {
      const personalizations = await personalizationService
        .getHubPersonalizationByEmployeeId(Number(modules.user.userProfile.id))

      const uniquePersonalizations = await this.context.dispatch('cleanUpPersonalizations', personalizations)
      await this.context.commit('SET_PERSONALIZATIONS', uniquePersonalizations)
    }
  }

  @Action({ rawError: true })
  async applyPersonalizations (personalizations?: IPersonalizationDetails[]) {
    if (personalizations) {
      this.context.commit('SET_PERSONALIZATIONS', personalizations)
    }

    this.personalizations.forEach(setting => {
      const isValue = setting.value !== null && setting.value !== undefined && setting.value !== ''
      const applier = personalizationAppliers[setting.displayName]

      if (isValue && applier) applier(setting)
    })
  }
}
