import Vue from 'vue'
import moment from 'moment'
import ScrollIntoView, { StandardBehaviorOptions } from 'scroll-into-view-if-needed'
import SmoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import download from 'downloadjs'

import { IDocumentFileDownload, IGuardTermSchedule, TIndexedObject, TNullableField } from '@/types'
import { httpService } from '@/services'

export * from './decorators'
export * from './filters-helpers'

moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
})

export const deepClone = <T> (obj: any): T => {
  // return result
  let newObj: any, i: number | string

  if (typeof obj !== 'object' || !obj) return obj

  if (Object.prototype.toString.apply(obj) === '[object Array]') {
    newObj = []

    for (i = 0; i < obj.length; i += 1) newObj[i] = deepClone(obj[i])

    return newObj
  }

  if (obj instanceof Date) {
    newObj = new Date(obj)
  } else {
    newObj = {}
    for (const i in obj) newObj[i] = deepClone(obj[i])
  }

  return newObj
}

export const estToLocal = (send?: boolean) => {
  const diff = (new Date().getTimezoneOffset() - 300) / 60
  return send ? diff : -diff
}

export const dateTimeNew = (format = 'MM/DD/YYYY', value?: string | Date): string => {
  const date = value ? new Date(value) : new Date()

  const day = date.getDate()
  const replaceDay = day > 9 ? day.toString() : `0${day}`

  const month = date.getMonth() + 1
  const replaceMonth = month > 9 ? month.toString() : `0${month}`

  const replaceYear = date.getFullYear().toString()

  const hours = date.getHours()
  const replaceHours = hours > 9 ? hours.toString() : `0${hours}`

  const minutes = date.getMinutes()
  const replaceMinutes = minutes > 9 ? minutes.toString() : `0${minutes}`

  const seconds = date.getSeconds()
  const replaceSeconds = seconds > 9 ? seconds.toString() : `0${seconds}`

  return format
    .replace('DD', replaceDay)
    .replace('MM', replaceMonth)
    .replace('YYYY', replaceYear)
    .replace('hh', replaceHours)
    .replace('mm', replaceMinutes)
    .replace('ss', replaceSeconds)
}

export const date = (value: TNullableField<string>, format = 'MM/DD/YYYY') => {
  return value ? dateTimeNew(format, value) : ''
}

export const time = (value: string, format = 'HH:mm:ss', send = false) => {
  return value ? moment(new Date(value)).add(estToLocal(send), 'hours').format(format) : ''
}

export const timeNoSeconds = (value: string, format = 'HH:mm', send = false) => {
  return value ? moment(new Date(value)).add(estToLocal(send), 'hours').format(format) : ''
}

export const dateTime = (value: string, format = 'MM/DD/YYYY HH:mm:ss', send = false) => {
  return value ? moment(new Date(value)).add(estToLocal(send), 'hours').format(format) : ''
}

export const nullableDateTime = (value: TNullableField<string>, format = 'MM/DD/YYYY HH:mm:sss', send = false) => {
  return value ? moment(new Date(value)).add(estToLocal(send), 'hours').format(format) : ''
}

export function scrollToBottom (el: HTMLElement | Element) {
  if (el) el.scrollTo(0, el.scrollHeight)
}

export const currencyFormat = (value: number | string) => {
  const number = +value
  if (number || number === 0) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number)
  } else {
    return '-'
  }
}

export function arrayToIndex<T = any> (arr: T[], field: string): { [key: string]: T } {
  return arr.reduce(function (acc: TIndexedObject<T>, item: T) {
    return {
      ...acc,
      [(item as any)[field]]: item
    }
  }, {} as TIndexedObject<T>)
}

export const downloadFile = async (
  apiUrl: string,
  toastText = 'Please wait while we prepare your files for download'
) => {
  try {
    Vue.toasted.info(toastText, { duration: 30000 })
    const file: IDocumentFileDownload = await httpService.get(apiUrl)
    await download(atob(file.fileData || ''), file.fileName || 'Untitled', file.mimeType)
    Vue.toasted.clear()
  } catch (e) {
    return Promise.reject(e)
  }
}

// Checks the value and returns - if it's empty
export function parsedValue (value: any) {
  // eslint-disable-next-line
  return value !== '' && value !== undefined && value !== null && value != -1 ? value : '-'
}

export function scrollIntoView (element: Element, options: StandardBehaviorOptions = {} as StandardBehaviorOptions) {
  const scrollIntoViewSmoothly = 'scrollBehavior' in document.body.style ? ScrollIntoView : SmoothScrollIntoView
  scrollIntoViewSmoothly(element, options)
}

export const getNestedProp = (obj: any, key: string) => {
  const arr: string[] = key.split('.')
  while (arr.length && obj) {
    const shift: any = arr.shift()
    obj = obj[shift]
  }
  return obj as any
}

export function groupBy (arr: any[], key: string) {
  return arr.reduce((acc, cur) => {
    const nestedKey = getNestedProp(cur, key)
    if (!nestedKey) {
      return acc
    }
    (acc[nestedKey] = acc[nestedKey] || []).push(cur)
    return acc
  }, {})
}

export function parseSchedule (schedule: IGuardTermSchedule[]) {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  return days.map(day => ({
    day,
    start: (schedule.find(scheduleDay => scheduleDay.day === day) || { start: '' }).start,
    end: (schedule.find(scheduleDay => scheduleDay.day === day) || { end: '' }).end
  }))
}

export function parsePhoneNumber (phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + '.' + match[2] + '.' + match[3]
  }
  return phoneNumber
}

export function filterUnique (el: any, index: number, self: any[]) {
  return el && self.indexOf(el) === index
}

export function truncate (str: TNullableField<string> | undefined, maxCharacters: number | undefined) {
  return (
    str &&
    maxCharacters &&
    maxCharacters > 0 &&
    str.length > maxCharacters ? `${str.slice(0, maxCharacters)}...` : str
  )
}

export const fileToBase64: (file: File) => Promise<TNullableField<string>> = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(String(reader.result).split(',')[1])
    reader.onerror = error => reject(error)
  })
}

export function getPlainInnerText (htmlMarkup: string) {
  const el = document.createElement('div')
  el.innerHTML = htmlMarkup.replace(/(\w+)(?=[<])/g, '$1 ').replace(/\n/g, ' ')
  return el.innerText
}

export function validateEmail (email: string) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function getShiftType (shiftType: string) {
  switch (Number(shiftType)) {
    case 1:
      return '1st Shift'
    case 2:
      return '2nd Shift'
    case 3:
      return '3rd Shift'
    case 12:
      return '1st & 2nd Shift'
    case 13:
      return '1st & 3rd Shift'
    case 23:
      return '2nd & 3rd Shift'
    default:
      return '-'
  }
}

export function getActiveTabIndex (tabsName: string) {
  return `Aegis-${tabsName}-active-tab`
}

export function getWeekStartEnd (d: Date) {
  return {
    start: moment(d).startOf('week').toDate(),
    end: moment(d).endOf('week').toDate()
  }
}

export const dayHours = [...Array(24).keys()]

export const quarterMinutes = [0, 15, 30, 45]

export const parseToStrings = (object: any) => {
  const data: any = deepClone(object)

  const fn = ((value: any): any => {
    if (Array.isArray(value)) {
      value.forEach((i, index) => {
        if (typeof i === 'object') fn && fn(i)
        else value[index] = String(i)
      })
    } else if (typeof value === 'object') {
      for (const prop in value) {
        if (typeof prop === 'object') fn && fn(prop)
        else value[prop] = String(value[prop])
      }
    }
  })(data)

  return data
}

export const booleanFormatter = (value: string): any => {
  return {
    value,
    width: 165,
    sortable: true,
    formatter: (row: any) => row[value] === null ? '-' : (row[value] ? 'YES' : 'NO')
  }
}

export const pick = (object: any, keys: any): any => {
  return keys.reduce((obj: any, key: any) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export const sortBy = (key: any) => {
  return (a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)
}
