






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors } from '@/types'

import Modal from '@/components/shared/Modal.vue'
import CancelSave from '@/components/shared/CancelSave.vue'

@Component({
  name: 'ConfirmModal',
  components: {
    Modal,
    CancelSave
  }
})

export default class ConfirmModal extends Vue {
  /* P R O P S */
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) message!: string
  @Prop({ default: 'Ok' }) confirmText!: string
  @Prop({ default: false }) modalFixed!: boolean
  @Prop({ default: false }) saveButtonDisabled!: boolean
  @Prop({ default: true }) backDropClose!: boolean
  @Prop({ default: 'primary' }) confirmColor!: TColors
  @Prop() modalBoxMaxWidth?: string

  /* D A T A */
  promise = undefined
  opened = false
  resolvePromise: ((value?: any) => void) | undefined = undefined
  rejectPromise: ((value?: any) => void) | undefined = undefined

  /* M E T H O D S */
  async close () {
    const done = () => {
      this.updateOpened(false)
      this.rejectPromise && this.rejectPromise()
    }
    done()
  }

  updateOpened (value: boolean) {
    if (value) {
      this.$emit('open')
    } else {
      this.$emit('close')
    }
    this.opened = value
  }

  async confirm () {
    const done = () => {
      this.updateOpened(false)
      this.resolvePromise && this.resolvePromise()
    }
    done()
  }

  show () {
    this.updateOpened(true)
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve
      this.rejectPromise = reject
    })
  }

  destroyComponent () {
    // destroy the vue listeners, etc
    this.$destroy()

    // remove the element from the DOM
    this.$el.parentNode && this.$el.parentNode.removeChild(this.$el)
  }
}
