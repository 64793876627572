import { httpService, authService } from '@/services'
import { IGlobalSearch } from '@/types'

class GlobalService {
  getCurrentBuildVersion (): Promise<string> {
    return httpService.get('/Global/GetCurrentBuildVersion')
  }

  async getGuiVersion (): Promise<string> {
    if (process.env.NODE_ENV === 'development') {
      return '<dev-build>'
    }
    return httpService.get(authService.getOrigin() + '/version.txt')
  }

  search (term: string): Promise<IGlobalSearch[]> {
    return httpService.get('/Global/Search', { params: { term } })
  }
}

export const globalService = new GlobalService()
