import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { TIndexedObject, EPersonalizationSettings, ITableHeading } from '@/types'
import Vue from 'vue'
import { modules } from '@/store'

function parseHeadingsHelper (payload: { tableHeadings: ITableHeading[]; tableName: string }) {
  return {
    tableName: payload.tableName,
    tableHeadings: payload.tableHeadings.map(h => ({
      value: h.value,
      selected: h.selected || false,
      fixed: h.fixed || false
    }))
  }
}

@Module({ name: 'TableHeadingsModule', namespaced: true })
export default class TableHeadingsModule extends VuexModule {
  tablePreferencesNames = {
    CUSTOMERS: 'customers',
    CUSTOMER_CONTACTS: 'customer contacts',
    MASS_MAILINGS: 'mass mailings',
    PATROL_REPORT: 'patrol report',
    POSTING_DETACHED: 'posting detached',
    TERMINATED_EMPLOYEES: 'terminated employees',
    EMPLOYEES: 'employees',
    CANDIDATES: 'candidates',
    GUARD_TERMS: 'guard terms',
    PAYCHECKS: 'paychecks',
    LICENSES: 'licenses',
    POSTING: 'posting',
    REQUISITIONS: 'requisitions',
    UNATTACHED_POSTINGS: 'unattached postings',
    ARTICLES: 'articles',
    ASSETS: 'assets',
    ASSIGNED_ASSETS: 'assigned assets',
    TRANSACTIONS: 'transactions',
    NOTES: 'notes',
    CELAYIX_SHIFTS: 'celayix shifts',
    EXPORTS: 'exports',
    IMPORTS: 'imports',
    INCIDENTS: 'incidents',
    TICKETS: 'tickets',
    PROJECTS: 'projects',
    SERVICE_CALLS: 'service calls',
    DROP_SHIP: 'drop ship',
    POST_INSPECTIONS: 'post inspections',
    BENEFIT_ENROLLMENTS: 'benefit enrollments',
    PUBLIC_DOCUMENTS: 'public documents',
    PARTS: 'parts',
    UNBILLABLE: 'unbillable',
    VENDORS: 'vendors',
    JOBS: 'jobs',
    EMPLOYEE_ITEMS_SUMMARY: 'employee items summary',
    PERMISSIONS: 'permissions',
    REIMBURSEMENTS: 'reimbursements',
    SECURITY_AUDIT_REPORT: 'security audit report',
    OPPORTUNITY: 'opportunity',
    CONTRACT_APPROVAL_TYPE: 'contract approval type',
    ADDRESSES: 'addresses'
  }

  headingsState: TIndexedObject<ITableHeading[]> = {}
  headingsDefaultState: TIndexedObject<ITableHeading[]> = {}

  @Mutation
  SET_TABLE_DEFAULT_HEADINGS (payload: { tableHeadings: ITableHeading[]; tableName: string }) {
    if (payload) {
      Vue.set(this.headingsDefaultState, payload.tableName, payload.tableHeadings)
    }
  }

  @Mutation
  SET_TABLE_HEADINGS (payload: { tableHeadings: ITableHeading[]; tableName: string }) {
    if (payload) {
      Vue.set(this.headingsState, payload.tableName, payload.tableHeadings)
    }
  }

  @Mutation
  SET_ALL_TABLE_HEADINGS (tableHeadings: TIndexedObject<ITableHeading[]>) {
    this.headingsState = tableHeadings
  }

  @Action({ rawError: true })
  async applyPreferences () {
    const mappedPayload = Object.fromEntries(Object.entries(this.headingsState).map(([tableName, tableHeadings]) => {
      const mappedHeadings = parseHeadingsHelper({ tableName, tableHeadings })
      return [tableName, mappedHeadings.tableHeadings]
    }))

    await modules.personalization.update({
      setting: EPersonalizationSettings.TABLE_HEADINGS,
      value: mappedPayload
    })
  }

  @Action({ rawError: true })
  async updatePreferences (payload: { tableHeadings: ITableHeading[]; tableName: string }) {
    this.context.commit('SET_TABLE_HEADINGS', payload)
    await this.context.dispatch('applyPreferences')
  }

  @Action({ commit: 'SET_TABLE_DEFAULT_HEADINGS', rawError: true })
  createTableHeadings (payload:
      { obj: any; overrides: ITableHeading[]; tableName: string; useOverridesOnly: boolean }) {
    const tableHeaderPayload: { tableHeadings: ITableHeading[]; tableName: string } = {
      tableHeadings: [],
      tableName: payload.tableName
    }

    if (payload.useOverridesOnly && payload.overrides.length > 0) {
      tableHeaderPayload.tableHeadings = payload.overrides
    } else {
      // For every Property on of the Object, create a Table Header
      for (let i = 0; i < Object.keys(payload.obj).length; i++) {
        const label = Object.values(Object.keys(payload.obj))[i].replace(/([A-Z])/g, ' $1')

        const header: ITableHeading = {
          value: Object.values(Object.keys(payload.obj))[i], // Object.values() gets the name of the Property
          valueType: typeof Object.values(Object.keys(payload.obj))[i],
          label: label.charAt(0).toUpperCase() + label.slice(1), // Converts the camelCase Property name to Proper Case
          minWidth: 200,
          selected: Object.values(Object.keys(payload.obj))[i].toLowerCase() === 'id', // Select only ID field
          fixed: false,
          filterable: true,
          sortable: true
        }
        tableHeaderPayload.tableHeadings.push(header)
      }

      if (payload.overrides && payload.overrides.length > 0) {
        for (let i = 0; i < payload.overrides.length; i++) {
          for (let j = 0; j < tableHeaderPayload.tableHeadings.length; j++) {
            if (tableHeaderPayload.tableHeadings[j].value === payload.overrides[i].value) {
              tableHeaderPayload.tableHeadings[j] = payload.overrides[i]
            }
          }
        }
      }

      if (payload.overrides.filter((override) => override.value === 'actions').length > 0) {
        const actionsHeader: ITableHeading = {
          label: '',
          value: 'actions',
          fixed: 'right',
          width: '52',
          align: 'center',
          excludeFromNavigation: true,
          selected: true
        }
        tableHeaderPayload.tableHeadings.push(actionsHeader)
      }
    }

    return tableHeaderPayload
  }
}
