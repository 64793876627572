import { AxiosError, AxiosResponse } from 'axios'
import Vue from 'vue'
import { appRouter } from '@/router'
import { authRoutesNames } from '@/pages/auth/auth.routes'
import { httpService } from '@/services'
import { modules } from '@/store'

export const unwrapResponseInterceptor = (response: AxiosResponse) => {
  if (response.data && response.data.hasOwnProperty('errors')) {
    Vue.toasted.error(response.data.errors)
    throw Object.assign(
      {},
      {
        url: response.config.url,
        error: response.data.errors
      }
    )
  }
  return response.data
}

export const responseErrorInterceptor = async (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 401) {
      try {
        Vue.toasted.error("Session isn't valid or expired. Trying to refresh...")
        await modules.user.acquireToken()
        return httpService.request(error.config)
      } catch {
        appRouter.replace({ name: authRoutesNames.login })
      }
    }
  }
  Vue.toasted.error(error.message)
  return Promise.reject(error)
}
