var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.meta)?_c('div',{staticClass:"font-sans",class:{
    modal: _vm.$route.meta.modal || (_vm.$route.meta.modalOnMobile && _vm.$breakpoints.phone),
    sidebar: !_vm.$route.meta.modal,
    phone: _vm.$breakpoints.phone,
    tablet: _vm.$breakpoints.tablet,
    desktop: _vm.$breakpoints.tabletAndUp,
    closed: !_vm.opened,
    shadow: _vm.opened
  },style:([ _vm.sidebarWidth, _vm.sidebarMinWidth ]),attrs:{"id":"sidebar"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }