import { httpService } from '@/services'

import {
  ICandidate,
  ICandidateProfile,
  ICandidatePersonalEditing,
  ICandidateDetailsEditing,
  ICandidateCelayixDuplicate,
  INewCandidateForm,
  ICandidateType,
  ICandidateStatusType,
  ICandidateMatch,
  IHireCandidate,
  IEmployeeContact,
  IEmployeeEmployement,
  IEmployeeIntegrations,
  ICandidatePersonal
} from '@/types'

/* C A N D I D A T E */
class CandidateService {
  // getUploadedFilesByGuid: '/Candidate/GetUploadedFilesByGuid',
  // saveContactInformation: '/Candidate/SaveContactInformation',
  // updateCandidateEmploymentType: '/Candidate/UpdateCandidateEmploymentType'

  /* C A N D I D A T E S */
  getCandidates (activeOnly: boolean): Promise<ICandidate[]> {
    return httpService.get('/Candidate/GetAll', { params: { activeOnly } })
  }

  checkCandidate (payload: ICandidateMatch): Promise<ICandidateMatch[]> {
    return httpService.post('/Candidate/CheckDuplicate', payload)
  }

  resetCandidateStatus (id: number): Promise<''> {
    return httpService.post('/Candidate/ResetCandidateStatus', {}, { params: { id } })
  }

  addCandidate (payload: INewCandidateForm): Promise<''> {
    return httpService.post('/Candidate/ManuallyAddCandidate', payload)
  }

  createFlowFinityEmployee (id: number): Promise<''> {
    return httpService.post('/Candidate/CreateFlowFinityEmployee', {}, { params: { id } })
  }

  hireCandidate (params: IHireCandidate): Promise<''> {
    return httpService.post('/Candidate/HireCandidate', {}, { params })
  }

  updateFlowFinityId (id: number, flowFinityId: string): Promise<''> {
    return httpService.post('/Candidate/UpdateFlowFinityId', {}, { params: { id, flowFinityId } })
  }

  updatePaycorId (id: number, paycorId: number): Promise<''> {
    return httpService.post('/Candidate/UpdatePaycorId', {}, { params: { id, paycorId } })
  }

  sendPersonalInformationLink (id: number): Promise<''> {
    return httpService.post('/Candidate/SendPersonalInformationLink', {}, { params: { id } })
  }

  resetApplicationLink (id: number): Promise<''> {
    return httpService.post('/Candidate/ResetApplicationLink', {}, { params: { id } })
  }

  forceApply (id: number): Promise<''> {
    return httpService.post('/Candidate/ForceApply', {}, { params: { id } })
  }

  activateCelayixRecord (id: number): Promise<''> {
    return httpService.post('/Candidate/ActivateCelayixRecord', {}, { params: { id } })
  }

  activateFlowFinityRecord (id: number): Promise<''> {
    return httpService.post('/Candidate/ActivateFlowFinityRecord', {}, { params: { id } })
  }

  updatePersonalDetails (payload: ICandidatePersonalEditing): Promise<''> {
    return httpService.post('/Candidate/UpdatePersonalDetails', payload)
  }

  saveCandidateDetails (payload: ICandidateDetailsEditing): Promise<''> {
    return httpService.post('/Candidate/SaveCandidateDetails', payload)
  }

  getCandidateById (id: number | string): Promise<ICandidateProfile> {
    return httpService.get('/Candidate/GetById', { params: { id } })
  }

  updateCandidateStatus (candidateId: number, statusId: number): Promise<''> {
    return httpService.post('/Candidate/UpdateCandidateStatus', {}, { params: { candidateId, statusId } })
  }

  updateCandidateEmployeeType (candidateId: number, typeId: number): Promise<''> {
    return httpService.post('/Candidate/UpdateCandidateEmployeeType', {}, { params: { candidateId, typeId } })
  }

  updateCandidateProfileType (candidateId: number, typeId: number): Promise<''> {
    return httpService.post('/Candidate/UpdateCandidateType', {}, { params: { candidateId, typeId } })
  }

  createCelayixAccount (id: number): Promise<'' | { duplicate: ICandidateCelayixDuplicate }> {
    return httpService.post('/Candidate/CreateCelayixAccount', {}, { params: { id } })
  }

  createCelayixWebAccessAccount (id: number, shortName: string): Promise<''> {
    return httpService.post('/Candidate/CreateCelayixWebAccessAccount', {}, { params: { id, shortName } })
  }

  linkCelayixAccounts (payload: ICandidateCelayixDuplicate): Promise<''> {
    return httpService.post('/Candidate/LinkCelayixAccounts', payload)
  }

  candidateDoNotHireFlag (id: number, action: 'Get' | 'Set' | 'Unset'): Promise<boolean> {
    return httpService.post(`/Candidate/${action}DoNotHireFlag`, {}, { params: { id } })
  }

  /* C A N D I D A T E   T Y P E */
  getCandidateTypes (): Promise<ICandidateType[]> {
    return httpService.get('/CandidateType/GetCandidateTypes')
  }

  saveCandidateType (candidateType: ICandidateType): Promise<ICandidateType> {
    return httpService.post('/CandidateType/SaveCandidateType', candidateType)
  }

  updateCandidateType (candidateType: ICandidateType): Promise<ICandidateType> {
    return httpService.post('/CandidateType/UpdateCandidateType', candidateType)
  }

  toggleCandidateType (id: number): Promise<''> {
    return httpService.post('/CandidateType/ToggleCandidateType', {}, { params: { id } })
  }

  /* C A N D I D A T E   S T A T U S   T Y P E */
  getCandidateStatusTypes (): Promise<ICandidateStatusType[]> {
    return httpService.get('/CandidateStatusType/GetCandidateStatusTypes')
  }

  saveCandidateStatusType (candidateStatusType: ICandidateStatusType): Promise<''> {
    return httpService.post('/CandidateStatusType/SaveCandidateStatusType', candidateStatusType)
  }

  updateCandidateStatusType (candidateStatusType: ICandidateStatusType): Promise<''> {
    return httpService.post('/CandidateStatusType/UpdateCandidateStatusType', candidateStatusType)
  }

  toggleCandidateStatusType (id: number): Promise<''> {
    return httpService.post('/CandidateStatusType/ToggleCandidateStatusType', {}, { params: { id } })
  }

  getCandidateHistory (id: number): Promise<any> {
    return httpService.get('/Candidate/GetCandidateHistory', { params: { id } })
  }

  getCandidatePersonalById (id: number): Promise<ICandidatePersonal> {
    return httpService.get('/Candidate/GetCandidatePersonalById', { params: { id } })
  }

  getCandidateContactById (id: number): Promise<IEmployeeContact> {
    return httpService.get('/Candidate/GetCandidateContactById', { params: { id } })
  }

  getCandidateEmploymentById (id: number): Promise<IEmployeeEmployement> {
    return httpService.get('/Candidate/GetCandidateEmploymentById', { params: { id } })
  }

  getCandidateIntegrationsById (id: number): Promise<IEmployeeIntegrations> {
    return httpService.get('/Candidate/GetCandidateIntegrationsById', { params: { id } })
  }
}

export const candidateService = new CandidateService()
