import { httpService } from '@/services'
import { IPostingDetached } from '@/types'

class DetachedPostingsService {
  getPostingDetached (): Promise<IPostingDetached[]> {
    return httpService.get('/CommandCenter/GetPostingDetached')
  }

  removePostingDetached (id: number): Promise<''> {
    return httpService.post('/CommandCenter/RemovePosting', {}, { params: { id } })
  }

  relistPostingDetached (id: number): Promise<''> {
    return httpService.post('/CommandCenter/RelistPosting', {}, { params: { id } })
  }
}

export const detachedPostingsService = new DetachedPostingsService()
