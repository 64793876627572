const employeeItemsSidebarRoutesNames = {
  employeeItemsShow: 'employeeItemsShow',
  employeeItemsDetails: 'employeeItemsDetails'
}

const employeeItemsSidebarRoutes = [
  {
    name: employeeItemsSidebarRoutesNames.employeeItemsShow,
    path: '/employee-items-show',
    meta: { },
    component: () => import('./EmployeeItemsShow.vue')
  },
  {
    name: employeeItemsSidebarRoutesNames.employeeItemsDetails,
    path: '/employee-items-:id/details',
    meta: { },
    component: () => import('./EmployeeItemsDetails.vue')
  }
]

export {
  employeeItemsSidebarRoutesNames,
  employeeItemsSidebarRoutes
}
