import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsIncident: ICollectionConfig[] = [{
  label: 'Incident Involved Party Type',
  group: 'Incident_Involved_Party_Types',
  getEndpoint: '/IncidentInvolvedPartyType/GetIncidentInvolvedPartyTypes',
  saveEndpoint: '/IncidentInvolvedPartyType/SaveIncidentInvolvedPartyType',
  updateEndpoint: '/IncidentInvolvedPartyType/UpdateIncidentInvolvedPartyType',
  toggleEndpoint: '/IncidentInvolvedPartyType/ToggleIncidentInvolvedPartyType',
  collectionName: collectionNames.incidentInvolvedPartyTypes,
  form: [...naming]
},

{
  label: 'Incident Note Type',
  group: 'Incident_Note_Types',
  getEndpoint: '/IncidentNoteType/GetIncidentNoteTypes',
  saveEndpoint: '/IncidentNoteType/SaveIncidentNoteType',
  updateEndpoint: '/IncidentNoteType/UpdateIncidentNoteType',
  toggleEndpoint: '/IncidentNoteType/ToggleIncidentNoteType',
  collectionName: collectionNames.incidentNoteTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming, { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Incident Type',
  group: 'Incident_Types',
  getEndpoint: '/IncidentType/GetIncidentTypes',
  saveEndpoint: '/IncidentType/SaveIncidentType',
  updateEndpoint: '/IncidentType/UpdateIncidentType',
  toggleEndpoint: '/IncidentType/ToggleIncidentType',
  collectionName: collectionNames.incidentTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming, { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
}]
