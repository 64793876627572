




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ListItem'
})
export default class ListItem extends Vue {
  @Prop({ default: false }) noBorder!: boolean
  @Prop({ default: true }) cursorPointer!: boolean
  @Prop({ default: true }) showDescription!: boolean
  @Prop() title!: string
  @Prop({ default: '14' }) titleSize!: string
  @Prop() description!: string
  @Prop({ default: false }) reversed!: boolean
}
