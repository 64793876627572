import { notesSidebarRoutes, notesSidebarRoutesNames } from './notes/sidebar/notes.sidebar.routes'
import { exportsSidebarRoutes, exportsSidebarRoutesNames } from './exports/sidebar/exports.sidebar.routes'
import { celayixShiftsSidebarRoutes, celayixShiftsSidebarRoutesNames } from './celayix-shifts/sidebar/celayix-shifts.sidebar.routes'
import { employeeItemsSidebarRoutes, employeeItemsSidebarRoutesNames } from './employee-items/sidebar/employee-items.sidebar.routes'
import { importsSidebarRoutes, importsSidebarRoutesNames } from './imports/sidebar/imports.sidebar.routes'

const reportsSidebarRoutesNames = {
  ...notesSidebarRoutesNames,
  ...importsSidebarRoutesNames,
  ...exportsSidebarRoutesNames,
  ...celayixShiftsSidebarRoutesNames,
  ...employeeItemsSidebarRoutesNames
}

const reportsSidebarRoutes = [
  ...notesSidebarRoutes,
  ...importsSidebarRoutes,
  ...exportsSidebarRoutes,
  ...celayixShiftsSidebarRoutes,
  ...employeeItemsSidebarRoutes
]

export {
  reportsSidebarRoutesNames,
  reportsSidebarRoutes
}
