import { incidentsSidebarRoutes, incidentsSidebarRoutesNames } from './incidents/sidebar/incidents.sidebar.routes'
import { ticketSidebarRoutes, ticketSidebarRoutesNames } from './tickets/sidebar/ticket.sidebar.routes'

const ticketsIncidentsSidebarRoutesNames = {
  ...incidentsSidebarRoutesNames,
  ...ticketSidebarRoutesNames
}

const ticketsIncidentsSidebarRoutes = [
  ...incidentsSidebarRoutes,
  ...ticketSidebarRoutes
]

export {
  ticketsIncidentsSidebarRoutesNames,
  ticketsIncidentsSidebarRoutes
}
