export const parentRoutesNames = {
  clientServices: 'clientServices',
  commandCenter: 'commandCenter',
  humanResources: 'humanResources',
  knowledgeBase: 'knowledgeBase',
  maintenance: 'maintenance',
  reports: 'reports',
  sales: 'sales',
  techOps: 'techOps',
  ticketsIncidents: 'ticketsIncidents'
}
