import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { customerService, postingService, employeeService, collectionsService } from '@/services'
import {
  TIndexedObject,
  IPosting,
  IPostingStatusType,
  IShiftTime,
  ITRainingDaysByPostingId,
  ICustomerDetailsForPostingModel
} from '@/types'

@Module({ name: 'PostingModule', namespaced: true })
export default class PostingModule extends VuexModule {
  postingsLoading = false
  postings: readonly IPosting[] = []
  postingStatusTypes: IPostingStatusType[] = []
  blankPostings: IPosting[] = []
  shiftData: any[] = []
  trainingDaysByPostingId = {} as ITRainingDaysByPostingId
  employeeTrainingSessionsByPostingId: any[] = []
  postingDetailsLoading = false
  customerDetailsForPosting = {} as ICustomerDetailsForPostingModel

  /* C O M P U T E D */
  get indexedPostings () {
    return this.postings.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {} as TIndexedObject<IPosting>)
  }

  /* M U T A T I O N S   P O S T I N G */
  @Mutation
  SET_SHIFT_DATA (payload: any[]) {
    this.shiftData = payload
  }

  @Mutation
  SET_POSTINGS_LOADING (payload: boolean) {
    this.postingsLoading = payload
  }

  @Mutation
  SET_CUSTOMER_DETAILS_FOR_POSTING (payload: ICustomerDetailsForPostingModel) {
    this.customerDetailsForPosting = payload
  }

  @Mutation
  SET_POSTINGS (payload: readonly IPosting[]) {
    this.postings = payload
  }

  @Mutation
  SET_BLANK_POSTINGS (payload: any) {
    this.blankPostings = payload.guardTypes.map((i: any) => { return { ...i, value: Number(i.value) } })
  }

  @Mutation
  SET_POSTING_DETAILS_LOADING (payload: boolean) {
    this.postingDetailsLoading = payload
  }

  @Mutation
  SET_TRAINING_DAYS_BY_POSTING_ID (payload: ITRainingDaysByPostingId) {
    this.trainingDaysByPostingId = payload
  }

  @Mutation
  SET_EMPLOYEE_TRAINING_SESSIONS_BY_POSTING_ID (payload: any[]) {
    this.employeeTrainingSessionsByPostingId = payload
  }

  @Mutation
  SET_POSTING_STATUS_TYPES (payload: IPostingStatusType[]) {
    this.postingStatusTypes = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CUSTOMER_DETAILS_FOR_POSTING', rawError: true })
  async getCustomerDetailsForPosting (id: number) {
    return await customerService.getCustomerDetailsForPosting(id)
  }

  @Action({ commit: 'SET_POSTINGS', rawError: true })
  async getPostings (activeToggleState: boolean) {
    return await postingService.getPostings(activeToggleState)
  }

  @Action({ commit: 'SET_BLANK_POSTINGS', rawError: true })
  async getBlankPosting () {
    return await postingService.getBlankPosting()
  }

  @Action({ commit: 'SET_POSTING_STATUS_TYPES', rawError: true })
  async getPostingStatusTypes () {
    return await collectionsService.getCollection('postingStatuses')
  }

  @Action({ commit: 'SET_TRAINING_DAYS_BY_POSTING_ID', rawError: true })
  async getTrainingDaysByPostingId (id: number) {
    return await postingService.getTrainingDaysByPostingId(id)
  }

  @Action({ commit: 'SET_EMPLOYEE_TRAINING_SESSIONS_BY_POSTING_ID', rawError: true })
  async getEmployeeTrainingSessionsByPostingId (postingId: number) {
    return await employeeService.getEmployeeTrainingSessionsByPostingId(postingId)
  }

  @Action({ commit: 'SET_SHIFT_DATA', rawError: true })
  buildShiftData (id: string) {
    const posting: any = this.indexedPostings[id] || {}

    const shiftData = [
      { isActive: false, day: 'Monday', start: '', end: '', lunch: '' },
      { isActive: false, day: 'Tuesday', start: '', end: '', lunch: '' },
      { isActive: false, day: 'Wednesday', start: '', end: '', lunch: '' },
      { isActive: false, day: 'Thursday', start: '', end: '', lunch: '' },
      { isActive: false, day: 'Friday', start: '', end: '', lunch: '' },
      { isActive: false, day: 'Saturday', start: '', end: '', lunch: '' },
      { isActive: false, day: 'Sunday', start: '', end: '', lunch: '' }
    ]

    shiftData.map((i: IShiftTime) => {
      i.isActive = posting[`shiftEnabled${i.day}`]

      if (posting[`shiftTime${i.day}`]) {
        i.start = posting[`startHour${i.day}`] + ':' + posting[`startMinute${i.day}`]
        i.start = i.start.split(':').map((item: any) => item.length === 1 ? `0${item}` : item).join(':')

        i.end = posting[`endHour${i.day}`] + ':' + posting[`endMinute${i.day}`]
        i.end = i.end.split(':').map((item: any) => item.length === 1 ? `0${item}` : item).join(':')

        i.lunch = posting[`lunchDuration${i.day}`]
      }
    })

    return shiftData
  }

  get uniqueExistingPostingShifts () {
    return Object.values(this.postings.reduce((acc: any, item: any) => {
      acc[item.shiftType] = { text: item.shiftTypeAsString, value: item.shiftType }
      return acc
    }, {}))
  }
}
