import { appRouter } from '@/router'
import { sidebarRouter } from '@/sidebar/router'
import { httpService } from '@/services'

import { modules } from '@/store'

import {
  EAlertType,
  IAlertByEmployeeId,
  IAlertQueueItem,
  IAlertUpdateSubscriptionPayload,
  IParsedAlertRoute
} from '@/types'

import { humanResourcesRouteNames } from '@/pages/human-resources/human-resources.routes'
import { ticketsIncidentsRouteNames } from '@/pages/tickets-incidents/tickets-incidents.routes'

import { ticketSidebarRoutesNames } from '@/pages/tickets-incidents/tickets/sidebar/ticket.sidebar.routes'
import { incidentsSidebarRoutesNames } from '@/pages/tickets-incidents/incidents/sidebar/incidents.sidebar.routes'
import { employeesSidebarRoutesNames } from '@/pages/human-resources/employees/sidebar/employees.sidebar.routes'
import { guardTermsSidebarRoutesNames } from '@/pages/human-resources/guard-terms/sidebar/guard-terms.sidebar.routes'
import { licenseSidebarRoutesNames } from '@/pages/human-resources/licenses/sidebar/licenses.sidebar.routes'
import { paycheckSidebarRoutesNames } from '@/pages/human-resources/paychecks/sidebar/paychecks.sidebar.routes'

class AlertsService {
  getAlertByEmployeeId (employeeId: number): Promise<IAlertByEmployeeId[]> {
    return httpService.get('/Alert/GetAlertByEmployeeId', { params: { id: employeeId } })
  }

  getAlertQueues (
    employeeId?: number,
    alertId?: number,
    alertTypeId?: number,
    alertChannelId?: number
  ): Promise<IAlertQueueItem[]> {
    return httpService.get('/AlertQueue/GetAlertQueues', {
      params: { employeeId, alertId, alertTypeId, alertChannelId }
    })
  }

  upsertAlertSubscription (payload: IAlertUpdateSubscriptionPayload): Promise<''> {
    return httpService.post('/AlertSubscription/UpsertAlertSubscription', payload)
  }

  remindMeLater (alertId: number, reminderTimeInMinutes: number) {
    return httpService.post('/AlertQueue/RemindMeLater', {}, {
      params: { alertId, reminderTimeInMinutes }
    })
  }

  updateIsReadFlag (alertId: number, isRead: boolean) {
    return httpService.post('/AlertQueue/UpdateIsReadFlag', {}, {
      params: { alertId, isRead }
    })
  }

  updateAllIsReadFlag (
    isRead: boolean,
    employeeId?: number,
    alertId?: number,
    alertTypeId?: number,
    alertChannelId?: number
  ) {
    return httpService.post('/AlertQueue/UpdateAllIsReadFlag', {}, {
      params: { isRead, employeeId, alertId, alertTypeId, alertChannelId }
    })
  }

  deleteById (id: number) {
    return httpService.post('/AlertQueue/DeleteById', {}, { params: { id } })
  }

  deleteAllByEmployeeId (id: number) {
    return httpService.post('/AlertQueue/DeleteAllByEmployeeId', {}, { params: { id } })
  }

  async updateTableData (alertTypeId: number) {
    let loader
    let updateMethod
    let shouldUpdateTable
    switch (alertTypeId) {
      // EMPLOYEE
      case EAlertType.EMPLOYEE:
        loader = modules.employee.SET_EMPLOYEES_LOADING
        updateMethod = () => modules.employee.getEmployees(modules.general.activeToggleState)
        shouldUpdateTable = appRouter.currentRoute.name === humanResourcesRouteNames.employees
        break

      // GUARD_TERM
      case EAlertType.GUARD_TERM:
        loader = modules.guardTerms.SET_GUARD_TERMS_LOADING
        updateMethod = () => modules.guardTerms.getGuardTerms(modules.general.activeToggleState)
        shouldUpdateTable = appRouter.currentRoute.name === humanResourcesRouteNames.guardTerms
        break

      // DISCIPLINE
      case EAlertType.DISCIPLINE:
        break// TODO: add discipline route

      // LICENSE
      case EAlertType.LICENSE:
        loader = modules.licenses.SET_LICENSES_LOADING
        updateMethod = () => modules.licenses.getLicenses(modules.general.activeToggleState)
        shouldUpdateTable = appRouter.currentRoute.name === humanResourcesRouteNames.licenses
        break

      // TICKET
      case EAlertType.TICKET:
        loader = modules.tickets.LOADING
        updateMethod = () => modules.tickets.getTickets(modules.tickets.activeTab)
        shouldUpdateTable = appRouter.currentRoute.name === ticketsIncidentsRouteNames.tickets
        break

      // INCIDENT
      case EAlertType.INCIDENT:
        loader = modules.incidents.LOADING
        updateMethod = () => modules.incidents.getIncidents(modules.tickets.activeTab)
        shouldUpdateTable = appRouter.currentRoute.name === ticketsIncidentsRouteNames.incidents
        break

      // PAYCHECK
      case EAlertType.PAYCHECK:
        loader = modules.paychecks.SET_PAYCHECKS_LOADING
        updateMethod = modules.paychecks.getPaychecks
        shouldUpdateTable = appRouter.currentRoute.name === humanResourcesRouteNames.paychecks
        break
    }

    if (loader && updateMethod && shouldUpdateTable) {
      try {
        loader(true)
        await updateMethod()
      } finally {
        loader(true)
      }
    }
  }

  async goToAlertRoute (alertTypeId: number, uniqueIdValue: number) {
    let route: IParsedAlertRoute | undefined
    switch (alertTypeId) {
      // EMPLOYEE
      case EAlertType.EMPLOYEE:
        route = {
          app: { name: humanResourcesRouteNames.licenses },
          sidebar: {
            name: employeesSidebarRoutesNames.employeeProfile,
            params: { id: String(uniqueIdValue) }
          }
        }
        break

      // GUARD_TERM
      case EAlertType.GUARD_TERM:
        route = {
          app: { name: humanResourcesRouteNames.guardTerms },
          sidebar: {
            name: guardTermsSidebarRoutesNames.guardTermDetails,
            params: { termId: String(uniqueIdValue) }
          }
        }
        break

      // DISCIPLINE
      case EAlertType.DISCIPLINE:
        route = undefined
        break// TODO: add discipline route

        // LICENSE
      case EAlertType.LICENSE:
        route = {
          app: { name: humanResourcesRouteNames.licenses },
          sidebar: { name: licenseSidebarRoutesNames.licenseDetails, params: { id: String(uniqueIdValue) } }
        }
        break

      // TICKET
      case EAlertType.TICKET:
        route = {
          app: { name: ticketsIncidentsRouteNames.tickets },
          sidebar: {
            name: ticketSidebarRoutesNames.ticketDetails,
            params: { id: String(uniqueIdValue) }
          }
        }
        break

      // INCIDENT
      case EAlertType.INCIDENT:
        route = {
          app: { name: ticketsIncidentsRouteNames.incidents },
          sidebar: {
            name: incidentsSidebarRoutesNames.incidentDetails,
            params: {
              id: String(uniqueIdValue)
            }
          }
        }
        break

      // PAYCHECK
      case EAlertType.PAYCHECK:
        route = {
          app: { name: humanResourcesRouteNames.paychecks },
          sidebar: {
            name: paycheckSidebarRoutesNames.paycheckDetails,
            params: { id: String(uniqueIdValue) }
          }
        }
    }

    if (route) {
      if (appRouter.currentRoute.name !== route.app.name) {
        await appRouter.push(route.app)
      }
      if (route.sidebar) {
        await sidebarRouter.push(route.sidebar)
      }
    }
  }
}

export const alertsService = new AlertsService()
