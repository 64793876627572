import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsJob: ICollectionConfig[] = [
  {
    label: 'Job Status Type',
    group: 'JobStatusType',
    getEndpoint: '/JobStatusType/GetAll',
    getAllActive: '/JobStatusType/GetAllActive',
    saveEndpoint: '/JobStatusType/Create',
    updateEndpoint: '/JobStatusType/Update',
    toggleEndpoint: '/JobStatusType/Toggle',
    collectionName: collectionNames.jobStatusTypes,
    cellClass: ['isDefault'],
    headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
    form: [...naming,
      { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }
    ]
  },

  {
    label: 'Job Title Type',
    group: 'JobTitleType',
    getEndpoint: '/JobTitleType/GetAll',
    getAllActive: '/JobTitleType/GetAllActive',
    saveEndpoint: '/JobTitleType/Create',
    updateEndpoint: '/JobTitleType/Update',
    toggleEndpoint: '/JobTitleType/Toggle',
    collectionName: collectionNames.jobTitleTypes,
    headings: [{ label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 200 }],
    form: [...naming, {
      component: 'InputModule',
      model: 'securityGroupName',
      required: true,
      props: { label: 'Security Group Name', placeholder: 'Name', class: 'w-full mt-4' }
    }]
  },

  {
    label: 'Job Type',
    group: 'JobType',
    getEndpoint: '/JobType/GetAll',
    getAllActive: '/JobType/GetAllActive',
    saveEndpoint: '/JobType/Create',
    updateEndpoint: '/JobType/Update',
    toggleEndpoint: '/JobType/Toggle',
    formWrapClass: 'h-full flex-no-wrap flex-col',
    collectionName: collectionNames.jobTypes,
    headings: [{ label: 'Description', value: 'description', sortable: true, minWidth: 300 }],
    form: [...naming, {
      component: 'TextareaModule',
      model: 'description',
      props: { label: 'Description', placeholder: 'Description', class: 'flex-1 w-full mt-4' }
    }]
  },

  {
    label: 'Job Type Parameter',
    group: 'JobTypeParameter',
    getEndpoint: '/JobTypeParameter/GetAll',
    getAllActive: '/JobTypeParameter/GetAllActive',
    saveEndpoint: '/JobTypeParameter/Create',
    updateEndpoint: '/JobTypeParameter/Update',
    toggleEndpoint: '/JobTypeParameter/Toggle',
    formWrapClass: 'h-full flex-no-wrap flex-col',
    collectionName: collectionNames.jobTypeParameters,
    noName: true,
    headings: [
      { label: 'Job Type ID', value: 'jobTypeId', sortable: true, minWidth: 300 },
      { label: 'Parameter Name', value: 'parameterName', sortable: true, minWidth: 300 },
      { label: 'Description', value: 'description', sortable: true, minWidth: 300 }
    ],
    form: [{
      component: 'InputModule',
      model: 'parameterName',
      required: true,
      props: { label: 'Parameter Name', placeholder: 'Parameter Name', class: 'w-full' }
    }, {
      component: 'SelectModule',
      model: 'jobTypeId',
      options: 'jobTypes',
      props: {
        optionKey: 'displayName',
        valueKey: 'id',
        label: 'Job Type',
        placeholder: 'Select type',
        class: 'w-full mt-4'
      }
    }, {
      component: 'TextareaModule',
      model: 'description',
      required: true,
      props: { label: 'Description', placeholder: 'Description', class: 'flex-1 w-full mt-4' }
    }]
  }
]
