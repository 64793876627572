const terminatedEmployeesSidebarRoutesNames = {
  terminatedEmployeesDetails: 'terminatedEmployeesDetails'
}

const terminatedEmployeesSidebarRoutes = [
  {
    name: terminatedEmployeesSidebarRoutesNames.terminatedEmployeesDetails,
    path: '/terminated-employees-:id/details',
    meta: { group: 'LDH_CommandCenter_Terminated_View' },
    component: () => import('./TerminatedEmployeesDetails.vue')
  }
]

export {
  terminatedEmployeesSidebarRoutesNames,
  terminatedEmployeesSidebarRoutes
}
