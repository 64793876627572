const candidatesSidebarRoutesNames = {
  candidateCreate: 'candidateCreate',
  candidateNewComment: 'candidateNewComment',
  candidateNewDocument: 'candidateNewDocument',
  candidateProfile: 'candidateProfile',
  candidateTermDetails: 'candidateTermDetails',
  candidateTermNew: 'candidateTermNew',
  candidateDocuSignPreview: 'candidateDocuSignPreview'
}
const candidatesSidebarRoutes = [
  {
    name: candidatesSidebarRoutesNames.candidateCreate,
    path: '/candidate-create',
    component: () => import('./candidate-create/CandidateCreate.vue'),
    meta: { group: 'LDH_Candidate_Add', width: '700px' }
  },
  {
    name: candidatesSidebarRoutesNames.candidateNewComment,
    path: '/candidate-:id/new-comment',
    component: () => import('./CandidatesNewComment.vue'),
    meta: { group: 'LDH_Candidate_View' }
  },
  {
    name: candidatesSidebarRoutesNames.candidateNewDocument,
    path: '/candidate-:id/new-document',
    component: () => import('./CandidatesNewDocument.vue'),
    meta: { group: 'LDH_Candidate_AddDocument' }
  },
  {
    name: candidatesSidebarRoutesNames.candidateProfile,
    path: '/candidate-profile-:id',
    meta: { group: 'LDH_Candidate_View', width: '700px', minWidth: '500px' },
    component: () => import('./candidate-profile/CandidateProfile.vue')
  },
  {
    name: candidatesSidebarRoutesNames.candidateTermDetails,
    path: '/candidate-profile-:id/guard-term-:termId/details',
    meta: { width: '700px', group: 'LDH_GuardTerm_View', minWidth: '500px' },
    component: () => import('@/pages/human-resources/guard-terms/sidebar/GuardTermDetails.vue')
  },
  {
    name: candidatesSidebarRoutesNames.candidateTermNew,
    path: '/candidate-profile-:id/guard-term-new',
    meta: { width: '700px', group: 'LDH_GuardTerm_View', minWidth: '500px' },
    component: () => import('@/pages/human-resources/guard-terms/sidebar/GuardTermDetails.vue')
  },
  {
    name: candidatesSidebarRoutesNames.candidateDocuSignPreview,
    path: '/candidate-profile-:id/docu-sign-:documentId-:envelopeId',
    meta: { group: 'LDH_Candidate_View', width: '700px', minWidth: '500px' },
    component: () => import('@/pages/human-resources/candidates/sidebar/CandidateProfileDocuSignPreview.vue')
  }
]

export {
  candidatesSidebarRoutesNames,
  candidatesSidebarRoutes
}
