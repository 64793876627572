import {
  IActionType,
  IEmployeeAddress,
  IEmployeeEmail,
  IEmployeeEmergencyContact,
  IEmployeeIntegrationsEditing,
  IEmployeePhone,
  ITag, TNullableField,
  ITextValue,
  IEmployeePersonal,
  IEmployeeEmployement
} from '@/types'

export interface ICandidate {
  applied: string;
  branchName: null | string;
  candidateStatusType: string;
  completed: boolean;
  created: string;
  crime: boolean;
  disqualified: boolean;
  hired: boolean;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  legal: boolean;
  modified: string;
  obsolete: boolean;
  preferredName: null | string;
  processingDate: null | string;
  progress: null | string;
  phone: string;
  rehired: boolean;
  showRowExpander: boolean;
  startDate: null | string;
  terminated: boolean;
  tags: null | ITag[];
  eligibleToWorkInUsa: TNullableField<boolean>;
  haveBeenConvicted: TNullableField<boolean>;
  isHighSchoolOrGed: TNullableField<boolean>;
  transportationType: null | string;
  requisitionTitle: TNullableField<string>;
  requisitionId: TNullableField<number>;
  isDoNotHire: boolean;
  referralSource: string;
}

export interface ICandidateMatch {
  id: number;
  firstName: string;
  lastName: string;
  isCandidate?: boolean;
  isEmployee?: boolean;
  isTerminated?: boolean;
  ssnLast4?: string;
  email?: string;
  phone?: number;
}

export interface ICandidateProfile {
  primaryEmail: TNullableField<string>;
  preferredName: TNullableField<string>;
  legalFirstName: TNullableField<string>;
  maidenName: TNullableField<string>;
  firstName: TNullableField<string>;
  name: TNullableField<string>;
  lastName: TNullableField<string>;
  middleInitial: TNullableField<string>;
  dob: TNullableField<string>;
  tobacco: TNullableField<string>;
  disability: TNullableField<string>;
  veteran: TNullableField<string>;
  veteranType: TNullableField<string>;
  veteranDischargeDate: TNullableField<string>;
  titleId: TNullableField<number>;
  suffixId: TNullableField<number>;
  genderId: TNullableField<number>;
  raceId: TNullableField<number>;
  maritalStatusId: TNullableField<number>;
  referralId: number;
  referralOther: TNullableField<string>;
  referralItems: ITextValue[];
  titleItems: ITextValue[];
  suffixItems: ITextValue[];
  genderItems: ITextValue[];
  raceItems: ITextValue[];
  maritalStatusItems: ITextValue[];
  phoneTypeItems: ITextValue[];
  emailTypeItems: ITextValue[];
  stateItems: ITextValue[];
  relationshipTypeItems: ITextValue[];
  accountTypeItems: ITextValue[];
  employeeStatusTypeItems: ITextValue[];
  guidId: TNullableField<string>;
  facebookUsername: TNullableField<string>;
  linkedInUsername: TNullableField<string>;
  twitterUsername: TNullableField<string>;
  ssn: TNullableField<string>;
  id: number;
  hired: boolean;
  hireDate: TNullableField<string>;
  startDate: TNullableField<string>;
  processingDate: TNullableField<string>;
  rehired: boolean;
  rehireDate: TNullableField<string>;
  terminated: boolean;
  terminateDate: TNullableField<string>;
  termDate: TNullableField<string>;
  completed: boolean;
  created: TNullableField<string>;
  applied: TNullableField<string>;
  hairColor: TNullableField<string>;
  eyeColor: TNullableField<string>;
  heightFeet: TNullableField<number>;
  heightInches: TNullableField<number>;
  weight: TNullableField<number>;
  dependents: TNullableField<number>;
  paycorId: TNullableField<number>;
  celayixEmployeeId: TNullableField<string>;
  celayixWebAccessAccountCreated: boolean;
  celayixShortName: TNullableField<string>;
  obsolete: boolean;
  obsoleteDate: TNullableField<string>;
  createdBy: TNullableField<string>;
  modified: TNullableField<string>;
  modifiedBy: TNullableField<string>;
  employeeTypeId: number;
  employmentTypeId: TNullableField<number>;
  employeeStatusTypeId: TNullableField<number>;
  eligibleToWorkInUsa: TNullableField<boolean>;
  haveBeenConvicted: TNullableField<boolean>;
  isHighSchoolOrGed: TNullableField<boolean>;
  disabilityTypeId: TNullableField<number>;
  currentStatus: TNullableField<string>;
  candidateStatusTypeId: TNullableField<number>;
  celayixId: TNullableField<number>;
  flowFinityId: TNullableField<number>;
  candidateTypeId: TNullableField<number>;
  emergencyContactId: TNullableField<number>;
  armed: boolean;
  activeDirectoryPrincipal: TNullableField<string>;
  candidateStatusType: TNullableField<string>;
  candidateType: TNullableField<string>;
  candidateStatusTypeItems: IActionType[];
  candidateTypeItems: IActionType[];
  emergencyContact: IEmployeeEmergencyContact[];
  employeeStatusType: TNullableField<string>;
  employmentType: TNullableField<string>;
  gender: TNullableField<string>;
  maritalStatusType: TNullableField<string>;
  race: TNullableField<string>;
  referral: TNullableField<string>;
  suffix: TNullableField<string>;
  title: TNullableField<string>;
  phones: IEmployeePhone[];
  emails: IEmployeeEmail[];
  addresses: IEmployeeAddress[];
  employmentStatusTypeItems: IActionType[] | null;
  tags: ITag[];
  otherIds: number[];
  uniformDeposit: TNullableField<number>;
  _90dayFee: TNullableField<number>;
  managerId: TNullableField<number>;
  managerName: TNullableField<string>;
  jobTitle: TNullableField<string>;
  showRowExpander: TNullableField<boolean>;
  isDoNotHire: TNullableField<boolean>;
}

export interface ICandidatePersonalEditing {
  id: number;
  titleId: TNullableField<number>;
  suffixId: TNullableField<number>;
  firstName: TNullableField<string>;
  lastName: TNullableField<string>;
  preferredName: TNullableField<string>;
  middleInitial: TNullableField<string>;
  maidenName: TNullableField<string>;
  dob: TNullableField<string>;
  ssn: TNullableField<string>;
  genderId: TNullableField<number>;
  raceId: TNullableField<number>;
  maritalStatusId: TNullableField<number>;
  heightFeet: TNullableField<number>;
  heightInches: TNullableField<number>;
  weight: TNullableField<number>;
  hairColor: TNullableField<string>;
  eyeColor: TNullableField<string>;
  disability: TNullableField<string>;
  tobacco: TNullableField<string>;
  veteran: TNullableField<string>;
  veteranDischargeDate: TNullableField<string>;
  referralId: TNullableField<number>;
  referralOther: TNullableField<string>;
  dependents: TNullableField<number>;
}

export interface ICandidateInterviewsNotesEditing {
  interviewTypeId: TNullableField<number>;
  notes: TNullableField<string>;
  partOfTown: TNullableField<string>;
  transportationTypeId: TNullableField<number>;
  transportationTypeIdNotes: TNullableField<string>;
  hasPiercingsAndTattoos: TNullableField<number>;
  hasPiercingsAndTattoosNotes: TNullableField<string>;
  questionConvictedNotes: TNullableField<string>;
  questionBriefWorkHistory: TNullableField<string>;
  questionNextPosition: TNullableField<string>;
  questionWhyInterested: TNullableField<string>;
  questionWhenCanStart: TNullableField<string>;
  questionConvicted: TNullableField<string>;
  questionPassDrugTest: TNullableField<string>;
  questionPassDrugTestNotes: TNullableField<string>;
  questionEligibleToWorkInUsa: TNullableField<string>;
  questionEligibleToWorkInUsaNotes: TNullableField<string>;
}

export interface ICandidateDetailsEditing {
  id: number;
  candidateTypeId: TNullableField<number | string>;
  candidateStatusTypeId: TNullableField<number | string>;
  startDate: TNullableField<string>;
  processingDate: TNullableField<string>;
  guidId: TNullableField<string>;
}

export interface INewCandidateForm {
  candidateTypeId?: TNullableField<number | string>;
  requisitionId?: string | TNullableField<number>;
  legalFirstName?: TNullableField<string>;
  lastName?: TNullableField<string>;
  dob?: TNullableField<string>;
  ssn?: TNullableField<string>;
  emails: INewCandidateFormEmail[];
  phones: INewCandidateFormPhone[];
  addresses: INewCandidateFormAddress[];
  emergencies: INewCandidateFormEmergency[];
  isDoNotHire: boolean;
}

export interface INewCandidateFormEmail {
  emailAddress: TNullableField<string>;
  emailTypeId: TNullableField<number>;
}

export interface INewCandidateFormPhone {
  phoneNumber: TNullableField<string>;
  phoneTypeId: TNullableField<number>;
}

export interface INewCandidateFormAddress {
  city: TNullableField<string>;
  country: TNullableField<string>;
  county: TNullableField<string>;
  isPrimary: boolean;
  stateId: TNullableField<string>;
  street1: TNullableField<string>;
  street2: TNullableField<string>;
  zip: TNullableField<string>;
  zip4: TNullableField<string>;
}

export interface INewCandidateFormEmergency {
  id?: number;
  employeeId?: number;
  email?: TNullableField<string>;
  phoneHome?: TNullableField<string>;
  phoneMobile?: TNullableField<string>;
  relationshipTypeId?: TNullableField<number>;
  firstName?: TNullableField<string>;
  lastName?: TNullableField<string>;

  emailAddress?: TNullableField<string>;
  phoneNumber?: TNullableField<string>;
  mobileNumber?: TNullableField<string>;
  phoneType?: TNullableField<string>;
  employeeRelationshipTypeId?: TNullableField<number>;
}

export interface IHireCandidate {
  id: number;
  startDate: Date;
  managerId?: number;
}

export interface ICandidatePersonal extends IEmployeePersonal {
  startDate: string;
  processingDate: string;
  guidId: string;
}

export interface ICandidateEmployement extends IEmployeeEmployement {
  candidateTypeId: number;
  candidateStatusTypeId: number;
}

export type TCandidateIntegrationsEditing = IEmployeeIntegrationsEditing

export enum EPhone {
  PERSONAL = 1,
  HOME,
  MOBILE,
  FAX,
  WORK
}
