import { ICollectionConfig, IEngagementQuestion } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsEngagement: ICollectionConfig[] = [{
  label: 'Engagement Interaction Type',
  group: 'EngagementInteractionType',
  getEndpoint: '/EngagementInteractionType/GetEngagementInteractionTypes',
  saveEndpoint: '/EngagementInteractionType/SaveEngagementInteractionType',
  updateEndpoint: '/EngagementInteractionType/UpdateEngagementInteractionType',
  toggleEndpoint: '/EngagementInteractionType/ToggleEngagementInteractionType',
  collectionName: collectionNames.engagementInteractionTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming, { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Engagement Phase Type',
  group: 'EngagementPhaseType',
  getEndpoint: '/EngagementPhaseType/GetEngagementPhaseTypes',
  saveEndpoint: '/EngagementPhaseType/SaveEngagementPhaseType',
  updateEndpoint: '/EngagementPhaseType/UpdateEngagementPhaseType',
  toggleEndpoint: '/EngagementPhaseType/ToggleEngagementPhaseType',
  collectionName: collectionNames.engagementPhaseTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming, { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Engagement Question',
  group: 'EngagementQuestion',
  getEndpoint: '/EngagementQuestion/GetEngagementQuestions',
  saveEndpoint: '/EngagementQuestion/SaveEngagementQuestion',
  updateEndpoint: '/EngagementQuestion/UpdateEngagementQuestion',
  toggleEndpoint: '/EngagementQuestion/ToggleEngagementQuestion',
  collectionName: collectionNames.engagementQuestions,
  noName: true,
  formWrapClass: 'h-full flex-no-wrap flex-col',
  headings: [
    { label: 'Question Text', value: 'questionText', sortable: true, minWidth: 250 },
    {
      label: 'Phase Type',
      value: 'engagementPhaseType',
      width: 160,
      sortable: true,
      sort: (item: IEngagementQuestion) => `${item}`,
      formatter: (item: IEngagementQuestion) => item.engagementPhaseType.name || ''
    }
  ],
  form: [{
    component: 'SelectModule',
    model: 'engagementPhaseTypeId',
    options: 'engagementPhaseTypes',
    props: {
      optionKey: 'name',
      valueKey: 'id',
      label: 'Type',
      placeholder: 'Select type',
      class: 'w-full'
    }
  }, {
    component: 'TextareaModule',
    model: 'questionText',
    props: { label: 'Question Text', placeholder: 'Question Text', class: 'flex-1 w-full mt-4' }
  }]
}]
