






















import { Component } from 'vue-property-decorator'
import { ConfirmMixin } from '@/mixins/confirm.mixin'

import Modal from '@/components/shared/Modal.vue'
import CancelSave from '@/components/shared/CancelSave.vue'

@Component({
  name: 'LoadUpdatesModal',
  components: {
    Modal,
    CancelSave
  }
})
export default class LoadUpdatesModal extends ConfirmMixin {
  async show () {
    return this.INIT_CONFIRM_PROMISE()
  }
}
