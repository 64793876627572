import { RouteConfig } from 'vue-router'

const dropShipSidebarRoutesNames = {
  dropShipCreate: 'dropShipCreate',
  dropShipDetails: 'dropShipDetails'
}

const dropShipSidebarRoutes: RouteConfig[] = [
  {
    name: dropShipSidebarRoutesNames.dropShipCreate,
    path: '/drop-ship-create',
    meta: { group: 'LDH_DropShip_Add' },
    component: () => import('./DropShipCreate.vue')
  },
  {
    name: dropShipSidebarRoutesNames.dropShipDetails,
    path: '/drop-ship-:id/details',
    meta: {
      width: 'calc(100% - 250px)',
      minWidth: 'calc(100% - 250px)',
      meta: { group: 'LDH_DropShip_View' }
    },
    component: () => import('./DropShipDetails.vue')
  }
]

export {
  dropShipSidebarRoutesNames,
  dropShipSidebarRoutes
}
