import { RouteConfig } from 'vue-router'

const unbillableSidebarRoutesNames = {
  unbillableCheckOut: 'unbillableCheckOut'
}

const unbillableSidebarRoutes: RouteConfig[] = [
  {
    name: unbillableSidebarRoutesNames.unbillableCheckOut,
    path: '/unbillable-:unbillableId/check-out',
    meta: { width: '500px' },
    component: () => import('./UnbillableCheckOut.vue')
  }
]

export {
  unbillableSidebarRoutesNames,
  unbillableSidebarRoutes
}
