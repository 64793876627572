import { httpService } from '@/services'

import { ITransaction } from '@/types'

class TransactionService {
  getTransactionById (id: number): Promise<ITransaction> {
    return httpService.get('/IntegrationTransaction/GetById', { params: { id } })
  }

  getTransactions (): Promise<ITransaction> {
    return httpService.get('/IntegrationTransaction/Get')
  }

  getIntegrationTypes (): Promise<string[]> {
    return httpService.get('/IntegrationTransaction/GetIntegrationTypes')
  }

  runTransaction (id: number): Promise<''> {
    return httpService.post('/IntegrationTransaction/Run', {}, { params: { id } })
  }

  removeTransaction (id: number): Promise<''> {
    return httpService.post('/IntegrationTransaction/Remove', {}, { params: { id } })
  }
}

export const transactionService = new TransactionService()
