const articlesSidebarRoutesNames = {
  articleCreate: 'articleCreate',
  articleEdit: 'articleEdit',
  articleDetails: 'articleDetails'
}

const articlesSidebarRoutes = [
  {
    name: articlesSidebarRoutesNames.articleCreate,
    path: '/article-create',
    meta: { width: '600px', group: 'LDH_KnowledgeBase_Article_Add' },
    component: () => import('./ArticleCreate.vue')
  },
  {
    name: articlesSidebarRoutesNames.articleEdit,
    path: '/article-:id/edit',
    meta: { width: '600px', group: 'LDH_KnowledgeBase_Article_Update' },
    component: () => import('./ArticleEdit.vue')
  },
  {
    name: articlesSidebarRoutesNames.articleDetails,
    path: '/article-:id/details',
    meta: { width: '600px', group: 'LDH_KnowledgeBase_Article_View' },
    component: () => import('./ArticleDetails.vue')
  }
]

export {
  articlesSidebarRoutesNames,
  articlesSidebarRoutes
}
