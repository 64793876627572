import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { celayixShiftsService } from '@/services'
import { ICelayixShift, ICelayixShiftsOptions, ICustomerSearch } from '@/types'

@Module({ name: 'CelayixShiftsModule', namespaced: true })
export default class CelayixShiftsModule extends VuexModule {
  celayixShifts: ICelayixShift[] = []
  celayixShiftsLoading = false
  customers: ICustomerSearch[] = []

  selectedCelayixShiftsOptions: ICelayixShiftsOptions = {
    from: null,
    to: null,
    employeeId: null,
    customerId: null
  }

  /* M U T A T I O N S */
  @Mutation
  SET_CELAYIX_SHIFTS (celayixShifts: ICelayixShift[]) {
    this.celayixShifts = celayixShifts
  }

  @Mutation
  SET_CUSTOMERS (customers: ICustomerSearch[]) {
    this.customers = customers
  }

  @Mutation
  SET_LOADING (loading: boolean) {
    this.celayixShiftsLoading = loading
  }

  @Mutation
  CLEAR_SELECTED_CELAYIX_SHIFTS_OPTIONS () {
    this.selectedCelayixShiftsOptions = {
      from: null,
      to: null,
      employeeId: null,
      customerId: null
    }
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CELAYIX_SHIFTS', rawError: true })
  async getCelayixShifts (celayixShiftsOptions: ICelayixShiftsOptions) {
    return await celayixShiftsService.getCelayixShifts(celayixShiftsOptions)
  }
}
