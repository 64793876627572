const postInspectionsSidebarRoutesNames = {
  postInspectionView: 'postInspectionView',
  postInspectionApprove: 'postInspectionApprove',
  postInspectionCreate: 'postInspectionCreate'
}

const postInspectionsSidebarRoutes = [
  {
    name: postInspectionsSidebarRoutesNames.postInspectionView,
    path: '/post-inspection-:id/view',
    meta: { group: 'LDH_PostInspection_View' },
    component: () => import('./PostInspectionView.vue')
  },
  {
    name: postInspectionsSidebarRoutesNames.postInspectionCreate,
    path: '/post-inspection-create',
    meta: { group: 'LDH_PostInspection_Add' },
    component: () => import('./PostInspectionCreate.vue')
  },
  {
    name: postInspectionsSidebarRoutesNames.postInspectionApprove,
    path: '/post-inspection-:id/approve',
    meta: { group: 'LDH_PostInspection_Approve' },
    component: () => import('./PostInspectionApprove.vue')
  }
]

export {
  postInspectionsSidebarRoutesNames,
  postInspectionsSidebarRoutes
}
