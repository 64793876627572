import { naming } from './collections-general.config'
import { ICollectionConfig } from '@/types'
import { collectionNames } from '../collectionNames'

export const collectionsPayment: ICollectionConfig[] = [
  {
    label: 'Payment Term Type',
    group: 'PaymentTermType',
    getEndpoint: '/PaymentTermType/GetPaymentTermTypes',
    saveEndpoint: '/PaymentTermType/SavePaymentTermType',
    updateEndpoint: '/PaymentTermType/UpdatePaymentTermType',
    toggleEndpoint: '/PaymentTermType/TogglePaymentTermType',
    collectionName: collectionNames.paymentTermTypes,
    form: [...naming]
  },
  {
    label: 'Payment Type',
    group: 'PaymentType',
    getEndpoint: '/PaymentType/GetPaymentTypes',
    saveEndpoint: '/PaymentType/SavePaymentType',
    updateEndpoint: '/PaymentType/UpdatePaymentType',
    toggleEndpoint: '/PaymentType/TogglePaymentType',
    collectionName: collectionNames.paymentTypes,
    form: [...naming]
  }
]
