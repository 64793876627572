import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators'
import { benefitEmployeeService } from '@/services'
import { IDependent, IEmployeeBeneficiary } from '@/types'

@Module({ name: 'OnboardingModule', namespaced: true })
export default class OnboardingModule extends VuexModule {
  programs: any[] = []
  benefitEmployeeDependents: IDependent[] = []
  benefitEmployeeBeneficiary: IEmployeeBeneficiary[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_BENEFIT_EMPLOYEE_DEPENDENTS (payload: IDependent[]) {
    this.benefitEmployeeDependents = payload
  }

  @Mutation
  SET_BENEFIT_EMPLOYEE_BENEFICIARY (payload: IEmployeeBeneficiary[]) {
    this.benefitEmployeeBeneficiary = payload
  }

  @Mutation
  SET_PROGRAMS (payload: any[]) {
    this.programs = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_BENEFIT_EMPLOYEE_DEPENDENTS', rawError: true })
  async getBenefitEmployeeDependents () {
    return await benefitEmployeeService.getBenefitEmployeeDependents()
  }

  @Action({ commit: 'SET_BENEFIT_EMPLOYEE_BENEFICIARY', rawError: true })
  async getBenefitEmployeeBeneficiary () {
    return await benefitEmployeeService.getBenefitEmployeeBeneficiary()
  }
}
