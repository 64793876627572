






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors, TNullableField } from '@/types'
import { debounce, dateTimeNew } from '@/helpers'
import moment from 'moment'

import { ElInput } from 'element-ui/types/input'
import { ElDatePicker } from 'element-ui/types/date-picker'

import LabelModule from '@/components/shared/form-controls/LabelModule.vue'
import Icon from '@/components/shared/Icon.vue'

@Component({
  name: 'DatePickerModule',
  components: { LabelModule, Icon }
})
export default class DatePickerModule extends Vue {
  /* P R O P S */
  @Prop({ default: null }) value!: string
  @Prop({ default: false }) withCaret!: boolean
  @Prop() activatorClass?: string
  @Prop() label?: string
  @Prop() required?: boolean
  @Prop({ default: 'mm/dd/yyyy' }) placeholder!: string
  @Prop({ default: 'basic' }) color!: TColors
  @Prop({ default: () => ({}) }) pickerOptions!: {}
  @Prop() min?: string
  @Prop() max?: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: 'MM/dd/yyyy' }) format!: boolean
  @Prop({ default: true }) clearable!: boolean

  /* R E F S */
  $refs!: {
    elDatePicker: ElDatePicker & {
      $refs: { reference: ElInput };
      userInput: string;
    };
  }

  /* D A T A */
  oldUserInput = ''

  /* C O M P U T E D */
  get localValue () {
    return this.value ? dateTimeNew('YYYY-MM-DD') : ''
  }

  /* M E T H O D S */
  updateValue (value: TNullableField<string> | undefined) {
    if (this.$breakpoints.tabletAndUp) {
      this.$emit('input', value)
    } else {
      this.$emit('input', !value ? '' : new Date(value as string))
    }
  }

  @debounce(200)
  updateDay (direction: 'front' | 'back') {
    let range
    if (direction === 'front') {
      range = moment(this.value).add(1, 'day').toDate()
    } else if (direction === 'back') {
      range = moment(this.value).subtract(1, 'day').toDate()
    }
    this.updateValue(String(range))
  }

  /* H O O K S */
  mounted () {
    if (this.$breakpoints.tabletAndUp) {
      this.oldUserInput = this.$refs.elDatePicker.userInput

      this.$refs.elDatePicker.$refs.reference.$on('input', (str: string) => {
        const reg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/([1-2]\d)\d{2}$/
        const validPart = '01/01/1000'
        const checkFullDate = str + validPart.slice(str.length)

        if (!reg.test(checkFullDate)) {
          this.$refs.elDatePicker.userInput = this.oldUserInput
        } else {
          this.oldUserInput = str
        }
      })
    }
  }
}
