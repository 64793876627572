import {
  requisitionTabSidebarRoutes,
  requisitionTabSidebarRoutesNames
} from './requisitions-tab/sidebar/requisitions-tab.sidebar.routes'

import {
  requisitionsUnattachedPostingsSidebarRoutes,
  requisitionsUnattachedPostingsSidebarRoutesNames
} from './unatached-postings-tab/sidebar/requisition-unatached-postigns.sidebar.routes'

const requisitionsSidebarRoutesNames = {
  ...requisitionTabSidebarRoutesNames,
  ...requisitionsUnattachedPostingsSidebarRoutesNames
}

const requisitionsSidebarRoutes = [
  ...requisitionTabSidebarRoutes,
  ...requisitionsUnattachedPostingsSidebarRoutes
]

export {
  requisitionsSidebarRoutesNames,
  requisitionsSidebarRoutes
}
