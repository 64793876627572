import { RouteConfig } from 'vue-router'
import { EScheduleTypes } from '@/pages/tech-ops/schedule/schedule.types'

const scheduleSidebarRoutesNames = {
  scheduleUnbillable: 'scheduleUnbillable',
  scheduleUnbillableTechnician: 'scheduleUnbillableTechnician',

  scheduleServiceCalls: 'scheduleServiceCalls',
  scheduleServiceCallsTechnician: 'scheduleServiceCallsTechnician',

  scheduleProject: 'scheduleProject',
  scheduleProjectTechnician: 'scheduleProjectTechnician'
}

const scheduleSidebarRoutes: RouteConfig[] = [
  // U N B I L L A B L E
  {
    name: scheduleSidebarRoutesNames.scheduleUnbillable,
    path: '/unbillable-:unbillableId?/schedule',
    meta: { width: '500px', group: 'LDH_ScheduleUnbillable_Add' },
    component: () => import('./ScheduleUnbillable.vue')
  },
  {
    name: scheduleSidebarRoutesNames.scheduleUnbillableTechnician,
    path: '/unbillable/technician',
    meta: { width: 'calc(100% - 250px)', minWidth: 'calc(100% - 250px)' },
    props: {
      scheduleType: EScheduleTypes.UNBILLABLE
    },
    component: () => import('./ScheduleTechnicians.vue')
  },

  // S E R V C I C E  C A L L S
  {
    name: scheduleSidebarRoutesNames.scheduleServiceCalls,
    path: '/service-call-:serviceCallId?/schedule',
    meta: { width: '500px', group: 'LDH_ScheduleServiceCall_Add' },
    component: () => import('./ScheduleServiceCall.vue')
  },
  {
    name: scheduleSidebarRoutesNames.scheduleServiceCallsTechnician,
    path: '/service-call-:serviceCallId?/technician',
    meta: { width: 'calc(100% - 250px)', minWidth: 'calc(100% - 250px)', group: 'LDH_ScheduleServiceCall_Add' },
    props: {
      scheduleType: EScheduleTypes.SERVICE_CALL
    },
    component: () => import('./ScheduleTechnicians.vue')
  },

  // P R O J E C T S
  {
    name: scheduleSidebarRoutesNames.scheduleProject,
    path: '/project-:projectId?/schedule',
    meta: { width: '750px', group: 'LDH_ScheduleProject_Add' },
    component: () => import('./ScheduleProject.vue')
  },
  {
    name: scheduleSidebarRoutesNames.scheduleProjectTechnician,
    path: '/project-:projectId?/technician',
    meta: { width: 'calc(100% - 250px)', minWidth: 'calc(100% - 250px)', group: 'LDH_ScheduleProject_Add' },
    props: {
      scheduleType: EScheduleTypes.PROJECT
    },
    component: () => import('./ScheduleTechnicians.vue')
  }
]

export {
  scheduleSidebarRoutesNames,
  scheduleSidebarRoutes
}
