import { httpService } from '@/services'
import { ICelayixShiftsOptions } from '@/types'
import { ICelayixShift } from '@/pages/reports/celayix-shifts/celayix-shifts.types'

class CelayixShiftsService {
  getCelayixShifts (params: ICelayixShiftsOptions): Promise<ICelayixShift[]> {
    return httpService.get('/Reports/GetByShiftDateCustomerEmployee', { params })
  }
}

export const celayixShiftsService = new CelayixShiftsService()
