import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsCandidate: ICollectionConfig[] = [{
  label: 'Candidate Status Type',
  group: 'CandidateStatusType',
  getEndpoint: '/CandidateStatusType/GetCandidateStatusTypes',
  saveEndpoint: '/CandidateStatusType/SaveCandidateStatusType',
  updateEndpoint: '/CandidateStatusType/UpdateCandidateStatusType',
  toggleEndpoint: '/CandidateStatusType/ToggleCandidateStatusType',
  collectionName: collectionNames.candidateStatusTypes,
  cellClass: ['defaultValue', 'drugTest', 'onboarding', 'background', 'idChecks', 'resume', 'disqualified'],
  headings: [
    { label: 'Default', ...booleanFormatter('defaultValue') },
    { label: 'Drug Test', ...booleanFormatter('drugTest') },
    { label: 'Onboarding', ...booleanFormatter('onboarding') },
    { label: 'Background', ...booleanFormatter('background') },
    { label: 'ID Checks', ...booleanFormatter('idChecks') },
    { label: 'Resume', ...booleanFormatter('resume') },
    { label: 'Disqualified', ...booleanFormatter('disqualified') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'drugTest', props: { label: 'Drug Test', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'idChecks', props: { label: 'ID Checks', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'onboarding', props: { label: 'Onboarding', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'background', props: { label: 'Background', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'resume', props: { label: 'Resume', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'disqualified', props: { label: 'Disqualified', class: 'w-1/2 mt-4' } }
  ]
},

{
  label: 'Candidate Type',
  group: 'CandidateType',
  getEndpoint: '/CandidateType/GetCandidateTypes',
  saveEndpoint: '/CandidateType/SaveCandidateType',
  updateEndpoint: '/CandidateType/UpdateCandidateType',
  toggleEndpoint: '/CandidateType/ToggleCandidateType',
  collectionName: collectionNames.candidateTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming,
    { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }
  ]
}]
