import { httpService } from '@/services'
import { IPosting } from '@/types'

class PostingService {
  /* P O S T I N G */
  getPostings (activeOnly: boolean): Promise<IPosting[]> {
    return httpService.get('/Posting/GetPostings', { params: { activeOnly } })
  }

  getPostingById (id: number): Promise<IPosting> {
    return httpService.get('/Posting/GetPostingById', { params: { id } })
  }

  getBlankPosting (): Promise<IPosting[]> {
    return httpService.get('/Posting/GetBlankPosting')
  }

  getTrainingDaysByPostingId (id: number): Promise<any> {
    return httpService.get('/Posting/GetTrainingDaysByPostingId', { params: { id } })
  }

  setInitialPosting (id: number): Promise<''> {
    return httpService.post('/Posting/SetInitialPosting', {}, { params: { id } })
  }

  setTrainingPosting (id: number): Promise<''> {
    return httpService.post('/Posting/SetTrainingPosting', {}, { params: { id } })
  }

  setTemplatePosting (id: number): Promise<''> {
    return httpService.post('/Posting/SetTemplatePosting', {}, { params: { id } })
  }

  setHiredPosting (id: number): Promise<''> {
    return httpService.post('/Posting/SetHiredPosting', {}, { params: { id } })
  }

  setFilledPosting (id: number): Promise<''> {
    return httpService.post('/Posting/SetFilledPosting', {}, { params: { id } })
  }

  togglePosting (id: number): Promise<''> {
    return httpService.post('/Posting/TogglePosting', {}, { params: { id } })
  }

  savePosting (payload: any): Promise<''> {
    return httpService.post('/Posting/SavePosting', payload)
  }

  updatePosting (payload: any): Promise<''> {
    return httpService.post('/Posting/UpdatePosting', payload)
  }

  /* P O S T I N G   U N A T T A C H E D */
  getPostingsUnattached (): Promise<any[]> {
    return httpService.get('/Posting/GetPostingsUnattachedForRequisition')
  }
}

export const postingService = new PostingService()
