import { httpService } from '@/services'
import {
  IProject,
  IProjectAreaServicePart,
  IProjectById,
  IProjectInstallLabor,
  IProjectSite,
  IProjectsService,
  IProjectsServiceParts
} from '@/types'

class ProjectsService {
  getProjects (activeOnly: boolean): Promise<IProject[]> {
    return httpService.get('/Project/All', { params: { activeOnly } })
  }

  getAreaServiceParts (projectId: string | number): Promise<IProjectAreaServicePart[]> {
    return httpService.get('/Project/AreaServiceParts', { params: { projectId } })
  }

  getServices (projectId: number): Promise<IProjectsService[]> {
    return httpService.get('/Project/Services', { params: { projectId } })
  }

  getServicesParts (projectId: number, serviceId: number): Promise<IProjectsServiceParts[]> {
    return httpService.get('/Project/ServiceParts', { params: { projectId, serviceId } })
  }

  getById (projectId: string | number): Promise<IProjectById> {
    return httpService.get('/Project/Details', { params: { projectId } })
  }

  getInstallLabor (siteContractId: string): Promise<IProjectInstallLabor> {
    return httpService.get('/Project/InstallLabor', { params: { siteContractId } })
  }

  getParts (siteContractId: string): Promise<IProjectSite[]> {
    return httpService.get('/Project/Parts', { params: { siteContractId, isWireOrConduit: true } })
  }

  getWireOrConduit (siteContractId: string): Promise<any[]> {
    return httpService.get('/Project/Parts', { params: { siteContractId, isWireOrConduit: true } })
  }

  getSubContracts (siteContractId: string): Promise<any> {
    return httpService.get('/Project/SubContracts', { params: { siteContractId } })
  }

  getRentals (siteContractId: string): Promise<any> {
    return httpService.get('/Project/Rentals', { params: { siteContractId } })
  }

  getTravelExpenses (siteContractId: string): Promise<any> {
    return httpService.get('/Project/TravelExpenses', { params: { siteContractId } })
  }

  getRecurring (siteContractId: string): Promise<any> {
    return httpService.get('/Project/Recurring', { params: { siteContractId } })
  }

  getCancellation (siteContractId: string): Promise<any> {
    return httpService.get('/Project/Cancellation', { params: { siteContractId } })
  }
}

export const projectsService = new ProjectsService()
