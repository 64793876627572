const dashboardRouteNames = {
  dashboard: 'Dashboard'
}

const dashboardRoutes = [
  {
    path: '',
    name: dashboardRouteNames.dashboard,
    meta: {
      requireAuth: true,
      label: 'Dashboard'
    },
    component: () => import('@/pages/dashboard/Dashboard.vue')
  }
]

export {
  dashboardRouteNames,
  dashboardRoutes
}
