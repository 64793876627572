import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { routeGuard } from '@/router/routeGuard'
import { modules } from '@/store'
import { sidebarService } from '@/sidebar/sidebar.services'

import { clientServicesSidebarRoutes } from '@/pages/client-services/client-services.sidebar.routes'
import { commandCenterSidebarRoutes } from '@/pages/command-center/command-center.sidebar.routes'
import { maintenanceSidebarRoutes } from '@/pages/maintenance/maintenance.sidebar.routes'
import { dashboardSidebarRoutes } from '@/pages/dashboard/sidebar/dashboard.sidebar.routes'
import { humanResourcesSidebarRoutes } from '@/pages/human-resources/human-resources.sidebar.routes'
import { knowledgeBaseSidebarRoutes } from '@/pages/knowledge-base/knowledge-base.sidebar.routes'
import { reportsSidebarRoutes } from '@/pages/reports/reports.sidebar.routes'
import { ticketsIncidentsSidebarRoutes } from '@/pages/tickets-incidents/tickets-incidents.sidebar.routes'
import { techOpsSidebarRoutes } from '@/pages/tech-ops/tech-ops.sidebar.routes'

import { sidebarRouteNames } from '@/sidebar/router/sidebarRouteNames'

Vue.use(VueRouter)

export const sidebarRouter = new VueRouter({
  mode: 'hash',
  routes: [
    {
      name: 'table-constructor-preferences',
      path: '/preferences-:tablePreferenceName',
      component: () => import('@/components/shared/table-constructor/TableConstructorPreferences.vue')
    },
    {
      name: 'modify-history',
      path: '/modify-history/:name',
      meta: { width: '1000px' },
      component: () => import('@/components/shared/ModifyHistory.vue')
    },

    dashboardSidebarRoutes,
    clientServicesSidebarRoutes,
    commandCenterSidebarRoutes,
    maintenanceSidebarRoutes,
    knowledgeBaseSidebarRoutes,
    humanResourcesSidebarRoutes,
    reportsSidebarRoutes,
    ticketsIncidentsSidebarRoutes,
    techOpsSidebarRoutes
  ].flat()
})

sidebarRouter.beforeEach(async function (to, from, next) {
  /* C O N F I R M A T I O N   D I A L O G */
  if (modules.general.isAnyChanges) {
    const confirmationDialog = sidebarService.getConfirmationElement()
    await (confirmationDialog.$refs.confirm as any).show()
    modules.general.SET_ENTRY_DATA(null)
    modules.general.SET_CHANGED_ENTRY_DATA(null);
    (confirmationDialog.$refs.confirm as any).destroyComponent()
  }

  await routeGuard(to, from, next)

  const exclusions = [sidebarRouteNames.configurationCreate, sidebarRouteNames.configurationEdit]

  if (to.path === '/' && from.name && !exclusions.includes(from.name)) {
    modules.general.SET_PAGE_TITLE('')
  }
})

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix,@typescript-eslint/naming-convention
  interface Vue {
    $sidebar: VueRouter;
    $sidebarRoute: Route;
  }
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix,@typescript-eslint/naming-convention
  interface VueConstructor {
    $sidebar: VueRouter;
    $sidebarRoute: Route;
  }
}
