





































































import { Component, Vue } from 'vue-property-decorator'
import { modules } from '@/store'
import { IApplication, INavigationTypes } from '@/types'
import { getActiveTabIndex } from '@/helpers'
import { routeNames } from '@/router'
import { sidebarRouteNames } from '@/sidebar/router/sidebarRouteNames'

import Icon from '@/components/shared/Icon.vue'
import Collapse from '@/components/shared/collapse/Collapse.vue'

@Component({
  name: 'MenuList',
  components: { Icon, Collapse }
})
export default class MenuList extends Vue {
  /* R E F S */
  $refs!: {
    collapse: Collapse & {
      open: (key: string) => void;
      closeAll: () => void;
    };
  }

  routeNames = routeNames
  loading = false

  /* C O M P U T E D */
  get userNavigation () {
    if (this.$breakpoints.phone) {
      return modules.user.userNavigation.reduce((acc, cur) => {
        const filteredChildren = cur.children.filter(navChild => {
          return navChild.showOnMobile === undefined || navChild.showOnMobile
        })
        if (filteredChildren.length > 0) {
          acc.push({ ...cur, children: filteredChildren })
        }
        return acc
      }, [] as INavigationTypes[])
    }
    const userNav = modules.user.userNavigation
    return userNav
  }

  get menuCollapsed () {
    return modules.general.menuCollapsed
  }

  /* M E T H O D S */
  closeOnLeave () {
    if (this.menuCollapsed) {
      this.$refs.collapse.closeAll()
    }
  }

  onNavClick () {
    if (this.$sidebarRoute.path !== '/' && !modules.general.isAnyChanges) {
      this.$sidebar.push('/')
    }

    sessionStorage.removeItem(getActiveTabIndex('profile'))
    sessionStorage.removeItem(getActiveTabIndex(sidebarRouteNames.candidateProfile))
    this.$emit('item-clicked')
  }

  openActiveRouteCollapse () {
    this.$nextTick(() => {
      if (this.$route.meta) {
        this.$refs.collapse.open(this.$route.meta.parentName)
      }
    })
  }

  /* H O O K S */
  async mounted () {
    this.loading = true
    await this.loadApplicationsMenu()
    this.loading = false
    if (!this.menuCollapsed) {
      this.openActiveRouteCollapse()
    }
  }

  async loadApplicationsMenu () {
    const applications = await modules.configurations.getApplications()

    const applicationsMenu = modules.user.userNavigation.find(item => item.name === 'Applications')

    if (applications.length <= 0 || applicationsMenu) { return }

    modules.user.userNavigation.unshift(
      {
        name: 'Applications',
        label: 'Applications',
        excludeFromNavigation: false,
        group: 'LDH_Applications_View',
        icon: 'icon-grid',
        isParent: true,
        children: applications.map((app: IApplication) => {
          return {
            name: app.name,
            label: app.name,
            group: app.group,
            excludeFromNavigation: false,
            showOnMobile: true,
            isExternalLink: true,
            externalLink: app.link
          }
        })
      }
    )
  }
}
