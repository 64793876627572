import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { unbillableService } from '@/services'
import { IUnbillable } from '@/types'

@Module({ name: 'UnbillableModule', namespaced: true })
export default class UnbillableModule extends VuexModule {
  loading = false
  unbillable: readonly IUnbillable[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_LOADING (payload: boolean) {
    this.loading = payload
  }

  @Mutation
  SET_UNBILLABLE (payload: readonly IUnbillable[]) {
    this.unbillable = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_UNBILLABLE', rawError: true })
  async getUnbillable (activeToggleState: boolean) {
    return await unbillableService.getAll(activeToggleState)
  }
}
