import { httpService } from '@/services/http'
import {
  IGroupedPermission,
  IPermission,
  IRole,
  ISaveGroupedPermission,
  IAssignedEmployees,
  IAuditReport
} from '@/types'

class SecurityService {
  getActiveRoles (): Promise<IRole[]> {
    return httpService.get('/Role/GetAllActive')
  }

  createRole (payload: IRole): Promise<''> {
    return httpService.post('/Role/Create', payload)
  }

  cloneRole (payload: { id: number; displayName: string }) {
    return httpService.post('/Role/Clone', {}, { params: payload })
  }

  updateRole (payload: IRole): Promise<''> {
    return httpService.post('/Role/Update', payload)
  }

  toggleRole (id: number): Promise<''> {
    return httpService.post(`/Role/Toggle?id=${id}`)
  }

  /* P E R M I S S I O N S */
  createPermission (payload: IPermission): Promise<''> {
    return httpService.post('/Permission/Create', payload)
  }

  /* R O L E   T O   P E R M I S S I O N S */
  getGroupedPermissionsByRole (roleId: number): Promise<IPermission[]> {
    return httpService.get('/RoleToPermission/Groups', { params: { roleId } })
  }

  getGroupedPermission (roleId: number, groupName: string): Promise<IGroupedPermission[]> {
    return httpService.get('/RoleToPermission/Group', { params: { roleId, groupName } })
  }

  setRoleToPermission (params: { permissionId: number; roleId: number; isChecked: boolean }) {
    return httpService.post('/RoleToPermission/Set', {}, { params: params })
  }

  saveGroupedPermission (permission: ISaveGroupedPermission): Promise<''> {
    return httpService.post('/RoleToPermission/SaveMany', permission)
  }

  /* E M P L O Y E E   T O   R O L E */
  getSecurityByEmployee (employeeId: number): Promise<{ roles: IRole[]; permissions: IPermission[] }> {
    return httpService.get('/EmployeeToRole/GetForEmployee', { params: { employeeId } })
  }

  getAssignedEmployees (roleId: number): Promise<IAssignedEmployees[]> {
    return httpService.get('/EmployeeToRole/GetAssignedEmployees', { params: { roleId } })
  }

  createEmployeeToRole (payload: { employeeId: number; roleId: number }) {
    return httpService.post('/EmployeeToRole/Create', payload)
  }

  deassignEmployeeToRole (payload: { employeeId: number; roleId: number }): Promise<''> {
    return httpService.post('/EmployeeToRole/Deassign', {}, { params: payload })
  }

  getAuditReports (): Promise<IAuditReport[]> {
    return httpService.get('/Permission/AuditReport')
  }
}

export const securityService = new SecurityService()
