import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { requisitionService, postingService } from '@/services'
import { IRequisitionMain, IPosting } from '@/types'

@Module({ name: 'RequisitionsModule', namespaced: true })
export default class RequisitionsModule extends VuexModule {
  requisitionsLoading = false
  requisitionDetailsLoading = false
  postingsUnattachedLoading = false

  requisitions = [] as IRequisitionMain[]
  activeRequisitions = [] as IRequisitionMain[]
  postingsUnattached = [] as IPosting[]
  requisitionBlank: any = {}

  /* R E Q U I S I T I O N S   M U T A T I O N S */
  @Mutation
  SET_REQUISITIONS_LOADING (payload: boolean) {
    this.requisitionsLoading = payload
  }

  @Mutation
  SET_REQUISITION_DETAILS_LOADING (payload: boolean) {
    this.requisitionDetailsLoading = payload
  }

  @Mutation
  SET_REQUISITIONS (payload: IRequisitionMain[]) {
    this.requisitions = payload
  }

  @Mutation
  SET_ACTIVE_REQUISITIONS (payload: IRequisitionMain[]) {
    this.activeRequisitions = payload
  }

  @Mutation
  SET_REQUISITION_BLANK (payload: any) {
    this.requisitionBlank = payload
  }

  /* P O S T I N G S   U N A T T A C H E D   M U T A T I O N S */
  @Mutation
  SET_POSTINGS_UNATTACHED_LOADING (payload: boolean) {
    this.postingsUnattachedLoading = payload
  }

  @Mutation
  SET_POSTINGS_UNATTACHED (payload: IPosting[]) {
    this.postingsUnattached = payload
  }

  /* R E Q U I S I T I O N S   A C T I O N S */
  @Action({ commit: 'SET_REQUISITIONS', rawError: true })
  async getRequisitions (activeToggleState: boolean) {
    return await requisitionService.getRequisitions(activeToggleState)
  }

  /* P O S T I N G S   U N A T T A C H E D   A C T I O N S */
  @Action({ commit: 'SET_POSTINGS_UNATTACHED', rawError: true })
  async getPostingsUnattached () {
    return await postingService.getPostingsUnattached()
  }

  @Action({ commit: 'SET_ACTIVE_REQUISITIONS', rawError: true })
  async getActiveRequisitionsAsList () {
    return await requisitionService.getActiveRequisitionsAsList()
  }

  @Action({ commit: 'SET_REQUISITION_BLANK', rawError: true })
  async getRequisitionBlank () {
    return await requisitionService.getRequisitionBlank()
  }
}
