import { ICollectionConfig, IGuardRepresentative } from '@/types'
import { booleanFormatter, date } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsGuard: ICollectionConfig[] = [{
  label: 'Guard Branch',
  group: 'Branches',
  getEndpoint: '/GuardBranch/GetGuardBranches',
  saveEndpoint: '/GuardBranch/SaveGuardBranch',
  updateEndpoint: '/GuardBranch/UpdateGuardBranch',
  toggleEndpoint: '/GuardBranch/ToggleGuardBranch',
  collectionName: collectionNames.guardBranches,
  formWrapClass: 'h-full flex-no-wrap flex-col',
  headings: [
    { label: 'Celayix Name', value: 'celayixBranchName', sortable: true, width: 150 },
    { label: 'Celayix ID', value: 'celayixBranchId', sortable: true, width: 150 },
    {
      label: 'Start Date',
      value: 'startDate',
      minWidth: 100,
      sortable: true,
      formatter: i => date(i.startDate)
    },
    {
      label: 'End Date',
      value: 'endDate',
      minWidth: 100,
      sortable: true,
      formatter: i => date(i.endDate)
    }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'celayixBranchName',
    props: { label: 'Celayix Branch Name', placeholder: 'Celayix Branch Name', class: 'w-full mt-4' },
    required: true
  }, {
    component: 'InputModule',
    model: 'celayixBranchId',
    props: { label: 'Celayix Branch Id', placeholder: 'ID', class: 'w-full mt-4', type: 'number' },
    required: true
  }, {
    component: 'DatePickerModule',
    model: 'startDate',
    props: { label: 'Start Date', class: 'w-full mt-4' }
  }, {
    component: 'DatePickerModule',
    model: 'endDate',
    props: { label: 'End Date', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'street1',
    props: { label: 'Street 1', placeholder: 'Street 1', class: 'w-full mt-4', clearable: true }
  },
  {
    component: 'InputModule',
    model: 'street2',
    props: { label: 'Street 2', placeholder: 'Street 2', class: 'w-full mt-4', clearable: true }
  },
  {
    component: 'InputModule',
    model: 'city',
    props: { label: 'City', placeholder: 'City', class: 'w-full mt-4', clearable: true }
  },
  {
    component: 'InputModule',
    model: 'state',
    props: { label: 'State', placeholder: 'State', class: 'w-full mt-4', maxlength: 2, clearable: true }
  },
  {
    component: 'InputModule',
    model: 'zip',
    props: { label: 'Zip', placeholder: 'Zip', class: 'w-full mt-4', type: 'number', maxlength: 5, clearable: true }
  },
  {
    component: 'InputModule',
    model: 'zip4',
    props: { label: 'Zip4', placeholder: 'Zip4', class: 'w-full mt-4', type: 'number', maxlength: 4, clearable: true }
  },
  {
    component: 'InputModule',
    model: 'phone',
    props: { label: 'Phone', placeholder: 'Phone', class: 'w-full mt-4', clearable: true },
    required: true
  },
  {
    component: 'InputModule',
    model: 'email',
    props: { label: 'Email', placeholder: 'Email', class: 'w-full mt-4', clearable: true },
    required: true
  }]
},

{
  label: 'Guard Level',
  group: 'GuardLevel',
  getEndpoint: '/GuardLevel/GetGuardLevels',
  saveEndpoint: '/GuardLevel/SaveGuardLevel',
  updateEndpoint: '/GuardLevel/UpdateGuardLevel',
  toggleEndpoint: '/GuardLevel/ToggleGuardLevel',
  collectionName: collectionNames.guardLevels,
  cellClass: ['isArmed', 'isCleared'],
  headings: [
    { label: 'Title', value: 'title', sortable: true, minWidth: 250 },
    { label: 'Account Type', value: 'accountType', sortable: true, width: 140, align: 'center' },
    { label: 'Compensation Low', value: 'compensationLow', sortable: true, width: 180, align: 'center' },
    { label: 'Compensation High', value: 'compensationHigh', sortable: true, width: 180, align: 'center' },
    { label: 'Schedule', value: 'schedule', sortable: true, width: 120, align: 'center' },
    { label: 'Availability', value: 'availability', sortable: true, width: 130, align: 'center' },
    { label: 'Armed', value: 'armed', sortable: true, width: 90, align: 'center' },
    { label: 'Cleared', value: 'cleared', sortable: true, width: 120, align: 'center' },
    { label: 'Is Armed', ...booleanFormatter('isArmed') },
    { label: 'Is Cleared', ...booleanFormatter('isCleared') }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'title',
    props: { label: 'Title', placeholder: 'Title', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'level',
    props: { label: 'Level', placeholder: 'Level', class: 'w-full mt-4' }
  }, {
    component: 'InputModule',
    model: 'accountType',
    props: { label: 'Account Type', placeholder: 'Account Type', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'availability',
    props: { label: 'Availability', placeholder: 'Availability', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'compensationLow',
    props: { label: 'Compensation Low', placeholder: 'Compensation Low', class: 'w-1/2 mt-4 pr-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'compensationHigh',
    props: { label: 'Compensation High', placeholder: 'Compensation High', class: 'w-1/2 mt-4 pl-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'armed',
    props: { label: 'Armed', placeholder: 'Armed', class: 'w-1/2 mt-4 pr-2', type: 'number' }
  }, {
    component: 'InputModule',
    model: 'cleared',
    props: { label: 'Cleared', placeholder: 'Cleared', class: 'w-1/2 mt-4 pl-2', type: 'number' }
  }, {
    component: 'CheckboxModule',
    model: 'isArmed',
    props: { label: 'Is Armed', class: 'w-1/2 mt-4' }
  }, {
    component: 'CheckboxModule',
    model: 'isCleared',
    props: { label: 'Is Cleared', class: 'w-1/2 mt-4' }
  }]
},

{
  label: 'Guard Location',
  group: 'GuardLocation',
  getEndpoint: '/GuardLocation/GetGuardLocations',
  saveEndpoint: '/GuardLocation/SaveGuardLocation',
  updateEndpoint: '/GuardLocation/UpdateGuardLocation',
  toggleEndpoint: '/GuardLocation/ToggleGuardLocation',
  collectionName: collectionNames.guardLocations,
  form: [...naming]
},

{
  label: 'Guard Representative',
  group: 'GuardRepresentative',
  getEndpoint: '/GuardRepresentative/GetGuardRepresentative',
  saveEndpoint: '',
  updateEndpoint: '',
  toggleEndpoint: '/GuardRepresentative/RefreshGuardRepresentative',
  collectionName: collectionNames.guardRepresentatives,
  headings: [
    {
      label: 'Type',
      value: 'representativeType',
      sortable: true,
      minWidth: 150,
      sort: (item: IGuardRepresentative) => `${item}`,
      formatter: (item: IGuardRepresentative) => item.representativeType.name || ''
    }
  ]
},

{
  label: 'Guard Representative Type',
  group: 'GuardRepresentativeType',
  getEndpoint: '/GuardRepresentativeType/GetGuardRepresentativeTypes',
  saveEndpoint: '/GuardRepresentativeType/SaveGuardRepresentativeType',
  updateEndpoint: '/GuardRepresentativeType/UpdateGuardRepresentativeType',
  toggleEndpoint: '/GuardRepresentativeType/ToggleGuardRepresentativeType',
  collectionName: collectionNames.guardRepresentativeTypes,
  cellClass: ['default'],
  headings: [
    { label: 'AD Group', value: 'activeDirectoryGroupName', sortable: true, width: 200 },
    { label: 'Default', ...booleanFormatter('default') }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'activeDirectoryGroupName',
    props: { label: 'AD Group', placeholder: 'AD Group', class: 'w-full mt-4' }
  }, {
    component: 'CheckboxModule',
    model: 'default',
    props: { label: 'Default', class: 'w-1/2 mt-4' }
  }]
},

{
  label: 'Guard Tag',
  group: 'GuardTag',
  getEndpoint: '/GuardTag/GetGuardTags',
  saveEndpoint: '/GuardTag/SaveGuardTag',
  updateEndpoint: '/GuardTag/UpdateGuardTag',
  toggleEndpoint: '/GuardTag/ToggleGuardTag',
  formWrapClass: 'h-full flex-no-wrap flex-col',
  collectionName: collectionNames.guardTags,
  noName: true,
  headings: [
    { label: 'Representative', value: 'guardRepresentative', sortable: true, minWidth: 200 },
    { label: 'Color', value: 'color', sortable: true, width: 100 },
    { label: 'Description', value: 'description', sortable: true, minWidth: 400 }
  ],
  form: [{
    component: 'InputModule',
    model: 'displayName',
    required: true,
    props: { label: 'Display Name', placeholder: 'Display Name', class: 'w-full' }
  }, {
    component: 'InputModule',
    model: 'color',
    props: { label: 'Accent color', placeholder: 'Color', class: 'w-full mt-4' }
  }, {
    component: 'SelectModule',
    model: 'guardRepresentativeTypeId',
    options: 'guardRepresentativeTypes',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Representative Type',
      placeholder: 'Select type',
      class: 'w-full mt-4'
    }
  }, {
    component: 'SelectModule',
    model: 'guardRepresentativeId',
    options: 'tagBlanksRepresentatives',
    required: true,
    props: {
      optionKey: 'text',
      valueKey: 'value',
      label: 'Representative',
      placeholder: 'Select representative',
      class: 'w-full mt-4'
    }
  }, {
    component: 'TextareaModule',
    model: 'description',
    props: { label: 'Description', placeholder: 'Description', class: 'flex-1 w-full mt-4' }
  }]
},

{
  label: 'Guard Term Status Type',
  group: 'GuardTermStatusType',
  getEndpoint: '/GuardTermStatusType/GetGuardTermStatusTypes',
  saveEndpoint: '/GuardTermStatusType/SaveGuardTermStatusType',
  updateEndpoint: '/GuardTermStatusType/UpdateGuardTermStatusType',
  toggleEndpoint: '/GuardTermStatusType/ToggleGuardTermStatusType',
  collectionName: collectionNames.guardTermStatusTypes,
  cellClass: ['defaultValue', 'isPendingStatus', 'isAllowHire', 'isHireStatus'],
  headings: [
    { label: 'Default', ...booleanFormatter('defaultValue') },
    { label: 'Pending Status', ...booleanFormatter('isPendingStatus') },
    { label: 'Allow Hire', ...booleanFormatter('isAllowHire') },
    { label: 'Is Hire', ...booleanFormatter('isHireStatus') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isPendingStatus', props: { label: 'Pending Status', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isAllowHire', props: { label: 'Allow Hire', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isHireStatus', props: { label: 'Is Hire', class: 'w-1/2 mt-4' } }
  ]
},

{
  label: 'Guard Term Type',
  group: 'GuardTermType',
  getEndpoint: '/GuardTermType/GetGuardTermTypes',
  saveEndpoint: '/GuardTermType/SaveGuardTermType',
  updateEndpoint: '/GuardTermType/UpdateGuardTermType',
  toggleEndpoint: '/GuardTermType/ToggleGuardTermType',
  collectionName: collectionNames.guardTermTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming, { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Guard Type',
  group: 'GuardType',
  getEndpoint: '/GuardType/GetGuardTypes',
  saveEndpoint: '/GuardType/SaveGuardType',
  updateEndpoint: '/GuardType/UpdateGuardType',
  toggleEndpoint: '/GuardType/ToggleGuardType',
  collectionName: collectionNames.guardTypes,
  cellClass: ['requireCustomer', 'defaultValue'],
  headings: [
    { label: 'Customer Required', ...booleanFormatter('requireCustomer'), width: 180 },
    { label: 'Default', ...booleanFormatter('defaultValue') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'requireCustomer', props: { label: 'Require Customer', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'w-1/2 mt-4' } }
  ]
}]
