import { httpService } from '@/services'
import { IImport, IImportUploadPayload } from '@/types'

class ImportsService {
  getImports (activeOnly: boolean): Promise<IImport[]> {
    return httpService.get('/ImportDocument/GetAll', { params: { activeOnly } })
  }

  getImportById (id: number): Promise<IImport> {
    return httpService.get('/ImportDocument/GetById', { params: { id } })
  }

  create (payload: IImportUploadPayload): Promise<''> {
    return httpService.post('/ImportDocument/Create', payload)
  }
}

export const importsService = new ImportsService()
