const opportunitiesRoutesNames = {
  opportunity: 'opportunity',
  opportunityEdit: 'OpportunityEdit',
  opportunityCreate: 'OpportunityCreate',
  opportunityCreateFromCustomer: 'OpportunityCreateFromCustomer',
  opportunityEditFromCustomer: 'OpportunityEditFromCustomer'
}

const parentName = opportunitiesRoutesNames.opportunity

const opportunitiesRoutes = [
  {
    name: opportunitiesRoutesNames.opportunity,
    path: '',
    meta: {
      parentName,
      group: 'HUB_Sales_Opportunity_View',
      label: 'Opportunity'
    },
    component: () => import('@/pages/sales/opportunities/Opportunities.vue')
  },
  {
    name: opportunitiesRoutesNames.opportunityEdit,
    path: 'opportunity-details/:id/',
    meta: {
      parentName,
      group: 'HUB_Sales_Opportunity_Update',
      label: 'Opportunity Details'
    },
    component: () =>
      import('@/pages/sales/opportunities/opportunity/OpportunityEdit.vue')
  },
  {
    name: opportunitiesRoutesNames.opportunityCreate,
    path: 'opportunity-create/',
    meta: {
      parentName,
      group: 'HUB_Sales_Opportunity_Update',
      label: 'New Opportunity'
    },
    component: () =>
      import('@/pages/sales/opportunities/opportunity/OpportunityCreate.vue')
  },
  {
    name: opportunitiesRoutesNames.opportunityCreateFromCustomer,
    path: 'opportunity-create/customer/:customerId/',
    meta: {
      parentName,
      group: 'HUB_Sales_Opportunity_Update',
      label: 'New Opportunity'
    },
    component: () =>
      import('@/pages/sales/opportunities/opportunity/OpportunityCreateFromCustomer.vue')
  },
  {
    name: opportunitiesRoutesNames.opportunityEditFromCustomer,
    path: 'opportunity-edit/:id/customer/:customerId/',
    meta: {
      parentName,
      group: 'HUB_Sales_Opportunity_Update',
      label: 'Opportunity Details'
    },
    component: () =>
      import('@/pages/sales/opportunities/opportunity/OpportunityEditFromCustomer.vue')
  }
]

export { opportunitiesRoutesNames, opportunitiesRoutes }
