import { httpService } from '@/services'

import {
  IAsset,
  IAssignedAsset,
  IAssignedAssetById,
  IAssignedAssetItem,
  IAssignedAssetNote,
  IAssignedAssetReassign,
  IEmployeeAssetByEmployeeId
} from '@/types'

class AssetService {
  /* A S S E T */
  getAssets (activeOnly: boolean): Promise<IAsset[]> {
    return httpService.get('/Asset/GetAssets', { params: { activeOnly } })
  }

  getAssetById (id: string): Promise<IAsset> {
    return httpService.get('/Asset/GetById', { params: { id } })
  }

  getAssignedAssetById (id: string, isEmployeeAssigned: boolean): Promise<IAssignedAssetById> {
    const url = isEmployeeAssigned ? 'GetEmployeeAssignedById' : 'GetCustomerSiteAssignedById'
    return httpService.get(`/Asset/${url}`, { params: { id } })
  }

  saveAsset (payload: IAsset): Promise<''> {
    return httpService.post('/Asset/Save', payload)
  }

  updateAsset (payload: IAsset): Promise<''> {
    return httpService.post('/Asset/Update', payload)
  }

  searchAssets (term: string): Promise<IAsset[]> {
    return httpService.get('/Asset/Search', { params: { term } })
  }

  assignAsset (payload: IAssignedAssetItem): Promise<''> {
    return httpService.post('/Asset/AssignAsset', payload)
  }

  unAssignAsset (employeeId: number, customerSiteId: number): Promise<''> {
    return httpService.post('/Asset/UnAssignAsset', {}, { params: { employeeId, customerSiteId } })
  }

  reAssignAsset (payload: IAssignedAssetReassign, id: number): Promise<''> {
    return httpService.post('/Asset/ReAssignAsset', {}, { params: { ...payload, id } })
  }

  /* A S S E T   N O T E */
  getByAssetToEmployeeId (id: number): Promise<IAssignedAssetNote[]> {
    return httpService.get('/AssetNote/GetByAssetToEmployeeId', { params: { id } })
  }

  saveAssetNote (payload: IAssignedAssetNote): Promise<''> {
    return httpService.post('/AssetNote/Save', payload)
  }

  /* A S S E T   T O   E M P L O Y E E */
  getAssignedAssets (activeOnly: boolean): Promise<IAssignedAsset[]> {
    return httpService.get('/Asset/GetAssignedAssets', { params: { activeOnly } })
  }

  getAssetsByEmployeeId (id: number): Promise<IEmployeeAssetByEmployeeId[]> {
    return httpService.get('/Asset/GetAllByEmployeeId', { params: { id } })
  }

  updateAssignedAsset (payload: IAssignedAssetItem): Promise<''> {
    return httpService.post('/Asset/UpdateAssignAsset', payload)
  }
}

export const assetService = new AssetService()
