import { RouteConfig } from 'vue-router'

const ticketSidebarRoutesNames = {
  ticketCreate: 'ticketCreate',
  ticketDetails: 'ticketDetails',
  ticketEdit: 'ticketEdit',
  ticketArticles: 'ticketArticles',
  ticketAssignment: 'ticketAssignment',
  ticketChangeCategory: 'ticketChangeCategory',
  ticketComplete: 'ticketComplete',
  ticketNotes: 'ticketNotes',
  ticketRelations: 'ticketRelations'
}

const ticketSidebarRoutes: RouteConfig[] = [
  {
    name: ticketSidebarRoutesNames.ticketCreate,
    path: '/ticket-create',
    meta: { width: '600px' },
    component: () => import('./TicketCreate.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketDetails,
    path: '/ticket-:id/details',
    meta: { width: '600px' },
    component: () => import('./TicketDetails.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketEdit,
    path: '/ticket-:id/edit',
    meta: { width: '600px' },
    component: () => import('./TicketEdit.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketArticles,
    path: '/ticket-:id/articles',
    meta: { width: '600px' },
    component: () => import('./TicketArticles.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketAssignment,
    path: '/ticket-:id/assign',
    meta: { width: '600px' },
    component: () => import('./TicketAssignment.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketChangeCategory,
    path: '/ticket-:id/change-category',
    meta: { width: '600px' },
    component: () => import('./TicketChangeCategory.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketComplete,
    path: '/ticket-:id/complete',
    meta: { width: '600px' },
    component: () => import('./TicketComplete.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketNotes,
    path: '/ticket-:id/notes',
    meta: { width: '600px' },
    component: () => import('./TicketNotes.vue')
  },
  {
    name: ticketSidebarRoutesNames.ticketRelations,
    path: '/ticket-:id/relations',
    meta: { width: '600px' },
    component: () => import('./TicketRelations.vue')
  }
]

export {
  ticketSidebarRoutesNames,
  ticketSidebarRoutes
}
