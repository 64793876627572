import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { assetService, collectionsService } from '@/services'
import {
  IAsset,
  IAssignedAsset,
  IBaseModelNamed
} from '@/types'

@Module({ name: 'AssetModule', namespaced: true })
export default class AssetModule extends VuexModule {
  assets: IAsset[] = []
  assetToEmployee: IAssignedAsset[] = []
  assetTypes = [] as IBaseModelNamed[]

  assetLoading = false
  assignedAssetLoading = false

  /* A S S E T   T Y P E S   M U T A T I O N S */
  @Mutation
  SET_ASSET_TYPES (payload: IBaseModelNamed[]) {
    this.assetTypes = payload
  }

  /* A S S I G N E D   A S S E T S   M U T A T I O N S */
  @Mutation
  SET_ASSIGNED_ASSET_LOADING (payload: boolean) {
    this.assignedAssetLoading = payload
  }

  @Mutation
  SET_ASSET_TO_EMPLOYEE (payload: IAssignedAsset[]) {
    this.assetToEmployee = payload
  }

  /* A S S E T S   M U T A T I O N S */
  @Mutation
  SET_ASSET_LOADING (payload: boolean) {
    this.assetLoading = payload
  }

  @Mutation
  SET_ASSETS (payload: IAsset[]) {
    this.assets = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_ASSETS', rawError: true })
  async getAssets (activeToggleState: boolean) {
    return await assetService.getAssets(activeToggleState)
  }

  @Action({ commit: 'SET_ASSET_TO_EMPLOYEE', rawError: true })
  async getAssignedAssets (activeToggleState: boolean) {
    return await assetService.getAssignedAssets(activeToggleState)
  }

  @Action({ commit: 'SET_ASSET_TYPES', rawError: true })
  async getAssetTypes () {
    return await collectionsService.getCollection('assetTypes')
  }
}
