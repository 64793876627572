































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TSizes } from '@/types'

import LabelModule from '@/components/shared/form-controls/LabelModule.vue'
import { ElSelect } from 'element-ui/types/select'

@Component({
  name: 'SelectModule',
  components: { LabelModule }
})
export default class SelectModule extends Vue {
  /* P R O P S */
  @Prop({ default: () => [] }) options!: any[]
  @Prop({ default: 'lg' }) size!: TSizes

  @Prop() label?: string
  @Prop() valueKey!: string
  @Prop() optionKey!: string
  @Prop() placeholder?: string
  @Prop() popperClass?: string
  @Prop() optionClasses?: string

  @Prop() inline!: boolean | string | undefined
  @Prop() search!: boolean | string | undefined
  @Prop() required!: boolean | string | undefined
  @Prop({ default: false }) disabled?: boolean
  @Prop({ default: true }) bodyPopperAppend!: boolean

  /* R E F S */
  $refs!: { select: ElSelect & { $el: any } }

  /* C O M P U T E D */
  get isInline (): boolean {
    return this.setProp(this.inline)
  }

  get isSearch (): boolean {
    return this.setProp(this.search)
  }

  get isRequired (): boolean {
    return this.setProp(this.required)
  }

  get popperAppendToBody () {
    if (typeof this.bodyPopperAppend === 'boolean') return this.bodyPopperAppend
    else if (this.bodyPopperAppend === '') return false
    else return this.bodyPopperAppend
  }

  get dynamicClasses () {
    return [
      this.isSearch && 'select-module-search',
      `form-input--${this.size}`,
      this.isInline && 'form-inline'
    ]
  }

  /* M E T H O D S */
  setProp (value: boolean | string | undefined): boolean {
    if (typeof value === 'boolean') return value
    else if (value === '') return true
    else return !!value
  }
}
