import { httpService } from '@/services'
import {
  IPostInspection,
  IPostInspectionItem,
  IPostInspectionNote,
  IPostInspectionNoteItem,
  IPostInspectionDocument,
  IPostInspectionDocumentItem,
  IApprovePostInspection,
  IPostInspectionAnswer,
  IPostInspectionAnswerItem,
  IPostInspectionTicket
} from '@/types'

class PostInspectionsService {
  /* P O S T   I N S P E C T I O N S */
  getPostInspections (activeToggleState: boolean): Promise<IPostInspection[]> {
    return httpService.get(`/PostInspection/GetAll${activeToggleState ? 'Active' : ''}`)
  }

  getPostInspectionById (id: number): Promise<IPostInspection> {
    return httpService.get('/PostInspection/GetById', { params: { id } })
  }

  getPostInspectionsByEmployeeId (id: number): Promise<IPostInspection[]> {
    return httpService.get('/PostInspection/GetAllByEmployeeId', { params: { id } })
  }

  savePostInspection (payload: any): Promise<''> {
    return httpService.post('/PostInspection/Save', payload)
  }

  approvePostInspection (payload: IApprovePostInspection): Promise<''> {
    return httpService.post('/PostInspection/Approve', payload)
  }

  updatePostInspection (payload: IPostInspectionItem): Promise<''> {
    return httpService.post('/PostInspection/Update', payload)
  }

  togglePostInspection (id: number): Promise<''> {
    return httpService.post('/PostInspection/Toggle', {}, { params: { id } })
  }

  emailPostInspectionReport ({ id, reportSubject, reportMessageToCustomer, recipientsAsEmail }:
    { id: number; reportSubject: string; reportMessageToCustomer: string; recipientsAsEmail: string[] }): Promise<''> {
    return httpService.post('/PostInspection/EmailReport', {}, { params: { id, reportSubject, reportMessageToCustomer, recipientsAsEmail } })
  }

  /* P O S T   I N S P E C T I O N   A N S W E R */
  getPostInspectionAnswersByPostInspectionId (id: number): Promise<IPostInspectionAnswer[]> {
    return httpService.get('/PostInspectionAnswer/GetAllByPostInspectionId', { params: { id } })
  }

  savePostInspectionAnswer (payload: IPostInspectionAnswerItem): Promise<''> {
    return httpService.post('/PostInspectionAnswer/Save', payload)
  }

  updatePostInspectionAnswer (payload: IPostInspectionAnswerItem): Promise<''> {
    return httpService.post('/PostInspectionAnswer/Update', payload)
  }

  removePostInspectionAnswer (id: number): Promise<''> {
    return httpService.post('/PostInspectionAnswer/Remove', {}, { params: { id } })
  }

  /* P O S T   I N S P E C T I O N   D O C U M E N T */
  getPostInspectionDocumentsByPostInspectionId (id: number): Promise<IPostInspectionDocument[]> {
    return httpService.get('/PostInspectionDocument/GetByPostInspectionId', { params: { id } })
  }

  getPostInspectionDocumentsById (id: number): Promise<IPostInspectionDocument> {
    return httpService.get('/PostInspectionDocument/GetById', { params: { id } })
  }

  savePostInspectionDocument (payload: IPostInspectionDocumentItem): Promise<''> {
    return httpService.post('/PostInspectionDocument/Save', payload)
  }

  removePostInspectionDocument (id: number): Promise<''> {
    return httpService.post('/PostInspectionDocument/RemoveById', {}, { params: { id } })
  }

  saveOfficerSignature (payload: IPostInspectionDocumentItem): Promise<''> {
    return httpService.post('/PostInspectionDocument/SaveOfficerSignature', payload)
  }

  /* P O S T   I N S P E C T I O N   N O T E */
  getPostInspectionNotesByPostInspectionId (postInspectionId: number): Promise<IPostInspectionNote[]> {
    return httpService.get('/PostInspectionNote/GetByPostInspectionId', { params: { postInspectionId } })
  }

  savePostInspectionNote (payload: IPostInspectionNoteItem): Promise<''> {
    return httpService.post('/PostInspectionNote/Save', payload)
  }

  updatePostInspectionNote (payload: IPostInspectionNoteItem): Promise<''> {
    return httpService.post('/PostInspectionNote/Update', payload)
  }

  /* P O S T   I N S P E C T I O N   T I C K E T S */
  getTicketsByPostInspectionId (id: number): Promise<IPostInspectionTicket[]> {
    return httpService.get('/PostInspectionToTicket/GetAllByPostInspectionById', { params: { id } })
  }

  savePostInspectionTicket (payload: { postInspectionId: number; ticketId: number }): Promise<''> {
    return httpService.post('/PostInspectionToTicket/Save', payload)
  }

  removePostInspectionTicket (id: number): Promise<''> {
    return httpService.post('/PostInspectionToTicket/Remove', {}, { params: { id } })
  }
}

export const postInspectionsService = new PostInspectionsService()
