import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { massMailingsService } from '@/services'
import { IMassMailing } from '@/types'

@Module({ name: 'MassMailingsModule', namespaced: true })
export default class MassMailingsModule extends VuexModule {
  massMailings = [] as readonly IMassMailing[]
  massMailingsLoading = false

  // M U T A T I O N S
  @Mutation
  SET_MASS_MAILINGS (payload: readonly IMassMailing[]) {
    this.massMailings = payload
  }

  @Mutation
  SET_MASS_MAILINGS_LOADING (payload: boolean) {
    this.massMailingsLoading = payload
  }

  // A C T I O N S
  @Action({ commit: 'SET_MASS_MAILINGS', rawError: true })
  async getMassMailings () {
    return await massMailingsService.getMassMailings()
  }
}
