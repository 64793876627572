import { httpService } from '@/services'
import { IPatrolReport, IPatrolReportForm, IPatrolReportDocument, IPatrolReportNote, IFileData } from '@/types'

class PatrolReportsService {
  /* P A T R O L   R E P O R T S */
  getPatrolReports (): Promise<IPatrolReport[]> {
    return httpService.get('/PatrolReport/GetPatrolReports')
  }

  getPatrolReportById (id: string): Promise<IPatrolReport> {
    return httpService.get('/PatrolReport/GetPatrolReportById', { params: { id } })
  }

  savePatrolReport (payload: IPatrolReportForm & { employeeId: number}): Promise<''> {
    return httpService.post('/PatrolReport/SavePatrolReport', payload)
  }

  updatePatrolReport (payload: IPatrolReport): Promise<''> {
    return httpService.post('/PatrolReport/UpdatePatrolReport', payload)
  }

  removePatrolReport (id: number): Promise<''> {
    return httpService.post('/PatrolReport/RemovePatrolReport', {}, { params: { id } })
  }

  emailPatrolReport (id: number): Promise<''> {
    return httpService.post('/PatrolReport/EmailPatrolReport', {}, { params: { id } })
  }

  /* P A T R O L   R E P O R T   D O C U M E N T S */
  getDocumentsByPatrolReportId (id: number): Promise<IFileData[]> {
    return httpService.get('/PatrolReportDocument/GetByPatrolReportId', { params: { id } })
  }

  getPatrolReportDocumentById (id: number): Promise<IFileData> {
    return httpService.get('/PatrolReportDocument/GetById', { params: { id } })
  }

  uploadPatrolReportDocument (payload: IPatrolReportDocument): Promise<''> {
    return httpService.post('/PatrolReportDocument/UploadPatrolReportDocument', payload)
  }

  removePatrolReportDocument (id: number): Promise<''> {
    return httpService.post('/PatrolReportDocument/RemovePatrolReportDocument', {}, { params: { id } })
  }

  /* P A T R O L   R E P O R T   N O T E S */
  getNotesByPatrolReportId (id: number): Promise<IPatrolReportNote[]> {
    return httpService.get('/PatrolReportNote/GetByPatrolReportId', { params: { id } })
  }

  savePatrolReportNote (payload: IPatrolReportNote): Promise<''> {
    return httpService.post('/PatrolReportNote/SavePatrolReportNote', payload)
  }
}

export const patrolReportsService = new PatrolReportsService()
