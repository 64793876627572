import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export class ModalMixin extends Vue {
  @Prop({ default: false }) opened!: boolean

  closeModal () {
    this.$emit('update:opened', false)
  }
}
