import { RouteConfig } from 'vue-router'

const messagesSidebarRoutesNames = {
  conversationMembers: 'conversationMembers',
  messageTemplates: 'messageTemplates'
}

const messagesSidebarRoutes: RouteConfig[] = [
  {
    name: messagesSidebarRoutesNames.conversationMembers,
    path: '/members-:id',
    component: () => import('./ConversationMembers.vue')
  },
  {
    name: messagesSidebarRoutesNames.messageTemplates,
    path: '/templates',
    meta: { width: '700px' },
    component: () => import('./MessageTemplates.vue')
  }
]

export {
  messagesSidebarRoutesNames,
  messagesSidebarRoutes
}
