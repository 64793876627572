import { httpService } from '@/services'
import { IKBAttachment, IKnowledgeBaseArticle } from '@/types'

class ArticlesService {
  /* K N O W L E D G E   B A S E   A R T I C L E */
  getKnowledgeBaseArticles (): Promise<IKnowledgeBaseArticle[]> {
    return httpService.get('/KnowledgeBaseArticle/GetKnowledgeBaseArticles')
  }

  getKnowledgeBaseArticleById (id: number): Promise<IKnowledgeBaseArticle> {
    return httpService.get('/KnowledgeBaseArticle/GetKnowledgeBaseArticleById', { params: { id } })
  }

  saveKnowledgeBaseArticle (payload: IKnowledgeBaseArticle & { knowledgeBaseArticleAttachments: IKBAttachment[] }): Promise<''> {
    return httpService.post('/KnowledgeBaseArticle/SaveKnowledgeBaseArticle', payload)
  }

  updateKnowledgeBaseArticle (payload: Pick<
    IKnowledgeBaseArticle, 'id' | 'article' | 'departmentId' |'isPublic' | 'isGuard'
    >): Promise<''> {
    return httpService.post('/KnowledgeBaseArticle/UpdateKnowledgeBaseArticle', payload)
  }

  toggleKnowledgeBaseArticle (id: number): Promise<''> {
    return httpService.post('/KnowledgeBaseArticle/ToggleKnowledgeBaseArticle', {}, { params: { id } })
  }

  /* K N O W L E D G E   B A S E   A R T I C L E   A T T A C H M E N T S */
  getKnowledgeBaseArticleAttachments (articleId: number): Promise<IKBAttachment[]> {
    return httpService.get('/KnowledgeBaseArticleAttachment/GetByArticle', { params: { articleId } })
  }

  getKnowledgeBaseArticleAttachmentById (id: number): Promise<IKBAttachment> {
    return httpService.get('/KnowledgeBaseArticleAttachment/GetById', { params: { id } })
  }

  saveKnowledgeBaseArticleAttachments (payload: IKBAttachment[]): Promise<''> {
    return httpService.post('/KnowledgeBaseArticleAttachment/AddRange', payload)
  }

  toggleKnowledgeBaseArticleAttachment (id: number): Promise<''> {
    return httpService.post('/KnowledgeBaseArticleAttachment/Toggle', {}, { params: { id } })
  }
}

export const articlesService = new ArticlesService()
