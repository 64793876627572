import { httpService } from '@/services'
import { IReimbursementStatusTypeWorkFlow, IReimbursement, IReimbursementMile, IFileData } from '@/types'

class ReimbursementService {
  /* R E I M B U S E M E N T */
  getReimbursements (activeOnly?: boolean): Promise<IReimbursement[]> {
    return httpService.get('/Reimbursement/GetAll', { params: { activeOnly } })
  }

  getReimbursementsForEmployee (employeeId: string): Promise<IReimbursement[]> {
    return httpService.get('/Reimbursement/GetForEmployee', { params: { employeeId } })
  }

  getReimbursementById (id: number): Promise<IReimbursement> {
    return httpService.get('/Reimbursement/GetById', { params: { id } })
  }

  createReimbursement (payload: any): Promise<''> {
    return httpService.post('/Reimbursement/Create', payload)
  }

  updateReimbursement (payload: any): Promise<''> {
    return httpService.post('/Reimbursement/Update', payload)
  }

  toggleReimbursement (id: number): Promise<''> {
    return httpService.post('/Reimbursement/Toggle', {}, { params: { id } })
  }

  /* R E I M B U S E M E N T   M I L E */
  getReimbursementMilesByReimbursementId (reimbursementId: number): Promise<[]> {
    return httpService.get('/ReimbursementMile/GetByReimbursementId', { params: { reimbursementId } })
  }

  getReimbursementMileById (id: string): Promise<IReimbursementMile> {
    return httpService.get('/ReimbursementMile/GetById', { params: { id } })
  }

  createReimbursementMile (payload: IReimbursementMile): Promise<''> {
    return httpService.post('/ReimbursementMile/Create', payload)
  }

  updateReimbursementMile (payload: IReimbursementMile): Promise<''> {
    return httpService.post('/ReimbursementMile/Update', payload)
  }

  /* R E I M B U S E M E N T   R E C E I P T */
  getReimbursementReceiptById (id: number): Promise<IFileData> {
    return httpService.get('/ReimbursementReceipt/GetById', { params: { id } })
  }

  getReimbursementReceiptsByReimbursementId (reimbursementId: number): Promise<IFileData[]> {
    return httpService.get('/ReimbursementReceipt/GetByReimbursementId', { params: { reimbursementId } })
  }

  saveReimbursementReceipt (payload: IFileData): Promise<''> {
    return httpService.post('/ReimbursementReceipt/Create', payload)
  }

  removeReimbursementReceipt (id: number): Promise<''> {
    return httpService.post('/ReimbursementReceipt/Toggle', {}, { params: { id } })
  }

  /* R E I M B U S E M E N T   S T A T U S   T Y P E   W O R K   F L O W */
  getReimbursementStatusAvailableById (id: number): Promise<IReimbursementStatusTypeWorkFlow[]> {
    return httpService.get('/ReimbursementStatusTypeWorkFlow/GetAvailableById', { params: { id } })
  }
}

export const reimbursementService = new ReimbursementService()
