import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators'
import { benefitEnrollmentService } from '@/services'
import { IBenefitEnrollment } from '@/types'

@Module({ name: 'BenefitEnrollmentModule', namespaced: true })
export default class BenefitEnrollmentModule extends VuexModule {
  hrEnrollmentLoading = false
  sidebarLoading = false
  benefitEnrollments: any[] = []
  carriers: any[] = []
  enrollmentSetup: any = null
  selectedCarriers: number[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_ENROLLMENT_LOADING (payload: boolean) {
    this.hrEnrollmentLoading = payload
  }

  @Mutation
  SET_ENROLLMENT_SIDEBAR_LOADING (payload: boolean) {
    this.sidebarLoading = payload
  }

  @Mutation
  SET_BENEFIT_ENROLLMENTS (payload: IBenefitEnrollment[]) {
    this.benefitEnrollments = payload
  }

  @Mutation
  SET_SELECTED_CARRIERS (payload: number[]) {
    this.selectedCarriers = payload
  }

  @Mutation
  SET_CARRIERS (payload: any[]) {
    this.carriers = payload
  }

  @Mutation
  SET_ENROLLMENT_SETUP (payload: any) {
    this.enrollmentSetup = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_BENEFIT_ENROLLMENTS', rawError: true })
  async getBenefitEnrollments () {
    return await benefitEnrollmentService.getBenefitEnrollments()
  }

  @Action({ commit: 'SET_BENEFIT_ENROLLMENT_CARRIERS', rawError: true })
  async getAllBenefitEnrollmentCarrierByEnrollmentYearId (enrollmentYearId: number) {
    return await benefitEnrollmentService.getAllBenefitEnrollmentCarrierByEnrollmentYearId(enrollmentYearId)
  }
}
