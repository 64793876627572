import { workQueueSidebarRouteNames, workQueueSidebarRoutes } from './work-queue/sidebar/work-queue.sidebar.routes'

const salesSidebarRouteNames = {
  ...workQueueSidebarRouteNames
}

const salesSidebarRoutes = [
  ...workQueueSidebarRoutes
]

export {
  salesSidebarRouteNames,
  salesSidebarRoutes
}
