import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
  fullWidth: true,
  fitToScreen: true,
  singleton: true,
  keepOnHover: true,
  closeOnSwipe: false
})
