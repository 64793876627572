import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsPostInspection: ICollectionConfig[] = [{
  label: 'Post Inspection Note Type',
  group: 'PostInspectionNoteType',
  getEndpoint: '/PostInspectionNoteType/GetAll',
  saveEndpoint: '/PostInspectionNoteType/Save',
  updateEndpoint: '/PostInspectionNoteType/Update',
  toggleEndpoint: '/PostInspectionNoteType/Toggle',
  collectionName: collectionNames.postInspectionNoteTypes,
  cellClass: ['isDefault'],
  headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } }]
},

{
  label: 'Post Inspection Question',
  group: 'PostInspectionQuestion',
  getEndpoint: '/PostInspectionQuestion/GetAll',
  saveEndpoint: '/PostInspectionQuestion/Save',
  updateEndpoint: '/PostInspectionQuestion/Update',
  toggleEndpoint: '/PostInspectionQuestion/Toggle',
  formWrapClass: 'h-full flex-no-wrap flex-col',
  collectionName: collectionNames.postInspectionQuestions,
  noName: true,
  headings: [
    { label: 'Challenge', value: 'challenge', sortable: true, minWidth: 200 },
    { label: 'Response', value: 'response', sortable: true, minWidth: 200 },
    { label: 'Customer Site', value: 'customerName', sortable: true, minWidth: 200 }
  ],
  form: [{
    component: 'InputModule',
    model: 'challenge',
    required: true,
    props: { label: 'Challenge', placeholder: 'Challenge', class: 'w-full' }
  }, {
    component: 'InputModule',
    model: 'response',
    required: true,
    props: { label: 'Response', placeholder: 'Response', class: 'w-full mt-4' }
  }, {
    component: 'SearchLocations',
    model: 'customerSiteId',
    required: true,
    props: { label: 'Customer', class: 'w-full mt-4' }
  }]
},

{
  label: 'Post Inspection Status Type',
  group: 'PostInspectionStatusType',
  getEndpoint: '/PostInspectionStatusType/GetAll',
  saveEndpoint: '/PostInspectionStatusType/Save',
  updateEndpoint: '/PostInspectionStatusType/Update',
  toggleEndpoint: '/PostInspectionStatusType/Toggle',
  collectionName: collectionNames.postInspectionStatusTypes,
  headings: [
    { label: 'Default', minWidth: 100, ...booleanFormatter('isDefault') },
    { label: 'Approved', minWidth: 100, ...booleanFormatter('isApproved') },
    { label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 200 }
  ],
  cellClass: ['isDefault', 'isApproved'],
  form: [...naming,
    {
      component: 'InputModule',
      model: 'securityGroupName',
      props: { label: 'Security Group Name', placeholder: 'Security Group Name', class: 'w-full mt-4' }
    },
    { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'isApproved', props: { label: 'Approved', class: 'w-1/2 mt-4' } }
  ]
}]
