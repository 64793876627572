











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'LabelModule' })
export default class LabelModule extends Vue {
  /* P R O P S */
  @Prop() label!: string
  @Prop() required?: boolean | string | undefined
  @Prop() noMargin?: boolean | string | undefined

  /* M E T H O D S */
  setProp (value: boolean | string | undefined) {
    return value === '' ? true : value
  }
}
