import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { projectsService } from '@/services'
import { IProject } from '@/types'

@Module({ name: 'ProjectsModule', namespaced: true })
export default class ProjectsModule extends VuexModule {
  loading = false
  projects: readonly IProject[] = []
  projectInfo = { id: 0, siteName: 'Site for customer:...' }

  @Mutation
  SET_LOADING (payload: boolean) {
    this.loading = payload
  }

  @Mutation
  SET_PROJECTS (payload: readonly IProject[]) {
    this.projects = payload
  }

  @Mutation
  SET_PROJECT_INFO (payload: { id: number; siteName: string }) {
    this.projectInfo = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_PROJECTS', rawError: true })
  async getProjects (activeToggleState: boolean) {
    return await projectsService.getProjects(activeToggleState)
  }
}
