const dashboardSidebarRouteNames = {
  birthdays: 'birthdays',
  anniversaries: 'anniversaries',
  publicDocuments: 'publicDocuments'
}

const dashboardSidebarRoutes = [
  {
    name: dashboardSidebarRouteNames.birthdays,
    path: '/birthdays',
    component: () => import('@/pages/dashboard/sidebar/Birthdays.vue'),
    meta: { parentName: 'dashboard', group: 'LDH_Dashboard_Office' }
  },
  {
    name: dashboardSidebarRouteNames.anniversaries,
    path: '/anniversaries',
    component: () => import('@/pages/dashboard/sidebar/Anniversaries.vue'),
    meta: { parentName: 'dashboard', group: 'LDH_Dashboard_Office' }
  },
  {
    name: dashboardSidebarRouteNames.publicDocuments,
    path: '/documents',
    component: () => import('@/pages/dashboard/sidebar/PublicDocuments.vue'),
    meta: { parentName: 'dashboard' }
  }
]

export {
  dashboardSidebarRouteNames,
  dashboardSidebarRoutes
}
