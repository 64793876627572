import { httpService } from '@/services'

import {
  IDocumentInfo,
  IEmployee,
  IEmployeeAnniversary,
  IEmployeeBirthday,
  IEmployeeImage,
  IEmployeeSearch,
  IEmploymentHistoryResponseObject,
  IEngagementById,
  IEngagementItem,
  IGuardEmployee,
  IEmployeeSchedule,
  IEmployeePersonalEditing,
  IEmployeeEngagementsSavePayload,
  IEmployeeRehireInformation,
  INewCandidateFormEmergency,
  IEmployeeItemCheckOutLog,
  IEmployeeItem,
  IEmployeeItemCheckIn,
  IEmployeeItemSummary,
  IEmployeeItemsOptions,
  IEmployeeStatusTypes,
  IPostInspectionEmployeeSite,
  IUniformType,
  IEmployeeGuardTermToPosting,
  ICreditCard,
  ISalesPerson,
  IEmployeePersonal,
  IEmployeeContact,
  IEmployeeEmployement,
  IEmployeeIntegrations
} from '@/types'

class EmployeeService {
  searchEmployee (term: string): Promise<{ employees: IEmployeeSearch[] }> {
    return httpService.get('/Employee/SearchForEmployee', { params: { term } })
  }

  searchForOfficeEmployee (term: string): Promise<{ employees: IEmployeeSearch[] }> {
    return httpService.get('/Employee/SearchForOfficeEmployee', { params: { term } })
  }

  searchForEmployeeIncludeOffice (term: string): Promise<{ employees: IEmployeeSearch[] }> {
    return httpService.get('/Employee/SearchForEmployeeIncludeOffice', { params: { term } })
  }

  getEmployeeById (id: number): Promise<IEmployee> {
    return httpService.get('/Employee/GetById', { params: { id } })
  }

  createPaycorEmployee (id: number): Promise<''> {
    return httpService.post('/Employee/CreatePaycorEmployee', {}, { params: { id } })
  }

  createFlowFinityEmployee (id: number): Promise<''> {
    return httpService.post('/Employee/CreateFlowFinityEmployee', {}, { params: { id } })
  }

  activateFlowFinityRecord (id: number): Promise<''> {
    return httpService.post('/Employee/ActivateFlowFinityRecord', {}, { params: { id } })
  }

  updateEmployeePersonal (payload: IEmployeePersonalEditing): Promise<''> {
    return httpService.post('/Employee/UpdatePersonalDetails', payload)
  }

  getEmployees (activeOnly: boolean): Promise<IGuardEmployee[]> {
    return httpService.get('/Employee/GetGuardEmployees', { params: { activeOnly } })
  }

  getEmployeeImage (id: number): Promise<IEmployeeImage> {
    return httpService.get('/Employee/Image', { params: { id } })
  }

  getEmployeeDocuments (id: number): Promise<IDocumentInfo[]> {
    return httpService.get('/Employee/GetUploadedFilesByGuid', { params: { id } })
  }

  getEngagementsByEmployeeId (id: number): Promise<{ engagements: IEngagementItem[] }> {
    return httpService.get('/Employee/GetEngagementsByEmployeeId', { params: { id } })
  }

  getEmployeeLastPayCheckExists (employeeId: number): Promise<boolean> {
    return httpService.get('/Employee/LastPayCheckExists', { params: { employeeId } })
  }

  getEngagementById (id: number): Promise<IEngagementById> {
    return httpService.get('/Engagement/GetEngagementById', { params: { id } })
  }

  saveEngagement (payload: IEmployeeEngagementsSavePayload) {
    return httpService.post('/Employee/SaveEngagement', payload)
  }

  getEmploymentHistory (id: number): Promise<IEmploymentHistoryResponseObject> {
    return httpService.get('/EmploymentHistory/GetEmploymentHistoryByEmployeeId', { params: { id } })
  }

  getEmployeeSchedule (id: number, from?: string, to?: string): Promise<IEmployeeSchedule[]> {
    return httpService.get('/GuardSchedule/GetScheduleByEmployeeIdAndDateRange', { params: { id, from, to } })
  }

  getRehireInformation (id: number): Promise<IEmployeeRehireInformation> {
    return httpService.get('/Employee/GetRehireInformation', { params: { id } })
  }

  sendBackToCandidate (celayixEmployeeId: string, requisitionId: number, employeeId: number): Promise<''> {
    return httpService.post('/Employee/SendBackToCandidate', { celayixEmployeeId, requisitionId, employeeId })
  }

  updateFlowFinityId (id: number, flowFinityId: string): Promise<''> {
    return httpService.post('/Employee/UpdateFlowFinityId', {}, { params: { id, flowFinityId } })
  }

  updatePaycorId (id: number, paycorId: number): Promise<''> {
    return httpService.post('/Employee/UpdatePaycorId', {}, { params: { id, paycorId } })
  }

  updateNetSuiteId (id: number, netSuiteId: string): Promise<''> {
    return httpService.post('/Employee/UpdateNetSuiteId', {}, { params: { id, netSuiteId } })
  }

  createNetSuiteId (hubId: number): Promise<''> {
    return httpService.post('/NetSuiteEmployee/Create', {}, { params: { hubId } })
  }

  getRelatedByEmployeeId (id: number): Promise<any> {
    return httpService.get('/Employee/GetRelatedByEmployeeId', { params: { id } })
  }

  getBirthdays (): Promise<IEmployeeBirthday[]> {
    return httpService.get('/Employee/GetEmployeeBirthdays')
  }

  getAnniversaries (): Promise<IEmployeeAnniversary[]> {
    return httpService.get('/Employee/GetEmployeeAnniversaries')
  }

  updateEmployeeJobTitle (id: number, jobTitle: string): Promise<any> {
    return httpService.post('/Employee/UpdateJobTitle', {}, { params: { id, jobTitle } })
  }

  employeeDoNotHireFlag (id: number, action: 'Get' | 'Set' | 'Unset'): Promise<boolean> {
    return httpService.post(`/Employee/${action}DoNotHireFlag`, {}, { params: { id } })
  }

  /* E M P L O Y E E   T R A I N I N G   S E S S I O N S */
  getEmployeeTrainingSessionsByPostingId (postingId: number): Promise<[]> {
    return httpService.get('/EmployeeTrainingSession/GetEmployeeTrainingSessionsByPostingId', { params: { postingId } })
  }

  updateEmployeeTrainingSession (payload: any): Promise<''> {
    return httpService.post('/EmployeeTrainingSession/UpdateEmployeeTrainingSession', payload)
  }

  saveEmployeeTrainingSession (payload: any): Promise<''> {
    return httpService.post('/EmployeeTrainingSession/SaveEmployeeTrainingSession', payload)
  }

  toggleEmployeeTrainingSession (id: number): Promise<''> {
    return httpService.post('/EmployeeTrainingSession/ToggleEmployeeTrainingSession', {}, { params: { id } })
  }

  // E M P L O Y E E    S T A T U S    T Y P E
  updateEmployeeEmployeeStatusType (employeeId: number, typeId: number): Promise<''> {
    return httpService.post('/Employee/UpdateEmployeeEmployeeStatusType', {}, { params: { employeeId, typeId } })
  }

  updateEmployeeTerminationStatusType (employeeId: number, statusTypeId: number, payload: any): Promise<''> {
    return httpService.post('/Employee/UpdateEmployeeTerminationStatusType', { ...payload, statusTypeId, employeeId })
  }

  saveTerminateDate (id: number, terminateDate: string): Promise<''> {
    return httpService.post('/Employee/SaveTerminateDate', {}, { params: { id, terminateDate } })
  }

  updateEmployeeEmployeeType (employeeId: number, typeId: number): Promise<''> {
    return httpService.post('/Employee/UpdateEmployeeEmployeeType', {}, { params: { employeeId, typeId } })
  }

  updateEmployeeStartDate (id: number, date: string): Promise<''> {
    return httpService.post('/Employee/UpdateStartDate', {}, { params: { id, date } })
  }

  // E M E R G E N C Y
  getActiveEmployeeEmergencyContactsByEmployeeId (id: number): Promise<[]> {
    return httpService.get('/EmployeeEmergencyContact/GetActiveEmployeeEmergencyContactsByEmployeeId', { params: { id } })
  }

  saveEmployeeEmergencyContact (emergency: INewCandidateFormEmergency): Promise<''> {
    return httpService.post('/EmployeeEmergencyContact/SaveEmployeeEmergencyContact', emergency)
  }

  updateEmployeeEmergencyContact (emergency: INewCandidateFormEmergency): Promise<''> {
    return httpService.post('/EmployeeEmergencyContact/UpdateEmployeeEmergencyContact', emergency)
  }

  toggleEmployeeEmergencyContact (id: number): Promise<''> {
    return httpService.post('/EmployeeEmergencyContact/ToggleEmployeeEmergencyContact', {}, { params: { id } })
  }

  /* E M P L O Y E E   I T E M S */
  getActiveEmployeeItems (): Promise<any> {
    return httpService.get('/EmployeeItem/GetAllActive')
  }

  getEmployeeItemsById (itemId: number): Promise<any> {
    return httpService.get('/EmployeeItem/GetById', { params: { id: itemId } })
  }

  getEmployeeItemsByEmployeeId (employeeId: number): Promise<any> {
    return httpService.get('/EmployeeItem/GetByEmployeeId', { params: { employeeId } })
  }

  getEmployeeItems (
    { payrollTermOnly }: IEmployeeItemsOptions = { payrollTermOnly: false }
  ): Promise<IEmployeeItemSummary[]> {
    return httpService.get('/EmployeeItem/Summary', { params: { payrollTermOnly } })
  }

  getEmployeeItemsSummary (employeeId: number): Promise<IEmployeeItemSummary> {
    return httpService.get('/EmployeeItem/EmployeeSummary', { params: { employeeId } })
  }

  getEmployeeItemsCheckLog (employeeId: number): Promise<any> {
    return httpService.get('/EmployeeItem/EmployeeCheckLog', { params: { employeeId } })
  }

  createEmployeeItem (data: any) {
    return httpService.post('/EmployeeItem/Create', data)
  }

  updateEmployeeItem (data: IEmployeeItem) {
    return httpService.post('/EmployeeItem/Update', data)
  }

  toggleEmployeeItem (id: number) {
    return httpService.post('/EmployeeItem/Toggle', {}, { params: { id } })
  }

  searchEmployeeItem (pattern: string): Promise<IEmployeeItem[]> {
    return httpService.get('/EmployeeItem/Search', { params: { pattern } })
  }

  /* E M P L O Y E E   I T E M S   C H E C K   O U T */

  getEmployeeItemsCheckOut (): Promise<IEmployeeItemCheckOutLog[]> {
    return httpService.get('/EmployeeItemCheckOutLog/GetAll')
  }

  getEmployeeItemsCheckOutActive (): Promise<IEmployeeItemCheckOutLog[]> {
    return httpService.get('/EmployeeItemCheckOutLog/GetAllActive')
  }

  createEmployeeItemCheckOut (data: IEmployeeItemCheckOutLog) {
    return httpService.post('/EmployeeItemCheckOutLog/Create', data)
  }

  updateEmployeeItemCheckOut (data: any) {
    return httpService.post('/EmployeeItemCheckOutLog/Update', data)
  }

  toggleEmployeeItemCheckOut (data: any) {
    return httpService.post('/EmployeeItemCheckOutLog/Toggle', data)
  }

  /* E M P L O Y E E   I T E M S   C H E C K   I N */

  checkInItem (data: IEmployeeItemCheckIn[]) {
    return httpService.post('/EmployeeItemCheckInLog/CheckIn', data)
  }

  generateEmployeeItemPayload (
    type: 'update' | 'create',
    employeeForm: IEmployeeItem & { note: string }) {
    return {
      ...(type === 'update' && { id: employeeForm.id }),
      obsolete: employeeForm.obsolete,
      note: employeeForm.note,
      partId: employeeForm.partId
    }
  }

  generateEmployeeItemCheckOutPayload (type: 'update' | 'create', employeeForm: IEmployeeItemCheckOutLog) {
    return {
      ...(type === 'update' && { id: employeeForm.id }),
      employeeItemId: Number(employeeForm.employeeItemId),
      quantityOut: Number(employeeForm.quantityOut),
      quantityOutDate: employeeForm.quantityOutDate,
      note: employeeForm.note
    }
  }

  getEmployeeStatusTypes (): Promise<IEmployeeStatusTypes> {
    return httpService.get('/EmployeeStatusType/GetEmployeeStatusTypes')
  }

  /* P O S T   I N S P E C T I O N */
  getPostInspectionEmployeeSiteForEmployee (employeeId: number): Promise<IPostInspectionEmployeeSite[]> {
    return httpService.get('/PostInspectionEmployeeSite/GetForEmployee', { params: { employeeId } })
  }

  createPostInspectionEmployeeSite (data: any): Promise<''> {
    return httpService.post('/PostInspectionEmployeeSite/Create', data)
  }

  togglePostInspectionEmployeeSite (id: number): Promise<''> {
    return httpService.post('/PostInspectionEmployeeSite/Toggle', {}, { params: { id } })
  }

  getEmployeeHistory (id: number): Promise<any> {
    return httpService.get('/Employee/GetEmployeeHistory', { params: { id } })
  }

  getPostInspectionEmployeeSiteAvailableSites (customerId: number, employeeId: number): Promise<any> {
    return httpService.get('/PostInspectionEmployeeSite/GetAvailableSites', { params: { customerId, employeeId } })
  }

  /* U N I F O R M   T Y P E S */
  getUniformTypes (): Promise<IUniformType[]> {
    return httpService.get('/UniformType/GetAllActive')
  }

  getUniformByType (uniformTypeId: number): Promise<any> {
    return httpService.get('/Uniform/GetByType', { params: { uniformTypeId } })
  }

  /* C R E D I T   C A R D S */
  getAllCreditCards (allActive?: boolean): Promise<ICreditCard[]> {
    return httpService.get(`/CreditCard/GetAll${allActive ? 'Active' : ''}`)
  }

  getCreditCardByEmployeeId (employeeId: string): Promise<ICreditCard[]> {
    return httpService.get('/CreditCard/GetForEmployee', { params: { employeeId } })
  }

  createCreditCard (payload: any): Promise<''> {
    return httpService.post('/CreditCard/Create', payload)
  }

  updateCreditCard (payload: any): Promise<''> {
    return httpService.post('/CreditCard/Update', payload)
  }

  toggleCreditCard (id: any): Promise<''> {
    return httpService.post('/CreditCard/Toggle', {}, { params: { id } })
  }

  employeeGuardTermToPostingUpdate (payload: IEmployeeGuardTermToPosting): Promise<any> {
    return httpService.post('/EmployeeGuardTermToPosting/Update', payload)
  }

  getJobTitles (): Promise<any[]> {
    return httpService.get('/JobTitleType/GetJobTitles')
  }

  setupEmployeeProfile (): Promise<any[]> {
    return httpService.get('/Employee/SetupEmployeeProfile')
  }

  getEmployeePersonalById (id: number): Promise<IEmployeePersonal> {
    return httpService.get('/Employee/GetEmployeePersonalById', { params: { id } })
  }

  getEmployeeContactById (id: number): Promise<IEmployeeContact> {
    return httpService.get('/Employee/GetEmployeeContactById', { params: { id } })
  }

  getEmployeeEmploymentById (id: number): Promise<IEmployeeEmployement> {
    return httpService.get('/Employee/GetEmployeeEmploymentById', { params: { id } })
  }

  getEmployeeIntegrationsById (id: number): Promise<IEmployeeIntegrations> {
    return httpService.get('/Employee/GetEmployeeIntegrationsById', { params: { id } })
  }

  // S A L E S   P E R S O N S
  getSalesPersons (): Promise<ISalesPerson[]> {
    return httpService.get('/Employee/GetSalesPersons')
  }
}

export const employeeService = new EmployeeService()
