import { TNullableField } from '@/types'

function debounceFunction (func: any, wait: number, immediate: boolean) {
  let timeout: TNullableField<number>
  return function (this: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    // eslint-disable-next-line prefer-rest-params
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout as number)
    timeout = setTimeout(later, wait) as any as number
    if (callNow) {
      func.apply(context, args)
    }
  }
}

function throttleFunction (func: any, ms: number) {
  let isThrottled = false
  let savedArgs: any
  let savedThis: any

  function wrapper (this: any) {
    if (isThrottled) {
      // eslint-disable-next-line prefer-rest-params
      savedArgs = arguments
      savedThis = this
      return
    }
    // eslint-disable-next-line prefer-rest-params
    func.apply(this, arguments)
    isThrottled = true
    setTimeout(function () {
      isThrottled = false
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}

// @debounce decorator
export function debounce (wait = 500, immediate = false) {
  return function (target: Record<string, any>, propertyName: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value
    descriptor.value = debounceFunction(original, wait, immediate)
    return descriptor
  }
}

// @throttle debounce
export function throttle (milliseconds = 500) {
  return function (target: Record<string, any>, propertyName: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value
    descriptor.value = throttleFunction(original, milliseconds)
    return descriptor
  }
}
