import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { configurationsService } from '@/services'
import { IApplication, IConfiguration, IDocuSignType } from '@/types'
import { sortBy } from '@/helpers'

@Module({ name: 'ConfigurationsModule', namespaced: true })
export default class ConfigurationsModule extends VuexModule {
  configurations = [] as IConfiguration[]
  docuSignData = {} as IDocuSignType
  salesForceData = {} as any
  applications = [] as IApplication[]

  /* M U T A T I O N S */
  @Mutation
  SET_CONFIGURATIONS (payload: IConfiguration[]) {
    this.configurations = payload
  }

  @Mutation
  SET_DOCU_SIGN_DATA (payload: IDocuSignType) {
    this.docuSignData = payload
  }

  @Mutation
  SET_SALES_FORCE_DATA (payload: any) {
    this.salesForceData = payload
  }

  @Mutation
  SET_APPLICATIONS (payload: IApplication[]) {
    this.applications = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CONFIGURATIONS', rawError: true })
  async getAllSettings () {
    return await configurationsService.getAllSettings()
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_DOCU_SIGN_DATA', rawError: true })
  async getDocuSignData () {
    return await configurationsService.getDocuSignData()
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_APPLICATIONS', rawError: true })
  async getApplications (): Promise<IApplication[]> {
    const entries = (await this.getAllSettings()).filter(({ name, obsolete }) => name?.includes('Applications:') && !obsolete)
    return entries.map((setting: IConfiguration) => {
      return { name: setting.name?.split(':')[1], link: setting.value, group: 'LDH_Applications_View' }
    }).concat().sort(sortBy('name')) as IApplication[]
  }
}
