import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsEmployee: ICollectionConfig[] = [{
  label: 'Employee Relationship Type',
  group: 'EmployeeRelationshipType',
  getEndpoint: '/EmployeeRelationshipType/GetEmployeeRelationshipTypes',
  saveEndpoint: '/EmployeeRelationshipType/SaveEmployeeRelationshipType',
  updateEndpoint: '/EmployeeRelationshipType/UpdateEmployeeRelationshipType',
  toggleEndpoint: '/EmployeeRelationshipType/ToggleEmployeeRelationshipType',
  collectionName: collectionNames.employeeRelationshipTypes,
  form: [...naming]
},

{
  label: 'Employee Status Type',
  group: 'EmployeeStatusType',
  getEndpoint: '/EmployeeStatusType/GetEmployeeStatusTypes',
  saveEndpoint: '/EmployeeStatusType/SaveEmployeeStatusType',
  updateEndpoint: '/EmployeeStatusType/UpdateEmployeeStatusType',
  toggleEndpoint: '/EmployeeStatusType/ToggleEmployeeStatusType',
  collectionName: collectionNames.employeeStatusTypes,
  cellClass: ['hired', 'active', 'reviewed', 'licensed', 'tentativeTermination', 'pendingTermination', 'payrollTermination', 'terminated'],
  headings: [
    { label: 'H', width: 70, ...booleanFormatter('hired') },
    { label: 'A', width: 70, ...booleanFormatter('active') },
    { label: 'R', width: 70, ...booleanFormatter('reviewed') },
    { label: 'L', width: 70, ...booleanFormatter('licensed') },
    { label: 'TT', width: 70, ...booleanFormatter('tentativeTermination') },
    { label: 'PeT', width: 70, ...booleanFormatter('pendingTermination') },
    { label: 'PaT', width: 70, ...booleanFormatter('payrollTermination') },
    { label: 'T', width: 70, ...booleanFormatter('terminated') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'hired', props: { label: 'Hired', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'active', props: { label: 'Active', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'reviewed', props: { label: 'Reviewed', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'licensed', props: { label: 'Licensed', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'tentativeTermination', props: { label: 'Tentative Termination', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'pendingTermination', props: { label: 'Pending Termination', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'payrollTermination', props: { label: 'Payroll Termination', class: 'w-1/2 mt-4' } },
    { component: 'CheckboxModule', model: 'terminated', props: { label: 'Terminated', class: 'w-1/2 mt-4' } }
  ]
},

{
  label: 'Employment Type',
  group: 'EmploymentType',
  getEndpoint: '/EmploymentType/GetEmploymentTypes',
  saveEndpoint: '/EmploymentType/SaveEmploymentType',
  updateEndpoint: '/EmploymentType/UpdateEmploymentType',
  toggleEndpoint: '/EmploymentType/ToggleEmploymentType',
  collectionName: collectionNames.employmentTypes,
  headings: [{ label: 'PTO Statement', value: 'ptoStatement', sortable: true, minWidth: 250 }],
  formWrapClass: 'h-full flex-no-wrap flex-col',
  form: [...naming, {
    component: 'TextareaModule',
    model: 'ptoStatement',
    props: { label: 'PTO Statement', placeholder: 'PTO Statement', class: 'flex-1 w-full mt-4' }
  }]
}]
