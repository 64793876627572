import { httpService } from '@/services'
import { ITerminatedEmployee } from '@/types'

class TerminatedEmployeesService {
  getTerminatedEmployees (): Promise<ITerminatedEmployee[]> {
    return httpService.get('/CommandCenter/GetTerminatedEmployees')
  }

  removeTerminatedEmployee (id: number): Promise<''> {
    return httpService.post('/CommandCenter/RemoveFromTemplateByEmployeeId', {}, { params: { id } })
  }
}

export const terminatedEmployeesService = new TerminatedEmployeesService()
