import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.maintenance

const maintenanceRouteNames = {
  assets: 'Asset',
  benefitEnrollments: 'BenefitEnrollment',
  collections: 'Collection',
  configuration: 'Configuration',
  integrations: 'Integration',
  transactions: 'Transaction',
  publicDocuments: 'PublicDocument',
  jobs: 'Job',
  security: 'Security'
}

const maintenanceRoutes = [
  {
    name: maintenanceRouteNames.assets,
    path: 'assets',
    meta: {
      parentName,
      group: 'LDH_Nav_Assets_View',
      label: 'Assets'
    },
    component: () => import('@/pages/maintenance/assets/Assets.vue')
  },
  {
    name: maintenanceRouteNames.benefitEnrollments,
    path: 'benefit-enrollments',
    meta: {
      parentName,
      group: 'LDH_Nav_Benefit_Enrollments_View',
      label: 'Benefit Enrollments'
    },
    component: () => import('@/pages/maintenance/benefit-enrollments/BenefitEnrollments.vue')
  },
  {
    name: 'collections',
    path: 'collections',
    meta: {
      parentName,
      group: 'LDH_Nav_Collections_View',
      label: 'Collections'
    },
    component: () => import('@/pages/maintenance/collections/Collections.vue')
  },
  {
    name: maintenanceRouteNames.configuration,
    path: 'configuration',
    meta: {
      parentName,
      group: 'LDH_Nav_Configuration_View',
      label: 'Configuration'
    },
    component: () => import('@/pages/maintenance/configuration/Configuration.vue')
  },
  {
    name: maintenanceRouteNames.jobs,
    path: 'jobs',
    meta: {
      parentName,
      group: 'LDH_Nav_Jobs_View',
      label: 'Jobs'
    },
    component: () => import('@/pages/maintenance/jobs/Jobs.vue')
  },
  {
    name: maintenanceRouteNames.publicDocuments,
    path: 'public-documents',
    meta: {
      parentName,
      group: 'LDH_Nav_Public_Documents_View',
      label: 'Public Documents'
    },
    component: () => import('@/pages/maintenance/public-documents/PublicDocuments.vue')
  },
  {
    name: maintenanceRouteNames.transactions,
    path: 'transactions',
    meta: {
      parentName,
      group: 'LDH_Nav_Transactions_View',
      label: 'Transactions'
    },
    component: () => import('@/pages/maintenance/transactions/Transactions.vue')
  },
  {
    name: maintenanceRouteNames.security,
    path: 'security',
    meta: {
      parentName,
      group: 'LDH_Security_View',
      label: 'Security'
    },
    component: () => import('@/pages/maintenance/security/Security.vue')
  }
]

export {
  maintenanceRoutes,
  maintenanceRouteNames
}
