import { ICollectionConfig } from '@/types'
import { booleanFormatter } from '@/helpers'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsCustomer: ICollectionConfig[] = [{
  label: 'Customer Billing Type',
  group: 'CustomerBilling',
  getEndpoint: '/CustomerBillingType/GetCustomerBillingTypes',
  saveEndpoint: '/CustomerBillingType/SaveCustomerBillingType',
  updateEndpoint: '/CustomerBillingType/UpdateCustomerBillingType',
  toggleEndpoint: '/CustomerBillingType/ToggleCustomerBillingType',
  collectionName: collectionNames.customerBillingTypes,
  cellClass: ['isMonthly', 'isFlatBill', 'isRateSheet'],
  headings: [
    { label: 'Monthly', ...booleanFormatter('isMonthly') },
    { label: 'Flat Bill', ...booleanFormatter('isFlatBill') },
    { label: 'Rate Sheet', ...booleanFormatter('isRateSheet') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'isMonthly', props: { label: 'Monthly', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isFlatBill', props: { label: 'Flat Bill', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isRateSheet', props: { label: 'Rate Sheet', class: 'w-full mt-4' } }
  ]
},

{
  label: 'Customer Comment Type',
  group: 'CustomerCommentType',
  getEndpoint: '/CustomerCommentType/GetCustomerCommentTypes',
  saveEndpoint: '/CustomerCommentType/SaveCustomerCommentType',
  updateEndpoint: '/CustomerCommentType/UpdateCustomerCommentType',
  toggleEndpoint: '/CustomerCommentType/ToggleCustomerCommentType',
  collectionName: collectionNames.customerCommentTypes,
  cellClass: ['isDefault', 'isFinancial', 'isPost'],
  headings: [
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Financial', ...booleanFormatter('isFinancial') },
    { label: 'Post', ...booleanFormatter('isPost') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isFinancial', props: { label: 'Financial', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isPost', props: { label: 'Post', class: 'w-full mt-4' } }
  ]
},

{
  label: 'Customer Document Type',
  group: 'CustomerDocumentType',
  getEndpoint: '/CustomerDocumentType/GetCustomerDocumentTypes',
  saveEndpoint: '/CustomerDocumentType/SaveCustomerDocumentType',
  updateEndpoint: '/CustomerDocumentType/UpdateCustomerDocumentType',
  toggleEndpoint: '/CustomerDocumentType/ToggleCustomerDocumentType',
  collectionName: collectionNames.customerDocumentTypes,
  cellClass: ['isDefault'],
  headings: [{ label: 'Default', ...booleanFormatter('isDefault') }],
  form: [...naming, { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'mt-4' } }]
},

{
  label: 'Customer Service Level Agreement',
  group: 'CustomerServiceLevelAgreement',
  getEndpoint: '/CustomerServiceLevelAgreement/GetCustomerServiceLevelAgreements',
  saveEndpoint: '/CustomerServiceLevelAgreement/SaveCustomerServiceLevelAgreement',
  updateEndpoint: '/CustomerServiceLevelAgreement/UpdateCustomerServiceLevelAgreement',
  toggleEndpoint: '/CustomerServiceLevelAgreement/ToggleCustomerServiceLevelAgreement',
  collectionName: collectionNames.customerServiceLevelAgreements,
  headings: [
    { label: 'Level', value: 'level', sortable: true, width: 120 },
    { label: 'Account Leadership', value: 'accountLeadership', sortable: true, width: 190 },
    { label: 'Approval', value: 'approval', sortable: true, width: 120 },
    { label: 'Classification', value: 'classification', sortable: true, width: 150 },
    { label: 'Guard Requirement', value: 'guardRequirement', sortable: true, width: 180 },
    { label: 'Lead Time', value: 'leadTime', sortable: true, width: 120 },
    { label: 'Staffing', value: 'staffing', sortable: true, width: 120 },
    { label: 'Staffing Approval', value: 'staffingApproval', sortable: true, width: 170 },
    { label: 'Terms', value: 'terms', sortable: true, width: 120 },
    { label: 'Type', value: 'type', sortable: true, width: 120 },
    { label: 'MC Target', value: 'mcTarget', sortable: true, width: 120 },
    { label: 'OT Target', value: 'otTarget', sortable: true, width: 120 },
    { label: 'Unfilled Target', value: 'unfilledTarget', sortable: true, width: 160 },
    { label: 'Added', value: 'added', sortable: true, width: 120 },
    { label: 'Time Off', value: 'timeOff', sortable: true, width: 120 },
    { label: 'Call Off', value: 'callOff', sortable: true, width: 120 },
    { label: 'Salaried', value: 'salaried', sortable: true, width: 120 },
    { label: 'Training', value: 'training', sortable: true, width: 120 },
    { label: 'Flex', value: 'flex', sortable: true, width: 120 },
    { label: 'Turnover', value: 'turnover', sortable: true, width: 120 },
    { label: 'Description', value: 'description', sortable: true, minWidth: 300 }
  ],
  form: [...naming, {
    component: 'InputModule',
    model: 'level',
    props: { label: 'Level', placeholder: 'Level', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'accountLeadership',
    props: { label: 'Account Leadership', placeholder: 'Account Leadership', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'approval',
    props: { label: 'Approval', placeholder: 'Approval', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'classification',
    props: { label: 'Classification', placeholder: 'Classification', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'guardRequirement',
    props: { label: 'Guard Requirement', placeholder: 'Guard Requirement', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'leadTime',
    props: { label: 'Lead Time', placeholder: 'Lead Time', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'staffing',
    props: { label: 'Staffing', placeholder: 'Staffing', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'staffingApproval',
    props: { label: 'Staffing Approval', placeholder: 'Staffing Approval', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'terms',
    props: { label: 'Terms', placeholder: 'Terms', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'type',
    props: { label: 'Type', placeholder: 'Type', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'mcTarget',
    props: { label: 'MC Target', placeholder: 'MC Target', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'otTarget',
    props: { label: 'OT Target', placeholder: 'OT Target', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'unfilledTarget',
    props: { label: 'Unfilled Target', placeholder: 'Unfilled Target', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'added',
    props: { label: 'Added', placeholder: 'Added', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'timeOff',
    props: { label: 'Time Off', placeholder: 'Time Off', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'callOff',
    props: { label: 'Call Off', placeholder: 'Call Off', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'salaried',
    props: { label: 'Salaried', placeholder: 'Salaried', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'training',
    props: { label: 'Training', placeholder: 'Training', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'InputModule',
    model: 'flex',
    props: { label: 'Flex', placeholder: 'Flex', class: 'w-1/2 mt-4 pr-2' }
  }, {
    component: 'InputModule',
    model: 'turnover',
    props: { label: 'Turnover', placeholder: 'Turnover', class: 'w-1/2 mt-4 pl-2' }
  }, {
    component: 'TextareaModule',
    model: 'description',
    props: { label: 'Description', placeholder: 'Description', class: 'w-full mt-4' }
  }]
},

{
  label: 'Customer Site Contact Type',
  group: 'CustomerSiteContactType',
  getEndpoint: '/CustomerSiteContactType/GetCustomerSiteContactTypes',
  saveEndpoint: '/CustomerSiteContactType/SaveCustomerSiteContactType',
  updateEndpoint: '/CustomerSiteContactType/UpdateCustomerSiteContactType',
  toggleEndpoint: '/CustomerSiteContactType/ToggleCustomerSiteContactType',
  collectionName: collectionNames.customerSiteContactTypes,
  form: [...naming]
},

{
  label: 'Customer Site Contract Type',
  group: 'CustomerSiteContractType',
  getEndpoint: '/CustomerSiteContractType/GetCustomerSiteContractTypes',
  saveEndpoint: '/CustomerSiteContractType/SaveCustomerSiteContractType',
  updateEndpoint: '/CustomerSiteContractType/UpdateCustomerSiteContractType',
  toggleEndpoint: '/CustomerSiteContractType/ToggleCustomerSiteContractType',
  collectionName: collectionNames.customerSiteContractTypes,
  cellClass: ['defaultValue'],
  headings: [{ label: 'Default', ...booleanFormatter('defaultValue') }],
  form: [...naming, { component: 'CheckboxModule', model: 'defaultValue', props: { label: 'Default', class: 'mt-4' } }]
},

{
  label: 'Customer Site Position Requirement',
  group: 'PositionRequirement',
  getEndpoint: '/CustomerSitePositionRequirement/GetCustomerSitePositionRequirements',
  saveEndpoint: '/CustomerSitePositionRequirement/SaveCustomerSitePositionRequirement',
  updateEndpoint: '/CustomerSitePositionRequirement/UpdateCustomerSitePositionRequirement',
  toggleEndpoint: '/CustomerSitePositionRequirement/ToggleCustomerSitePositionRequirement',
  collectionName: collectionNames.customerSitePositionRequirements,
  form: [...naming]
},

{
  label: 'Customer Status Risk Type',
  group: 'CustomerStatusRiskType',
  getEndpoint: '/CustomerStatusRiskType/GetCustomerStatusRiskTypes',
  saveEndpoint: '/CustomerStatusRiskType/SaveCustomerStatusRiskType',
  updateEndpoint: '/CustomerStatusRiskType/UpdateCustomerStatusRiskType',
  toggleEndpoint: '/CustomerStatusRiskType/ToggleCustomerStatusRiskType',
  collectionName: collectionNames.customerStatusRiskTypes,
  cellClass: ['isGood', 'isElevated', 'isHigh', 'isCritical'],
  headings: [
    { label: 'Good', ...booleanFormatter('isGood') },
    { label: 'Elevated', ...booleanFormatter('isElevated') },
    { label: 'High', ...booleanFormatter('isHigh') },
    { label: 'Critical', ...booleanFormatter('isCritical') }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'isGood', props: { label: 'Good', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isElevated', props: { label: 'Elevated', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isHigh', props: { label: 'High', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isCritical', props: { label: 'Critical', class: 'w-full mt-4' } }
  ]
},

{
  label: 'Customer Status Type',
  group: 'CustomerStatusType',
  getEndpoint: '/CustomerStatusType/GetCustomerStatusTypes',
  saveEndpoint: '/CustomerStatusType/SaveCustomerStatusType',
  updateEndpoint: '/CustomerStatusType/UpdateCustomerStatusType',
  toggleEndpoint: '/CustomerStatusType/ToggleCustomerStatusType',
  collectionName: collectionNames.customerStatusTypes,
  cellClass: ['isActive', 'isInactive', 'isPendingActivation', 'isPendingDeactivation', 'isPendingClientServiceApproval', 'isDefault'],
  headings: [
    { label: 'Active', ...booleanFormatter('isActive') },
    { label: 'Inactive', ...booleanFormatter('isInactive') },
    { label: 'Default', ...booleanFormatter('isDefault') },
    { label: 'Pending Activation', ...booleanFormatter('isPendingActivation'), width: 180 },
    { label: 'Pending Deactivation', ...booleanFormatter('isPendingDeactivation'), width: 200 },
    { label: 'Pending Client Service Approval', ...booleanFormatter('isPendingClientServiceApproval'), width: 270 }
  ],
  form: [...naming,
    { component: 'CheckboxModule', model: 'isActive', props: { label: 'Active', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isInactive', props: { label: 'Inactive', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isDefault', props: { label: 'Default', class: 'mt-4' } },
    { component: 'CheckboxModule', model: 'isPendingActivation', props: { label: 'Pending Activation', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isPendingDeactivation', props: { label: 'Pending Deactivation', class: 'w-full mt-4' } },
    { component: 'CheckboxModule', model: 'isPendingClientServiceApproval', props: { label: 'Pending Client Service Approval', class: 'w-full mt-4' } }
  ]
},

{
  label: 'Competitor',
  group: 'Competitor',
  getEndpoint: '/Competitor/GetAll',
  getAllActive: '/Competitor/GetAllActive',
  saveEndpoint: '/Competitor/Create',
  updateEndpoint: '/Competitor/Update',
  toggleEndpoint: '/Competitor/Toggle',
  collectionName: collectionNames.competitor,
  address: true,
  headings: [{ label: 'Address', value: 'address', sortable: true, width: 300 }],
  form: [...naming]
},

{
  label: 'Contact Type',
  group: 'ContactType',
  getEndpoint: '/ContactType/GetAll',
  getAllActive: '/ContactType/GetAllActive',
  saveEndpoint: '/ContactType/Create',
  updateEndpoint: '/ContactType/Update',
  toggleEndpoint: '/ContactType/Toggle',
  collectionName: collectionNames.contactType,
  form: [...naming]
},

{
  label: 'Competitor to Service Line',
  group: 'CompetitorToServiceLine',
  getEndpoint: '/CompetitorToServiceLine/GetAll',
  getAllActive: '/CompetitorToServiceLine/GetAllActive',
  saveEndpoint: '/CompetitorToServiceLine/Create',
  updateEndpoint: '/CompetitorToServiceLine/Update',
  toggleEndpoint: '/CompetitorToServiceLine/Toggle',
  collectionName: collectionNames.competitorToServiceLine,
  noName: true,
  headings: [
    { label: 'Competitor', value: 'competitorDisplayName', sortable: true, minWidth: 100 },
    { label: 'Service Line', value: 'serviceLineDisplayName', sortable: true, minWidth: 100 }
  ],
  form: [{
    component: 'SelectModule',
    model: 'competitorId',
    options: 'competitors',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Competitor',
      placeholder: 'Select competitor',
      class: 'w-full'
    }
  }, {
    component: 'SelectModule',
    model: 'serviceLineId',
    options: 'serviceLines',
    required: true,
    props: {
      optionKey: 'displayName',
      valueKey: 'id',
      label: 'Service Line',
      placeholder: 'Select service line',
      class: 'w-full mt-4'
    }
  }]
}]
