const workQueueSidebarRouteNames = {
  leadQueueDetails: 'leadQueueDetails',
  opportunityQueueDetails: 'opportunityQueueDetails'
}

const workQueueSidebarRoutes = [
  /* L E A D   Q U E U E */
  {
    name: workQueueSidebarRouteNames.leadQueueDetails,
    path: '/lead-queue-details',
    meta: { group: 'LDH_AssetToEmployee_View' },
    component: () => import('./lead-queue-tab/LeadQueueDetails.vue')
  },
  /* O P P O R T U N I T Y   Q U E U E */
  {
    name: workQueueSidebarRouteNames.opportunityQueueDetails,
    path: '/opportunity-queue-details',
    meta: { group: 'LDH_AssetToEmployee_View' },
    component: () => import('./opportunity-queue-tab/OpportunityQueueDetails.vue')
  }
]

export {
  workQueueSidebarRouteNames,
  workQueueSidebarRoutes
}
