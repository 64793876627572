







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors } from '@/types'

@Component({
  name: 'ToggleModule'
})
export default class ToggleModule extends Vue {
  /* P R O P S */
  @Prop() value!: string
  @Prop({ default: 'basic' }) color!: TColors
  @Prop({ default: false }) disabled!: boolean
  @Prop() activeText!: string
  @Prop() inactiveText!: string

  /* D A T A */
  focused = false

  /* M E T H O D S */
  deactivate () {
    if (!this.disabled) {
      this.$emit('input', false)
      this.$emit('off')
      this.focused = true
    }
  }

  activate () {
    if (!this.disabled) {
      this.$emit('input', true)
      this.$emit('on')
      this.focused = true
    }
  }

  toggle () {
    if (!this.disabled) {
      this.$emit('input', !this.value)
      this.focused = true
    }
  }
}
