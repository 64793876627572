const customerContactsSidebarRoutesNames = {
  customerContacts: 'customerContacts',
  customerContactsDetails: 'customerContactsDetails'
}

const customerContactsSidebarRoutes = [
  {
    name: customerContactsSidebarRoutesNames.customerContacts,
    path: '/customer-:customerId/contacts',
    component: () => import('./CustomerDetails.vue'),
    meta: {
      group: 'LDH_Customer_View',
      width: '700px',
      minWidth: '500px'
    }
  },
  {
    name: customerContactsSidebarRoutesNames.customerContactsDetails,
    path: '/customer-:customerId/contacts/contact-details-:contactId',
    component: () => import('@/pages/client-services/customers/sidebar/customer-details/customer-details-tabs/customer-contact/CustomerContactDetails.vue'),
    meta: {
      width: '700px',
      minWidth: '500px'
    }
  }
]

export {
  customerContactsSidebarRoutesNames,
  customerContactsSidebarRoutes
}
