




import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'aux-layout'
})
export default class AuxLayout extends Vue {}
