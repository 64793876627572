import Vue from 'vue'
import Vuex from 'vuex'
import { getModule } from 'vuex-module-decorators'
import UserModule from '@/store/modules/user.module'
import GeneralModule from '@/store/modules/general.module'
import PersonalizationModule from '@/store/modules/personalization.module'
import NotificationsModule from '@/store/modules/notifications.module'
import TextusModule from '@/store/modules/textus.module'
import TableHeadingsModule from '@/store/modules/table-headings.module'
import OnboardingModule from '@/store/modules/onboarding.module'

// C O M P O N E N T S
import TableHeaderFilterModule from '@/components/shared/table-header-filter/table-header-filter.module'

// D A S H B O A R D
import DashboardModule from '@/pages/dashboard/dashboard.module'

// C L I E N T   S E R V I C E S
import CustomersModule from '@/pages/client-services/customers/customers.module'
import VendorsModule from '@/pages/client-services/vendors/vendors.module'
import AddressesModule from '@/pages/client-services/addresses/addresses.module'

// C O M M A N D  C E N T E R
import MassMailingsModule from '@/pages/command-center/mass-mailings/mass-mailings.module'
import PatrolReportsModule from '@/pages/client-services/patrol-reports/patrol-report.module'
import DetachedPostingsModule from '@/pages/command-center/detached-postings/detached-postings.module'
import TerminatedEmployeesModule from '@/pages/command-center/terminated-employees/terminated-employees.module'

// K N O W L E D G E   B A S E
import ArticlesModule from '@/pages/knowledge-base/articles/articles.module'

// H U M A N  R E S O U R C E S
import CandidateModule from '@/pages/human-resources/candidates/candidate.module'
import EmployeeModule from '@/pages/human-resources/employees/employee.module'
import GuardTermsModule from '@/pages/human-resources/guard-terms/guard-terms.module'
import LicensesModule from '@/pages/human-resources/licenses/licenses.module'
import MessagesModule from '@/pages/human-resources/messages/messages.module'
import PaychecksModule from '@/pages/human-resources/paychecks/paychecks.module'
import PostInspectionsModule from '@/pages/client-services/post-inspections/post-inspections.module'
import PostingModule from '@/pages/human-resources/posting/posting.module'
import RequisitionsModule from '@/pages/human-resources/requisitions/requisitions.module'
import ReimbursementsModule from '@/pages/human-resources/reimbursements/reimbursement.module'

// M A I N T E N A N C E
import AssetModule from '@/pages/maintenance/assets/assets.module'
import BenefitEnrollmentModule from '@/pages/maintenance/benefit-enrollments/benefit-enrollments.module'

import CollectionsModule from '@/pages/maintenance/collections/collections.module'

import ConfigurationsModule from '@/pages/maintenance/configuration/configuration.module'
import JobsModule from '@/pages/maintenance/jobs/jobs.module'
import PublicDocumentsModule from '@/pages/maintenance/public-documents/public-documents.module'
import TransactionModule from '@/pages/maintenance/transactions/transactions.module'
import SecurityModule from '@/pages/maintenance/security/security.module'

// R E P O R T S
import ImportsModule from '@/pages/reports/imports/imports.module'
import ExportsModule from '@/pages/reports/exports/exports.module'
import NotesModule from '@/pages/reports/notes/notes.module'
import CelayixShiftsModule from '@/pages/reports/celayix-shifts/celayix-shifts.module'
import EmployeeItemsModule from '@/pages/reports/employee-items/employee-items.module'

// T I C K E T S  &  I N C I D E N T S
import IncidentsModule from '@/pages/tickets-incidents/incidents/incidents.module'
import TicketsModule from '@/pages/tickets-incidents/tickets/tickets.module'

// S A L E S
import OpportunityModule from '@/pages/sales/opportunities/opportunities.module'

// T E C H  O P S
import DropShipModule from '@/pages/tech-ops/drop-ship/drop-ship.module'
import ProjectsModule from '@/pages/tech-ops/projects/projects.module'
import ServiceCallsModule from '@/pages/tech-ops/service-calls/service-calls.module'
import ScheduleModule from '@/pages/tech-ops/schedule/schedule.module'
import UnbillableModule from '@/pages/tech-ops/unbillable/unbillable.module'
import PartsModule from '@/pages/tech-ops/parts/parts.module'
import ContractApprovalTypesModule from '@/pages/tech-ops/contract-approval-types/contractApprovalTypes.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    UserModule,
    LicensesModule,
    GeneralModule,
    GuardTermsModule,
    EmployeeModule,
    TicketsModule,
    IncidentsModule,
    TerminatedEmployeesModule,
    DashboardModule,
    PostingModule,
    ImportsModule,
    ExportsModule,
    JobsModule,
    NotesModule,
    EmployeeItemsModule,
    PaychecksModule,
    CandidateModule,
    CollectionsModule,
    ArticlesModule,
    PersonalizationModule,
    NotificationsModule,
    RequisitionsModule,
    AssetModule,
    PatrolReportsModule,
    ConfigurationsModule,
    TextusModule,
    MassMailingsModule,
    CustomersModule,
    MessagesModule,
    DetachedPostingsModule,
    ServiceCallsModule,
    DropShipModule,
    TransactionModule,
    ProjectsModule,
    TableHeadingsModule,
    TableHeaderFilterModule,
    OnboardingModule,
    PostInspectionsModule,
    BenefitEnrollmentModule,
    ScheduleModule,
    PublicDocumentsModule,
    PartsModule,
    UnbillableModule,
    VendorsModule,
    SecurityModule,
    CelayixShiftsModule,
    ReimbursementsModule,
    OpportunityModule,
    ContractApprovalTypesModule,
    AddressesModule
  }
})

export const modules = {
  user: getModule(UserModule, store),
  licenses: getModule(LicensesModule, store),
  general: getModule(GeneralModule, store),
  guardTerms: getModule(GuardTermsModule, store),
  employee: getModule(EmployeeModule, store),
  terminatedEmployees: getModule(TerminatedEmployeesModule, store),
  posting: getModule(PostingModule, store),
  tickets: getModule(TicketsModule, store),
  imports: getModule(ImportsModule, store),
  exports: getModule(ExportsModule, store),
  jobs: getModule(JobsModule, store),
  notes: getModule(NotesModule, store),
  employeeItems: getModule(EmployeeItemsModule, store),
  dashboard: getModule(DashboardModule, store),
  paychecks: getModule(PaychecksModule, store),
  incidents: getModule(IncidentsModule, store),
  candidate: getModule(CandidateModule, store),
  collections: getModule(CollectionsModule, store),
  articles: getModule(ArticlesModule, store),
  personalization: getModule(PersonalizationModule, store),
  tableHeadings: getModule(TableHeadingsModule, store),
  tableHeaderFilters: getModule(TableHeaderFilterModule, store),
  notifications: getModule(NotificationsModule, store),
  requisitions: getModule(RequisitionsModule, store),
  asset: getModule(AssetModule, store),
  patrolReports: getModule(PatrolReportsModule, store),
  textus: getModule(TextusModule, store),
  configurations: getModule(ConfigurationsModule, store),
  massMailings: getModule(MassMailingsModule, store),
  customers: getModule(CustomersModule, store),
  messages: getModule(MessagesModule, store),
  detachedPostings: getModule(DetachedPostingsModule, store),
  dropShip: getModule(DropShipModule, store),
  transactions: getModule(TransactionModule, store),
  serviceCalls: getModule(ServiceCallsModule, store),
  projects: getModule(ProjectsModule, store),
  onboarding: getModule(OnboardingModule, store),
  postInspections: getModule(PostInspectionsModule, store),
  benefitEnrollment: getModule(BenefitEnrollmentModule, store),
  schedule: getModule(ScheduleModule, store),
  publicDocuments: getModule(PublicDocumentsModule, store),
  parts: getModule(PartsModule, store),
  unbillable: getModule(UnbillableModule, store),
  vendors: getModule(VendorsModule, store),
  security: getModule(SecurityModule, store),
  celayixShifts: getModule(CelayixShiftsModule, store),
  reimbursements: getModule(ReimbursementsModule, store),
  opportunity: getModule(OpportunityModule, store),
  contractApprovalType: getModule(ContractApprovalTypesModule, store),
  addresses: getModule(AddressesModule, store)
}
