const detachedPostingsSidebarRoutesNames = {
  detachedPostingDetails: 'detachedPostingDetails'
}

const detachedPostingsSidebarRoutes = [
  {
    name: detachedPostingsSidebarRoutesNames.detachedPostingDetails,
    path: '/detached-posting-:id/details',
    meta: { group: 'LDH_CommandCenter_Detached_View' },
    component: () => import('./DetachedPostingsDetails.vue')
  }
]

export {
  detachedPostingsSidebarRoutesNames,
  detachedPostingsSidebarRoutes
}
