import { httpService } from '@/services'
import { ILicense, ILicenseForm, ILicenseStatusWorkflow } from '@/types'

class LicensesService {
  getLicenses (activeOnly: boolean): Promise<ILicense[]> {
    return httpService.get('/License/GetLicenses', { params: { activeOnly } })
  }

  getLicenseById (id: string): Promise<ILicense> {
    return httpService.get('/License/GetLicenseById', { params: { id } })
  }

  getLicensesByEmployeeId (id: number): Promise<{ licenses: ILicense[] }> {
    return httpService.get('/License/GetLicensesByEmployeeId', { params: { id } })
  }

  getLicenseStatusWorkflowById (id: number): Promise<ILicenseStatusWorkflow[]> {
    return httpService.get('/LicenseStatusType/GetLicenseStatusTypeWorkflowById', { params: { id } })
  }

  getLicensesActiveStatuses (): Promise<[]> {
    return httpService.get('/LicenseStatusType/GetActiveLicenseStatusTypes')
  }

  getLicensesActiveTypes (): Promise<ILicense[]> {
    return httpService.get('/LicenseType/GetActiveLicenseTypes')
  }

  createLicense (payload: any): Promise<''> {
    return httpService.post('/License/SaveLicense', payload)
  }

  updateLicense (payload: any): Promise<''> {
    return httpService.post('/License/UpdateLicense', payload)
  }

  removeLicense (id: number): Promise<''> {
    return httpService.post('/License/RemoveLicense', { id })
  }

  signLicense (signature: string, id: number): Promise<''> {
    return httpService.post('/License/SignLicense', { signature, id })
  }

  transferLicense (license: any): Promise<''> {
    return httpService.post('/License/TransferLicense', license)
  }

  generateLicensePayload (type: 'update' | 'create', licenseForm: ILicenseForm) {
    const payload = {
      ...(type === 'update' && { id: licenseForm.id }),
      employeeId: Number(licenseForm.employeeId),
      licenseNumber: licenseForm.licenseNumber,
      licenseStatusTypeId: licenseForm.licenseStatusTypeId,
      licenseTypeId: licenseForm.licenseTypeId,
      issueDate: licenseForm.issueDate,
      expiryDate: licenseForm.expiryDate,
      submittedDate: licenseForm.submittedDate,
      receivedDate: licenseForm.receivedDate,
      signedDate: licenseForm.signedDate,
      fingerPrintSubmittedDate: licenseForm.fingerPrintSubmittedDate,
      fingerPrintSubmissionId: licenseForm.fingerPrintSubmissionId,
      fbiSubmittedDate: licenseForm.fbiSubmittedDate,
      fbiSubmissionId: licenseForm.fbiSubmissionId
    }
    return Object.entries(payload).reduce((acc, [key, value]) => {
      return !value ? acc : { ...acc, [key]: value }
    }, {})
  }
}

export const licensesService = new LicensesService()
