import { httpService } from '@/services'
import { ICandidateInterviewsNotesEditing, IInterview } from '@/types'

class InterviewsService {
  private apiUrls = {
    interviewsByEmployeeId: '/Interview/GetInterviewsByEmployeeId',
    interviewById: '/Interview/GetInterviewDataById',
    interviewByCandidateId: '/Interview/GetInterviewDataByCandidateId',
    saveInterview: '/Interview/Save'
  }

  getInterviewsByEmployeeId (employeeId: number): Promise<IInterview[]> {
    return httpService.get(this.apiUrls.interviewsByEmployeeId, { params: { id: employeeId } })
  }

  saveInterview (candidateId: number, payload: ICandidateInterviewsNotesEditing): Promise<''> {
    return httpService.post(this.apiUrls.saveInterview, { ...payload, candidateId })
  }

  getInterviewById (interviewId: number): Promise<IInterview> {
    return httpService.get(this.apiUrls.interviewById, { params: { id: interviewId } })
  }
}

export const interviewsService = new InterviewsService()
