import { TNullableField } from '@/types'

export interface IPaycheck {
  branchName: TNullableField<string>;
  checkNumber: TNullableField<string>;
  comments: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeeId: number;
  id: number;
  modifiedBy: string;
  modified: string;
  payDate: string;
  obsolete: boolean;
  signature: TNullableField<string>;
  signedDate: TNullableField<string>;
  paycardIssued: false;
  paycheckStatusType: string;
  paycheckStatusTypeId: number | undefined;
  paycheckType: string | IPaycheckTypesType;
  paycheckTypeId: number | undefined;
}

export interface IPaycheckStatusTypesType {
  id: number;
  name: TNullableField<string>;
  displayName: TNullableField<string>;
  isDefault: boolean;
  isCompleted: boolean;
  isSigned: boolean;
  isReDeposited: boolean;
  obsolete: boolean;
}

export interface IPaycheckTypesType {
  id: number;
  name: TNullableField<string>;
  displayName: TNullableField<string>;
  isDefault: boolean;
  isSignable: boolean;
  obsolete: boolean;
}

export enum EPaycheckStatus {
  CREATED = 1,
  PICKED_UP = 3,
  RETURN_TO_PAY_ROLL = 4,
  SIGNED = 6,
}
