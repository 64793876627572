import { httpService } from '@/services'
import { IJob } from '@/types'

class JobsService {
  getJobs (allActive?: boolean): Promise<IJob[]> {
    return httpService.get(`/Job/GetAll${allActive ? 'Active' : ''}`)
  }

  getJobById (id: string): Promise<IJob> {
    return httpService.get('/Job/GetById', { params: { id } })
  }

  createJob (payload: IJob): Promise<''> {
    return httpService.post('/Job/Create', payload)
  }

  updateJob (payload: IJob): Promise<''> {
    return httpService.post('/Job/Update', payload)
  }

  toggleJob (id: number): Promise<''> {
    return httpService.post('/Job/Toggle', {}, { params: { id } })
  }

  getJobTypeParameterByJobType (jobTypeId: number): Promise<any> {
    return httpService.get('/JobTypeParameter/ByJobType', { params: { jobTypeId } })
  }
}

export const jobsService = new JobsService()
