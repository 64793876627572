import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { collectionsService, serviceCallsService } from '@/services'
import { uniqueFilter } from '@/helpers'
import { modules } from '@/store'
import {
  IServiceCall,
  IServiceCallIssueNoteType,
  IServiceCallStatusType,
  IServiceCallType
} from '@/types'

@Module({ name: 'ServiceCallsModule', namespaced: true })
export default class ServiceCallsModule extends VuexModule {
  loading = false
  serviceCalls: IServiceCall[] = []
  serviceCallTypes: IServiceCallType[] = []
  serviceCallStatusTypes: IServiceCallStatusType[] = []
  serviceCallIssueNoteTypes: IServiceCallIssueNoteType[] = []
  serviceCallAddress: any = {}

  addressLoading = false

  get mappedServiceCalls () {
    return this.serviceCalls.map(sc => ({
      ...sc,
      issues: sc.issues.map(i => ({ ...i, statusTypeId: sc.statusTypeId }))
    }))
  }

  /* M U T A T I O N S */
  @Mutation
  SET_LOADING (loadingState: boolean) {
    this.loading = loadingState
  }

  @Mutation
  SET_SERVICE_CALLS (payload: IServiceCall[]) {
    this.serviceCalls = payload
  }

  @Mutation
  SET_SERVICE_CALL_TYPES (payload: IServiceCallType[]) {
    this.serviceCallTypes = payload
  }

  @Mutation
  SET_SERVICE_CALL_STATUS_TYPES (payload: IServiceCallStatusType[]) {
    this.serviceCallStatusTypes = payload
  }

  @Mutation
  SET_SERVICE_CALL_ISSUE_NOTE_TYPES (payload: IServiceCallIssueNoteType[]) {
    this.serviceCallIssueNoteTypes = payload
  }

  @Mutation
  async SET_SERIVICE_CALL_ADDRESS (siteId: any) {
    this.addressLoading = true
    this.serviceCallAddress = {}
    const site = await modules.customers.getSiteById(siteId)
    if (site.address.id) this.serviceCallAddress = await modules.addresses.getAddressInformation(site.address.id)
    this.addressLoading = false
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_SERVICE_CALLS', rawError: true })
  async getServiceCalls (activeToggleState: boolean) {
    return await serviceCallsService.getAll(activeToggleState)
  }

  @Action({ commit: 'SET_SERVICE_CALL_TYPES', rawError: true })
  async getTypes () {
    return await collectionsService.getCollection('serviceCallTypes')
  }

  @Action({ commit: 'SET_SERVICE_CALL_STATUS_TYPES', rawError: true })
  async getStatusTypes () {
    return await collectionsService.getCollection('serviceCallStatusTypes')
  }

  @Action({ commit: 'SET_SERVICE_CALL_ISSUE_NOTE_TYPES', rawError: true })
  async getIssueNoteTypes () {
    return await collectionsService.getCollection('serviceCallIssueNoteTypes')
  }

  /* F I L T E R S */
  get uniqueServiceCallsStatuses () {
    return uniqueFilter(this.serviceCalls, 'statusTypeDisplayName')
  }
}
