const licenseSidebarRoutesNames = {
  licenseCreate: 'licenseCreate',
  licenseDetails: 'licenseDetails',
  licenseEdit: 'licenseEdit',
  licenseTransfer: 'licenseTransfer'
}

const licenseSidebarRoutes = [
  {
    name: licenseSidebarRoutesNames.licenseCreate,
    path: '/license-create',
    component: () => import('./LicenseCreate.vue'),
    meta: { group: 'LDH_License_Add' }
  },
  {
    name: licenseSidebarRoutesNames.licenseDetails,
    path: '/license-:id/details',
    component: () => import('./LicenseDetails.vue'),
    meta: { group: 'LDH_License_View' }
  },
  {
    name: licenseSidebarRoutesNames.licenseEdit,
    path: '/license-:id/edit',
    component: () => import('./LicenseEdit.vue'),
    meta: { group: 'LDH_License_Update' }
  },
  {
    name: licenseSidebarRoutesNames.licenseTransfer,
    path: '/license-:id/transfer',
    component: () => import('./LicenseTransfer.vue'),
    meta: { group: 'LDH_License_Transfer' }
  }
]

export {
  licenseSidebarRoutesNames,
  licenseSidebarRoutes
}
