import { assetsSidebarRouteNames, assetsSidebarRoutes } from './assets/sidebar/assets.sidebar.routes'
import { hrEnrollmentsSidebarRoutes, hrEnrollmentsSidebarRouteNames } from './benefit-enrollments/sidebar/benefit-enrollments.sidebar.routes'
import { collectionsSidebarRoutes } from './collections/collections.sidebar.routes'
import { configurationSidebarRoutes, configurationSidebarRouteNames } from './configuration/sidebar/configuration.sidebar.routes'
import { jobsSidebarRoutes, jobsSidebarRoutesNames } from './jobs/sidebars/jobs.sidebar.routes'
import { publicDocumentSidebarRoutes, publicDocumentSidebarRoutesNames } from '@/pages/maintenance/public-documents/sidebar/public-documents.sidebar.routes'
import { transactionsSidebarRoutes, transactionsSidebarRouteNames } from './transactions/sidebar/transaction.sidebar.routes'
import { securitySidebarRoutes, securitySidebarRoutesNames } from '@/pages/maintenance/security/sidebar/security.sidebar.routes'

const maintenanceSidebarRouteNames = {
  ...assetsSidebarRouteNames,
  ...hrEnrollmentsSidebarRouteNames,
  ...configurationSidebarRouteNames,
  ...jobsSidebarRoutesNames,
  ...publicDocumentSidebarRoutesNames,
  ...transactionsSidebarRouteNames,
  ...securitySidebarRoutesNames
}

const maintenanceSidebarRoutes = [
  ...assetsSidebarRoutes,
  ...hrEnrollmentsSidebarRoutes,
  ...collectionsSidebarRoutes,
  ...configurationSidebarRoutes,
  ...jobsSidebarRoutes,
  ...publicDocumentSidebarRoutes,
  ...transactionsSidebarRoutes,
  ...securitySidebarRoutes
]

export {
  maintenanceSidebarRouteNames,
  maintenanceSidebarRoutes
}
