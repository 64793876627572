import { httpService } from '@/services'

import {
  ICustomerUpdateIntegrationInformationPayload,
  ICustomerUpdateBillingInformationPayload,
  ICustomerDetailsForPostingModel,
  ICustomerUpdateDetailPayload,
  ICustomerBillingInformation,
  ICustomerContactForm,
  ICustomerInformation,
  ICustomerIntegration,
  ICustomerSearch,
  ICustomerPostModel,
  ICustomerSiteSearch,
  ICustomerFileData,
  IExistingCustomer,
  TCustomerContact,
  ICustomerCreate,
  ICustomer,
  IEmployee,
  IExistingCustomerSitesParams,
  ICustomerRelationshipStatus,
  ICustomerStatusType,
  IExistingCustomerSiteV2,
  ICustomerExistingContact,
  IFacilityType,
  IGuardUniform,
  ICustomerSiteModel,
  IUpdateCustomerSiteResponse,
  ICreateCustomerSiteResponse,
  IUpdateCustomerAddressResponse,
  ILeadCreate
} from '@/types'

class CustomerService {
  getCustomers (activeOnly: boolean): Promise<ICustomer[]> {
    return httpService.get('/Customer/GetAllCustomers', {
      params: { activeOnly }
    })
  }

  getCustomerInformation (id: string): Promise<ICustomerInformation> {
    return httpService.get('/Customer/GetInformationById', { params: { id } })
  }

  updateCustomer (payload: any): Promise<''> {
    return httpService.post('/Customer/UpdateInformation', payload)
  }

  updateCustomerAddress (payload: any): Promise<IUpdateCustomerAddressResponse> {
    return httpService.post('/Customer/UpdateAddress', payload)
  }

  updateCustomerDetails (payload: ICustomerUpdateDetailPayload): Promise<''> {
    return httpService.post('/Customer/UpdateCustomerDetails', payload)
  }

  updateBillingInformation (
    payload: ICustomerUpdateBillingInformationPayload
  ): Promise<''> {
    return httpService.post('/Customer/UpdateBillingInformation', payload)
  }

  updateIntegrationInformation (
    payload: ICustomerUpdateIntegrationInformationPayload
  ): Promise<''> {
    return httpService.post('/Customer/UpdateIntegrationInformation', payload)
  }

  saveCustomerContact (payload: ICustomerContactForm): Promise<''> {
    return httpService.post('/Customer/SaveCustomerContact', payload)
  }

  // updateCustomerContact (payload: ICustomerContactForm): Promise<''> {
  //   return httpService.post('/Customer/UpdateCustomerContact', payload)
  // }

  removeCustomerContact (id: number | string): Promise<''> {
    return httpService.post(
      '/Customer/RemoveCustomerContact',
      {},
      { params: { id } }
    )
  }

  getCustomerContactsByCustomerId (
    id: number
  ): Promise<{ contacts: TCustomerContact[] }> {
    return httpService.get('/Customer/GetCustomerContactsByCustomerId', {
      params: { id }
    })
  }

  toggleCustomer (id: number): Promise<''> {
    return httpService.post('/Customer/ToggleCustomer', {}, { params: { id } })
  }

  searchForCustomer (term: string): Promise<{ customers: ICustomerSearch[] }> {
    return httpService.get('/Customer/SearchForCustomer', { params: { term } })
  }

  search (
    term: string,
    V2?: boolean
  ): Promise<{ customers: ICustomerSiteSearch[] }> {
    return httpService.get(`/CustomerSite/Search${V2 ? 'V2' : ''}`, {
      params: { term }
    })
  }

  getEmployeesByCustomerSiteId (id: number): Promise<IEmployee[]> {
    return httpService.get('/CustomerSite/GetEmployeesByCustomerSiteId', {
      params: { id }
    })
  }

  getBranchToCustomerAssignment (
    id: number
  ): Promise<{ assignedCustomers: any[]; availableCustomers: any[] }> {
    return httpService.get('/Customer/GetBranchToCustomerAssignment', {
      params: { id }
    })
  }

  getCustomerSitesById (id: number): Promise<[]> {
    return httpService.get('/Customer/GetCustomerSitesById', { params: { id } })
  }

  getCustomerContactDetailsById (id: number): Promise<any> {
    return httpService.get('/Customer/GetCustomerContactDetailsById', {
      params: { id }
    })
  }

  getNetSuiteByCustomerId (id: number): Promise<any> {
    return httpService.get('/NetSuiteCustomer/GetById', { params: { id } })
  }

  getBillingInformation (id: number): Promise<ICustomerBillingInformation> {
    return httpService.get('/Customer/GetBillingInformation', {
      params: { id }
    })
  }

  getIntegrations (id: number): Promise<ICustomerIntegration> {
    return httpService.get('/Customer/GetIntegrations', { params: { id } })
  }

  leadCreate (payload: ILeadCreate): Promise<any> {
    return httpService.post('/Customer/CreateLead', payload)
  }

  customerCreate (payload: ICustomerCreate): Promise<any> {
    return httpService.post('/Customer/Create', payload)
  }

  /*  C U S T O M E R    C O M M E N T S  */
  getNotesByCustomerId (id: number): Promise<any> {
    return httpService.get('/CustomerComment/GetNotesByCustomerId', {
      params: { id }
    })
  }

  saveCustomerComment (payload: any): Promise<''> {
    return httpService.post('/CustomerComment/SaveCustomerComment', payload)
  }

  /* C U S T O M E R   D O C U M E N T */
  getCustomerDocumentById (id: number): Promise<ICustomerFileData> {
    return httpService.get('/CustomerDocument/GetById', { params: { id } })
  }

  getCustomerDocumentsByCustomerId (id: number): Promise<ICustomerFileData[]> {
    return httpService.get('/CustomerDocument/GetAllByCustomerId', {
      params: { id }
    })
  }

  uploadManyCustomerDocument (payload: {
    customerId: number;
    documents: ICustomerFileData;
  }): Promise<''> {
    return httpService.post('/CustomerDocument/UploadMany', payload)
  }

  removeCustomerDocument (id: number): Promise<''> {
    return httpService.post(
      '/CustomerDocument/RemoveCustomerDocument',
      {},
      { params: { id } }
    )
  }

  /* T A G S */
  assignTagToCustomer (tagId: number, id: number): Promise<''> {
    return httpService.post(
      '/Customer/AssignTagToCustomer',
      {},
      { params: { id, tagId } }
    )
  }

  removeTagFromCustomer (tagId: number, customerId: number): Promise<''> {
    return httpService.post(
      '/Customer/RemoveTag',
      {},
      { params: { tagId, customerId } }
    )
  }

  /* C U S T O M E R   P O S T   D E T A I L S */
  getCustomerPostById (id: number): Promise<ICustomerPostModel> {
    return httpService.get('/CustomerSite/GetById', { params: { id } })
  }

  getCustomerDetailsForPosting (
    id: number
  ): Promise<ICustomerDetailsForPostingModel[]> {
    return httpService.get('/CustomerSite/GetDetailsForPostingById', {
      params: { id }
    })
  }

  getCustomerPostsDetailsByCustomerId (customerId: number): Promise<any> {
    return httpService.get('/Customer/GetCustomerPostsDetailsByCustomerId', {
      params: { customerId }
    })
  }

  updateCustomerPost (payload: any): Promise<''> {
    return httpService.post('/CustomerSite/UpdateCustomerSite', payload)
  }

  customerPostSave (payload: any): Promise<''> {
    return httpService.post('/CustomerSite/Save', payload)
  }

  toggleCustomerPost (id: number): Promise<''> {
    return httpService.post('/CustomerSite/Toggle', {}, { params: { id } })
  }

  /* C U S T O M E R   S I T E   D E T A I L S */
  async getSitesForCustomer (customerId: number): Promise<ICustomerSiteModel[]> {
    return await httpService.get('/CustomerSiteV2/GetForCustomer', { params: { customerId } })
  }

  getOpportunitiesForCustomer (customerId: number): Promise<any[]> {
    return httpService.get('/Opportunity/GetOpportunitiesByCustomerId', { params: { customerId } })
  }

  customerSiteCreate (payload: ICustomerSiteModel): Promise<ICreateCustomerSiteResponse> {
    return httpService.post('/CustomerSiteV2/Create', payload)
  }

  toggleCustomerSite (id: number): Promise<''> {
    return httpService.post(
      '/CustomerSiteV2/ToggleCustomerSite',
      {},
      { params: { id } }
    )
  }

  updateCustomerSite (payload: ICustomerSiteModel): Promise<IUpdateCustomerSiteResponse> {
    return httpService.post('/CustomerSiteV2/Update', payload)
  }

  getCustomerSiteById (id: number): Promise<ICustomerSiteModel> {
    return httpService.get('/CustomerSiteV2/GetCustomerSiteById', { params: { id } })
  }

  getMinimumSiteByid (id: number): Promise<any> {
    return httpService.get('/CustomerSiteV2/GetCustomerSiteMinimumById', { params: { id } })
  }

  /* G U A R D   U N I F O R M */
  getGuardUniform (): Promise<IGuardUniform[]> {
    return httpService.get('/GuardUniform/GetGuardUniforms')
  }

  getExistingCustomers (params: IExistingCustomer): Promise<any> {
    return httpService.get('/Customer/NameOrAddress', { params })
  }

  getExistingCustomerSites (params: IExistingCustomerSitesParams): Promise<IExistingCustomerSiteV2[]> {
    return httpService.get('/CustomerSiteV2/Address', { params })
  }

  checkContactByEmail (email: string): Promise<ICustomerExistingContact> {
    return httpService.get('/Contact/Email', { params: { email } })
  }

  getCustomerRelationshipStatus (): Promise<ICustomerRelationshipStatus[]> {
    return httpService.get('/CustomerRelationshipStatus/GetAllActive')
  }

  getCustomerStatusTypes (): Promise<ICustomerStatusType[]> {
    return httpService.get('/CustomerStatusType/GetCustomerStatusTypes')
  }

  getFacilityTypes (): Promise<IFacilityType[]> {
    return httpService.get('/FacilityType/GetAllActive')
  }

  getCustomerContactsById (id: number): Promise<any> {
    return httpService.get('/Contact/GetContactById', { params: { id } })
  }

  updateCustomerContact (payload: any): Promise<any> {
    return httpService.post('/Contact/UpdateContact', payload)
  }

  getCustomerNames (): Promise<any> {
    return httpService.get('/Customer/GetCustomerNames')
  }

  getAllCustomerContactsByCustomerId (
    id: number
  ): Promise<{ contacts: TCustomerContact[] }> {
    return httpService.get('/Customer/GetAllCustomerContactsByCustomerId', {
      params: { id }
    })
  }

  getSingleCustomerContactByCustomerIdAndTableName (id: number, tableName: string): Promise<any> {
    return httpService.get('/Customer/GetSingleCustomerContactByCustomerIdAndTableName', { params: { id, tableName } })
  }
}

export const customerService = new CustomerService()
