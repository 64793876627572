import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { contractApprovalTypesService } from '@/services'

@Module({ name: 'ContractApprovalTypesModule', namespaced: true })
export default class ContractApprovalTypesModule extends VuexModule {
  contractApprovalTypes: readonly any[] = []
  contractApprovalTypeTable: any[] = []
  contractApprovalTypeLoading = false

  /* M U T A T I O N S */
  @Mutation
  SET_CONTRACT_APPROVAL_TYPE_LOADING (payload: boolean) {
    this.contractApprovalTypeLoading = payload
  }

  @Mutation
  SET_CONTRACT_APPROVAL_TYPE (payload: readonly any[]) {
    this.contractApprovalTypes = payload
  }

  @Mutation
  SET_CONTRACT_APPROVAL_TYPE_TABLE (payload: any[]) {
    this.contractApprovalTypeTable = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_CONTRACT_APPROVAL_TYPE', rawError: true })
  async getContractApprovalTypes () {
    return await contractApprovalTypesService.getContractApprovalTypes()
  }

  @Action({ commit: 'SET_CONTRACT_APPROVAL_TYPE_TABLE', rawError: true })
  async getContractApprovalTypeTable () {
    return await contractApprovalTypesService.getContractApprovalTypeTable()
  }
}
