import { httpService } from '@/services'
import { IPersonalizationDetails, TIndexedObject } from '@/types'

const existPromises: TIndexedObject<Promise<any>> = {}

class PersonalizationService {
  getHubPersonalizations (activeOnly = true): Promise<IPersonalizationDetails[]> {
    return httpService.get('/HubPersonalization/GetHubPersonalizations', { params: { activeOnly } })
  }

  deleteHubPersonalizations (id: number): Promise<''> {
    return httpService.post('/HubPersonalization/DeleteHubPersonalizationById', {}, { params: { id } })
  }

  getHubPersonalizationById (id: number): Promise<IPersonalizationDetails> {
    return httpService.get('/HubPersonalization/GetHubPersonalizationById', { params: { id } })
  }

  getHubPersonalizationByEmployeeId (id: number): Promise<IPersonalizationDetails[]> {
    return httpService.get('/HubPersonalization/GetHubPersonalizationByEmployeeId', { params: { id } })
  }

  async createNewHubPersonalization (name: string, displayName: string, value: string) {
    const promiseKey = '/HubPersonalization/CreateNewHubPersonalization' + displayName

    if ((existPromises as TIndexedObject)[promiseKey]) {
      return existPromises[promiseKey]
    } else {
      const request = httpService.post('/HubPersonalization/CreateNewHubPersonalization', { name, displayName, value })
      existPromises[promiseKey] = request
      await request
      delete existPromises[promiseKey]
      return request
    }
  }

  updateHubPersonalization (id: number, value: string) {
    return httpService.post('/HubPersonalization/UpdateHubPersonalization', { id, value })
  }
}

export const personalizationService = new PersonalizationService()
