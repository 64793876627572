import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { articlesService } from '@/services'
import { IKnowledgeBaseArticle } from '@/types'

@Module({ name: 'ArticlesModule', namespaced: true })
export default class ArticlesModule extends VuexModule {
  knowledgeBaseArticles: IKnowledgeBaseArticle[] = []
  loading = false

  // M U T A T I O N S
  @Mutation
  SET_LOADING (loading: boolean) {
    this.loading = loading
  }

  @Mutation
  SET_KNOWLEDGE_BASE_ARTICLES (payload: IKnowledgeBaseArticle[]) {
    this.knowledgeBaseArticles = payload
  }

  // A C T I O N S
  @Action({ commit: 'SET_KNOWLEDGE_BASE_ARTICLES', rawError: true })
  async getKnowledgeBaseArticles () {
    return await articlesService.getKnowledgeBaseArticles()
  }
}
