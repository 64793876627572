import { httpService } from '@/services'
import {
  IBenefitEnrollment,
  IBenefitEnrollmentItem,
  IBenefitEnrollmentPlanItem,
  IBenefitEnrollmentPlanCoverageItem,
  IBenefitEnrollmentPlanToEmployeeTypeItem,
  IBenefitEnrollmentPlanToEmploymentTypeItem,
  IBenefitEnrollmentDocumentItem,
  IBenefitEnrollmentCarrierItem
} from '@/types'

class BenefitEnrollmentService {
  /* B E N E F I T   E N R O L L M E N T */
  getBenefitEnrollments (): Promise<IBenefitEnrollment[]> {
    return httpService.get('/BenefitEnrollment/Get')
  }

  saveBenefitEnrollment (payload: IBenefitEnrollmentItem): Promise<''> {
    return httpService.post('/BenefitEnrollment/Save', payload)
  }

  updateBenefitEnrollment (payload: IBenefitEnrollmentItem): Promise<''> {
    return httpService.post('/BenefitEnrollment/Update', payload)
  }

  /* B E N E F I T   E N R O L L M E N T   P L A N */
  getBenefitEnrollmentPlansByIds (enrollmentYearId: number, carrierId: number): Promise<any[]> {
    return httpService.get('/BenefitEnrollmentPlan/GetAllByEnrollmentYearIdAndCarrierId', { params: { enrollmentYearId, carrierId } })
  }

  createBenefitEnrollmentPlan (payload: IBenefitEnrollmentPlanItem): Promise<any> {
    return httpService.post('/BenefitEnrollmentPlan/Create', payload)
  }

  deleteBenefitEnrollmentPlan (id: number): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlan/Delete', {}, { params: { id } })
  }

  /* B E N E F I T   E N R O L L M E N T   P L A N   C O V E R A G E */
  getBenefitEnrollmentPlanCoveragesById (enrollmentPlanId: number): Promise<any[]> {
    return httpService.get('/BenefitEnrollmentPlanCoverage/GetAllByEnrollmentPlanId', { params: { enrollmentPlanId } })
  }

  createBenefitEnrollmentPlanCoverage (payload: IBenefitEnrollmentPlanCoverageItem): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanCoverage/Create', payload)
  }

  updateBenefitEnrollmentPlanCoverage (payload: IBenefitEnrollmentPlanCoverageItem): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanCoverage/Update', payload)
  }

  deleteBenefitEnrollmentPlanCoverage (enrollmentPlanId: number): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanCoverage/Delete', {}, { params: { enrollmentPlanId } })
  }

  /* B E N E F I T   E N R O L L M E N T   E M P L O Y E E   T Y P E */
  createBenefitEnrollmentPlanToEmployeeType (payload: IBenefitEnrollmentPlanToEmployeeTypeItem[]): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanToEmployeeType/Create', payload)
  }

  deleteBenefitEnrollmentPlanToEmployeeType (enrollmentPlanId: number): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanToEmployeeType/Delete', {}, { params: { enrollmentPlanId } })
  }

  /* B E N E F I T   E N R O L L M E N T   E M P L O Y M E N T   T Y P E */
  createBenefitEnrollmentPlanToEmploymentType (payload: IBenefitEnrollmentPlanToEmploymentTypeItem[]): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanToEmploymentType/Create', payload)
  }

  deleteBenefitEnrollmentPlanToEmploymentType (enrollmentPlanId: number): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanToEmploymentType/Delete', {}, { params: { enrollmentPlanId } })
  }

  /* B E N E F I T   E N R O L L M E N T   D O C U M E N T */
  getBenefitEnrollmentDocumentsByEnrollmentId (enrollmentId: number): Promise<any[]> {
    return httpService.get('/BenefitEnrollmentDocument/GetByEnrollmentId', { params: { enrollmentId } })
  }

  getBenefitEnrollmentDocumentById (id: number): Promise<any> {
    return httpService.get('/BenefitEnrollmentDocument/GetById', { params: { id } })
  }

  saveBenefitEnrollmentDocument (payload: IBenefitEnrollmentDocumentItem): Promise<''> {
    return httpService.post('/BenefitEnrollmentDocument/Save', payload)
  }

  removeBenefitEnrollmentDocument (id: number): Promise<''> {
    return httpService.post('/BenefitEnrollmentDocument/Remove', {}, { params: { id } })
  }

  /* B E N E F I T   E N R O L L M E N T   P L A N   T O   E M P L O Y M E N T   T Y P E */
  saveBenefitEnrollmentPlanToEmploymentType (payload: IBenefitEnrollmentPlanToEmploymentTypeItem): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanToEmploymentType/Save', payload)
  }

  removeBenefitEnrollmentPlanToEmploymentType (id: number): Promise<''> {
    return httpService.post('/BenefitEnrollmentPlanToEmploymentType/Remove', {}, { params: { id } })
  }

  /* B E N E F I T   E N R O L L M E N T   C A R R I E R */
  getAllBenefitEnrollmentCarrierByEnrollmentYearId (enrollmentYearId: number): Promise<any> {
    return httpService.get('/BenefitEnrollmentCarrier/GetAllByEnrollmentYearId', { params: { enrollmentYearId } })
  }

  createBenefitEnrollmentCarrier (payload: IBenefitEnrollmentCarrierItem[]): Promise<''> {
    return httpService.post('/BenefitEnrollmentCarrier/Create', payload)
  }

  deleteBenefitEnrollmentCarrier (payload: number[]): Promise<''> {
    return httpService.post('/BenefitEnrollmentCarrier/Delete', payload)
  }
}

export const benefitEnrollmentService = new BenefitEnrollmentService()
