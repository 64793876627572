const addressesSidebarRoutesNames = {
  addressCreate: 'addressCreate',
  addressDetails: 'addressDetails',
  addressEdit: 'addressEdit',
  addressAssociatedEntities: 'addressAssociatedEntities'
}

const addressesSidebarRoutes = [
  {
    name: addressesSidebarRoutesNames.addressCreate,
    path: '/address-create',
    component: () => import('./address-create/AddressCreate.vue'),
    meta: { group: 'HUB_ClientServices_Addresses_Update' }
  },
  {
    name: addressesSidebarRoutesNames.addressDetails,
    path: '/address-:addressId',
    redirect: { name: addressesSidebarRoutesNames.addressEdit },
    component: () => import('./address-details/AddressDetails.vue'),
    meta: { group: 'HUB_ClientServices_Addresses_View' },
    children: [
      {
        name: addressesSidebarRoutesNames.addressEdit,
        path: 'address-edit',
        component: () =>
          import(
            './address-details/address-edit/AddressEdit.vue'
          ),
        meta: { group: 'HUB_ClientServices_Addresses_Update' }
      },
      {
        name: addressesSidebarRoutesNames.addressAssociatedEntities,
        path: 'associated-entities',
        component: () =>
          import(
            './address-details/associated-entities/AssociatedEntities.vue'
          ),
        meta: { group: 'HUB_ClientServices_Addresses_View' }
      }
    ]
  }
]

export { addressesSidebarRoutesNames, addressesSidebarRoutes }
