

























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { modules } from '@/store'
import { authService } from '@/services'
import { EPersonalizationSettings } from '@/types'
import { throttle } from '@/helpers'
import { routeNames } from '@/router/routeNames'

import MobileMenu from '@/components/shared/menu/MobileMenu.vue'
import MenuList from '@/components/shared/menu/MenuList.vue'
import Icon from '@/components/shared/Icon.vue'
import CancelSave from '@/components/shared/CancelSave.vue'
import GlobalSearchModal from '@/components/GlobalSearchModal.vue'
import UpdatedGroupsModal from '@/components/UpdatedGroupsModal.vue'

@Component({
  name: 'DefaultLayout',
  components: {
    UpdatedGroupsModal,
    GlobalSearchModal,
    CancelSave,
    MobileMenu,
    MenuList,
    Icon
  }
})
export default class DefaultLayout extends Vue {
  $refs!: {
    menuList: MenuList & {
      openActiveRouteCollapse: () => void;
      closeOnLeave: () => void;
    };
  }

  /* D A T A */
  showRedirectPrompt = false

  /* C O M P U T E D */
  get guiVersion () {
    return modules.general.guiVersion
  }

  get apiVersion () {
    return modules.general.apiVersion
  }

  get appLoading () {
    return modules.general.loading
  }

  get menuCollapsed () {
    return modules.general.menuCollapsed
  }

  get lastUrl () {
    const personalization = modules.personalization.indexedPersonalizations[EPersonalizationSettings.LAST_ROUTE]
    return personalization && personalization.value
  }

  get lastUrlIsSame () {
    return this.resolvedPath && window.location.href
      .replace(/[#/]/g, '')
      .includes(this.resolvedPath.replace(/[#/]/g, ''))
  }

  get path () {
    const lastUrl = this.lastUrl ? this.lastUrl.split('//')[1].split('/').slice(1).join('/') : ''
    return '/' + lastUrl.replace(/"/g, '')
  }

  get appRouterResolve () {
    return this.$appRouter.resolve(this.path)
  }

  get resolvedPath () {
    if (!this.lastUrl) return false

    const path = '/' + this.lastUrl
      .split('//')[1]
      .split('/')
      .slice(1)
      .join('/')
      .slice(0, -1)

    const resolvedPath = this.$appRouter.resolve(path)
    const { name, meta } = resolvedPath.route

    if (meta) {
      return !(meta.notFound || meta.accessDenied || name === routeNames.login) && path
    }
  }

  get resolvedRouteName () {
    if (!this.resolvedPath) return false
    const { route } = this.$appRouter.resolve(this.resolvedPath)
    let routeName = route.name

    const hash = this.resolvedPath.split('#')[1]
    if (hash) {
      const resolvedSidebar = this.$sidebar.resolve(hash)
      if (resolvedSidebar.route.name) {
        routeName = routeName + ' & ' + resolvedSidebar.route.name
      }
      const params = Object.values(resolvedSidebar.route.params).join(', ')
      if (params) {
        routeName = routeName + ` (${params})`
      }
    }

    return routeName
  }

  /* M E T H O D S */
  toggleMenu () {
    modules.general.toggleMenu()
  }

  closeOnLeave () {
    this.$refs.menuList.closeOnLeave()
  }

  openActiveRouteCollapse () {
    this.$refs.menuList.openActiveRouteCollapse()
  }

  onLogoClick () {
    if (this.$sidebarRoute.path !== '/') {
      this.$sidebar.push('/')
    }
    if (this.$appRoute.name !== routeNames.dashboard) {
      this.$appRouter.push({ name: routeNames.dashboard })
    }
  }

  redirectToLastUrl () {
    if (this.resolvedPath) {
      this.$appRouter.push(this.resolvedPath)
      const hash = this.resolvedPath.split('#')[1]
      this.$sidebar.push(hash || '/')
    } else {
      history.replaceState(null, document.title, this.lastUrl)
    }
    this.showRedirectPrompt = false
  }

  @throttle()
  saveLastRoute () {
    modules.personalization.update({ setting: EPersonalizationSettings.LAST_ROUTE, value: window.location.href })
  }

  /* HOOKS */
  async created () {
    if (!modules.user.userProfileLoaded) {
      await modules.user.getUserProfile()
    }
    if (modules.personalization.personalizations.length <= 0) {
      await modules.personalization.fetch()
      await modules.personalization.applyPersonalizations()
    }
    const { group } = this.appRouterResolve.resolved.meta as any
    this.showRedirectPrompt = !!this.lastUrl && !this.lastUrlIsSame && authService.isInGroup(group)
  }

  /* WATCHERS */
  @Watch('$route.fullPath')
  onRouterChange () {
    this.saveLastRoute()
    this.showRedirectPrompt = false
  }

  @Watch('$sidebarRoute.fullPath')
  onSidebarRouterChange () {
    this.saveLastRoute()
    this.showRedirectPrompt = false
  }
}
