import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { reimbursementService } from '@/services'

import {
  TIndexedObject,
  IReimbursement
} from '@/types'

@Module({ name: 'ReimbursementsModule', namespaced: true })
export default class ReimbursementsModule extends VuexModule {
  reimbursementsLoading = false
  reimbursements: readonly IReimbursement[] = []

  /* C O M P U T E D */
  get indexedReimbursements () {
    return this.reimbursements.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {} as TIndexedObject<IReimbursement>)
  }

  /* M U T A T I O N S   P O S T I N G */
  @Mutation
  SET_REIMBURSEMENTS_LOADING (payload: boolean) {
    this.reimbursementsLoading = payload
  }

  @Mutation
  SET_REIMBURSEMENTS (payload: readonly IReimbursement[]) {
    this.reimbursements = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_REIMBURSEMENTS', rawError: true })
  async getReimbursements (activeToggleState: boolean) {
    return await reimbursementService.getReimbursements(activeToggleState)
  }
}
