import { collectionsGeneral } from './collections-general.config'
import { collectionsBenefit } from './collections-benefit.config'
import { collectionsCandidate } from './collections-candidate.config'
import { collectionsCustomer } from './collections-customer.config'
import { collectionsDropShip } from './collections-drop-ship.config'
import { collectionsEmployee } from './collections-employee.config'
import { collectionsEngagement } from './collections-engagement.config'
import { collectionsGuard } from './collections-guard.config'
import { collectionsHistorical } from './collections-historical.config'
import { collectionsImportantDate } from './collections-important-date.config'
import { collectionsIncident } from './collections-incident.config'
import { collectionsJob } from './collections-job.config'
import { collectionsLicense } from './collections-license.config'
import { collectionsPart } from './collections-part.config'
import { collectionsPaycheck } from './collections-paycheck.config'
import { collectionsPayment } from './collections-payment.config'
import { collectionsPostInspection } from './collections-post-inspection.config'
import { collectionsReimbursements } from './collections-reimbursements.config'
import { collectionsService } from './collections-service.config'
import { collectionsVendor } from './collections-vendor.config'
import { collectionsTicket } from './collections-ticket.config'
import { collectionsUniformTypes } from './collections-uniform-types.config'

export const config = [
  ...collectionsGeneral,
  ...collectionsBenefit,
  ...collectionsCandidate,
  ...collectionsCustomer,
  ...collectionsDropShip,
  ...collectionsEmployee,
  ...collectionsEngagement,
  ...collectionsGuard,
  ...collectionsHistorical,
  ...collectionsImportantDate,
  ...collectionsIncident,
  ...collectionsJob,
  ...collectionsLicense,
  ...collectionsPart,
  ...collectionsPaycheck,
  ...collectionsPayment,
  ...collectionsPostInspection,
  ...collectionsReimbursements,
  ...collectionsService,
  ...collectionsVendor,
  ...collectionsTicket,
  ...collectionsUniformTypes
]
