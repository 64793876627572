import { TIndexedObject } from '@/types'

export const filteredItems = (
  items: any,
  filters: any,
  fn?: (item: any, filterValue: any, key: string) => boolean
) => {
  const appliedFilters = Object.keys(filters).filter(filterKey => {
    const filterValue = filters[filterKey]
    return Array.isArray(filterValue) ? filterValue.length > 0 : (filterValue !== null && filterValue !== '')
  })

  if (appliedFilters.length === 0) return items

  return items.filter((item: TIndexedObject) => {
    return appliedFilters.every(key => {
      if (key.includes('Exclude')) return true

      const filterValue = filters[key]
      if (key.includes('From')) {
        const splitKey = key.split('From')[0]
        // costFrom is a number filter
        if (splitKey.toLowerCase().includes('cost')) {
          return (item[splitKey] >= filterValue || !filterValue)
        }
        // Assuming that anything that isn't "costFrom" is going to be a date filter
        return (new Date(item[splitKey]).getTime() >= new Date(filterValue).getTime() || !filterValue)
      }
      if (key.includes('To')) {
        const splitKey = key.split('To')[0]
        // costTo is a number filter
        if (splitKey.toLowerCase().includes('cost')) {
          return (item[splitKey] <= filterValue || !filterValue)
        }
        // Assuming that anything that isn't "costTo" is going to be a date filter
        return (new Date(item[splitKey]).getTime() <= new Date(filterValue).getTime() || !filterValue)
      }

      const excludeExist = appliedFilters.includes(`${key}Exclude`)

      fn && fn(item, filterValue, key)

      if (Array.isArray(filterValue)) {
        if (excludeExist) {
          if (filters[`${key}Exclude`]) {
            return !filterValue.includes(item[key])
          } else {
            return filterValue.includes(item[key])
          }
        } else {
          return filterValue.includes(item[key])
        }
      }

      if (excludeExist) {
        if (filters[`${key}Exclude`]) {
          return filterValue !== item[key]
        } else {
          return filterValue === item[key]
        }
      }

      return filterValue === item[key]
    })
  })
}

export const editedFiltersCount = (filters: any, editedFilters: any) => {
  return Object.keys(filters).filter(filterKey => {
    if (filterKey.includes('Exclude')) return false

    const filterValue = editedFilters[filterKey]
    return Array.isArray(filterValue) ? filterValue.length > 0 : (filterValue !== null && filterValue !== '')
  }).length
}

export const nonAppliedFiltersCount = (filters: any, editedFilters: any) => {
  return Object.keys(filters).filter(key => editedFilters[key] !== filters[key]).length
}

export const uniqueFilter = (list: any, field: string | string[]) => {
  let result = []
  if (typeof field === 'string') result = list.map((i: any) => i[field])
  else result = list.map((i: any) => `${i[field[0]]} ${i[field[1]]}`)

  return [...new Set(result)].filter((i: any) => i && i !== '-').sort()
}

export const uniqueHeaderFilter = (list: any) => {
  return [...new Set<any>(list)].filter((i: any) => i && i !== '-')
}

export const filterNumberRange = (items: any[], filter: any, headerName: string, excludeSelected: boolean) => {
  const to = filter.numberTo === '' ? '' : Number(filter.numberTo)
  const from = filter.numberFrom === '' ? '' : Number(filter.numberFrom)
  if (from !== '' && to === '') {
    items = items.filter((item: any) => excludeSelected ? item[headerName] < from : item[headerName] >= from)
  } else if (from === '' && to !== '') {
    items = items.filter((item: any) => excludeSelected ? item[headerName] > to : item[headerName] <= to)
  } else if (from !== '' && to !== '') {
    items = items
      .filter((item: any) => excludeSelected
        ? item[headerName] < from || item[headerName] > to
        : item[headerName] >= from && item[headerName] <= to)
  }

  return items
}

export const filterDateRange = (items: any[], filter: any, headerName: string, excludeSelected: boolean) => {
  const to = filter.dateTo === '' ? '' : new Date(filter.dateTo)
  const from = filter.dateFrom === '' ? '' : new Date(filter.dateFrom)
  if (from !== '' && to === '') {
    items = items.filter((item: any) => excludeSelected ? new Date(item[headerName]) < from
      : new Date(item[headerName]) >= from)
  } else if (from === '' && to !== '') {
    items = items.filter((item: any) => excludeSelected ? new Date(item[headerName]) > to
      : new Date(item[headerName]) <= to)
  } else if (from !== '' && to !== '') {
    items = items
      .filter((item: any) => excludeSelected
        ? new Date(item[headerName]) < from || new Date(item[headerName]) > to
        : new Date(item[headerName]) >= from && new Date(item[headerName]) <= to)
  }

  return items
}

export const filterStringRange = (items: any[], filter: any, headerName: string, excludeSelected: boolean) => {
  let filteredItems: any[] = []

  if (excludeSelected) {
    filteredItems = items.filter((item) => {
      return filter.values.indexOf(item[headerName]) === -1
    })
    return filteredItems
  } else {
    filteredItems = items.filter((item) => {
      return filter.values.indexOf(item[headerName]) > -1
    })

    return filteredItems
  }
}
