import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { modules } from '@/store'
import { alertsService } from '@/services'
import { IAlertChannel, IAlertQueueItem, IAlertType } from '@/types'

@Module({ name: 'NotificationsModule', namespaced: true })
export default class NotificationsModule extends VuexModule {
  notifications: IAlertQueueItem[] = []
  notificationsChannels: IAlertChannel[] = []
  notificationsTypes: IAlertType[] = []

  get unreadNotificationsCount () {
    return this.notifications.filter(n => !n.isRead).length
  }

  /* M U T A T I O N S */
  @Mutation
  SET_NOTIFICATIONS_QUEUE (notifications: IAlertQueueItem[]) {
    this.notifications = notifications
  }

  @Mutation
  ADD_NOTIFICATION (notification: IAlertQueueItem) {
    this.notifications.unshift(notification)
  }

  @Mutation
  SET_NOTIFICATIONS_CHANNELS (channels: IAlertChannel[]) {
    this.notificationsChannels = channels
  }

  @Mutation
  SET_NOTIFICATIONS_TYPES (types: IAlertType[]) {
    this.notificationsTypes = types
  }

  @Mutation
  SET_READ ({ alertId, isRead }: { alertId: number; isRead: boolean }) {
    const alertIndex = this.notifications.findIndex(alert => alert.id === alertId)
    Vue.set(this.notifications[alertIndex], 'isRead', isRead)
  }

  @Mutation
  SET_READ_ALL (isRead: boolean) {
    this.notifications = this.notifications.map(n => ({ ...n, isRead }))
  }

  @Action({ rawError: true })
  async readAll (isRead: boolean) {
    const employeeId = modules.user.userProfile.id
    await alertsService.updateAllIsReadFlag(isRead, Number(employeeId))
    this.SET_READ_ALL(isRead)
  }

  @Action({ rawError: true })
  async dismissAll () {
    const employeeId = Number(modules.user.userProfile.id)
    await alertsService.deleteAllByEmployeeId(employeeId)
    this.SET_NOTIFICATIONS_QUEUE([])
  }
}
