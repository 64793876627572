const celayixShiftsSidebarRoutesNames = {
  celayixShiftsShow: 'CelayixShiftsShow',
  celayixShiftsDetails: 'CelayixShiftsDetails'
}

const celayixShiftsSidebarRoutes = [
  {
    name: celayixShiftsSidebarRoutesNames.celayixShiftsShow,
    path: '/celayix-shifts-show',
    meta: { group: 'LDH_Reports_Shifts_View' },
    component: () => import('./CelayixShiftsShow.vue')
  }
]

export {
  celayixShiftsSidebarRoutesNames,
  celayixShiftsSidebarRoutes
}
