import { httpService } from '@/services'

import {
  IIncidentAssignment,
  IIncidentDocument,
  IIncidentExtended,
  IIncidentResponse,
  IIncidentShort,
  IIncidentInvolvedParty,
  IShortTicket
} from '@/types'

class IncidentResponseService {
  createResponse (payload: any) {
    return httpService.post('/IncidentResponse/SaveIncidentResponse', payload)
  }

  getById (id: number) {
    return httpService.get<IIncidentResponse>('/IncidentResponse/GetIncidentResponseById', { params: { id } })
  }
}

class IncidentAssignmentsService {
  getByIncidentId (incidentId: number) {
    return httpService.get<IIncidentAssignment[]>('/IncidentAssignment/GetIncidentAssignmentsByIncidentId', {
      params: { incidentId }
    })
  }

  assignIncident (incidentId: number, employeeId: number) {
    return httpService.post('/IncidentAssignment/SaveIncidentAssignment', {
      employeeId,
      incidentId
    } as any)
  }

  deassignIncident (id: number) {
    return httpService.post('/IncidentAssignment/DeleteIncidentAssignment', {}, { params: { id } })
  }
}

class IncidentPartiesService {
  saveIncidentInvolvedParty (payload: any) {
    return httpService.post('/IncidentInvolvedParty/SaveIncidentInvolvedParty', payload)
  }

  updateIncidentInvolvedParty (payload: IIncidentInvolvedParty) {
    return httpService.post('/IncidentInvolvedParty/UpdateIncidentInvolvedParty', payload)
  }

  deleteIncidentInvolvedParty (id: number) {
    return httpService.post('/IncidentInvolvedParty/DeleteIncidentInvolvedParty', {}, { params: { id } })
  }
}

class IncidentDocumentsService {
  private apiUrls = {
    getIncidentDocumentById: '/IncidentDocument/GetIncidentDocumentById',
    getIncidentDocumentsByIncidentId: '/IncidentDocument/GetIncidentDocumentsByIncidentId'
  }

  add (payload: any) {
    return httpService.post('/IncidentDocument/SaveIncidentDocument', payload)
  }

  delete (id: number) {
    return httpService.post('/IncidentDocument/DeleteIncidentDocument', {}, { params: { id } })
  }

  getById (id: number) {
    return httpService.get<IIncidentDocument>(this.apiUrls.getIncidentDocumentById, { params: { id } })
  }

  getByIncident (incidentId: number) {
    return httpService.get<IIncidentDocument[]>(this.apiUrls.getIncidentDocumentById, { params: { incidentId } })
  }
}

class IncidentsService {
  responses = new IncidentResponseService()
  assignments = new IncidentAssignmentsService()
  parties = new IncidentPartiesService()
  documents = new IncidentDocumentsService()

  create (payload: any) {
    return httpService.post('/Incident/CreateNewIncident', payload)
  }

  update (payload: any) {
    return httpService.post<IIncidentExtended>('/Incident/UpdateIncident', payload)
  }

  delete (id: number) {
    return httpService.post('/Incident/DeleteIncident', {}, { params: { id } })
  }

  complete (id: number) {
    return httpService.post('/Incident/MarkIncidentComplete', {}, { params: { id } })
  }

  getAll () {
    return httpService.get<IIncidentExtended[]>('/Incident/GetIncidents')
  }

  getFromView () {
    return httpService.get<IIncidentShort[]>('/Incident/GetIncidentsFromView')
  }

  getById (id: number) {
    return httpService.get<IIncidentExtended>('/Incident/GetIncidentById', { params: { id } })
  }

  getTicketsToIncidentById (id: number) {
    return httpService.get<IShortTicket[]>('/TicketToIncident/GetTicketsToIncidentById', {
      params: { id }
    })
  }

  saveTicketToIncident (incidentId: number, ticketId: number): Promise<''> {
    return httpService.post('/TicketToIncident/SaveTicketToIncident', { incidentId, ticketId })
  }

  deleteTicketToIncident (id: number): Promise<''> {
    return httpService.post('/TicketToIncident/DeleteTicketToIncident', {}, { params: { id } })
  }
}

export const incidentsService = new IncidentsService()
