import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { detachedPostingsService } from '@/services'
import { TIndexedObject, IPostingDetached } from '@/types'

@Module({ name: 'DetachedPostingsModule', namespaced: true })
export default class DetachedPostingsModule extends VuexModule {
  postingDetached: readonly IPostingDetached[] = []

  /* C O M P U T E D */
  get indexedPostingDetached () {
    return this.postingDetached.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {} as TIndexedObject<IPostingDetached>)
  }

  /* M U T A T I O N S */
  @Mutation
  SET_DETACHED_POSTINGS (payload: readonly IPostingDetached[]) {
    this.postingDetached = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_DETACHED_POSTINGS', rawError: true })
  async getPostingDetached () {
    return await detachedPostingsService.getPostingDetached()
  }
}
