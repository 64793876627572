import { httpService } from '@/services'
import { IMassMailing, IMassMailingById, IMassMailingPayload } from '@/types'

class MassMailingsService {
  getMassMailings (): Promise<IMassMailing[]> {
    return httpService.get('/Email/GetEmailMassMailings')
  }

  getMassEmailById (id: string): Promise<IMassMailingById> {
    return httpService.get('/Email/GetMassEmailById', { params: { id } })
  }

  getMassMailingTemplate (): Promise<{ template: string}> {
    return httpService.get('/Email/GetMassMailingTemplate')
  }

  saveMassMailingDraft (payload: IMassMailingPayload): Promise<''> {
    return httpService.post('/Email/SaveMassEmailDraft', payload)
  }

  sendMassMailing (payload: IMassMailingPayload): Promise<''> {
    return httpService.post('/Email/SendMassEmail', payload)
  }
}

export const massMailingsService = new MassMailingsService()
