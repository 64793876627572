import { authRoutes, authRoutesNames } from '@/pages/auth/auth.routes'
import { dashboardRoutes } from '@/pages/dashboard/dashboard.routes'
import { clientServicesRoutes } from '@/pages/client-services/client-services.routes'
import { commandCenterRoutes } from '@/pages/command-center/command-center.routes'
import { knowledgeBaseRoutes } from '@/pages/knowledge-base/knowledge-base.routes'
import { humanResourcesRoutes } from '@/pages/human-resources/human-resources.routes'
import { maintenanceRoutes } from '@/pages/maintenance/maintenance.routes'
import { reportsRoutes } from '@/pages/reports/reports.routes'
import { ticketsIncidentsRoutes } from '@/pages/tickets-incidents/tickets-incidents.routes'
import { salesRoutes } from '@/pages/sales/sales.routes'
import { techOpsRoutes } from '@/pages/tech-ops/tech-ops.routes'
import { INavChild, INavigationTypes } from '@/types'
import { parentRoutesNames } from '@/router/parentRouteNames'

import AuxLayout from '@/layouts/AuxLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export const routes = [
  {
    path: '/',
    redirect: { name: authRoutesNames.login }
  },
  {
    path: '*',
    redirect: { name: authRoutesNames['404'] }
  },
  {
    path: '/auth',
    redirect: { name: authRoutesNames.login },
    meta: { requireAuth: false },
    component: AuthLayout,
    children: authRoutes
  },
  {
    path: '/',
    meta: { requireAuth: true },
    component: DefaultLayout,
    children: [
      {
        path: '',
        meta: {
          single: true,
          requireAuth: true,
          label: 'Dashboard',
          icon: 'icon-layout',
          excludeFromNavigation: true
        },
        component: AuxLayout,
        children: dashboardRoutes
      },
      {
        name: parentRoutesNames.clientServices,
        path: 'client-services',
        meta: {
          requireAuth: true,
          icon: 'icon-briefcase',
          group: 'LDH_Nav_Client_Services_View',
          isParent: true,
          label: 'Client Services'
        },
        component: AuxLayout,
        children: clientServicesRoutes
      },
      {
        name: parentRoutesNames.commandCenter,
        path: 'command-center',
        meta: {
          requireAuth: true,
          icon: 'icon-monitor',
          group: 'LDH_Nav_Command_Center_View',
          isParent: true,
          label: 'Command Center'
        },
        component: AuxLayout,
        children: commandCenterRoutes
      },
      {
        name: parentRoutesNames.humanResources,
        path: 'human-resources',
        meta: {
          requireAuth: true,
          icon: 'icon-people',
          group: 'LDH_Nav_Human_Resources_View',
          isParent: true,
          label: 'Human Resources'
        },
        component: AuxLayout,
        children: humanResourcesRoutes
      },
      {
        name: parentRoutesNames.knowledgeBase,
        path: 'knowledge-base',
        meta: {
          requireAuth: true,
          icon: 'icon-book-open',
          group: 'LDH_Nav_Knowledge_Base_View',
          isParent: true,
          label: 'Knowledge Base'
        },
        component: AuxLayout,
        children: knowledgeBaseRoutes
      },
      {
        name: parentRoutesNames.maintenance,
        path: 'maintenance',
        meta: {
          requireAuth: true,
          icon: 'icon-settings',
          group: 'LDH_Nav_Maintenance_View',
          isParent: true,
          label: 'Maintenance'
        },
        component: AuxLayout,
        children: maintenanceRoutes
      },
      {
        name: parentRoutesNames.reports,
        path: 'reports',
        meta: {
          requireAuth: true,
          icon: 'icon-file-text',
          group: 'LDH_Nav_Reports_View',
          isParent: true,
          label: 'Reports'
        },
        component: AuxLayout,
        children: reportsRoutes
      },
      {
        name: parentRoutesNames.sales,
        path: 'sales',
        meta: {
          requireAuth: true,
          icon: 'icon-copy',
          group: 'LDH_Nav_Tech_Ops_View',
          isParent: true,
          label: 'Sales'
        },
        component: AuxLayout,
        children: salesRoutes
      },
      {
        name: parentRoutesNames.techOps,
        path: 'tech-ops',
        meta: {
          requireAuth: true,
          icon: 'icon-calendar',
          group: 'LDH_Nav_Tech_Ops_View',
          isParent: true,
          label: 'Tech Ops'
        },
        component: AuxLayout,
        children: techOpsRoutes
      },
      {
        name: parentRoutesNames.ticketsIncidents,
        path: 'tickets-incidents',
        meta: {
          requireAuth: true,
          icon: 'icon-alert-circle',
          group: 'LDH_Nav_Tickets_&_Incidents_View',
          isParent: true,
          label: 'Tickets & Incidents'
        },
        component: AuxLayout,
        // preparing to structure refactor
        children: ticketsIncidentsRoutes
      }
    ]
  }
]

// Maps routes and generates items for mobile menu
export const navigation: INavigationTypes[] = (routes[3].children as any[]).map(parent => {
  return {
    name: parent.name,
    label: parent.meta.label,
    ...(parent.meta.icon && { icon: parent.meta.icon }),
    group: parent.meta.group,
    excludeFromNavigation: parent.meta.excludeFromNavigation === undefined ? false : parent.meta.excludeFromNavigation,
    isParent: true,
    children: parent.children.reduce((acc: any, cur: any) => {
      acc.push({
        excludeFromNavigation: cur.meta.excludeFromNavigation === undefined ? false : cur.meta.excludeFromNavigation,
        name: cur.name || cur.children[0].name,
        label: cur.meta.label,
        group: cur.meta.group,
        showOnMobile: cur.meta.showOnMobile
      } as INavChild)
      return acc
    }, [] as INavChild[])
  }
})
