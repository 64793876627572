













import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors } from '@/types'

@Component({
  name: 'ButtonGhost'
})
export default class ButtonGhost extends Vue {
  @Prop({ default: 'primary' }) color!: TColors
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: '16' }) leading!: string
  @Prop({ default: '14' }) size!: string
}
