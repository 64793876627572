// todo refactor requests
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { modules } from '@/store'
import { authService, departmentsService, employeeService, activeDirectoryService } from '@/services'
import { IEmployee, IFileData, INavigationTypes, ITicketDepartment, TIndexedObject } from '@/types'
import { navigation } from '@/router/routes'

let acquirePromise: null | Promise<any> = null

@Module({ name: 'UserModule', namespaced: true })
export default class UserModule extends VuexModule {
  groups: string[] = []
  tokenInfo = JSON.parse(localStorage.getItem(authService.localStorageTokenKey) || '{}') || {
    accessToken: '',
    expiresOn: ''
  }

  userDepartments: ITicketDepartment[] = []
  userProfile: IEmployee = {} as IEmployee
  userProfileDetails: any = {} as any
  userPictureData: IFileData | null = null
  userProfileLoaded = false
  userProfileLoading = false
  userPictureLoading = false

  /* Creates navigation for specific user */
  get userNavigation () {
    return navigation.reduce((acc, cur) => {
      if ((cur.group === undefined || this.groups.includes(cur.group)) && !cur.excludeFromNavigation) {
        const filteredChildRoutes = cur.children.filter(childRoute => {
          return (
            (childRoute.group === undefined || this.groups.includes(childRoute.group)) &&
            !childRoute.excludeFromNavigation
          )
        })
        acc.push({ ...cur, children: filteredChildRoutes })
      }
      return acc
    }, [] as INavigationTypes[])
  }

  // Creates indexes for navigation
  get indexedUserNavigation (): TIndexedObject<INavigationTypes> {
    return this.userNavigation.reduce((acc, cur) => {
      acc[cur.name] = cur
      return acc
    }, {} as TIndexedObject<INavigationTypes>)
  }

  get userPicture () {
    if (this.userPictureData) {
      return this.userPictureData.fileData
    } else {
      return null
    }
  }

  get userPrimaryEmail () {
    return this.userProfile.emails && (this.userProfile.emails[0] || { emailAddress: '' }).emailAddress
  }

  /* M U T A T I O N S */
  @Mutation
  SET_USER_PROFILE (userProfile: IEmployee) {
    this.userProfile = userProfile
    this.userProfileLoaded = true
  }

  @Mutation
  SET_USER_PROFILE_DETAILS (userProfileDetails: IEmployee) {
    this.userProfileDetails = userProfileDetails
  }

  @Mutation
  SET_USER_DEPARTMENTS (departments: ITicketDepartment[]) {
    this.userDepartments = departments
  }

  @Mutation
  SET_USER_PROFILE_LOADING (loading: boolean) {
    this.userProfileLoading = loading
  }

  @Mutation
  SET_USER_PICTURE_LOADING (loading: boolean) {
    this.userPictureLoading = loading
  }

  @Mutation
  SET_USER_PICTURE (picture: IFileData) {
    this.userPictureData = picture
  }

  @Mutation
  SET_GROUPS ({ groups, setLocalStorage }: { groups: string[]; setLocalStorage?: { userId: string } | false }) {
    this.groups = groups

    if (setLocalStorage) {
      const savedGroups = JSON.parse(localStorage.getItem(authService.securityGroupsStorageName) || '{}')
      savedGroups[setLocalStorage.userId] = groups
      localStorage.setItem(authService.securityGroupsStorageName, JSON.stringify(savedGroups))
    }
  }

  @Mutation
  SET_TOKEN ({ accessToken, expiresOn }: { accessToken: string; expiresOn: string | Date }) {
    this.tokenInfo.accessToken = accessToken
    this.tokenInfo.expiresOn = expiresOn
    localStorage.setItem(authService.localStorageTokenKey, JSON.stringify(this.tokenInfo))
  }

  @Mutation
  CLEAR_USER () {
    this.tokenInfo = { accessToken: '', expiresOn: '' }
    this.userProfile = {} as IEmployee
    this.userProfileLoaded = false
    this.userDepartments = []
    localStorage.removeItem(authService.localStorageTokenKey)
  }

  /* A C T I O N S */
  @Action({ rawError: true })
  async getUserProfile () {
    const { employee, securityGroups } = await authService.getUser()

    modules.employee.ADD_SEARCH_EMPLOYEE_ITEMS([employee])

    this.context.commit('SET_USER_PROFILE', employee)

    return { employee, securityGroups }
  }

  @Action({ commit: 'SET_USER_PROFILE_DETAILS', rawError: true })
  async getUserProfileDetails () {
    return await activeDirectoryService.getProfileDetails()
  }

  @Action({ commit: 'SET_USER_DEPARTMENTS', rawError: true })
  async getDepartmentForCurrentEmployee () {
    return await departmentsService.getDepartmentForCurrentEmployee()
  }

  @Action({ commit: 'SET_USER_PICTURE', rawError: true })
  async getUserPicture (employeeId: number) {
    return await employeeService.getEmployeeImage(employeeId)
  }

  @Action({ commit: 'SET_TOKEN', rawError: true })
  async acquireToken () {
    let res
    if (acquirePromise) {
      res = await acquirePromise
      acquirePromise = null
    } else {
      acquirePromise = authService.acquireToken()
      res = acquirePromise
    }

    const { accessToken, expiresOn } = await res
    return { accessToken, expiresOn }
  }
}
