import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.ticketsIncidents

const ticketsIncidentsRouteNames = {
  incidents: 'Incident',
  tickets: 'Ticket'
}

const ticketsIncidentsRoutes = [
  {
    name: ticketsIncidentsRouteNames.incidents,
    path: 'incidents',
    meta: {
      parentName,
      label: 'Incidents',
      group: 'LDH_Nav_Incidents_View'
    },
    component: () => import('@/pages/tickets-incidents/incidents/Incidents.vue')
  },
  {
    name: ticketsIncidentsRouteNames.tickets,
    path: 'tickets',
    meta: {
      parentName,
      label: 'Tickets',
      group: 'LDH_Nav_Tickets_View'
    },
    component: () => import('@/pages/tickets-incidents/tickets/Tickets.vue')
  }
]

export {
  ticketsIncidentsRouteNames,
  ticketsIncidentsRoutes
}
