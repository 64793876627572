import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.humanResources

export const humanResourcesRouteNames = {
  candidates: 'Candidate',
  employees: 'Employee',
  guardTerms: 'GuardTerm',
  licenses: 'License',
  messaging: 'Messaging',
  paychecks: 'Paycheck',
  posting: 'Posting',
  requisitions: 'Requisition',
  reimbursements: 'Reimbursements'
}

export const humanResourcesRoutes = [
  {
    name: humanResourcesRouteNames.candidates,
    path: 'candidates',
    meta: {
      parentName,
      label: 'Candidates',
      group: 'LDH_Nav_Candidates_View'
    },
    component: () => import('@/pages/human-resources/candidates/Candidates.vue')
  },
  {
    name: humanResourcesRouteNames.employees,
    path: 'employees',
    meta: {
      parentName,
      group: 'LDH_Nav_Employees_View',
      label: 'Employees'
    },
    component: () => import('@/pages/human-resources/employees/Employees.vue')
  },
  {
    name: humanResourcesRouteNames.guardTerms,
    path: 'guard-terms',
    meta: {
      parentName,
      group: 'LDH_Nav_Guard_Terms_View',
      label: 'Guard Terms'
    },
    component: () => import('@/pages/human-resources/guard-terms/GuardTerms.vue')
  },
  {
    name: humanResourcesRouteNames.licenses,
    path: 'licenses',
    meta: {
      parentName,
      group: 'LDH_Nav_Licenses_View',
      label: 'Licenses'
    },
    component: () => import('@/pages/human-resources/licenses/Licenses.vue')
  },
  {
    name: humanResourcesRouteNames.messaging,
    path: 'messaging',
    meta: {
      parentName,
      label: 'Messaging',
      group: 'LDH_Nav_Messaging_View'
    },
    component: () => import('@/pages/human-resources/messages/Messaging.vue')
  },
  {
    name: humanResourcesRouteNames.paychecks,
    path: 'paychecks',
    meta: {
      parentName,
      group: 'LDH_Nav_Paychecks_View',
      label: 'Paychecks'
    },
    component: () => import('@/pages/human-resources/paychecks/Paychecks.vue')
  },
  {
    name: humanResourcesRouteNames.posting,
    path: 'posting',
    meta: {
      parentName,
      group: 'LDH_Nav_Posting_View',
      label: 'Posting'
    },
    component: () => import('@/pages/human-resources/posting/Posting.vue')
  },
  {
    name: humanResourcesRouteNames.requisitions,
    path: 'requisitions',
    meta: {
      parentName,
      group: 'LDH_Nav_Requisitions_View',
      label: 'Requisitions'
    },
    component: () => import('@/pages/human-resources/requisitions/Requisitions.vue')
  },
  {
    name: humanResourcesRouteNames.reimbursements,
    path: 'reimbursements',
    meta: {
      parentName,
      group: 'LDH_Nav_Reimbursements_View',
      label: 'Reimbursements'
    },
    component: () => import('@/pages/human-resources/reimbursements/Reimbursements.vue')
  }
]
