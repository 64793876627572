import { httpService } from '@/services'
import {
  IAccountStatus,
  INotifications,
  IResetPasswordPayload,
  ISecurityGroups,
  IVerifyIdentity,
  IVerifyIdentityPayload
} from '@/types'

class ActiveDirectoryService {
  updateSecurityGroups (): Promise<ISecurityGroups> {
    return httpService.post('/ActiveDirectory/UpdateEmployeeGroups')
  }

  assignEmployeeManager (employeeId: number, managerId: number): Promise<''> {
    return httpService.post('/ActiveDirectory/AssignManager', {}, { params: { employeeId, managerId } })
  }

  getNotifications (): Promise<INotifications> {
    return httpService.post('/ActiveDirectory/GetNotifications')
  }

  createActiveDirectoryAccount (id: number): Promise<''> {
    return httpService.post('/ActiveDirectory/CreateActiveDirectoryAccount', {}, { params: { id } })
  }

  enableAccountByEmployeeId (id: number): Promise<''> {
    return httpService.post('/ActiveDirectory/EnableAccountByEmployeeId', {}, { params: { id } })
  }

  disableAccountByEmployeeId (id: number): Promise<''> {
    return httpService.post('/ActiveDirectory/DisableAccountByEmployeeId', {}, { params: { id } })
  }

  resetPasswordByEmployeeId (id: number): Promise<''> {
    return httpService.post('/ActiveDirectory/ResetPasswordByEmployeeId', {}, { params: { id } })
  }

  unlockAccountByEmployeeId (id: number): Promise<''> {
    return httpService.post('/ActiveDirectory/UnlockAccountByEmployeeId', {}, { params: { id } })
  }

  verifyIdentity (params: IVerifyIdentityPayload): Promise<IVerifyIdentity> {
    return httpService.post('/ActiveDirectory/VerifyIdentity', {}, { params })
  }

  resetPassword (params: IResetPasswordPayload): Promise<''> {
    return httpService.post('/ActiveDirectory/ResetPassword', {}, { params })
  }

  getAccountStatusByEmployeeId (id: number): Promise<IAccountStatus> {
    return httpService.post('/ActiveDirectory/GetAccountStatusByEmployeeId', {}, { params: { id } })
  }

  getProfileDetails (): Promise<any> {
    return httpService.post('/ActiveDirectory/ProfileDetails')
  }
}

export const activeDirectoryService = new ActiveDirectoryService()
