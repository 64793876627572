import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { incidentsService } from '@/services'
import { IIncidentShort, TIndexedObject } from '@/types'

const incidentsRequests: TIndexedObject<() => Promise<IIncidentShort[]>> = {
  unassigned: () => incidentsService.getFromView(),
  allIncidents: () => incidentsService.getFromView(),
  openAssignedToMe: () => incidentsService.getFromView(),
  createdByMe: () => incidentsService.getFromView()
}

const getIncidentStatus = (incident: IIncidentShort) => {
  // if (incident.isComplete) { return 'Closed' }
  if (incident.isAssigned) { return 'Assigned' }
  return 'Unassigned'
}

@Module({ name: 'IncidentsModule', namespaced: true })
export default class IncidentsModule extends VuexModule {
  incidentsLoaded = false
  incidentsLoading = false

  activeTab = 'openAssignedToMe'
  incidentsByTab: TIndexedObject<IIncidentShort[]> = {
    unassigned: [],
    allIncidents: [],
    openAssignedToMe: [],
    createdByMe: []
  }

  get locations () {
    const allLocations = this.incidentsByTab[this.activeTab]
      .map(i => i.customerSite)
      .filter(l => !!l)
    return Array.from(new Set(allLocations))
  }

  get assignments () {
    const allAssignments = this.incidentsByTab[this.activeTab]
      .map(t => t.assignedTo)
      .join(',')
      .split(',')
      .map(assign => assign.trim())
      .filter(s => !!s)
    return Array.from(new Set(allAssignments))
  }

  get statuses () {
    const statuses = this.incidentsByTab[this.activeTab].map(getIncidentStatus)
    return Array.from(new Set(statuses))
  }

  get incidents () {
    return this.incidentsByTab[this.activeTab]
  }

  /* M U T A T I O N S */
  @Mutation
  SET_INCIDENTS ({ tab, incidents }: { tab: string; incidents: any[] }) {
    this.incidentsByTab[tab] = incidents
    this.incidentsLoaded = true
  }

  @Mutation
  SET_TAB (tab: string) {
    this.activeTab = tab
  }

  @Mutation
  DELETE_INCIDENT (incidentId: number) {
    Object.keys(this.incidentsByTab).forEach(tab => {
      this.incidentsByTab[tab] = this.incidentsByTab[tab].filter(incident => incident.id !== incidentId)
    })
  }

  @Mutation
  LOADING (val: boolean) {
    this.incidentsLoading = val
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_INCIDENTS', rawError: true })
  async getIncidents (tab: string) {
    return { tab, incidents: await incidentsRequests[tab]() }
  }

  @Action({ rawError: true })
  async updateIncidents (tab?: string) {
    if (tab) {
      this.SET_TAB(tab)
    }
    this.LOADING(true)
    await this.getIncidents(this.activeTab)
    for (let i = 0; i < this.incidents.length; i++) {
      this.incidents[i].status = getIncidentStatus(this.incidents[i])
    }
    this.LOADING(false)
  }
}
