import { httpService } from '@/services'
import { ICommentPayload, ICommentItem } from '@/types'

class CommentsService {
  private apiUrls = {
    commentsByEmployeeId: '/Comment/GetCommentsByEmployeeId',
    getNewComment: '/Comment/GetNewCommentData',
    saveComment: '/Comment/SaveComment',
    updateComment: '/Comment/UpdateComment'
  }

  getCommentsByEmployeeId (employeeId: number): Promise<ICommentItem[]> {
    return httpService.get(this.apiUrls.commentsByEmployeeId, { params: { id: employeeId } })
  }

  getNewComment (): Promise<[]> {
    return httpService.get(this.apiUrls.getNewComment)
  }

  saveComment (payload: ICommentPayload) {
    return httpService.post(this.apiUrls.saveComment, payload)
  }

  updateComment (payload: ICommentPayload) {
    return httpService.post(this.apiUrls.updateComment, payload)
  }
}

export const commentsService = new CommentsService()
