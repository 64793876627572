import {
  ISchedule,
  IScheduleFilterParams, IScheduleProjectPayload,
  IScheduleServiceCallSavePayload,
  IScheduleServiceCallUpdatePayload,
  IScheduleTechnicianAvailability,
  IScheduleUnbillableCreatePayload, IScheduleUnbillableUpdatePayload,
  IServiceCallCheckoutDraftRequest
} from '@/types'
import { httpService } from '@/services'

class ScheduleService {
  getByFilter (payload: IScheduleFilterParams): Promise<ISchedule[]> {
    return httpService.post('/Schedule/GetByFilter', { ...payload, activeOnly: true })
  }

  delete (id: number): Promise<''> {
    return httpService.post('/Schedule/Toggle', {}, { params: { id } })
  }

  update (payload: { id: number; startDateTime: string; endDateTime: string }): Promise<''> {
    return httpService.post('/Schedule/Update', payload)
  }

  toggle (id: number): Promise<''> {
    return httpService.post('/Schedule/Toggle', {}, { params: { id } })
  }

  getTechnicianAvailability (params: { fromDate: string; toDate: string }): Promise<IScheduleTechnicianAvailability[]> {
    return httpService.get('/Schedule/GetTechnicianAvailability', { params })
  }

  // S E R V I C E  C A L L S
  scheduleServiceCall (payload: IScheduleServiceCallSavePayload): Promise<''> {
    return httpService.post('/ScheduleServiceCall/Save', payload)
  }

  toggleScheduleServiceCall (id: number): Promise<''> {
    return httpService.post('/ScheduleServiceCall/Toggle', {}, { params: { id } })
  }

  updateServiceCallSchedule (payload: IScheduleServiceCallUpdatePayload): Promise<''> {
    return httpService.post('/ScheduleServiceCall/Update', payload)
  }

  checkIn (params: { dateTime?: string; scheduleId?: number }) {
    return httpService.post('/ScheduleTechnician/CheckIn', {}, { params })
  }

  checkOut (payload: any) {
    return httpService.post('/ScheduleTechnician/CheckOut', payload)
  }

  saveDraft (payload: any) {
    return httpService.post('/ScheduleTechnician/Draft', payload)
  }

  getCheckoutDetails (id: number): Promise<IServiceCallCheckoutDraftRequest> {
    return httpService.get('/ScheduleTechnician/GetDetails', { params: { id } })
  }

  // U N B I L L A B L E
  scheduleUnbillable (payload: IScheduleUnbillableCreatePayload): Promise<''> {
    return httpService.post('/Unbillable/Schedule', payload)
  }

  updateUnbillableSchedule (payload: IScheduleUnbillableUpdatePayload): Promise<''> {
    return httpService.post('/Unbillable/Update', payload)
  }

  // P R O J E C T
  scheduleProject (payload: IScheduleProjectPayload): Promise<''> {
    return httpService.post('/ScheduleProject/Save', payload)
  }

  updateProjectSchedule (payload: IScheduleProjectPayload): Promise<''> {
    return httpService.post('/ScheduleProject/Update', payload)
  }
}

export const scheduleService = new ScheduleService()
