import { RouteConfig } from 'vue-router'
import { parentRoutesNames } from '@/router/parentRouteNames'
import { opportunitiesRoutesNames, opportunitiesRoutes } from './opportunities/opportunities.routes'
import AuxLayout from '@/layouts/AuxLayout.vue'

const parentName = parentRoutesNames.sales

const salesRouteNames = {
  guardTemplates: 'GuardTemplates',
  techTemplates: 'TechTemplates',
  employeeSignature: 'EmployeeSignature',
  workQueue: 'WorkQueue',
  ...opportunitiesRoutesNames
}

const salesRoutes: RouteConfig[] = [
  {
    name: salesRouteNames.guardTemplates,
    path: 'guard-templates',
    meta: {
      parentName,
      group: 'LDH_Nav_Drop_Ship_View',
      label: 'Guard Templates'
    },
    component: () => import('@/pages/sales/guard-templates/GuardTemplates.vue')
  },
  {
    // name: salesRouteNames.opportunity,
    path: 'opportunity',
    meta: {
      parentName,
      group: 'HUB_Sales_Opportunity_View',
      label: 'Opportunity'
    },
    component: AuxLayout,
    children: opportunitiesRoutes
  },
  {
    name: salesRouteNames.techTemplates,
    path: 'tech-templates',
    meta: {
      parentName,
      group: 'LDH_Nav_Parts_View',
      label: 'Tech Templates'
    },
    component: () => import('@/pages/sales/tech-templates/TechTemplates.vue')
  },
  {
    name: salesRouteNames.workQueue,
    path: 'work-queue',
    meta: {
      parentName,
      group: 'LDH_Nav_Parts_View',
      label: 'Work Queue'
    },
    component: () => import('@/pages/sales/work-queue/WorkQueue.vue')
  }
]

export { salesRouteNames, salesRoutes }
