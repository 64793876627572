import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { textUsService } from '@/services'
import { ITextUsMessage } from '@/types'

@Module({ name: 'TextusModule', namespaced: true })
export default class TextusModule extends VuexModule {
  messages: ITextUsMessage[] = []

  @Mutation
  SET_MESSAGES (messages: ITextUsMessage[]) {
    this.messages = messages
  }

  @Mutation
  ADD_MESSAGE (message: ITextUsMessage) {
    this.messages.push(message)
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_MESSAGES', rawError: true })
  async getTextUsMessages (personId: string) {
    return await textUsService.getMessages(personId)
  }
}
