import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { notesService } from '@/services'
import { INote, INotesOptions, TIndexedObject } from '@/types'

@Module({ name: 'NotesModule', namespaced: true })
export default class NotesModule extends VuexModule {
  notes: readonly INote[] = []
  notesLoading = false
  selectedNotesOptions: INotesOptions = { from: null, to: null, searchBy: '1' }

  get indexedNotes () {
    return this.notes.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {} as TIndexedObject<INote>)
  }

  /* M U T A T I O N S */
  @Mutation
  SET_NOTES (payload: readonly INote[]) {
    this.notes = payload
  }

  @Mutation
  SET_LOADING (payload: boolean) {
    this.notesLoading = payload
  }

  @Mutation
  CLEAR_SELECTED_NOTES_OPTIONS () {
    this.selectedNotesOptions = { from: null, to: null, searchBy: '1' }
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_NOTES', rawError: true })
  async getNotes (notesOptions: INotesOptions) {
    return await notesService.getNotes(notesOptions)
  }
}
