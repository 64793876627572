import { httpService } from '@/services'

class EmailService {
  private apiUrls = {
    getEmailsByEmployeeId: '/Email/GetEmailsByEmployeeId',
    saveEmail: '/Email/SaveEmail',
    updateEmail: '/Email/UpdateEmail',
    deleteEmail: '/Email/ToggleEmail'
  }

  getEmailsByEmployeeId (id: number): Promise<any[]> {
    return httpService.get(this.apiUrls.getEmailsByEmployeeId, { params: { id } })
  }

  createEmail (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.saveEmail, payload)
  }

  updateEmail (payload: any): Promise<''> {
    return httpService.post(this.apiUrls.updateEmail, payload)
  }

  deleteEmail (id: number): Promise<''> {
    return httpService.post(this.apiUrls.deleteEmail, {}, {
      params: { id }
    })
  }
}

export const emailService = new EmailService()
