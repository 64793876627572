const jobsSidebarRoutesNames = {
  createJob: 'createJob',
  editJob: 'editJob'
}

const jobsSidebarRoutes = [
  {
    name: jobsSidebarRoutesNames.createJob,
    path: '/create-job',
    component: () => import('./JobCreate.vue'),
    meta: { group: 'LDH_Job_Add' }
  },
  {
    name: jobsSidebarRoutesNames.editJob,
    path: '/job-:id/edit',
    component: () => import('./JobEdit.vue'),
    meta: { group: 'LDH_Job_Update' }
  }
]

export {
  jobsSidebarRoutesNames,
  jobsSidebarRoutes
}
