import { httpService } from '@/services'
import { IDocumentFileDownload, IDocuSign, IDocuSignDocument, IFileData } from '@/types'
import { downloadFile } from '@/helpers'

class DocumentService {
  private apiUrls = {
    downloadDocument: (documentId: number) => `/Document/GetById?id=${documentId}`,
    getResumeByEmployeeId: '/Document/GetResumeByEmployeeId',
    downloadDocuSign: (documentId: string, envelopeId: number) => {
      return `/DocuSign/GetDocumentById?id=${documentId}&envelopeId=${envelopeId}`
    },
    getDocuSignInformationById: '/Candidate/GetDocuSignInformationById',
    docuSignList: '/DocuSign/ListDocumentsById',
    resetDocuSign: '/DocuSign/ResetDocuSign',
    uploadDocument: '/Document/UploadDocument',
    uploadDocuments: '/Document/UploadDocuments',
    removeDocument: '/Document/RemoveDocument',
    emailDocument: '/Document/EmailDocument',
    getDocumentById: '/Document/GetById'
  }

  getDocuSignInformationById (ownerId: number): Promise<IDocuSign[]> {
    return httpService.get(this.apiUrls.getDocuSignInformationById, {
      params: { id: ownerId }
    })
  }

  getDocuSignList (docId: number): Promise<IDocuSignDocument[]> {
    return httpService.get(this.apiUrls.docuSignList, {
      params: { id: docId }
    })
  }

  resetDocuSign (candidateId: number): Promise<''> {
    return httpService.post(this.apiUrls.resetDocuSign, {}, {
      params: { id: candidateId }
    })
  }

  getDocuSign (documentId: string, envelopeId: number): Promise<IFileData> {
    return httpService.get(this.apiUrls.downloadDocuSign(documentId, envelopeId))
  }

  downloadDocuSign (documentId: string, envelopeId: number) {
    return downloadFile(this.apiUrls.downloadDocuSign(documentId, envelopeId))
  }

  downloadDocument (documentId: number) {
    return downloadFile(this.apiUrls.downloadDocument(documentId))
  }

  getResumeByEmployeeId (id: number | string): Promise<{ files: IDocumentFileDownload[] }> {
    return httpService.get(this.apiUrls.getResumeByEmployeeId, { params: { id } })
  }

  uploadDocument (payload: any) {
    return httpService.post(this.apiUrls.uploadDocument, payload)
  }

  uploadDocuments (payload: any) {
    return httpService.post(this.apiUrls.uploadDocuments, payload)
  }

  emailDocument (
    documentId: number,
    subject: string,
    message: string,
    addressTo: string[],
    addressCc: string[],
    addressBcc: string[]
  ) {
    return httpService.post(this.apiUrls.uploadDocuments, {
      documentId,
      subject,
      message,
      addressTo,
      addressCc,
      addressBcc
    })
  }

  removeDocument (documentId: number) {
    return httpService.post(this.apiUrls.removeDocument, {}, {
      params: {
        id: documentId
      }
    })
  }

  getDocumentById (id: number): Promise<IFileData> {
    return httpService.get(this.apiUrls.getDocumentById, { params: { id } })
  }
}

export const documentService = new DocumentService()
