import { RouteConfig } from 'vue-router'

const serviceCallsSidebarRoutesNames = {
  serviceCallDetails: 'serviceCallDetails',
  serviceCallCreate: 'serviceCallCreate',
  serviceCallCheckOut: 'serviceCallCheckOut'
}

const serviceCallsSidebarRoutes: RouteConfig[] = [
  {
    name: serviceCallsSidebarRoutesNames.serviceCallDetails,
    path: '/service-call-:serviceCallId/details',
    meta: { width: '700px', group: 'LDH_ServiceCall_View' },
    component: () => import('./ServiceCallDetails.vue')
  },
  {
    name: serviceCallsSidebarRoutesNames.serviceCallCreate,
    path: '/service-call-create',
    meta: { width: '700px', group: 'LDH_ServiceCall_Add' },
    component: () => import('./ServiceCallCreate.vue')
  },
  {
    name: serviceCallsSidebarRoutesNames.serviceCallCheckOut,
    path: '/service-call-:serviceCallId/check-out',
    meta: { width: '700px' },
    component: () => import('./service-call-check-out/ServiceCallCheckOut.vue')
  }
]

export {
  serviceCallsSidebarRoutesNames,
  serviceCallsSidebarRoutes
}
