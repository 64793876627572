
















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { modules } from '@/store'
import { globalService } from '@/services'
import { IGlobalSearch, ITableHeading } from '@/types'
import { routeNames } from '@/router'
import { sidebarRouteNames } from '@/sidebar/router/sidebarRouteNames'

import Modal from './shared/Modal.vue'
import InputModule from '@/components/shared/form-controls/InputModule.vue'
import TableConstructor from '@/components/shared/table-constructor/TableConstructor.vue'
import CheckboxModule from '@/components/shared/form-controls/CheckboxModule.vue'
import ListItem from '@/components/shared/list-item/ListItem.vue'

@Component({
  name: 'GlobalSearchModal',
  components: {
    Modal,
    InputModule,
    TableConstructor,
    CheckboxModule,
    ListItem
  }
})
export default class GlobalSearchModal extends Vue {
  /* R E F S */
  $refs!: {
    globalSearchInput: InputModule & { focus: () => void };
  }

  /* D A T A */
  globalSearchLoading = false
  globalSearchResults: IGlobalSearch[] = []
  globalSearch = ''
  resultsVisible = false
  selectedSearchTypes: string[] = []

  searchHeadings: ITableHeading[] = [
    { label: 'ID', value: 'value', width: 100 },
    { label: 'Type', value: 'searchResultType', sortable: true, width: 150 },
    { label: 'Text', value: 'displayName', minWidth: 150 },
    { label: 'Description', value: 'description', minWidth: 150, disableToolTip: true }
  ]

  /* C O M P U T E D */
  get globalSearchOpened () {
    return modules.general.globalSearchOpened
  }

  get uniqueSearchTypes () {
    return [...new Set(this.globalSearchResults.map(res => res.searchResultType))].sort()
  }

  get filteredSearchResults () {
    return this.globalSearchResults.filter(res => this.selectedSearchTypes.includes(res.searchResultType))
  }

  /* M E T H O D S */
  async getGlobalSearchResults (search: string) {
    if (!search) {
      this.resultsVisible = false
      this.globalSearchResults = []
    } else {
      try {
        this.resultsVisible = true
        this.globalSearchLoading = true
        this.globalSearchResults = await globalService.search(search)
        this.selectedSearchTypes = [...this.uniqueSearchTypes]
      } finally {
        this.globalSearchLoading = false
      }
    }
  }

  onSearchResultClick (result: { row: IGlobalSearch }) {
    this.closeGlobalSearch()
    setTimeout(async () => {
      if (result.row.isTicket) {
        if (this.$route.name !== routeNames.tickets) {
          await this.$appRouter.push({ name: routeNames.tickets })
        }
        await this.$sidebar.push({
          name: sidebarRouteNames.ticketDetails,
          params: { id: String(result.row.id) }
        })
      } else {
        const name = result.row.isHired ? 'Employee' : 'Candidate'
        if (this.$route.name !== name) {
          await this.$appRouter.push({ name })
        }
        await this.$sidebar.push({
          name: name.toLowerCase() + 'Profile',
          params: { id: String(result.row.id) }
        })
      }
      this.globalSearchResults = []
    }, 400)
  }

  closeGlobalSearch () {
    this.globalSearch = ''
    this.globalSearchResults = []
    this.resultsVisible = false
    modules.general.SET_GLOBAL_SEARCH_OPENED(false)
  }

  /* W A T C H E R S */
  @Watch('globalSearchOpened')
  onGlobalSearchOpenedChanged (newVal: boolean) {
    if (newVal) {
      this.$nextTick(() => {
        this.$refs.globalSearchInput.focus()
      })
    }
  }
}
