


































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// import { Table } from 'element-ui'
import Paginate from 'vuejs-paginate'

import { modules } from '@/store'
import { filterDateRange, filterNumberRange, filterStringRange, getNestedProp, parsedValue } from '@/helpers'
import {
  EPersonalizationSettings,
  EPersonalizationTables,
  ITableHeading,
  TFormatterFunction,
  TIndexedObject
} from '@/types'

import ButtonModule from '@/components/shared/form-controls/ButtonModule.vue'
import InputModule from '@/components/shared/form-controls/InputModule.vue'
import SelectModule from '@/components/shared/form-controls/SelectModule.vue'
import ToggleModule from '@/components/shared/form-controls/ToggleModule.vue'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import Icon from '@/components/shared/Icon.vue'
import Modal from '@/components/shared/Modal.vue'
import CancelSave from '@/components/shared/CancelSave.vue'
import TableHeaderFilter from '@/components/shared/table-header-filter/TableHeaderFilter.vue'
import { IFilter, IFilterSettingUpdatePayload } from '../table-header-filter/table-header-filter.types'
import { ElTable } from 'element-ui/types/table'

@Component({
  name: 'TableConstructor',
  components: {
    Paginate,
    ButtonModule,
    InputModule,
    SelectModule,
    ToggleModule,
    ConfirmModal,
    DataExport: () => import(/* webpackChunkName: "DataExport" */ '@/components/shared/DataExport.vue'),
    Icon,
    Modal,
    TableHeaderFilter,
    CancelSave
  }
})
export default class TableConstructor extends Vue {
  /* R E F S */
  $refs!: TIndexedObject & {
    table: ElTable;
    confirm: ConfirmModal & { show: () => Promise<void> };
  }

  /* P R O P S */
  @Prop({ default: () => [] }) items!: any[]
  @Prop() headingsOverride?: ITableHeading[]
  @Prop() headingClass?: string
  @Prop({ default: true }) headerFixed!: boolean
  @Prop({ default: true }) truncateHeader!: boolean
  @Prop() showHeader?: boolean
  @Prop({ default: true }) isPageTable!: boolean
  @Prop({ default: true }) withTotalRecords!: boolean
  @Prop() exportFileName!: string
  @Prop() tablePreferenceName!: EPersonalizationTables
  @Prop({ default: false }) tableOnly!: boolean
  @Prop({ default: false }) useOverridesOnly?: boolean
  @Prop({ default: false }) stickyheaderNames!: boolean
  @Prop({ default: 'Search' }) searchPlaceholder!: string
  @Prop({ default: () => [] }) searchBy!: string[]
  @Prop({ default: () => true }) isResizable?: boolean
  @Prop() loading?: boolean
  @Prop() highlighting?: (e: { item: any; index: number }) => boolean
  @Prop() rowClass?: (e: { item: any; index: number }) => string
  @Prop() tableTitle!: string
  @Prop() rowKey!: string
  @Prop({ default: () => ({}) }) treeProps!: object

  @Prop({ default: () => ({}) }) cellClass!: TIndexedObject<TFormatterFunction>
  @Prop({ default: () => ({}) }) columnClassName!: TIndexedObject<string>

  @Prop({ default: () => [] }) rightActions!: string[]
  @Prop({ default: () => [] }) bottomActions!: string[]

  @Prop({ default: false }) isPredefined!: boolean
  @Prop({ default: '' }) personalizationTable!: EPersonalizationTables

  @Prop({ default: true }) isPagination!: boolean;

  /* D A T A */
  itemsPerPage = 10
  itemsPerPageOptions = [
    { label: '10 entries', value: 10 },
    { label: '25 entries', value: 25 },
    { label: '50 entries', value: 50 }
  ]

  tableHeaderFilteredItems: any[] = []

  tableKey = 0

  parsedValue = parsedValue
  search = ''
  isAutoRefresh = true
  currentPage = 1
  nonSortedItems: any[] = []

  // sorting
  sortBy = ''
  sortDirection = 0
  sortFunction: TFormatterFunction | null = null

  // predefinded filters
  predefinedSettings = ''
  savePreferenceOpened = false
  preferencesName = ''

  predefinedLoading = false

  /* C O M P U T E D */
  get showPreferences () {
    return this.tablePreferenceName
  }

  get activeToggleState () {
    return modules.general.activeToggleState
  }

  get actions () {
    return {
      right: this.rightActions.filter(action => !!action),
      bottom: this.bottomActions.filter(action => !!action)
    }
  }

  get actionsData (): any {
    return {
      'auto-refresh': {
        component: 'toggle-module',
        props: {
          value: this.isAutoRefresh,
          activeText: 'Auto Refresh',
          class: 'ml-4'
        },
        listeners: {
          input: (newVal: boolean) => { this.isAutoRefresh = newVal }
        }
      },
      add: {
        component: 'ButtonModule',
        innerText: 'Add',
        props: {
          color: 'primary',
          size: 'lg',
          icon: 'icon-plus',
          class: 'ml-4'
        },
        listeners: {
          click: () => { this.$emit('add-click') }
        }
      },
      sync: {
        component: 'ButtonModule',
        innerText: 'Sync',
        props: {
          color: 'primary',
          size: 'lg',
          icon: 'icon-refresh',
          class: 'ml-4'
        },
        listeners: {
          click: () => { this.$emit('sync-click') }
        }
      },
      export: {
        component: 'ButtonModule',
        innerText: 'Export',
        props: {
          color: 'primary',
          size: 'lg',
          class: 'ml-4'
        },
        listeners: {
          click: () => { this.$emit('export-click') }
        }
      },
      import: {
        component: 'ButtonModule',
        innerText: 'Import',
        props: {
          color: 'primary',
          size: 'lg',
          class: 'ml-4'
        },
        listeners: {
          click: () => { this.$emit('import-click') }
        }
      },
      'active-toggle': {
        component: 'toggle-module',
        props: {
          activeText: 'Active',
          inactiveText: 'All',
          color: 'primary',
          class: 'ml-4',
          value: this.activeToggleState
        },
        listeners: {
          input: (newVal: boolean) => this.toggleActiveState(newVal)
        }
      },
      show: {
        component: 'ButtonModule',
        innerText: 'Show',
        props: {
          color: 'primary',
          size: 'lg',
          class: 'ml-4'
        },
        listeners: {
          click: () => { this.$emit('show-click') }
        }
      }
    }
  }

  get sidebarOpened () {
    if (this.isPageTable) {
      return this.$sidebarRoute.path !== '/'
    }
    return false
  }

  get tablePreferenceItemsPerPage () {
    const defaultItemsPerPage = this.itemsPerPageOptions[0].value
    const personalization = modules.personalization
      .indexedPersonalizations[EPersonalizationSettings.TABLE_ITEMS_PER_PAGE]

    if (personalization) {
      const itemsPerPageTableIndex = JSON.parse(personalization.value)
      if (itemsPerPageTableIndex) {
        return itemsPerPageTableIndex[this.tablePreferenceName] || defaultItemsPerPage
      }
    }
    return defaultItemsPerPage
  }

  get tablePreferenceSorting (): { sortBy: string; sortDirection: number } {
    const defaultSorting = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection
    }

    const personalization = modules.personalization
      .indexedPersonalizations[EPersonalizationSettings.TABLE_SORTING]
    if (personalization) {
      const sorting = JSON.parse(personalization.value)
      if (sorting) {
        return sorting[this.tablePreferenceName] || defaultSorting
      }
    }
    return defaultSorting
  }

  get defaultHeadings () {
    return modules.tableHeadings.headingsDefaultState[this.tablePreferenceName]
  }

  get tablePreferenceHeadings () {
    return modules.tableHeadings.headingsState[this.tablePreferenceName]
  }

  get mergedPreferencesHeadings () {
    if (!this.tablePreferenceName) {
      return this.headingsOverride
    }
    if ((this.tablePreferenceHeadings && this.tablePreferenceHeadings.length > 0) &&
        (this.useOverridesOnly && this.headingsOverride && this.headingsOverride.length > 0)) {
      return this.headingsOverride.map(heading => {
        const preferenceHeading = this.tablePreferenceHeadings.find(preferenceHeading => {
          return preferenceHeading.value === heading.value
        })
        if (preferenceHeading) {
          return {
            ...heading,
            ...preferenceHeading,
            label: heading.label
          }
        } else {
          return heading
        }
      }).filter(h => h.selected)
    }
    if ((this.tablePreferenceHeadings && this.tablePreferenceHeadings.length > 0) &&
        (this.defaultHeadings && this.defaultHeadings.length > 0)) {
      return this.defaultHeadings.map(heading => {
        const preferenceHeading = this.tablePreferenceHeadings.find(preferenceHeading => {
          return preferenceHeading.value === heading.value
        })
        if (preferenceHeading) {
          return {
            ...heading,
            ...preferenceHeading,
            label: heading.label
          }
        } else {
          return heading
        }
      }).filter(h => h.selected)
    } else if ((this.headingsOverride && this.headingsOverride.length > 0) &&
      (!this.tablePreferenceHeadings)) {
      return this.headingsOverride.filter(h => h.selected || ['id', 'actions'].includes(h.value))
    } else {
      if (this.defaultHeadings && this.defaultHeadings.length > 0) {
        return this.defaultHeadings.filter(h => h.selected || ['id', 'actions'].includes(h.value))
      } else {
        return []
      }
    }
  }

  get predefinedSettingsOptions () {
    const tableSettings = this.predefinedSettingsPersonalization

    if (tableSettings && tableSettings.value) {
      const valueObject = tableSettings.value
      const parsedData = JSON.parse(valueObject)[this.personalizationTable]

      if (this.personalizationTable && parsedData) {
        return Object.keys(parsedData).map(item => ({
          label: item,
          value: item,
          isDefault: parsedData[item].isDefault || false
        }))
      }
    }

    return []
  }

  @Watch('mergedPreferencesHeadings')
  onMergedPreferencesHeadingsChanged () {
    this.tableKey += 1
    this.sortHeadings()
  }

  get pageCount () {
    return Math.ceil(this.filteredItems.length / this.itemsPerPage)
  }

  get paginatedItems () {
    if (this.itemsPerPage && this.isPagination) {
      const from = (this.currentPage - 1) * this.itemsPerPage
      const to = this.currentPage * this.itemsPerPage
      return this.sortedItems.slice(from, to)
    }
    return this.sortedItems
  }

  get filteredItems () {
    if (this.search !== '') {
      return this.tableHeaderFilteredItems.filter(item => {
        if (this.mergedPreferencesHeadings && this.mergedPreferencesHeadings.length > 0) {
          const filtersArray = this.searchBy.length > 0 ? this.searchBy : this.mergedPreferencesHeadings
            .map(heading => {
              return heading.formatter ? heading.formatter(item) : item[heading.value]
            })
          const filtersFields = filtersArray.join(' ').toLocaleLowerCase()
          return filtersFields.includes(this.search.toLocaleLowerCase())
        }
      })
    }
    return this.tableHeaderFilteredItems
  }

  get sortedItems () {
    if (this.sorting.enabled) {
      const sorted = [...this.filteredItems]
      sorted.sort((a: any, b: any) => {
        if (this.sorting.desc) {
          [a, b] = [b, a]
        }
        a = this.sortFunction ? this.sortFunction(a) : getNestedProp(a, this.sortBy)
        b = this.sortFunction ? this.sortFunction(b) : getNestedProp(b, this.sortBy)

        if (typeof a === 'string' && typeof b === 'string') {
          return a.localeCompare(b)
        }

        if (a === undefined || a === null) {
          return -1
        }
        if (b === undefined || b === null) {
          return 1
        }
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      })
      return sorted
    }
    return this.nonSortedItems
  }

  get sorting () {
    return {
      enabled: this.sortDirection !== 0,
      asc: this.sortDirection === 1,
      desc: this.sortDirection === 2,
      value: this.sortBy
    }
  }

  get actionsIncludeActiveToggle () {
    return [...this.rightActions, ...this.bottomActions].includes('active-toggle')
  }

  get predefinedSettingsPersonalization () {
    return modules.personalization
      .indexedPersonalizations[EPersonalizationSettings.TABLE_PREDEFINED_SETTINGS]
  }

  get tableHeaderFilters () {
    return modules.tableHeaderFilters.filters || []
  }

  get filtersForThisTable () {
    const filtersForThisTable: TIndexedObject<IFilter> = {}
    for (const key in this.tableHeaderFilters) {
      if (key.split('.')[0] === this.tablePreferenceName) {
        Vue.set(filtersForThisTable, key, this.tableHeaderFilters[key])
      }
    }
    return filtersForThisTable
  }

  get countFiltersForThisTable () {
    let counter = 0
    for (const key in this.tableHeaderFilters) {
      if (key.split('.')[0] === this.tablePreferenceName) {
        counter++
      }
    }
    return counter
  }

  /* M E T H O D S */
  async openPreferences () {
    if (modules.general.isAnyChanges) {
      await this.$refs.confirm.show()
    }

    this.$sidebar.push({
      name: 'table-constructor-preferences',
      params: { tablePreferenceName: this.tablePreferenceName }
    })
  }

  onItemsPerPageChanged (newVal: number) {
    this.itemsPerPage = newVal
    this.currentPage = 1
    modules.personalization.updateItemsPerPage({ table: this.tablePreferenceName, entries: newVal })
  }

  cellClassName ({ row, column }: { row: any; column: any }) {
    if (this.cellClass && this.cellClass[column.property]) {
      const dynamicCellClass = this.cellClass[column.property](row)
      return column.property === 'id' ? dynamicCellClass + 'cursor-text' : dynamicCellClass
    }
    return column.property === 'id' ? 'cursor-text' : ''
  }

  generateValue (heading: ITableHeading, row: any, raw = false) {
    const dashInEmptyCell = heading.dashInEmptyCell || heading.dashInEmptyCell === undefined
    const localValue = heading.formatter ? heading.formatter(row) : row[heading.value]
    if (raw) {
      return localValue
    }
    return dashInEmptyCell ? parsedValue(localValue) : localValue
  }

  resetPagination () {
    if (this.currentPage > 1) {
      this.currentPage = 1
    }
  }

  onRowClick (row: any, col: any) {
    if (col && this.mergedPreferencesHeadings && (col.property !== 'id' || this.mergedPreferencesHeadings.length <= 2)) {
      this.$emit('row-click', { row, col })
    }
  }

  rowClassName (data: any) {
    const classes: string[] = []

    if (data.row.obsolete) {
      classes.push('text-basic-500 cursor-not-allowed')
    }

    if (this.$listeners['row-click']) {
      classes.push('cursor-pointer')
    }

    if (this.highlighting && this.highlighting(data)) {
      classes.push('active')
    }

    if (this.rowClass && this.rowClass(data)) {
      classes.push(this.rowClass(data))
    }

    return classes
  }

  setSorting (col: any) {
    if (this.mergedPreferencesHeadings && this.mergedPreferencesHeadings.length > 0) {
      const sortBy = col.property
      const header = this.mergedPreferencesHeadings.find(h => h.value === sortBy) as ITableHeading
      if (header.sortable) {
        this.sortFunction = header.sort || null
        if (this.sortBy === sortBy) {
          this.sortDirection = (this.sortDirection + 1) % 3
          if (this.sortDirection === 0) {
            this.sortBy = ''
          }
        } else {
          this.sortBy = sortBy
          this.sortDirection = 1
        }
      }

      modules.personalization.updateSorting({
        table: this.tablePreferenceName,
        sorting: {
          sortBy: this.sortBy,
          sortDirection: this.sortDirection
        }
      })
    }
  }

  toggleActiveState (newVal: boolean) {
    modules.general.SET_ACTIVE_ONLY_ITEMS(newVal)
    this.$emit('active-toggle-change', newVal)
  }

  /* P R E F E R E N C E S */
  async onSavePreference () {
    try {
      this.predefinedLoading = true

      await modules.personalization.updatePredefinedSettings({
        type: this.personalizationTable,
        settings: {
          [this.preferencesName]: {
            [EPersonalizationSettings.FILTERS]: this.filtersForThisTable,
            [EPersonalizationSettings.PREFERENCES]: modules.tableHeadings.headingsState[this.tablePreferenceName]
              ? [...modules.tableHeadings.headingsState[this.tablePreferenceName]] : [],
            isDefault: false
          }
        }
      })

      this.onPredefinedSettingsChanged(this.preferencesName)

      this.closePreference()
    } finally {
      this.predefinedLoading = false
    }
  }

  closePreference () {
    this.preferencesName = ''
    this.savePreferenceOpened = false
  }

  async updatePreferences (headings: ITableHeading<any>[]) {
    await modules.tableHeadings.updatePreferences({
      tableName: this.tablePreferenceName,
      tableHeadings: headings
    })
  }

  onPredefinedSettingsChanged (newVal: string) {
    this.predefinedSettings = newVal

    if (newVal) {
      const valueObject = this.predefinedSettingsPersonalization.value

      if (this.personalizationTable) {
        const data = JSON.parse(valueObject)[this.personalizationTable]
        const headings = data[newVal][EPersonalizationSettings.PREFERENCES]
        const settingsFilters = data[newVal][EPersonalizationSettings.FILTERS]

        const filterUpdatePayload: IFilterSettingUpdatePayload =
          { tableName: this.tablePreferenceName, filters: settingsFilters }
        modules.tableHeaderFilters.UPDATE_SETTINGS_TABLE_HEADER_FILTERS(filterUpdatePayload)

        this.updatePreferences(headings)

        this.sortHeadings()
      }
    } else {
      const valueObject = this.predefinedSettingsPersonalization.value

      const data = JSON.parse(valueObject)[this.personalizationTable]

      Object.keys(data).forEach(key => {
        if (data[key].isDefault) {
          this.onPredefinedSettingsChanged(key)
        }
      })
    }
  }

  async onPinPredefinedSetting (value: string) {
    try {
      this.predefinedLoading = true

      const valueObject = this.predefinedSettingsPersonalization.value

      const data = JSON.parse(valueObject)[this.personalizationTable]

      Object.keys(data).forEach(key => {
        if (data[key]) {
          if (key !== value) {
            data[key].isDefault = false
          } else {
            data[value].isDefault = !data[value].isDefault
          }
        }
      })

      await modules.personalization.updatePredefinedSettings({
        type: this.personalizationTable,
        settings: { ...data }
      })

      if (data[value].isDefault) {
        this.onPredefinedSettingsChanged(value)
      }
    } finally {
      this.predefinedLoading = false
    }
  }

  onRemovePredefinedSetting (value: string) {
    if (value) {
      try {
        this.predefinedLoading = true

        const valueObject = this.predefinedSettingsPersonalization.value

        const data = JSON.parse(valueObject)[this.personalizationTable]

        if (Object.keys(data).includes(value)) {
          delete data[value]

          modules.personalization.updatePredefinedSettings({
            type: this.personalizationTable,
            settings: { ...data }
          })

          if (this.predefinedSettings === value) this.predefinedSettings = ''
        }
      } finally {
        this.predefinedLoading = false
      }
    }
  }

  preselectDefaultPreset () {
    const valueObject = this.predefinedSettingsPersonalization

    if (valueObject && valueObject.value) {
      const data = JSON.parse(valueObject.value)[this.personalizationTable]

      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key] && data[key].isDefault) {
            this.onPredefinedSettingsChanged(key)
            this.predefinedSettings = key
          }
        })
      }
    } else {
      this.sortHeadings()
    }
  }

  addFilter (filters: TIndexedObject<IFilter>) {
    // New variable to hold filtered items
    if (this.tableHeaderFilteredItems !== this.items) {
      this.tableHeaderFilteredItems = this.items
    }
    const headerTableNames = Object.keys(filters)
      .filter((nf) => nf.substring(0, nf.indexOf('.')) === this.tablePreferenceName)
      .map((nf) => nf.substring(0, nf.indexOf('.')))

    const headerNames = Object.keys(filters)
      .filter((nf) => nf.substring(0, nf.indexOf('.')) === this.tablePreferenceName)
      .map((nf) => nf.substring(nf.indexOf('.') + 1, nf.length))

    if (headerTableNames.includes(this.tablePreferenceName)) {
      for (let i = 0; i < headerNames.length; i++) {
        const filter: IFilter = filters[`${this.tablePreferenceName}.${headerNames[i]}`]
        switch (filter.type) {
          case 'number':
            this.tableHeaderFilteredItems =
              filterNumberRange(this.tableHeaderFilteredItems, filter.value, headerNames[i], filter.excludeSelected)
            break

          case 'dateTime':
            this.tableHeaderFilteredItems =
              filterDateRange(this.tableHeaderFilteredItems, filter.value, headerNames[i], filter.excludeSelected)
            break

          case 'string':
            this.tableHeaderFilteredItems =
              filterStringRange(this.tableHeaderFilteredItems, filter.value, headerNames[i], filter.excludeSelected)
            break

          case 'boolean':
            this.tableHeaderFilteredItems =
              filterStringRange(this.tableHeaderFilteredItems, filter.value, headerNames[i], filter.excludeSelected)
            break
        }
      }
    }
  }

  headTooltipDisabled (ref: string) {
    if (this.$refs[ref]) {
      const { scrollHeight, clientHeight } = Array.isArray(this.$refs[ref]) && this.$refs[ref].length
        ? this.$refs[ref][0]
        : this.$refs[ref]
      return !(scrollHeight > clientHeight)
    }
  }

  sortHeadings () {
    // to sort in relevant order
    if ((this.tablePreferenceHeadings && this.tablePreferenceHeadings.length > 0) &&
        (this.mergedPreferencesHeadings && this.mergedPreferencesHeadings.length > 0)) {
      const preferencesHeadingsOrder = this.tablePreferenceHeadings.map(h => h.value)
      this.mergedPreferencesHeadings.sort((a: any, b: any) =>
        preferencesHeadingsOrder.indexOf(a.value) - preferencesHeadingsOrder.indexOf(b.value))
    }
  }

  onSearchInput (value: string) {
    this.search = value
    this.resetPagination()
  }

  mapObjectProperty (headingValue: string) {
    return this.items.map((item: any) => item[`${headingValue}`])
  }

  mapFormattedObjectProperty (headingValue: string) {
    if (this.mergedPreferencesHeadings && this.mergedPreferencesHeadings.length > 0) {
      const formatter = this.mergedPreferencesHeadings
        .filter((heading: ITableHeading) => heading.value === headingValue)
        .map((heading: ITableHeading) => heading.formatter)[0]
      if (formatter !== undefined) {
        return this.items.map((item: any) => { return { label: formatter(item), value: item[`${headingValue}`] } })
      } else {
        return []
      }
    }
  }

  // Will add bulk header clearing in the future
  async clearHeaderFilters (tableName: string) {
    modules.tableHeaderFilters.DESTROY_TABLE_HEADER_FILTERS(tableName)
    await modules.personalization.updateFilters({ filters: this.tableHeaderFilters })
    for (let i = 0; i < this.$refs.headerFilter.length; i++) {
      await this.$refs.headerFilter[i].init()
    }
  }

  /* W A T C H E R S */
  @Watch('tableHeaderFilters', { immediate: true })
  filterHeader (newFilters: TIndexedObject<IFilter>) {
    this.addFilter(newFilters)
  }

  @Watch('filteredItems', { immediate: true })
  onItemsChange (newItems: any) {
    if (this.pageCount > 0 && this.currentPage > this.pageCount) {
      this.currentPage = this.pageCount
    }
    this.nonSortedItems = [...newItems]
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  }

  // Once the items load, create the TableHeadings
  // The timing for loading items can't be done in created() or mounted()
  @Watch('items', { immediate: true })
  onItemsLoad (newItems: any) {
    if (newItems.length > 0 && this.tablePreferenceName) {
      const payload: { obj: any; overrides: any[]; tableName: string; useOverridesOnly: boolean } = {
        obj: newItems[0],
        overrides: this.headingsOverride || [],
        tableName: this.tablePreferenceName,
        useOverridesOnly: this.useOverridesOnly || false
      }
      modules.tableHeadings.createTableHeadings(payload)
    }
    this.addFilter(this.tableHeaderFilters)

    this.sortHeadings()
  }

  /* H O O K S */
  created () {
    if (this.actionsIncludeActiveToggle) {
      modules.general.SET_ACTIVE_ONLY_ITEMS(true)
    }
    if (this.tablePreferenceName) {
      this.itemsPerPage = this.tablePreferenceItemsPerPage

      this.sortBy = this.tablePreferenceSorting.sortBy
      this.sortDirection = this.tablePreferenceSorting.sortDirection

      if (this.isPredefined) {
        this.preselectDefaultPreset()
      }
    }
    this.sortHeadings()
  }
}
