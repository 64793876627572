import { parentRoutesNames } from '@/router/parentRouteNames'

const parentName = parentRoutesNames.reports

const reportsRouteNames = {
  imports: 'Import',
  exports: 'Export',
  notes: 'Note',
  celayixShifts: 'celayixShifts',
  employeeItems: 'employeeItems'
}

const reportsRoutes = [
  {
    name: reportsRouteNames.celayixShifts,
    path: 'celayix-shifts',
    meta: {
      parentName,
      group: 'LDH_Nav_Shifts_View',
      label: 'Celayix Shifts'
    },
    component: () => import('@/pages/reports/celayix-shifts/CelayixShifts.vue')
  },
  {
    name: reportsRouteNames.imports,
    path: 'imports',
    meta: {
      parentName,
      group: 'LDH_Nav_Exports_View',
      label: 'Imports'
    },
    component: () => import('@/pages/reports/imports/Imports.vue')
  },
  {
    name: reportsRouteNames.exports,
    path: 'exports',
    meta: {
      parentName,
      group: 'LDH_Nav_Exports_View',
      label: 'Exports'
    },
    component: () => import('@/pages/reports/exports/Exports.vue')
  },
  {
    name: reportsRouteNames.notes,
    path: 'notes',
    meta: {
      parentName,
      group: 'LDH_Nav_Notes_View',
      label: 'Notes'
    },
    component: () => import('@/pages/reports/notes/Notes.vue')
  },
  {
    name: reportsRouteNames.employeeItems,
    path: 'employee-items',
    meta: {
      parentName,
      group: 'LDH_Nav_Employee_Items_View',
      label: 'Employee Items'
    },
    component: () => import('@/pages/reports/employee-items/EmployeeItems.vue')
  }
]

export {
  reportsRouteNames,
  reportsRoutes
}
