const paycheckSidebarRoutesNames = {
  paycheckCreate: 'paycheckCreate',
  paycheckDetails: 'paycheckDetails',
  paycheckEdit: 'paycheckEdit'
}

const paycheckSidebarRoutes = [
  {
    name: paycheckSidebarRoutesNames.paycheckCreate,
    path: '/paycheck-create',
    component: () => import('./PaycheckCreate.vue'),
    meta: { group: 'LDH_Paychecks_Add' }
  },
  {
    name: paycheckSidebarRoutesNames.paycheckDetails,
    path: '/paycheck-:id/details',
    component: () => import('./PaycheckDetails.vue'),
    meta: { group: 'LDH_Paychecks_View' }
  },
  {
    name: paycheckSidebarRoutesNames.paycheckEdit,
    path: '/paycheck-:id/edit',
    component: () => import('./PaycheckEdit.vue'),
    meta: { group: 'LDH_Paychecks_Update' }
  }
]

export {
  paycheckSidebarRoutesNames,
  paycheckSidebarRoutes
}
