var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.fullScreen)?_c('Backdrop',{staticClass:"modal-back-drop",attrs:{"visible":_vm.opened,"position":_vm.modalFixed ? 'fixed' : 'absolute'}}):_vm._e(),_c('transition',{attrs:{"name":_vm.animation}},[(_vm.opened)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"modal-wrapper inset-0 flex",class:{
        'p-4': !_vm.fullScreen && !_vm.modalStick,
        'items-center justify-center': !_vm.modalStick,
        'justify-center': _vm.stickToBottom || _vm.stickToTop,
        'items-end mt-10': _vm.stickToBottom,
        'items-start mb-10': _vm.stickToTop,
        'justify-end': _vm.stickToRight,
        'fixed': _vm.modalFixed,
        'absolute': !_vm.modalFixed,
      },style:({
        zIndex: _vm.zIndex
      })},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.backDropClose ? _vm.closeModal : function () { return false; }),expression:"backDropClose ? closeModal : () => false"}],staticClass:"bg-white flex flex-col max-h-full overflow-hidden",class:[
          _vm.modalClass,
          {
            rounded: !_vm.modalStick,
            'rounded-t-xl w-full': _vm.stickToBottom,
            'rounded-b-xl w-full': _vm.stickToTop,
            'h-full w-full': _vm.fullScreen
          }
        ],style:({
          maxWidth: _vm.modalBoxMaxWidth,
          minWidth: _vm.modalBoxMinWidth,
          width: _vm.modalBoxWidth
        })},[(_vm.showHeader)?_c('div',{staticClass:"flex-shrink-0 transition-3ms",class:{ shadow: _vm.showModalHeaderShadow },attrs:{"data-test":"modal-header"}},[_c('div',{staticClass:"modal-header text-18 font-bold tabletAndUp:p-5 p-4",class:{ 'border-b border-basic-400': _vm.headerBorder }},[_c('div',{staticClass:"flex items-center"},[(_vm.closeIcon)?_c('Icon',{staticClass:"icon-close mr-5",attrs:{"size":"12","disabled":_vm.closeIconDisabled},on:{"click":_vm.closeModal}}):_vm._e(),_vm._t("header",function(){return [_c('span',{staticClass:"font-bold text-18"},[_vm._v(_vm._s(_vm.title))])]})],2)]),_vm._t("header-content")],2):_vm._e(),_c('div',{staticClass:"modal-body flex-grow overflow-auto scrolling-touch max-h-full",class:!!_vm.bodyClass ? _vm.bodyClass : 'p-4'},[_vm._t("default")],2),(_vm.showFooter)?_c('div',{staticClass:"flex-shrink-0",class:[_vm.footerClass]},[_vm._t("footer")],2):_vm._e()])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }