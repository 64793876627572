/*eslint-disable*/
import app from '@/main'
import { Route } from 'vue-router'
import { activeDirectoryService, authService } from '@/services'
import { modules } from '@/store'
import { INavigationTypes } from '@/types'
import { mountSidebar } from '@/sidebar/sidebar-app'
// import { startSignalRConnection } from '@/plugins/signal-r.plugin'
import { sidebarRouter } from '@/sidebar/router'
import { authRoutesNames } from '@/pages/auth/auth.routes'
import { employeesSidebarRoutesNames } from '@/pages/human-resources/employees/sidebar/employees.sidebar.routes'
import { dashboardRouteNames } from '@/pages/dashboard/dashboard.routes'

const checkProfileGroup = (next: Function) => {
  if (authService.isInGroup('LDH_Me')) {
    next({
      name: employeesSidebarRoutesNames.userProfile,
      params: {
        id: String(modules.user.userProfile.id)
      }
    })
  } else {
    next({ name: authRoutesNames.accessDenied })
  }
}

const goToFirstExistingRoute = (next: Function) => {
  if (modules.user.userNavigation.length > 0) {
    const hasChildren = (modules.user.userNavigation.find(nav => nav.children.length > 0) as INavigationTypes)
    if (hasChildren) {
      next({ name: hasChildren.children[0].name })
    } else {
      checkProfileGroup(next)
    }
  } else {
    checkProfileGroup(next)
  }
}

const goToChildRoute = (parentName: string, next: Function) => {
  const navExists = modules.user.indexedUserNavigation[parentName]
  if (navExists) {
    const hasChildren = navExists.children.length > 0
    if (hasChildren) {
      next({ name: modules.user.indexedUserNavigation[parentName].children[0].name })
    } else {
      goToFirstExistingRoute(next)
    }
  } else {
    goToFirstExistingRoute(next)
  }
}

async function getNotifications () {
  const notifications = (await activeDirectoryService.getNotifications()).notifications
  modules.notifications.SET_NOTIFICATIONS_CHANNELS(notifications.alertChannels)
  modules.notifications.SET_NOTIFICATIONS_TYPES(notifications.alertTypes)
  modules.notifications.SET_NOTIFICATIONS_QUEUE(notifications.alertQueue)
}

async function updateSecurityGroups (securityGroups: string[]) {
  try {
    const existingGroups = modules.user.groups
    const userId = String(modules.user.userProfile.id)

    app.$notify.success({
      title: 'Success',
      duration: 5000,
      message: 'Security groups update succeeded.',
      position: 'top-right'
    })

    if (securityGroups.length !== existingGroups.length) {
      await (app.$children[0].$children[1].$refs.updatedGroupsModal as any).show()
      modules.user.SET_GROUPS({ groups: securityGroups, setLocalStorage: { userId } })
    }
  } catch (e) {
    app.$notify.success({
      title: 'Error',
      duration: 5000,
      message: 'Security groups update failed.',
      position: 'top-right'
    })
  }
}

function initApp (securityGroups: string[]) {
  modules.general.fetchVersion()
  getNotifications()
  updateSecurityGroups(securityGroups)
  // startSignalRConnection()
  mountSidebar()
}

let initializing = false
const authorizeUser = async () => {
  if (initializing) return
  try {
    initializing = true
    modules.general.SET_LOADING(true)
    modules.general.SET_LOADING_TEXT('Loading Profile Information')
    const { employee: user, securityGroups } = await modules.user.getUserProfile()
    modules.user.getUserPicture(user.id)

    const userId = String(user.id)
    const savedGroups = JSON.parse(localStorage.getItem(authService.securityGroupsStorageName) || '{}')
    const groups = savedGroups[userId] as string[]

    if (!groups || !groups.includes("LDH_Me")) {
      modules.general.SET_LOADING_TEXT("Looks like you are logging in for the first time. Updating your permissions, please wait...")
      modules.user.SET_GROUPS({ groups: securityGroups, setLocalStorage: { userId } })
    } else {
      modules.general.SET_LOADING_TEXT('Loading Personalizations')
      modules.user.SET_GROUPS({ groups })
    }

    await modules.personalization.fetch()
    await modules.personalization.applyPersonalizations()

    modules.general.SET_LOADING(false)
    
    // to render layout
    setTimeout(() => initApp(securityGroups), 200)
  } finally {
    initializing = false
    modules.general.SET_LOADING(false)
  }
}

export const routeGuard = async (to: Route, from: Route, next: Function) => {
  const isAuthenticated = await authService?.isAuthenticated()

  if (isAuthenticated) {
    modules.general.SET_HEADER_SHADOW(0)
    if (!modules.user.userProfileLoaded) {
      await authorizeUser()
    }
    if (to.name === authRoutesNames.login) {
      next({ name: dashboardRouteNames.dashboard })
    } else if (
      to.meta &&
      to.meta.group &&
      !authService.isInGroup(to.meta.group) &&
      to.name !== authRoutesNames.accessDenied &&
      to.name !== authRoutesNames['404']
    ) {
      next({ name: authRoutesNames.accessDenied })
    } else if (to.meta && to.meta.isParent) {
      goToChildRoute(to.name as string, next)
    } else {
      next()
    }
  } else {
    if (to.matched.some(record => record.meta.requireAuth)) {
      localStorage.setItem(authService.aegisRedirectionUrl, window.location.href)
      await sidebarRouter.push('/')
      history.replaceState(null, document.title, ' ')
      next({ name: authRoutesNames.login })
    } else {
      next()
    }
  }
}
