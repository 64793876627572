const guardTermsSidebarRoutesNames = {
  guardTermDetails: 'guardTermDetails'
}

const guardTermsSidebarRoutes = [
  {
    name: guardTermsSidebarRoutesNames.guardTermDetails,
    path: '/guard-term-:termId/details',
    meta: { width: '700px', group: 'LDH_GuardTerm_View' },
    component: () => import('./GuardTermDetails.vue')
  }
]

export {
  guardTermsSidebarRoutesNames,
  guardTermsSidebarRoutes
}
