import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { jobsService } from '@/services'
import { IJob } from '@/types'

@Module({ name: 'JobsModule', namespaced: true })
export default class JobsModule extends VuexModule {
  loading = false
  jobs: readonly IJob[] = []

  /* M U T A T I O N S */
  @Mutation
  SET_LOADING (payload = false) {
    this.loading = payload
  }

  @Mutation
  SET_JOBS (payload: readonly IJob[]) {
    this.jobs = payload
  }

  /* A C T I O N S */
  @Action({ commit: 'SET_JOBS', rawError: true })
  async getJobs (allActive?: boolean) {
    return await jobsService.getJobs(allActive)
  }
}
