import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { publicDocumentsService } from '@/services'
import { IPublicDocument } from '@/types'

@Module({ name: 'PublicDocumentsModule', namespaced: true })
export default class PublicDocumentsModule extends VuexModule {
  publicDocuments: IPublicDocument[] = []
  publicDocumentsLoading = false

  // M U T A T I O N S
  @Mutation
  SET_PUBLIC_DOCUMENTS (payload: IPublicDocument[]) {
    this.publicDocuments = payload
  }

  @Mutation
  SET_PUBLIC_DOCUMENTS_LOADING (payload: boolean) {
    this.publicDocumentsLoading = payload
  }

  // A C T I O N S
  @Action({ commit: 'SET_PUBLIC_DOCUMENTS', rawError: true })
  async getPublicDocuments () {
    return await publicDocumentsService.getPublicDocuments()
  }
}
