import { Component, Vue } from 'vue-property-decorator'

@Component
export class ConfirmMixin extends Vue {
  private opened = false
  private resolvePromise: Function | null = null
  private rejectPromise: Function | null = null

  INIT_CONFIRM_PROMISE () {
    this.opened = true
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve
      this.rejectPromise = reject
    })
  }

  async RESOLVE_CONFIRM (...args: any[]) {
    this.opened = false
    if (this.resolvePromise) {
      this.resolvePromise(...args)
      this.CLOSE_CONFIRM()
    }
  }

  CLOSE_CONFIRM (...args: any[]) {
    if (this.rejectPromise) {
      this.rejectPromise(...args)
    }
    this.opened = false
    this.resolvePromise = null
    this.rejectPromise = null
  }
}
