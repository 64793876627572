import { ICollectionConfig } from '@/types'
import { collectionNames } from '../collectionNames'
import { naming } from './collections-general.config'

export const collectionsHistorical: ICollectionConfig[] = [
  {
    label: 'History Field',
    group: 'HistoricalTableFieldType',
    getEndpoint: '/HistoricalTableFieldType/Get',
    saveEndpoint: '/HistoricalTableFieldType/Save',
    updateEndpoint: '/HistoricalTableFieldType/Update',
    toggleEndpoint: '/HistoricalTableFieldType/Toggle',
    collectionName: collectionNames.historicalTableFieldTypes,
    headings: [
      { label: 'Schema Name', value: 'schemaName', sortable: true, minWidth: 200 },
      { label: 'Table Name', value: 'tableName', sortable: true, minWidth: 200 },
      { label: 'Field Name', value: 'fieldName', sortable: true, minWidth: 200 },
      { label: 'Security Group Name', value: 'securityGroupName', sortable: true, minWidth: 200 }
    ],
    form: [{
      component: 'SelectModule',
      model: 'schemaName',
      options: 'historicalShemaNames',
      required: true,
      props: {
        optionKey: 'schemaName',
        valueKey: 'schemaName',
        label: 'Schema Name',
        placeholder: 'Select Schema',
        class: 'w-full'
      }
    }, {
      component: 'SelectModule',
      model: 'tableName',
      options: 'historyFieldTables',
      required: true,
      props: {
        optionKey: 'tableName',
        valueKey: 'tableName',
        label: 'Table Name',
        placeholder: 'Select name',
        class: 'w-full mt-4'
      }
    }, {
      component: 'SelectModule',
      model: 'fieldName',
      options: 'historyFieldNames',
      required: true,
      props: {
        optionKey: 'columnName',
        valueKey: 'columnName',
        label: 'Field Name',
        placeholder: 'Field name',
        class: 'w-full my-4'
      }
    }, ...naming, {
      component: 'InputModule',
      model: 'securityGroupName',
      required: true,
      props: { label: 'Security Group Name', placeholder: 'Name', class: 'w-full mt-4' }
    }]
  },

  {
    label: 'History Group',
    group: 'HistoricalTableGroupType',
    getEndpoint: '/HistoricalTableGroupType/Get',
    saveEndpoint: '/HistoricalTableGroupType/Save',
    updateEndpoint: '/HistoricalTableGroupType/Update',
    toggleEndpoint: '/HistoricalTableGroupType/Toggle',
    formWrapClass: 'h-full flex-no-wrap flex-col',
    collectionName: collectionNames.historicalTableGroupTypes,
    form: [...naming, {
      component: 'SelectModule',
      model: 'parentSchemaName',
      options: 'historySchemas',
      required: true,
      props: { optionKey: 'schemaName', valueKey: 'schemaName', label: 'Parent Schema Name', placeholder: 'Select name', class: 'w-full mt-4' }
    }, {
      component: 'SelectModule',
      model: 'parentTableName',
      options: 'historyTables',
      required: true,
      props: { optionKey: 'tableName', valueKey: 'tableName', label: 'Parent Table Name', placeholder: 'Select name', class: 'w-full mt-4' }
    }, {
      component: 'InputModule',
      model: 'parentIdFieldName',
      disabled: true,
      props: { label: 'Parent Id Field Name', placeholder: 'ID', class: 'w-full mt-4' }
    }, {
      component: 'InputModule',
      model: 'childIdFieldName',
      props: { label: 'Child Id Field Name', placeholder: 'ID', class: 'w-full mt-4' }
    }]
  },

  {
    label: 'History Field to History Group',
    group: 'HistoricalTableGroupType',
    getEndpoint: '/HistoricalTableFieldTypeToGroupType/GetAllFromView',
    saveEndpoint: '/HistoricalTableFieldTypeToGroupType/Save',
    updateEndpoint: '/HistoricalTableFieldTypeToGroupType/Update',
    toggleEndpoint: '/HistoricalTableFieldTypeToGroupType/Toggle',
    formWrapClass: 'h-full flex-no-wrap flex-col',
    collectionName: collectionNames.historicalTableFieldTypeToGroupTypes,
    noName: true,
    headings: [
      { label: 'Group Name', value: 'groupName', sortable: true, minWidth: 180 },
      { label: 'Field Name', value: 'fieldName', sortable: true, minWidth: 180 },
      { label: 'Database Table', value: 'dbtable', sortable: true, minWidth: 180 },
      { label: 'Database Schema', value: 'dbschema', sortable: true, minWidth: 150 },
      { label: 'Database Field', value: 'dbfield', sortable: true, minWidth: 150 }
    ],
    form: [
      {
        component: 'SelectModule',
        model: 'groupTypeId',
        options: 'historyGroups',
        required: true,
        props: {
          optionKey: 'displayName',
          valueKey: 'id',
          label: 'Group',
          placeholder: 'Select group',
          class: 'w-full'
        }
      },
      {
        component: 'SelectModule',
        model: 'fieldTypeId',
        options: 'historyFields',
        required: true,
        props: {
          optionKey: 'fieldName',
          valueKey: 'id',
          label: 'Field',
          placeholder: 'Select field',
          class: 'w-full mt-4'
        }
      }
    ]
  }
]
