import { httpService } from '@/services'
import {
  ITextingConversation,
  ITextingMember,
  ITextingMessage,
  ISaveTextingConversation,
  IRenameTextingConversation,
  ITextingConversationEmployee,
  ISaveTextingMessage
} from '@/types'

class MessagesService {
  /* T E X T I N G   C O N V E R S A T I O N */
  getAllTextingConversationsForManager (): Promise<ITextingConversation[]> {
    return httpService.get('/TextingConversation/GetAllForManager')
  }

  saveTextingConversation (payment: ISaveTextingConversation): Promise<''> {
    return httpService.post('/TextingConversation/Save', payment)
  }

  renameTextingConversation (payment: IRenameTextingConversation): Promise<''> {
    return httpService.post('/TextingConversation/Rename', payment)
  }

  /* T E X T I N G   C O N V E R S A T I O N   E M P L O Y E E */
  getTextingConversations (): Promise<ITextingConversation[]> {
    return httpService.get('/TextingConversationEmployee/GetForCurrentEmployee')
  }

  getMembersByConversationId (id: string): Promise<ITextingMember[]> {
    return httpService.get('/TextingConversationEmployee/GetMembersByConversationId', { params: { id } })
  }

  saveTextingConversationEmployee (payment: ITextingConversationEmployee): Promise<''> {
    return httpService.post('/TextingConversationEmployee/Save', payment)
  }

  toggleTextingConversationEmployee (payment: ITextingConversationEmployee): Promise<''> {
    return httpService.post('/TextingConversationEmployee/Toggle', payment)
  }

  toggleIsReadTextingConversationEmployee (payment: ITextingConversationEmployee): Promise<''> {
    return httpService.post('/TextingConversationEmployee/ToggleIsRead', payment)
  }

  /* T E X T I N G   M E S S A G E */
  getTextingMessageByConversationId (conversationId: number): Promise<ITextingMessage[]> {
    return httpService.get('/TextingMessage/GetByConversationId', { params: { conversationId } })
  }

  getAllTextingMessageForEmployee (employeeId: number): Promise<ITextingMessage[]> {
    return httpService.get('/TextingMessage/GetAllForEmployee', { params: { employeeId } })
  }

  sendTextingMessage (payment: ISaveTextingMessage): Promise<''> {
    return httpService.post('/TextingMessage/Send', payment)
  }

  /* T E X T I N G   M E S S A G E   F I L E */
  getTextingMessageFileByFileId (fileId: string): Promise<any> {
    return httpService.get('/TextingMessageFile/GetByFileId', { params: { fileId } })
  }
}

export const messagesService = new MessagesService()
