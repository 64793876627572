








import { Component, Prop, Vue } from 'vue-property-decorator'
import { TColors, TSizes } from '@/types'

@Component({
  name: 'CircleMarker'
})
export default class CircleMarker extends Vue {
  @Prop() color!: TColors
  @Prop({ default: 3 }) size!: TSizes
  @Prop() explicitColor?: string
}
