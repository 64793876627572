












import { Component, Vue, Watch } from 'vue-property-decorator'
import { modules } from '@/store'

import LoadUpdatesModal from '@/components/LoadUpdatesModal.vue'

@Component({
  name: 'App',
  components: { LoadUpdatesModal },
  metaInfo (this: any) {
    return { title: this.pageTitle, titleTemplate: 'Aegis %s' }
  }
})
export default class App extends Vue {
  /* R E F S */
  $refs!: {
    loadUpdatesModal: LoadUpdatesModal & { show: () => Promise<void> };
  }

  /* D A T A */
  loading = false

  /* C O M P U T E D */
  get appLoading () {
    return modules.general.loading
  }

  get loadingText () {
    return modules.general.loadingText
  }

  get pageTitle () {
    const meta = this.$route.meta

    if (meta) {
      const label = meta.label ? `- ${meta.label}` : ''
      const title = modules.general.pageTitle

      return `${label} ${title}`
    }
  }

  /* M E T H O D S */
  initLoader () {
    const appPreloader = document.querySelector<HTMLElement>('.el-loading-mask.is-fullscreen')
    if (appPreloader) {
      appPreloader.style.zIndex = '2006'
      appPreloader.style.backgroundColor = 'white'
      setTimeout(() => {
        const loadingSpinner = appPreloader.querySelector<HTMLElement>('.el-loading-spinner')

        if (!document.getElementById('refresh-btn')) {
          const refreshBtn = document.createElement('p')
          refreshBtn.id = 'refresh-btn'
          refreshBtn.classList.add('cursor-pointer', 'text-primary', 'leading-16', 'text-14', 'font-bold')
          refreshBtn.innerText = 'Refresh'
          refreshBtn.onclick = window.location.reload.bind(window.location)

          if (loadingSpinner) {
            loadingSpinner.insertAdjacentElement('beforeend', refreshBtn)
          }
        }
      }, 30000)
    }
  }

  /* W A T C H E R S */
  @Watch('appLoading', { immediate: true })
  onAppLoadingChanged (newVal: boolean) {
    if (newVal) {
      this.loading = true
      setTimeout(() => { this.initLoader() }, 0)
    } else {
      setTimeout(() => { this.loading = false }, 500)
    }
  }
}
